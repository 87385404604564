import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import mapValues from 'lodash/mapValues';
import memoize from 'memoize-one';

import CRUDManager from '../common/CRUDManager';
import stopsActions from '../../actions/stops';

const fields = [
  {
    // See wrapStopsInRecord function below
    key: '$$ROOT',
    sortingKey: 'name',
    label: 'Nom',
    type: 'PLACE',
    showMap: true,
    reverseGeocode: false,
    disableCustomPlaces: true,
    constraints: {
      presence: true,
    },
  },
];
const resourceLabel = 'arrêt';

const style = { height: 'calc(100% - 80px)' };

// We have to do this because we want to give the whole stop as a PLACE field
// to the CRUD manager, and the CRUD manager currently has no way of acting
// directly on the root object, only on subfields
const wrapStopsInRecord = memoize(stops =>
  mapValues(stops, stop => ({ id: stop.id, $$ROOT: stop })),
);

const mapStateToProps = state => ({
  recordKey: 'id',
  records: wrapStopsInRecord(state.stops),
  resourceLabel,
  fields,
  style,
  ...state.stopsMeta,
  canImport: true,
});

const unwrapStopFromRecord = record => ({ ...record.$$ROOT, id: record.id });

const mapDispatchToProps = dispatch => ({
  fetchStart: compose(dispatch, stopsActions.fetchStart),
  createStart: compose(
    dispatch,
    stopsActions.createStart,
    unwrapStopFromRecord,
  ),
  updateStart: compose(
    dispatch,
    stopsActions.updateStart,
    unwrapStopFromRecord,
  ),
  deleteStart: compose(
    dispatch,
    stopsActions.deleteStart,
    unwrapStopFromRecord,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDManager);

import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import groupsActionCreators, { actionTypes } from '../../actions/groups';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const groupsSaga =
  decodedToken && decodedToken.company
    ? makeCrudSaga(
        'groups',
        actionTypes,
        groupsActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL +
          `/auth/companies/${decodedToken.company.id}/groups`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
        },
      )
    : () => {};

export default groupsSaga;

import moment from 'moment';
import last from 'lodash/last';

import getTripPath from './getTripPath';

async function computeTripStopDates(trip) {
  if (!trip.from || !trip.to) {
    return;
  }
  const { decoded_polyline: path, legs } = await getTripPath({
    departureTime: trip.departureTime,
    from: trip.from,
    to: trip.to,
    stops: trip.stops,
  });
  const allStops = [trip.from, ...(trip.stops || []), trip.to];
  const allStopsWithMaxDate = allStops.map((stop, stopIndex) => {
    const legsUntilStop = legs.slice(0, stopIndex);
    return {
      ...stop,
      maxDate: moment(trip.departureTime)
        .add(
          legsUntilStop.reduce(
            (totalTravelTime, leg) =>
              totalTravelTime + (leg.duration_in_traffic || leg.duration).value,
            0,
          ),
          'seconds',
        )
        .toDate(),
    };
  });

  return {
    ...trip,
    from: allStopsWithMaxDate[0],
    to: last(allStopsWithMaxDate),
    stops: allStopsWithMaxDate.slice(1, allStopsWithMaxDate.length - 1),
    path,
    legs,
  };
}

export default computeTripStopDates;

import React, { memo } from 'react';
import moment from 'moment';
import { Marker, InfoWindow } from 'react-google-maps';
import { TimeInput } from 'semantic-ui-calendar-react';
import { Form } from 'semantic-ui-react';

import PlacesAutocompleteInput from '../common/PlacesAutocompleteInput';

const markerIconPath = `M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5
 c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021
 C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333
 s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z`;

const TravelRequirementStopMarker = memo(
  ({
    label,
    stop,
    readonly,
    minTime,
    maxTime,
    color,
    onChange,
    onSelect,
    onDismiss,
  }) => {
    const updateStop = updates => {
      onChange({
        ...stop,
        ...updates,
      });
    };

    const dateToTimeString = date => {
      if (!date) {
        return '';
      }
      return moment(date).format('HH:mm');
    };

    const timeStringToDate = string => {
      if (!string) {
        return null;
      }
      return moment(string, 'HH:mm').toDate();
    };

    return (
      <Marker
        position={stop.coords}
        draggable={true}
        onClick={onSelect}
        onDragEnd={event => {
          updateStop({ coords: event.latLng.toJSON() });
        }}
        options={{}}
        icon={{
          fillColor: color || 'green',
          fillOpacity: 1,
          path: markerIconPath,
          strokeColor: 'white',
          strokeWeight: 2,
          strokeOpacity: 1,
          scale: 0.075,
          anchor: { x: 255, y: 470 },
        }}
      >
        {!readonly && (
          <InfoWindow onCloseClick={onDismiss}>
            <Form>
              <Form.Field>
                <span>{label}</span>
                <PlacesAutocompleteInput
                  place={stop}
                  onChange={place => updateStop(place)}
                />
                <TimeInput
                  value={dateToTimeString(stop.time)}
                  onChange={(event, { value }) => {
                    const time = timeStringToDate(value);
                    if (minTime && time < minTime) {
                      alert(
                        'Doit être supérieur ou égal à ' +
                          moment(minTime).format('HH:mm'),
                      );
                      return;
                    }
                    if (maxTime && time > maxTime) {
                      alert(
                        'Doit être inférieur ou égal à ' +
                          moment(maxTime).format('HH:mm'),
                      );
                      return;
                    }
                    updateStop({ time });
                  }}
                  disabled={readonly}
                  closable={true}
                  clearable={true}
                />
              </Form.Field>
            </Form>
          </InfoWindow>
        )}
      </Marker>
    );
  },
);

export default TravelRequirementStopMarker;

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import TripsTimelineView from './TripsTimelineView';
import tripActions from '../../../actions/trip';
import demandsActions from '../../../actions/demands';
import alertActions from '../../../actions/alert';

const mapStateToProps = (state, ownProps) => ({
  editing: state.trip.editing,
  deleting: state.trip.deleting,
  error: state.trip.error,
  focusedTrips: state.dashboard.focusedTrips,
  loading: state.trip.loading || ownProps.loading,
});

const mapDispatchToProps = dispatch => ({
  clearTrips: compose(dispatch, tripActions.clearTrips),
  editTrips: compose(dispatch, tripActions.editTrips),
  deleteTrip: compose(dispatch, tripActions.deleteTrip),
  createDemand: compose(dispatch, demandsActions.createStart),
  requestTripsInPeriod: compose(dispatch, tripActions.requestTripsInPeriod),
  showAlert: compose(dispatch, alertActions.show),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripsTimelineView);

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Image, Segment } from 'semantic-ui-react';
import { ToastsContainer, ToastsStore } from 'react-toasts';

import client from '../../providers/weego-client';
import bus from '../../assets/img/bus.svg';
import city from '../../assets/img/city.svg';
import AuthText from '../../components/auth/AuthText';
import AuthForm from '../../components/auth/AuthForm';
import AuthConfirm from '../../components/auth/AuthConfirm';
import weegoFbLogo from '../../assets/images/weego-fb-logo@3x.png';
import colors from '../../theme/colors';
import StepIndicator from '../../components/common/StepIndicator';

const steps = [
  { label: '', step: '1' },
  { label: '', step: '2' },
];

class Auth extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      phoneSending: false,
      validationNumber: '',
      focusedStep: 0,
    };
  }

  async componentDidMount() {
    if (await client.auth.isLoggedIn()) {
      // the default was /pro/dashboard but we need to hide it to vectalia
      this.props.history.replace('/pro');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isLoading } = this.props;
    if (isLoading && nextProps.isLoading === false) {
      if (nextProps.error) {
        ToastsStore.error('Erreur lors de la connexion');
      } else {
        window.location.reload();
      }
    }
  }

  connect = receiving => {
    const { phone } = this.state;
    const { login } = this.props;
    try {
      if (receiving) {
        login(phone);
        this.setState({
          phoneSending: true,
        });
      }
      this.setState({ focusedStep: 1 });
    } catch (e) {
      ToastsStore.error(e.message);
    }
  };

  validation = async receiveValidation => {
    const { validationNumber } = this.state;
    const num = await client.auth.verify(validationNumber);
    if (num) {
      // the default was /pro/dashboard but we need to hide it to vectalia
      window.location.replace('/pro');
    } else {
      ToastsStore.error('Code invalide');
    }
  };

  setValidation = numberValidation => {
    this.setState({
      validationNumber: numberValidation,
    });
  };

  onSetNumber = value => {
    this.setState({
      phone: value,
    });
  };
  render() {
    const { phoneSending, focusedStep } = this.state;
    return (
      <div style={styles.container}>
        <div style={styles.oval}>
          <Segment style={styles.form} basic>
            <AuthText />
            <div style={styles.stepIndicator}>
              <StepIndicator steps={steps} focusedStep={focusedStep} />
            </div>
            {phoneSending ? (
              <AuthConfirm
                getNumberValidation={this.setValidation.bind(this)}
                confirmation={this.validation.bind(this)}
              />
            ) : (
              <AuthForm
                onPhoneChange={this.onSetNumber.bind(this)}
                onSubmit={this.connect.bind(this)}
              />
            )}
          </Segment>
        </div>
        <div style={styles.rightContainer}>
          <div style={styles.logoContainer}>
            <Image style={styles.logo} src={weegoFbLogo} />
          </div>
          <div>
            <Image style={styles.bus} src={bus} />
          </div>
          <div style={styles.cityContainer}>
            <Image style={styles.city} src={city} />
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    background:
      '-webkit-radial-gradient(35.39% 29.87%, ellipse farthest-corner, rgba(252, 195, 201, 1) 30.84%, rgba(200, 151, 247, 1) 100%)',
    height: '100%',
  },
  rightContainer: {
    display: 'flex',
    flex: 0.6,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  oval: {
    display: 'flex',
    flex: 0.4,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
    paddingLeft: 30,
    paddingRight: 30,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    zIndex: 10,
    height: 1000,
    alignSelf: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: 50,
    paddingRight: 50,
  },
  logo: {
    width: 500,
    zIndex: 10,
  },
  bus: {
    width: 1000,
    marginLeft: -80,
    zIndex: 10,
  },
  city: {
    width: 850,
  },
  stepIndicator: { alignSelf: 'center', marginTop: 50, marginBottom: 50 },
  logoContainer: { position: 'absolute', top: 50, right: 90 },
  cityContainer: { position: 'absolute', bottom: 0, right: 0 },
};

export default withRouter(Auth);

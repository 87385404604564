import { takeLatest, all, put, takeEvery, select } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';

import weegoClient from '../../providers/weego-client';
import tripActions from '../../actions/trip';

function* requestTrips({ payload }) {
  try {
    const response = yield weegoClient.rideBooking.getTrips(payload.filter);
    yield put(tripActions.updateTrips(response));
    yield put(tripActions.requestTripsSuccess());
  } catch (e) {
    console.error(e);
    alert('Erreur lors de la récupération des voyages');
    yield put(tripActions.requestTripsError(e));
  }
}

function* requestTripsInPeriod({ payload }) {
  try {
    const response = yield weegoClient.rideBooking.getTripsInPeriod(
      payload,
      payload.filter,
      payload.overrideFilter,
    );
    const trips = yield select(state => state.trip.trips);
    if (!isEqual(trips, response.splitTrips)) {
      yield put(tripActions.updateTrips(response.splitTrips));
    }
    yield put(tripActions.requestTripsInPeriodSuccess());
  } catch (e) {
    console.error(e);
    alert('Erreur lors de la récupération des voyages');
    yield put(tripActions.requestTripsInPeriodError(e));
  }
}
function* createTrip({ payload }) {
  try {
    const trip = yield weegoClient.rideBooking.create('Trips', payload.trip);
    yield put(tripActions.createTripSuccess(trip));
    if (!payload.silent) {
      yield alert('Ajout du voyage réussi');
    }
  } catch (e) {
    yield alert("Erreur lors de l'ajout du voyage");
    yield put(tripActions.createTripError(e));
  }
}

function* deleteTrip({ payload: id }) {
  try {
    yield weegoClient.rideBooking.delete('Trips', id);
    yield put(tripActions.deleteTripSuccess(id));

    yield alert('Suppression du voyage réussie');
  } catch (e) {
    yield put(tripActions.deleteTripError(e));
    yield alert('Erreur lors de la suppression du voyage');
  }
}

function* deleteManyTrips({ payload: { where } }) {
  try {
    const { count } = yield weegoClient.rideBooking.deleteMany('Trips', {
      where,
    });
    yield put(tripActions.deleteManyTripsSuccess(count, where));
  } catch (e) {
    yield put(tripActions.deleteManyTripsError(e));
  }
}

function* editTrip({ payload }) {
  try {
    const updatedTrip = yield weegoClient.rideBooking.update(
      'Trips',
      payload.trip,
    );
    if (payload.trip.demands) {
      updatedTrip.demands = payload.trip.demands;
    }
    yield put(tripActions.editTripSuccess(updatedTrip));
    if (payload.silent) {
      return;
    }
    yield alert('Modification du voyage réussie');
  } catch (e) {
    console.error(e);
    yield put(tripActions.editTripError(e));
    if (payload.silent) {
      return;
    }
    alert('Erreur lors de la modification du voyage');
  }
}

function* editTrips({ payload }) {
  yield all(
    payload.trips.map(trip => editTrip({ payload: { trip, silent: true } })),
  );
  yield alert('Modification des voyages réussie');
}

function* editManyTrips({ payload: { where, updates } }) {
  try {
    const { count } = yield weegoClient.rideBooking.updateMany('Trips', {
      where,
      updates,
    });
    yield put(tripActions.editManyTripsSuccess(count, where, updates));
  } catch (e) {
    yield put(tripActions.editManyTripsError(e));
  }
}

function* importTrips({ payload: { trips, folder } }) {
  try {
    yield weegoClient.rideBooking.deleteMany('Trips', {
      where: { folder },
    });
    const tripsWithFolder = trips.map(trip => ({ ...trip, folder }));
    const createdTrips = yield all(
      tripsWithFolder.map(trip =>
        weegoClient.rideBooking.create('Trips', trip),
      ),
    );
    yield put(tripActions.importTripsSuccess(createdTrips));
  } catch (e) {
    yield put(tripActions.importTripsError(e));
  }
}

function* submitTripReport({ payload: tripReport }) {
  try {
    const newTripReport = yield weegoClient.rideBooking.createTripReport(
      tripReport,
    );
    yield put(tripActions.submitTripReportSuccess(newTripReport));
  } catch (e) {
    yield put(tripActions.submitTripReportError(e));
    yield alert('Erreur lors de la soumission du rapport');
  }
}

function* removeTripReport({ payload: tripReport }) {
  try {
    yield weegoClient.rideBooking.removeTripReport(tripReport);
    yield put(tripActions.removeTripReportSuccess(tripReport));
  } catch (e) {
    yield put(tripActions.removeTripReportError(e));
    yield alert('Erreur lors de la suppression du rapport');
  }
}

function* tripRootSaga() {
  yield all([
    takeLatest(tripActions.requestTrips.toString(), requestTrips),
    takeEvery(tripActions.createTrip.toString(), createTrip),
    takeEvery(tripActions.deleteTrip.toString(), deleteTrip),
    takeEvery(tripActions.deleteManyTrips.toString(), deleteManyTrips),
    takeEvery(tripActions.importTrips.toString(), importTrips),
    takeLatest(
      tripActions.requestTripsInPeriod.toString(),
      requestTripsInPeriod,
    ),
    takeEvery(tripActions.editTrip.toString(), editTrip),
    takeEvery(tripActions.editTrips.toString(), editTrips),
    takeEvery(tripActions.editManyTrips.toString(), editManyTrips),
    takeEvery(tripActions.submitTripReport.toString(), submitTripReport),
    takeEvery(tripActions.removeTripReport.toString(), removeTripReport),
  ]);
}

export default tripRootSaga;

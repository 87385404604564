import { createActions } from 'redux-actions';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

const { dashboard } = createActions({
  DASHBOARD: {
    FOCUS_TRIPS: (trips, disableVehicleAutofocus) => ({
      trips,
      disableVehicleAutofocus,
    }),
    UNFOCUS_TRIPS: () => null,
    UPDATE_FOCUSED_TRIPS_PATHS: paths => ({
      tripsWithPaths: keyBy(
        mapValues(paths, (path, tripId) => ({ tripId, path })),
        'tripId',
      ),
    }),
  },
});

export default dashboard;

import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class SponsoringClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @memberof SponsoringClient
   */
  constructor(endpoint, { tokens }) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/sponsoring`
    });
  }

  async getMyInvitationCode() {
    const response = await this.api.get(
      '/invitations/invitation-code',
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get('ACCOUNT_VERIFICATION')}`
        }
      }
    );
    handleResponseError(response);
    return response.data;
  }

  async logRegistration(throughCode) {
    const response = await this.api.post(
      `/invitations/log-registration`,
      { throughCode },
      {
        headers: {
          Authorization: `Bearer ${this.tokens.get('ACCOUNT_VERIFICATION')}`
        }
      }
    );
    handleResponseError(response);
    return response.data.registration;
  }

  async getRanking(n = 5) {
    const response = await this.api.get(`/ranking/top/${n}`);
    handleResponseError(response);
    return response.data.ranking;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/sponsoring`);
  }
}

export default SponsoringClient;

function stopLabel(stop) {
  if (!stop || !stop.name) {
    return '';
  }
  const parts = stop.name.split(',').slice();
  if (parts.length < 3) {
    return parts.join(', ');
  }
  return parts.slice(0, -2).join(', ');
}

export default stopLabel;

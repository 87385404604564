import {create} from 'apisauce';
import omit from 'lodash/omit';

import handleResponseError from '../utils/handleResponseError';

class RideBookingLCient {
  /**
   * create an instance of client
   * @param {String} endpoint-url for sunubus service
   * @param {Object} options- options
   * @memberof RideBookingClient
   */

  constructor(endpoint, {tokens}) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/ride-booking`,
    });
  }

  /**
   * Get A trip rating
   * @tripId Identifiant du voyage
   * @startDate Date de début
   * @endDate Date de fin
   */

  async getTripRating(trips, startDate, endDate) {
    const api = create({
      baseURL: 'http://localhost:5000',
    });
    const requestParams = {
      e: `{"event_type":"Noter_voyage","filters":[{"subprop_type":"event","subprop_key":"id_voyage","subprop_op":"is","subprop_value":${JSON.stringify(
        trips,
      )}}],"group_by":[{"type":"event","value":"id_voyage"}]}`,
      m: 'average',
      start: startDate,
      end: endDate,
      i: '1',
    };
    const response = await api.get('/events/segmentation', requestParams, {
      headers: {
        // Authorization: `Basic MjY2OWU4ZDUwZmQzYjA0M2JiMWY0MDc3ZDgxZmViMWY6ODRiYjZiMWU2OWJlYjg4NzZmNDcwMzUzNjk0OWEwODc=`
      },
    });
    handleResponseError(response);
    return response.data;
  }

  /**
   * Create
   *
   */

  async create(entityType, entityParams) {
    const {api} = this;
    const response = await api.post(`/${entityType}`, entityParams, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  /**
   * Delete
   *
   */
  async delete(entityType, entityId) {
    const {api} = this;
    const response = await api.delete(
      `/${entityType}/${entityId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * Delete
   *
   */
  async deleteMany(entityType, params) {
    const {api} = this;
    const response = await api.delete(`/${entityType}`, params, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  /**
   * Update
   *
   */
  async update(entityType, entityParams) {
    const {api} = this;
    const response = await api.patch(
      `/${entityType}/${entityParams.id}`,
      {...omit(entityParams, ['id'])},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * Update many entities
   *
   */
  async updateMany(entityType, params) {
    const {api} = this;
    const response = await api.post(
      `/${entityType}/update?where=${JSON.stringify(params.where)}`,
      params.updates,
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * Get
   */

  async getAll(entityType, filter = {}) {
    const {api} = this;
    const response = await api.get(
      `/${entityType}/`,
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }
  /**
   * Get a Trip
   */

  async getTrip(tripId, filter = {}) {
    const {api} = this;
    const response = await api.get(
      `/Trips/${tripId}`,
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * Get In Period
   */

  async getTripsInPeriod(period, filter = {}, overrideFilter = false) {
    const {api} = this;
    const response = await api.get(
      '/Trips/getTripsInPeriod',
      {
        ...period,
        filter: {
          ...filter,
          include: overrideFilter
            ? filter?.include
            : [
                ...(filter?.include || []),
                'vehicle',
                'driver',
                {demands: 'passenger'},
                {
                  relation: 'tripReports',
                  scope: {
                    where: {
                      date: {
                        gte: period.start,
                        lte: period.end,
                      },
                    },
                    include: ['vehicle', 'driver'],
                  },
                },
                {
                  relation: 'updates',
                  scope: {
                    where: {
                      start: {
                        gte: period.start,
                      },
                      end: {
                        lte: period.end,
                      },
                    },
                  },
                },
                {
                  relation: 'events',
                  scope: {
                    where: {
                      and: [{
                        date: {
                          gte: period.start,
                        },
                      }, {
                        date: {
                          lte: period.end,
                        },
                      }]
                    },
                  },
                },
              ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async updateTripImmutable(tripId, updates) {
    const {api} = this;
    const response = await api.patch(
      `/Trips/${tripId}/immutable`,
      {...omit(updates, ['id'])},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async createTripReport(tripReport) {
    const {api} = this;
    const response = await api.post('/TripReports', tripReport, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  async removeTripReport(tripReport) {
    const {api} = this;
    const response = await api.delete(
      `/TripReports/${tripReport.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async confirmTripForDriver(tripId, driverId) {
    const {api} = this;
    const response = await api.post(
      '/TripEvents',
      {
        type: 'DRIVER_CONFIRMED',
        date: new Date(),
        metadata: {
          driverId,
        },
        tripId,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async sendTripEvent(event) {
    const {api} = this;
    const response = await api.post('/TripEvents', event, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  async cancelTripForDriver(tripId, driverId) {
    const {api} = this;
    const response = await api.post(
      '/TripEvents',
      {
        type: 'DRIVER_CANCELLED',
        date: new Date(),
        metadata: {
          driverId,
        },
        tripId,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * Get appropriate Bus
   * @param departureTime
   * @param from
   * @param to
   * @memberof RideBookingClient
   */

  async getAppropriateBus(filter) {
    const {api} = this;
    const response = await api.get('/Vehicles/getAppropriateBus', filter, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  async getTrips(filter) {
    const {api} = this;
    const response = await api.get(
      '/Trips/',
      {
        filter: {
          ...filter,
          include: [...(filter?.include || []), 'vehicle', 'driver'],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async getDrivers(filter = {}) {
    const {api} = this;
    const response = await api.get(
      '/Drivers/',
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async getPassengers(filter = {}) {
    const {api} = this;
    const response = await api.get(
      '/Passengers/',
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async getDemands(filter = {}) {
    const {api} = this;
    const response = await api.get(
      '/Demands/',
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async getDemandById(id) {
    const {api} = this;
    const response = await api.get(
      `/Demands/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async book({trip, departure, arrival, dates, passengerId}) {
    const {api} = this;
    const response = await api.post(
      `/trips/${trip.id}/book`,
      {
        dates,
        departure,
        arrival,
        passengerId,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async upsertPassenger(where, data) {
    const {api} = this;
    const response = await api.post('/Passengers/upsertWithWhere', data, {
      params: {
        where,
      },
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  async updateEndpoint(endpoint) {
    const {api} = this;
    this.endpoint = endpoint;
    api.setBaseURL(`${endpoint}/ride-booking`);
  }
}

export default RideBookingLCient;

import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';
import moment from 'moment';

import HighlightedText from '../common/HighlightedText';
import vehicleIcon from '../../assets/images/vehicle-icon.svg';
import locationPinIcon from '../../assets/images/location-pin.svg';
import stopLabel from '../../utils/stopLabel';
import colors from '../../theme/colors.json';
import { Checkbox, Icon } from 'semantic-ui-react';

const RouteDisplay = memo(
  ({ focusedTrip, trips, selectedTrips, onFocus, onSelect }) => {
    return (
      <div style={styles.container}>
        <div style={styles.vehicleContainer}>
          <ReactSVG src={vehicleIcon} />
          <div style={styles.vehicleLabelContainer}>
            {trips[0].vehicle.carLabel || trips[0].vehicle.carNumber || ''}
          </div>
        </div>
        <div style={styles.tripsContainer}>
          {trips.map(trip => (
            <div
              onClick={() => onFocus(trip)}
              key={trip.id}
              style={{
                ...styles.trip,
                ...(focusedTrip?.id === trip.id && styles.focusedTrip),
              }}
            >
              <HighlightedText style={styles.tripPath}>
                <ReactSVG style={styles.stopIcon} src={locationPinIcon} />
                <span style={styles.stopTime}>
                  {moment(trip.from.maxDate || null).format('HH:mm')}
                  {' - '}
                </span>
                <div style={styles.stopContainer} title={stopLabel(trip.from)}>
                  {stopLabel(trip.from)}
                </div>
                &rarr;
                <span style={styles.stopTime}>
                  {moment(trip.to.maxDate || null).format('HH:mm')}
                  {' - '}
                </span>
                <div style={styles.stopContainer} title={stopLabel(trip.to)}>
                  {stopLabel(trip.to)}
                </div>
              </HighlightedText>
              <HighlightedText style={styles.tripOccupancy}>
                <Icon name="bus" />
                {Math.ceil(
                  (trip.demandIds.length / trip.vehicle.capacity) * 100,
                )}
                %
              </HighlightedText>
              <Checkbox
                checked={selectedTrips?.map(trip => trip.id).includes(trip.id)}
                onChange={(e, { checked }) => {
                  onSelect(trip, checked);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  },
);

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'solid 1px',
    borderColor: colors.LIGHT_GREY,
    borderRadius: 4,
    marginBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 6,
    paddingBottom: 6,
  },
  focusedTrip: {
    border: 'solid 1px',
    borderColor: colors.PRIMARY,
    borderWidth: 2,
  },
  vehicleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stopTime: {
    fontWeight: 'bold',
  },
  stopContainer: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },
  vehicleLabelContainer: {
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },
  tripsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '80%',
  },
  trip: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 5,
  },
  tripPath: {},
  tripOccupancy: {
    width: 70,
    marginLeft: 5,
    marginRight: 5,
  },
};

export default RouteDisplay;

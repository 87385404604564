import React, { memo, useEffect, useRef, useState } from 'react';
import { Divider, Form, Header, Message } from 'semantic-ui-react';
import keyBy from 'lodash/keyBy';

import PassengersList from '../TripDetails/PassengersList';
import { toast } from 'react-semantic-toasts';

const TripNotifications = memo(function TripNotifications({
  trip,
  t,
  sending,
  error,
  sendNotification,
  onFinish = () => {},
}) {
  const wasSending = useRef(sending);

  useEffect(() => {
    if (wasSending.current && !sending && !error) {
      toast({
        type: 'success',
        icon: 'check',
        title: 'Succès',
        description: t(`Notification envoyée avec succès`),
        animation: 'fade left',
        time: 5000,
      });
      onFinish();
    }
  }, [wasSending, sending, error, onFinish, t]);

  const [notification, setNotification] = useState({
    title: '',
    content: '',
    type: 'trip::messages::manual',
    recipients: trip.demands?.map(demand => demand.passenger),
  });

  const [viewMode, setViewMode] = useState('form');

  const nextStep = () => {
    if (viewMode === 'form') {
      setViewMode('preview');
    } else if (viewMode === 'preview') {
      sendNotification(notification);
    }
  };

  const previousStep = () => {
    if (viewMode === 'preview') {
      setViewMode('form');
    }
  };

  useEffect(() => {
    wasSending.current = sending;
  }, [sending]);

  return (
    <div style={styles.container}>
      {error && <Message error>{error.message || error}</Message>}
      <Header.Subheader>
        {t('Entrez les informations de la notification')}
      </Header.Subheader>
      <Divider />
      <div style={styles.notificationForm}>
        <Form>
          <Form.Field>
            <label>{t('Titre')}</label>
            {viewMode === 'form' ? (
              <input
                type="text"
                name={t('titre')}
                value={notification.title}
                onChange={e =>
                  setNotification({
                    ...notification,
                    title: e.target.value,
                  })
                }
              />
            ) : (
              <p>{notification.title}</p>
            )}
          </Form.Field>
          <Form.Field>
            <label>{t('Message')}</label>
            {viewMode === 'form' ? (
              <input
                type="text"
                name={t('message')}
                value={notification.content}
                onChange={e =>
                  setNotification({
                    ...notification,
                    content: e.target.value,
                  })
                }
              />
            ) : (
              <p>{notification.content}</p>
            )}
          </Form.Field>
          <PassengersList
            trip={trip}
            demands={keyBy(
              viewMode === 'form'
                ? trip.demands
                : trip.demands.filter(d =>
                    notification.recipients?.includes(d.passenger),
                  ),
              'id',
            )}
            selected={notification.recipients}
            onSelectionChange={
              viewMode === 'form'
                ? selected =>
                    setNotification({
                      ...notification,
                      recipients: selected,
                    })
                : null
            }
            displayMode="singleStop"
          />
          <Divider />
          <div style={styles.notificationFormActions}>
            {viewMode !== 'form' && (
              <Form.Button onClick={previousStep}>{t('Modifier')}</Form.Button>
            )}
            <Form.Button
              primary
              disabled={
                sending ||
                !notification.title ||
                !notification.content ||
                !notification.recipients.length
              }
              onClick={nextStep}
            >
              {viewMode === 'form' ? t('Passer en revue') : t('Envoyer')}
            </Form.Button>
          </div>
        </Form>
      </div>
    </div>
  );
});

const styles = {
  container: {},
  notificationFormActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

export default TripNotifications;

import React, { memo } from 'react';
import { Card, Image, Label, Button, Popup, Icon } from 'semantic-ui-react';
import moment from 'moment-timezone';
import Steps from 'rc-steps';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';

import jamBusIcon from '../../../assets/images/jam-bus.svg';
import arrowCircleRight from '../../../assets/images/arrow-circle-right.svg';
import tripPropType from '../tripPropType';
import colors from '../../../theme/colors.json';

import './TripSummary.css';
import HighlightedText from '../../common/HighlightedText';
import tripPassengerCount from '../../../utils/tripPassengerCount';
import withi18n from 'weego-common/src/hoc/i18n';

const TripSummary = memo(function TripSummary({
  trip,
  legs = [],
  focused,
  minimized = false,
  onClick,
  onDetailsClick,
  onNotificationsClick,
  onTargetClick,
  t,
}) {
  const closestNextStop = getClosestNextStop(trip);
  const totalDistance = sumBy(trip.legs || legs, leg => leg.distance.value);
  return (
    <Card
      className="trip-summary"
      onClick={onClick && (() => onClick(trip))}
      style={focused ? styles.focusedCard : null}
    >
      <Card.Content>
        <div style={styles.header}>
          <div style={styles.vehicle}>
            <Image style={styles.vehicleIcon} src={jamBusIcon} />
            <span style={styles.vehicleLabel}>
              {trip.vehicle?.carLabel || trip.vehicle?.carNumber}
              {trip.vehicle && !trip.vehicle.available ? (
                <Popup
                  content={t("Ce véhicule n'est pas disponible")}
                  position="bottom center"
                  trigger={<Icon name="warning sign" color="yellow" />}
                />
              ) : null}
              <br />
            </span>
          </div>
          {focused && (
            <Button
              onClick={e => {
                onTargetClick(trip.vehicle);
                e.stopPropagation();
              }}
              icon="crosshairs"
            ></Button>
          )}
          <div>
            <Button onClick={() => onNotificationsClick(trip)} icon>
              <Icon name="bell outline" />
            </Button>
            <Button onClick={() => onDetailsClick(trip)}>{t('Détails')}</Button>
          </div>
        </div>

        <HighlightedText style={styles.driver}>
          <span style={styles.driverName}>
            {trip.driver?.firstname}
            {trip.driver && !trip.driver.available ? (
              <Popup
                content={t("Ce conducteur n'est pas disponible")}
                position="bottom center"
                trigger={<Icon name="warning sign" color="yellow" />}
              />
            ) : null}
          </span>{' '}
          <span style={styles.driverPhone}>{trip.driver?.phone}</span>
        </HighlightedText>
      </Card.Content>
      {!minimized && (
        <Card.Content>
          <Steps current={0} direction="vertical" size="small">
            <Steps.Step
              title={trip.from?.name}
              icon={
                <div style={styles.currentStepIcon}>
                  <ReactSVG className="bus-step-icon" src={jamBusIcon} />
                </div>
              }
            ></Steps.Step>
            <Steps.Step
              icon={<div style={styles.pastStepIcon}></div>}
              title={
                <Label>
                  {t('{{count}} arrêts', {
                    count: trip.stops?.length ?? 0,
                  })}
                </Label>
              }
            ></Steps.Step>
            <Steps.Step
              icon={<div style={styles.lastStepIcon}></div>}
              title={trip.to?.name}
            ></Steps.Step>
          </Steps>
        </Card.Content>
      )}
      {!minimized && (
        <Card.Content extra>
          {trip.demandIds && trip.demandIds.length ? (
            <div style={styles.info}>
              <span style={styles.infoLabel}>{t('Nb de passagers')}</span>
              <span style={styles.infoValue}>{tripPassengerCount(trip)}</span>
            </div>
          ) : null}
          <div style={styles.info}>
            <span style={styles.infoLabel}>{t('Heure de départ')}</span>
            <span style={styles.infoValue}>
              {moment(trip.departureTime).format('DD/MM')}
              {' à '}
              {trip.from?.maxDate
                ? moment(trip.from.maxDate).format('HH:mm')
                : moment(trip.departureTime).format('HH:mm')}
            </span>
          </div>
          <div style={styles.info}>
            <span style={styles.infoLabel}>{t("Heure d'arrivée")}</span>
            <span style={styles.infoValue}>
              {trip.to?.maxDate
                ? moment(trip.to.maxDate).format('HH:mm')
                : 'Inconnue'}
            </span>
          </div>
          {(trip.legs || legs)?.length ? (
            <div style={styles.info}>
              <span style={styles.infoLabel}>{t('Distance')}</span>
              <span style={styles.infoValue}>
                {Math.round(totalDistance / 1000)} kms
              </span>
            </div>
          ) : null}

          {!minimized && <span style={styles.tripId}>{trip.id}</span>}
        </Card.Content>
      )}
      {minimized && closestNextStop && (
        <Card.Content extra style={styles.closestNextStop}>
          <div style={styles.closestNextStopNameContainer}>
            <Image src={arrowCircleRight} />
            <span style={styles.closestNextStopName}>
              {closestNextStop.name}
            </span>
          </div>
          <div>{moment(closestNextStop.maxDate).format('HH:mm')}</div>
        </Card.Content>
      )}
    </Card>
  );
});

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vehicle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  vehicleLabel: {
    color: colors.GREY_TEXT,
    paddingLeft: 10,
  },
  driver: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  driverName: {
    fontWeight: 'normal',
  },
  driverPhone: {},
  currentStepIcon: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    position: 'relative',
    left: -3.5,
    backgroundColor: colors.PRIMARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lastStepIcon: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: colors.APRICOT,
    border: `solid 3.5px ${colors.APRICOT_TRANSPARENT}`,
    backgroundClip: 'padding-box',
  },
  pastStepIcon: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: colors.GREY,
    border: `solid 4.5px #F8F8F8`,
  },
  info: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colors.PRIMARY_0_1,
    color: colors.PRIMARY,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    marginTop: 8,
    marginBottom: 8,
  },
  infoLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  infoValue: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  focusedCard: {
    border: 'solid 3px',
    borderColor: colors.PRIMARY,
  },
  closestNextStop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.DARK_GREY,
    fontWeight: 'bold',
  },
  closestNextStopNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  closestNextStopName: {
    marginLeft: 5,
  },
  tripId: {
    fontSize: 10,
    color: colors.DARK_GREY,
    float: 'right',
  },
};

TripSummary.defaultProps = {
  onClick: undefined,
};

TripSummary.propTypes = {
  trip: tripPropType,
  focused: PropTypes.bool,
  onClick: PropTypes.func,
  onDetailsClick: PropTypes.func.isRequired,
  onNotificationsClick: PropTypes.func.isRequired,
};

const getClosestNextStop = trip => {
  const allStops = [trip.from, ...(trip.stops || []), trip.to];
  const now = moment();
  return allStops.find(stop => {
    const tripDepartureTime = moment(trip.departureTime);
    const stopTime = moment(stop.maxDate).set({
      year: tripDepartureTime.year(),
      month: tripDepartureTime.month(),
      date: tripDepartureTime.date(),
    });
    return stopTime.isAfter(now);
  });
};

export default withi18n('dashboard')(TripSummary);

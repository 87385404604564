import React, { memo } from 'react';
import { Image } from 'semantic-ui-react';

import emptyArray from '../../assets/images/empty-array.png';

export default memo(function EmptyState({ text, imageHeight }) {
  return (
    <div style={styles.emptyState}>
      <Image style={{ height: imageHeight ?? 134 }} src={emptyArray} />
      <span style={styles.emptyStateText}>{text || 'Rien à voir ici'}</span>
    </div>
  );
});

const styles = {
  emptyState: {
    display: 'flex',
    height: '98%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  emptyStateText: {
    fontSize: 24,
    color: '#e0e0e0',
    paddingTop: 19,
    textAlign: 'center',
  },
};

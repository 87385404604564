import React, { memo } from 'react';
import { TimeInput } from 'semantic-ui-calendar-react';
import { Button, Icon } from 'semantic-ui-react';

import dateTimeToString from '../../utils/date/dateTimeToString';
import timeStringToDate from '../../utils/date/timeStringToDate';

export default memo(function TimeRangeInput({
  value,
  onChange,
  style,
  label,
  clearable,
}) {
  return (
    <div style={{ ...styles.sectionInputContainer, ...style }}>
      <span style={styles.timeRangeContainer}>{label || 'Période'}</span>
      <div style={styles.separator}></div>
      <TimeInput
        name="departureMin"
        value={dateTimeToString(value[0])}
        icon={false}
        onChange={(e, { value: timeString }) =>
          onChange([timeStringToDate(timeString), value[1]])
        }
        closable
        className="no-border-input"
        style={styles.timeInput}
      />
      -
      <TimeInput
        name="departureMax"
        value={dateTimeToString(value[1])}
        icon={false}
        onChange={(e, { value: timeString }) =>
          onChange([value[0], timeStringToDate(timeString)])
        }
        className="no-border-input"
        style={styles.timeInput}
      />
      <div style={{ flex: 1 }} />
      {clearable && (
        <Button icon onClick={() => onChange(null)} style={styles.clearButton}>
          <Icon name="close" />
        </Button>
      )}
    </div>
  );
});

const styles = {
  sectionInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e1e1e1',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  timeRangeContainer: {
    paddingLeft: 7,
    paddingRight: 7,
    textAlign: 'center',
  },
  timeInput: {
    width: 70,
  },
  separator: {
    width: 1,
    backgroundColor: '#e1e1e1',
    alignSelf: 'stretch',
  },
  clearButton: {
    margin: 0,
  },
};

import React, { memo, useEffect } from 'react';
import Auth from './Auth';
import WeegoPro from './WeegoPro';
import {
  BrowserRouter as Router,
  Route,
  // Redirect,
  withRouter,
} from 'react-router-dom';

const Screens = memo(location => {
  useEffect(() => {
    window.analytics.page();
  }, [location.location.pathname]);

  return (
    <div style={{ height: '100%' }}>
      <Route path="/auth" component={Auth} />
      <Route path="/pro" component={WeegoPro} />
      {/* the default was /pro/dashboard but we need to hide it to vectalia */}
      <Route path="/" exact component={null} />
    </div>
  );
});

const ScreensWithRouter = withRouter(Screens);

const ScreensRouter = () => {
  return (
    <Router>
      <ScreensWithRouter />
    </Router>
  );
};

export default ScreensRouter;

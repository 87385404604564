import React, {Component} from 'react';
import omit from 'lodash/omit';
import {connect} from 'react-redux';

import i18n from 'weego-common/src/providers/i18n';

const withi18n = scope => WrappedComponent => {
  class Wrapper extends Component {
    static displayName = `i18n(${WrappedComponent.displayName})`;

    t = (key, params) =>
      i18n.t(key.includes('~') ? key : `${scope}~${key}`, params);

    render() {
      return <WrappedComponent {...this.props} t={this.t} />;
    }
  }

  const mapStateToProps = state => ({
    lang: state.i18n.lang,
  });

  const WrapperWithLang = connect(mapStateToProps)(Wrapper);

  WrapperWithLang.propTypes = omit(WrappedComponent.propTypes, 't');
  WrapperWithLang.defaultProps = omit(WrappedComponent.defaultProps, 't');
  WrapperWithLang.navigationOptions = WrappedComponent.navigationOptions;

  return WrapperWithLang;
};

export default withi18n;

import { handleActions } from 'redux-actions';
import assign from 'lodash/assign';
import keyBy from 'lodash/keyBy';

import liveMapActions from '../../actions/liveMap';

const DEFAULT_STATE = {
  updating: false,
  error: null,
  positions: {},
  positionsByCarNumber: {},
  focusedVehicle: null,
  focusedVehicleHistory: [],
  vehicleTargeted: false,
};

export default handleActions(
  {
    [liveMapActions.requestUpdate]: state => ({
      ...state,
      updating: true,
    }),
    [liveMapActions.updatePositions]: (state, { payload: { positions } }) => ({
      ...state,
      updating: false,
      positions: assign({}, state.positions, positions),
      positionsByCarNumber: assign(
        {},
        state.positionsByCarNumber,
        keyBy(positions, 'number'),
      ),
    }),
    [liveMapActions.updatePositionsError]: (state, { payload: error }) => ({
      ...state,
      updating: false,
      error,
    }),
    [liveMapActions.focusVehicle]: (state, action) => ({
      ...state,
      focusedVehicle: action.payload.vehicle,
      vehicleTargeted: false,
    }),
    [liveMapActions.unfocusVehicle]: state => ({
      ...state,
      focusedVehicle: null,
      vehicleTargeted: false,
    }),
    [liveMapActions.updateVehicleHistory]: (state, action) => ({
      ...state,
      focusedVehicleHistory: action.payload.history,
    }),
    [liveMapActions.targetVehicle]: (state, action) => ({
      ...state,
      vehicleTargeted: true,
      focusedVehicle: action.payload.vehicle,
    }),
  },
  DEFAULT_STATE,
);

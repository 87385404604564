import PropTypes from 'prop-types';

const tripPropType = PropTypes.shape({
  departureTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.string,
  driver: PropTypes.shape({
    fistname: PropTypes.string,
    lastname: PropTypes.string,
    availability: PropTypes.bool,
    phone: PropTypes.string,
  }),
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      coords: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }).isRequired,
  ),
  vehicle: PropTypes.shape({
    carNumber: PropTypes.string,
    capacity: PropTypes.number,
  }),
  from: PropTypes.shape({
    name: PropTypes.string,
    coords: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
  to: PropTypes.shape({
    name: PropTypes.string,
    coords: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
}).isRequired;

export default tripPropType;

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import tripsActions from '../../../actions/trip';

import NewTripBuilder from './NewTripBuilder';

const mapStateToProps = state => ({
  creating: state.trip.creating,
  error: state.trip.error,
});

const mapDispatchToProps = dispatch => ({
  createTrip: compose(dispatch, tripsActions.createTrip),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTripBuilder);

import React, { Component } from 'react';
import {
  Button,
  Input,
  Header,
  Statistic,
  Message,
  Checkbox,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isNumber from 'lodash/isNumber';

import CRUDManager from '../../common/CRUDManager';
import DialogBox from '../../common/DialogBox';
import withi18n from 'weego-common/src/hoc/i18n';
import { fetchSavedFilters, saveFilters } from '../../../utils/persistFilters';

const FILTER_SCOPE = "_accounts"
class AccountsManager extends Component {
  state = {
    userAccountToRefill: null,
    refillModelOpen: false,
    refillAmount: '',
    showB2C: !!fetchSavedFilters()?.[FILTER_SCOPE]?.showB2CFilter,
    lastFetchStartData: {},
  };

  componentDidUpdate(prevProps) {
    const { t } = this.props;
    if (
      prevProps.isRefilling &&
      !this.props.isRefilling &&
      !this.props.refillError
    ) {
      alert(t('Recharge effectuée'));
      this.closeRefillModal();
    }
  }

  fetchB2BOrB2C = data => {
    const { fetchStart, userCompany } = this.props;
    const { showB2C } = this.state;
    saveFilters(FILTER_SCOPE, {showB2CFilter: showB2C})
    if (showB2C) {
      fetchStart(
        merge({}, data, {
          where: {
            company: { exists: false },
          },
        }),
      );
    } else {
      fetchStart(
        merge({}, data, {
          where: {
            company: userCompany.id,
          },
        }),
      );
    }
    this.setState({
      lastFetchStartData: data,
    });
  };

  createB2BOrB2C = (record, ...otherArgs) => {
    const { createStart, userCompany } = this.props;
    const { showB2C } = this.state;
    if (showB2C) {
      createStart(record, ...otherArgs);
    } else {
      createStart(
        merge({}, record, {
          company: userCompany.id,
        }),
        ...otherArgs,
      );
    }
  };

  openRefillModal = userAccountToRefill => {
    const { requestPaymentAccount } = this.props;
    this.setState({
      refillModelOpen: true,
      userAccountToRefill,
      refillAmount: '',
    });
    requestPaymentAccount(userAccountToRefill._id);
  };

  closeRefillModal = () => {
    this.setState({
      refillModelOpen: false,
      userAccountToRefill: null,
    });
  };

  refill = () => {
    const { refill, t } = this.props;
    const { userAccountToRefill, refillAmount } = this.state;
    const refillAmountInt = parseInt(refillAmount);
    if (isNumber(refillAmountInt)) {
      const userFullName =
        get(userAccountToRefill, 'metadata.profile.firstName') +
        ' ' +
        get(userAccountToRefill, 'metadata.profile.lastName');
      const userPhoneNumber = userAccountToRefill.phoneNumber;
      if (
        window.confirm(
          t(
            `Vous allez recharger {{amount}} MAD pour {{username}}, {{userphone}}. Confirmer ?`,
            {
              amount: refillAmountInt,
              username: userFullName,
              userphone: userPhoneNumber,
            },
          ),
        )
      ) {
        refill(userAccountToRefill._id, parseInt(refillAmount));
      }
    }
  };

  render() {
    const {
      isLoadingAccount,
      isRefilling,
      paymentAccountsByUserId,
      refillError,
      userAccount,
      t,
      ...props
    } = this.props;
    const {
      showB2C,
      lastFetchStartData,
      refillModelOpen,
      refillAmount,
      userAccountToRefill,
    } = this.state;
    const paymentAccountToRefill =
      userAccountToRefill && paymentAccountsByUserId[userAccountToRefill._id];

    const isSuperAdmin = userAccount?.roles?.includes('super_admin');

    return (
      <div style={{ height: 'calc(100% - 80px)' }}>
        <DialogBox
          title={t(`Recharger`)}
          subTitle={userAccountToRefill && userAccountToRefill._id}
          size="tiny"
          onClose={() => this.closeRefillModal()}
          open={refillModelOpen}
        >
          {refillError && (
            <Message negative>
              <Message.Header>{t('Erreur lors de la recharge')}</Message.Header>
              <p>
                {refillError.message || refillError.description || refillError}
              </p>
            </Message>
          )}
          <Header textAlign="center">
            {' '}
            {get(userAccountToRefill, 'metadata.profile.firstName')}{' '}
            {get(userAccountToRefill, 'metadata.profile.lastName')}
            <Header.Subheader>
              {get(userAccountToRefill, 'phoneNumber')}{' '}
            </Header.Subheader>
          </Header>
          <div style={styles.dialogBoxContent}>
            <Statistic style={styles.balance}>
              <Statistic.Label>{t('Solde')}</Statistic.Label>
              <Statistic.Value>
                {paymentAccountToRefill
                  ? paymentAccountToRefill.balance
                  : '...'}
              </Statistic.Value>
            </Statistic>
            <Input
              label={{ basic: true, content: 'MAD' }}
              labelPosition="left"
              placeholder="0"
              type="number"
              min="0"
              autoFocus
              value={refillAmount}
              onChange={(event, { value }) =>
                this.setState({ refillAmount: value })
              }
              action={{
                primary: true,
                disabled: isLoadingAccount || isRefilling,
                onClick: this.refill,
                content: t('Recharger'),
                loading: isRefilling,
              }}
              style={styles.refillInput}
            />
          </div>
        </DialogBox>
        <CRUDManager
          {...props}
          canImport={true}
          renderBeforeGlobalActions={() =>
            isSuperAdmin ? (
              <div style={styles.b2cCheckboxContainer}>
                <Checkbox
                  label="B2C"
                  checked={showB2C}
                  onChange={(e, { checked }) =>
                    this.setState({ showB2C: checked }, () => {
                      this.fetchB2BOrB2C(lastFetchStartData);
                    })
                  }
                />
              </div>
            ) : null
          }
          fetchStart={this.fetchB2BOrB2C}
          createStart={this.createB2BOrB2C}
          style={{ height: '100%' }}
          recordActions={[
            {
              render: ({ record }) => (
                <Button
                  key="recharge"
                  primary
                  inverted
                  onClick={() => this.openRefillModal(record)}
                >
                  {t('Recharger')}
                </Button>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

const styles = {
  dialogBoxContent: {
    display: 'flex',
    alignItems: 'center',
  },
  balance: {
    flex: 1,
  },
  refillInput: {
    flex: 1,
  },
};

AccountsManager.propTypes = {
  ...CRUDManager.propTypes,
  userAccount: PropTypes.object.isRequired,
  paymentAccountsByUserId: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      userId: PropTypes.string.isRequired,
      balance: PropTypes.number.isRequired,
    }).isRequired,
  ),
  isLoadingAccount: PropTypes.bool.isRequired,
  isRefilling: PropTypes.bool.isRequired,
  requestPaymentAccount: PropTypes.func.isRequired,
  refill: PropTypes.func.isRequired,
  updateAccountError: PropTypes.instanceOf(Error),
  refillError: PropTypes.instanceOf(Error),
};

export default withi18n('accounts')(AccountsManager);

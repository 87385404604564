import { handleActions } from 'redux-actions';
import keyBy from 'lodash/keyBy';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';

import tripActions from '../../actions/trip';
import { actionTypes as tripUpdateActionTypes } from '../../actions/tripUpdates';
import { actionTypes as tripEventActionTypes } from '../../actions/tripEvents';

const DEFAULT_STATE = {
  loading: false,
  error: null,
  tripsById: {},
};

export default handleActions(
  {
    [tripActions.updateTrips]: (state, action) => ({
      ...state,
      tripsById: mergeWith(
        {},
        state.tripsById,
        keyBy(action.payload, 'id'),
        (objValue, srcValue) => {
          if (isArray(objValue) || isArray(srcValue)) {
            return srcValue;
          }
        },
      ),
      error: null,
    }),
    [tripActions.clearTrips]: (state, action) => ({
      ...state,
      tripsById: {},
    }),
    [tripUpdateActionTypes.createSuccess]: (state, action) => {
      const trip = state.tripsById[action.record.tripId];
      return {
        ...state,
        tripsById: {
          ...state.tripsById,
          [action.record.tripId]: {
            ...trip,
            updates: (trip.updates || []).concat(action.record),
          },
        },
      };
    },
    [tripUpdateActionTypes.deleteSuccess]: (state, action) => {
      const trip = state.tripsById[action.record.tripId];

      return {
        ...state,
        tripsById: {
          ...state.tripsById,
          [action.record.tripId]: {
            ...trip,
            updates: (trip.updates || []).filter(
              u => u.id !== action.record.id,
            ),
          },
        },
      };
    },
    [tripEventActionTypes.createStart]: (state, action) => {
      const trip = state.tripsById[action.record.tripId];

      return {
        ...state,
        tripsById: {
          ...state.tripsById,
          [action.record.tripId]: {
            ...trip,
            events: (trip.events || []).concat({
              ...action.record,
              pending: true,
            }),
          },
        },
      };
    },
    [tripEventActionTypes.createSuccess]: (state, action) => {
      const trip = state.tripsById[action.record.tripId];

      return {
        ...state,
        tripsById: {
          ...state.tripsById,
          [action.record.tripId]: {
            ...trip,
            events: (trip.events || []).map(e =>
              e.id === action.record.id ? { ...e, pending: false } : e,
            ),
          },
        },
      };
    },
    [tripEventActionTypes.deleteStart]: (state, action) => {
      const trip = state.tripsById[action.record.tripId];

      return {
        ...state,
        tripsById: {
          ...state.tripsById,
          [action.record.tripId]: {
            ...trip,
            events: (trip.events || []).map(e =>
              e.id === action.record.id ? { ...e, pending: true } : e,
            ),
          },
        },
      };
    },
    [tripEventActionTypes.deleteSuccess]: (state, action) => {
      const trip = state.tripsById[action.record.tripId];

      return {
        ...state,
        tripsById: {
          ...state.tripsById,
          [action.record.tripId]: {
            ...trip,
            events: (trip.events || []).filter(u => u.id !== action.record.id),
          },
        },
      };
    },
  },
  DEFAULT_STATE,
);

export default [
  {
    name: 'Casablanca',
    // https://www.keene.edu/campus/maps/tool/?coordinates=-7.6869822%2C%2033.5719843%0A-7.6830196%2C%2033.5645962%0A-7.6839352%2C%2033.5619276%0A-7.6865101%2C%2033.5592113%0A-7.6891708%2C%2033.5576386%0A-7.6908016%2C%2033.5542072%0A-7.6828766%2C%2033.5397442%0A-7.6996994%2C%2033.5276115%0A-7.6942062%2C%2033.5130221%0A-7.6811600%2C%2033.5070140%0A-7.6722336%2C%2033.5058696%0A-7.6670837%2C%2033.4812743%0A-7.6610756%2C%2033.4766951%0A-7.6317215%2C%2033.4778260%0A-7.6271439%2C%2033.4993843%0A-7.4946213%2C%2033.5629783%0A-7.4829483%2C%2033.5652657%0A-7.4565125%2C%2033.6192871%0A-7.4987412%2C%2033.6604236%0A-7.5542450%2C%2033.6468089%0A-7.6005936%2C%2033.6356679%0A-7.6266861%2C%2033.6305254%0A-7.6507187%2C%2033.6236683%0A-7.6819611%2C%2033.6093810%0A-7.6953507%2C%2033.6002358%0A-7.7066803%2C%2033.5925188%0A-7.7101135%2C%2033.5885172%0A-7.7056503%2C%2033.5787828%0A-7.7031612%2C%2033.5763528%0A-7.7006722%2C%2033.5726124%0A-7.7000427%2C%2033.5705238%0A-7.6950645%2C%2033.5703809%0A-7.6901722%2C%2033.5709527%0A-7.6869750%2C%2033.5719761%0A-7.6869822%2C%2033.5719843
    bounds: [
      [
        [-7.6869822, 33.5719843],
        [-7.6830196, 33.5645962],
        [-7.6839352, 33.5619276],
        [-7.6865101, 33.5592113],
        [-7.6891708, 33.5576386],
        [-7.6908016, 33.5542072],
        [-7.6828766, 33.5397442],
        [-7.6996994, 33.5276115],
        [-7.6942062, 33.5130221],
        [-7.68116, 33.507014],
        [-7.6722336, 33.5058696],
        [-7.6670837, 33.4812743],
        [-7.6610756, 33.4766951],
        [-7.6317215, 33.477826],
        [-7.6271439, 33.4993843],
        [-7.4946213, 33.5629783],
        [-7.4829483, 33.5652657],
        [-7.4565125, 33.6192871],
        [-7.4987412, 33.6604236],
        [-7.554245, 33.6468089],
        [-7.6005936, 33.6356679],
        [-7.6266861, 33.6305254],
        [-7.6507187, 33.6236683],
        [-7.6819611, 33.609381],
        [-7.6953507, 33.6002358],
        [-7.7066803, 33.5925188],
        [-7.7101135, 33.5885172],
        [-7.7056503, 33.5787828],
        [-7.7031612, 33.5763528],
        [-7.7006722, 33.5726124],
        [-7.7000427, 33.5705238],
        [-7.6950645, 33.5703809],
        [-7.6901722, 33.5709527],
        [-7.686975, 33.5719761],
        [-7.6869822, 33.5719843],
      ],
    ],
    timeMultiplier: 2.5,
  },
  {
    name: 'Mohammedia',
    // https://www.keene.edu/campus/maps/tool/?coordinates=-7.3122025%2C%2033.7713016%0A-7.3926544%2C%2033.7322158%0A-7.4213791%2C%2033.7133797%0A-7.4491882%2C%2033.6776941%0A-7.4217224%2C%2033.6585605%0A-7.3942566%2C%2033.6428506%0A-7.3482513%2C%2033.6554187%0A-7.2586441%2C%2033.7076710%0A-7.3122025%2C%2033.7713016
    bounds: [
      [
        [-7.3122025, 33.7713016],
        [-7.3926544, 33.7322158],
        [-7.4213791, 33.7133797],
        [-7.4491882, 33.6776941],
        [-7.4217224, 33.6585605],
        [-7.3942566, 33.6428506],
        [-7.3482513, 33.6554187],
        [-7.2586441, 33.707671],
        [-7.3122025, 33.7713016],
      ],
    ],
    timeMultiplier: 1,
  },
  {
    // https://www.keene.edu/campus/maps/tool/?coordinates=-7.7122307%2C%2033.5830669%0A-7.7822685%2C%2033.5438001%0A-7.7420998%2C%2033.5003189%0A-7.6821899%2C%2033.5393195%0A-7.6892281%2C%2033.5504729%0A-7.6909447%2C%2033.5557631%0A-7.6876831%2C%2033.5586225%0A-7.6833916%2C%2033.5619108%0A-7.6832199%2C%2033.5653418%0A-7.6866531%2C%2033.5719177%0A-7.6996994%2C%2033.5703452%0A-7.7122307%2C%2033.5830669
    name: 'Rahma',
    bounds: [
      [
        [-7.7122307, 33.5830669],
        [-7.7822685, 33.5438001],
        [-7.7420998, 33.5003189],
        [-7.6821899, 33.5393195],
        [-7.6892281, 33.5504729],
        [-7.6909447, 33.5557631],
        [-7.6876831, 33.5586225],
        [-7.6833916, 33.5619108],
        [-7.6832199, 33.5653418],
        [-7.6866531, 33.5719177],
        [-7.6996994, 33.5703452],
        [-7.7122307, 33.5830669],
      ],
    ],
    timeMultiplier: 3,
  },
  {
    name: 'Bouskoura',
    // https://www.keene.edu/campus/maps/tool/?coordinates=-7.6698303%2C%2033.4764571%0A-7.6718903%2C%2033.4128949%0A-7.6255417%2C%2033.4186231%0A-7.6327515%2C%2033.4753122%0A-7.6698303%2C%2033.4764571
    bounds: [
      [
        [-7.6698303, 33.4764571],
        [-7.6718903, 33.4128949],
        [-7.6255417, 33.4186231],
        [-7.6327515, 33.4753122],
        [-7.6698303, 33.4764571],
      ],
    ],
    timeMultiplier: 2,
  },
  {
    name: 'Lagos',
    bounds: [
      [
        [2.70628943, 6.3670553],
        [4.35105495, 6.3670553],
        [4.35105495, 6.69679635],
        [2.70628943, 6.69679635],
        [2.70628943, 6.3670553],
      ],
    ],
    timeMultiplier: 2,
  },
];

import io from 'socket.io-client';
import Client from '../lib/sunubus-sdk';

const storage = {
  read: key => JSON.parse(localStorage.getItem(key)),
  write: (key, data) => localStorage.setItem(key, JSON.stringify(data)),
};

const client = new Client(process.env.REACT_APP_SUNUBUS_SERVICES_URL, {
  storage,
  io: (...args) => {
    return io(...args);
  },
});

export default client;

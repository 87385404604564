import {create} from 'apisauce';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import handleResponseError from '../utils/handleResponseError';

class RoutesClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @memberof RoutesClient
   */
  constructor(endpoint, {tokens}) {
    this.endpoint = endpoint;
    this.api = create({
      baseURL: `${endpoint}/routes`,
    });
    this.tokens = tokens;
  }

  /**
   * Get the list of buses
   *
   * @returns {Array<Bus>} - the list of buses
   * @memberof RoutesClient
   */
  async getBuses(
    keys = [
      'id',
      'company',
      'line',
      'enabled',
      'label',
      'firstStop',
      'lastStop',
      'zones',
      'type',
    ],
    where = {},
  ) {
    const {api} = this;
    const response = await api.get(
      '/buses',
      {
        keys: keys.join(','),
        ...where,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const buses = response.data;
    return buses.map(bus => ({
      ...bus,
      number: bus.number || bus.line,
    }));
  }

  /**
   * Get the list of zones
   *
   * @returns {Array<Zone>} - the list of zones
   * @memberof RoutesClient
   */
  async getZones() {
    const {api} = this;
    const response = await api.get('/zones');
    handleResponseError(response);
    const zones = response.data;
    return zones;
  }

  async getBusInfos(lineId) {
    const {api} = this;
    const response = await api.get(`/details/${lineId}`, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get(
          'ACCOUNT_VERIFICATION',
        )}`,
      },
    });
    handleResponseError(response);
    const busInfos = response.data;
    return busInfos;
  }

  /**
   * Get the path for a given bus
   *
   * @param {String} busId - the id of the bus
   * @returns {Path} path - the path followed by the bus
   * @memberof RoutesClient
   */
  async getPath(busId, direction = 'FORWARD') {
    const {api} = this;
    const response = await api.get(
      `/paths/${busId}`,
      {direction},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const path = response.data;
    path.path = path.path || path.stops.map(stop => stop.coordinates);
    return path;
  }

  /**
   * Get the path for a given bus
   *
   * @param {String} company - the company
   * @param {String} number - the line number
   * @returns {Path} path - the path followed by the bus
   * @memberof RoutesClient
   */
  async getPathFor(company, number, direction) {
    const {api} = this;
    const response = await api.get(
      `/paths/${company}/${number}`,
      {direction},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const path = response.data;
    path.path = path.path || path.stops.map(stop => stop.coordinates);
    return path;
  }

  /**
   * Get the lines that best match a succession of positions
   *
   * @param {Array<LatLng>} positions - the positions of the bus
   * @returns {Array<MatchResult>} matches - the matched lines
   * @memberof RoutesClient
   */
  async getMatchingLines(positions) {
    const {api} = this;
    const response = await api.post('/paths/match', {positions});
    handleResponseError(response);
    const {results} = response.data;
    return results;
  }

  /**
   * Get possible courses given an origin and a destination
   *
   * @param {Position} from - the origin coordinates
   * @param {Position} to - the destination coordinates
   * @returns {Array<Array<Segment>>} courses - the proposed courses
   * @memberof RoutesClient
   */
  async getCourses(from, to) {
    const {api} = this;
    let params = {};
    if (isObject(from)) {
      params.fromCoords = `${from.latitude},${from.longitude}`;
    } else if (isString(from)) {
      params.originString = from;
    }
    if (isObject(to)) {
      params.toCoords = `${to.latitude},${to.longitude}`;
    } else if (isString(to)) {
      params.destString = to;
    }

    const response = await api.get('/courses/courseFor', params);
    handleResponseError(response);
    return response.data.courses;
  }

  async getNearbyBuses(coords) {
    const {api} = this;
    const response = await api.get(
      `/buses/nearby?coords=${coords.latitude},${coords.longitude}`,
    );
    handleResponseError(response);
    const nearbyBuses = response.data?.[0];
    return nearbyBuses;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/routes`);
  }
}

export default RoutesClient;

/**
 * @typedef {Object} Position
 * @property {Number} latitude - The latitude of the position
 * @property {Number} longitude - The longitude of the position
 */

/**
 * @typedef {Object} Stop
 * @property {String} id - The id of the stop
 * @property {String} name - The name of the stop
 * @property {Position} coordinates - The geographical coordinates of the stop
 */

/**
 * @typedef {Object} Bus
 * @property {String} id - The id of the bus
 * @property {String} company - The company of the bus, ddd or aftu
 * @property {String} line - The line number of the bus, can be a string sometimes (e.g. 10A)
 * @property {Stop} firstStop - The stop the bus departs from
 * @property {Stop} lastStop - The stop where the bus ends his course
 */

/**
 * @typedef {Object} Path
 * @property {Array<Stop>} stops - The stops in the path
 */

/**
 * @typedef {Object} Segment
 * @property {Bus} bus - The bus to take for the segment
 * @property {Array<Stop>} stops - The stops in the segment
 */

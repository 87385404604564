import moment from 'weego-common/src/providers/moment';

const timeStringToDate = string => {
  if (!string) {
    return null;
  }
  return moment(string, 'HH:mm').toDate();
};

export default timeStringToDate;

import React, { memo, useState, useEffect, useRef } from 'react';
import { Button, Tab, Divider, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';

import TripGeneralInfosForm from '../TripGeneralInfosForm';
import TripStopsEditor from '../TripStopsEditor';
import TripExtraInfosForm from '../TripExtraInfosForm';
import isTripAutomaticallyPlanned from '../utils/isTripAutomaticallyPlanned';
import withi18n from 'weego-common/src/hoc/i18n';

const TripEditor = memo(function TripEditor({
  trip,
  editTrip,
  editing,
  createTrip,
  creating,
  error,
  disabledFieldKeys,
  onExit,
  isDuplicating,
  t,
}) {
  const [localTrip, setLocalTrip] = useState(trip);
  const wasEditing = useRef(editing);
  const wasCreating = useRef(creating);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const isAutomaticallyPlanned = isTripAutomaticallyPlanned(trip);

  const patchTrip = patch => {
    setLocalTrip({
      ...localTrip,
      ...patch,
    });
  };

  const save = () => {
    if (isDuplicating) {
      createTrip(omit(localTrip, 'id', 'vehicle', 'driver'));
    } else {
      const patch = omitBy(
        localTrip,
        (value, key) => key !== 'id' && isEqual(trip[key], localTrip[key]),
      );
      editTrip(patch);
    }
  };

  useEffect(() => {
    if (wasEditing.current && !editing && !error) {
      onExit();
    }
    if (wasCreating.current && !creating && !error) {
      onExit();
    }
  }, [wasEditing, wasCreating, editing, creating, error, onExit]);

  useEffect(() => {
    wasEditing.current = editing;
    wasCreating.current = creating;
  }, [editing, creating]);

  // renderActiveOnly={false} does not support render: () => ()
  // As we use this component for editing or duplication we can use by default renderActiveOnly={false}
  // https://github.com/Semantic-Org/Semantic-UI-React/issues/2168#issuecomment-472790819
  const panes = [
    {
      key: 'generalInfos',
      menuItem: t('Informations générales'),
      pane: (
        <Tab.Pane attached={false}>
          <TripGeneralInfosForm trip={localTrip} onChange={patchTrip} />
        </Tab.Pane>
      ),
    },
    {
      key: 'stops',
      menuItem: t('Arrêts'),
      pane: (
        <Tab.Pane attached={false} style={styles.stopsContainer}>
          <TripStopsEditor
            trip={localTrip}
            onChange={patchTrip}
            isVisible={activeTabIndex === 1}
          />
        </Tab.Pane>
      ),
    },
    {
      key: 'extraInfos',
      menuItem: t('Infos supplémentaires'),
      pane: (
        <Tab.Pane attached={false}>
          <TripExtraInfosForm
            trip={localTrip}
            disabledFieldKeys={disabledFieldKeys}
            onChange={patchTrip}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div>
      {isAutomaticallyPlanned && (
        <Message warning>
          {t(
            'Ce trajet a été généré automatiquement, certains champs ne peuvent être modifiés',
          )}
        </Message>
      )}
      <Tab
        renderActiveOnly={false}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        onTabChange={(e, { activeIndex }) => setActiveTabIndex(activeIndex)}
      />
      <Divider />
      <div style={styles.actions}>
        <Button
          style={styles.actionButton}
          size="medium"
          onClick={onExit}
          loading={editing}
        >
          {t('Annuler')}
        </Button>
        <Button
          style={styles.actionButton}
          primary
          size="medium"
          onClick={save}
          loading={editing || creating}
        >
          {isDuplicating ? t('Dupliquer') : t('Valider')}
        </Button>
      </div>
    </div>
  );
});

const styles = {
  stopsContainer: {
    height: 400,
  },
  actionButton: {
    paddingLeft: 60,
    paddingRight: 60,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

TripEditor.propTypes = {
  createTrip: PropTypes.func.isRequired,
  creating: PropTypes.bool.isRequired,
  disabledFieldKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  error: PropTypes.object,
  onExit: PropTypes.func.isRequired,
};

export default withi18n('trips')(TripEditor);

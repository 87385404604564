function accountLabel(account, { displayPhoneNumber = false }) {
  if (!account) {
    return '';
  }
  if (
    !account.metadata?.profile?.firstName &&
    !account.metadata?.profile?.lastName
  ) {
    return account.phoneNumber;
  }
  return `${account.metadata?.profile?.firstName || ''} ${
    account.metadata?.profile?.lastName || ''
  } ${displayPhoneNumber && account.phoneNumber}`.trim();
}

export default accountLabel;

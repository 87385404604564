import { handleActions } from 'redux-actions';
import authActions from '../../actions/auth';

const DEFAULT_STATE = {
  loading: false,
  error: null,
  phone: null,
  isLogin: false,
  isLogout: false,
  account: null,
  company: null,
};

export default handleActions(
  {
    [authActions.login]: (state, action) => ({
      ...state,
      loading: true,
      error: null,
      phone: action.payload,
      isLogin: true,
    }),
    [authActions.loginError]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      isLogin: false,
    }),
    [authActions.loginSuccess]: state => ({
      ...state,
      loading: false,
      error: null,
      isLogin: false,
    }),
    [authActions.logout]: state => ({
      ...state,
      loading: true,
      isLogout: true,
    }),
    [authActions.logouError]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      isLogout: false,
    }),
    [authActions.logoutSuccess]: state => ({
      ...state,
      loading: false,
      error: null,
      isLogout: false,
    }),
    [authActions.updateAccount]: (state, { payload: account }) => ({
      ...state,
      account,
    }),
    [authActions.updateCompany]: (state, { payload: { company } }) => ({
      ...state,
      company,
    }),
  },
  DEFAULT_STATE,
);

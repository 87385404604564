import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import filter from 'lodash/filter';

import DriverReplacementList from './DriverReplacementList';

import driversActions from '../../../actions/drivers';
import compose from 'recompose/compose';

const getAvailableDrivers = memoizeOne((drivers, trips, departureTime) => {
  return filter(drivers, d => d.available);
});

const mapStateToProps = (state, ownProps) => ({
  drivers: getAvailableDrivers(state.drivers),
});

const mapDispatchToProps = dispatch => ({
  fetchDrivers: compose(dispatch, driversActions.fetchStart),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(DriverReplacementList);

import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import transactionsActionCreators, {
  actionTypes,
} from '../../actions/transactions';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const transactionsSaga =
  tokens && tokens.ACCOUNT_VERIFICATION && decodedToken?.company
    ? makeCrudSaga(
        'transactions',
        actionTypes,
        transactionsActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/payment/transactions`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: false,
          key: 'id',
          stripKeyInRequest: true
        },
      )
    : () => {};

export default transactionsSaga;

import { all, put, takeLatest, select } from 'redux-saga/effects';
import fromPairs from 'lodash/fromPairs';
import minBy from 'lodash/minBy';
import moment from 'moment';

import dashboardActions from '../../actions/dashboard';
import livemapActions from '../../actions/liveMap';
import getTripPath from '../../utils/getTripPath';

function* fetchTripsPaths(action) {
  try {
    const { trips } = action.payload;
    const pathsKvPairs = yield all(
      trips.map(async trip => {
        if (trip.path && trip.legs) {
          return [trip.id, { path: trip.path, legs: trip.legs }];
        }
        const { decoded_polyline: path, legs } = await getTripPath(trip);
        return [trip.id, { path, legs }];
      }),
    );

    const pathsByTripId = fromPairs(pathsKvPairs);

    yield put(dashboardActions.updateFocusedTripsPaths(pathsByTripId));
  } catch (error) {
    console.error('Error when fetching trip path', error);
  }
}

function* focusTripWhenVehicleFocused(action) {
  const { vehicle } = action.payload;
  const trips = yield select(state => state.trip.trips);
  const vehicleTrips = trips?.filter(
    trip => trip.vehicle?.carNumber === vehicle.carNumber,
  );

  const currentlyFocusedTrips = yield select(
    state => state.dashboard.focusedTrips,
  );
  // Prevent infinite focus trip -> focus vehicle -> focus trip loop
  // if vehicle trip already focused
  if (vehicleTrips?.find(trip => currentlyFocusedTrips[trip.id])) {
    return;
  }

  const now = moment();
  const closestTrip = minBy(vehicleTrips, trip =>
    moment(trip.departureTime).diff(now),
  );
  if (!closestTrip) {
    yield put(dashboardActions.focusTrips([]));
    return;
  }
  yield put(dashboardActions.focusTrips([closestTrip]));
}

function* focusVehicleWhenTripFocused(action) {
  const { trips, disableVehicleAutofocus } = action.payload;
  if (disableVehicleAutofocus) {
    return;
  }
  // We only auto focus a vehicle if one trip only has been focused
  if (trips.length !== 1) {
    return;
  }
  const focusedTrip = trips[0];
  const tripVehicleNumber = focusedTrip.vehicle?.carNumber;
  const currentFocusedVehicle = yield select(
    state => state.liveMap.focusedVehicle,
  );
  // Prevent infinite focus trip -> focus vehicle -> focus trip loop
  if (currentFocusedVehicle?.carNumber === tripVehicleNumber) {
    return;
  }
  yield put(livemapActions.focusVehicle({ carNumber: tripVehicleNumber }));
}

function* requestHistoryWhenTripFocused(action) {
  const { trips } = action.payload;
  // We only auto focus a vehicle if one trip only has been focused
  if (trips.length !== 1) {
    return;
  }
  const focusedTrip = trips[0];
  const tripVehicleNumber = focusedTrip.vehicle?.carNumber;
  const tripDepartureTime = moment(focusedTrip.departureTime);
  const tripEndTime = moment(focusedTrip.to.maxDate).set({
    year: tripDepartureTime.year(),
    month: tripDepartureTime.month(),
    date: tripDepartureTime.date(),
  });
  yield put(
    livemapActions.requestVehicleHistory(
      tripVehicleNumber,
      tripDepartureTime.clone().subtract(15, 'minutes').toDate(),
      tripEndTime.clone().add(15, 'minutes').toDate(),
    ),
  );
}

function* unfocusVehicleWhenTripUnfocused() {
  yield put(livemapActions.unfocusVehicle());
}

function* dashboardRootSaga() {
  yield all([
    takeLatest(dashboardActions.focusTrips, fetchTripsPaths),
    takeLatest(livemapActions.focusVehicle, focusTripWhenVehicleFocused),
    takeLatest(dashboardActions.focusTrips, focusVehicleWhenTripFocused),
    takeLatest(dashboardActions.focusTrips, requestHistoryWhenTripFocused),
    takeLatest(dashboardActions.unfocusTrips, unfocusVehicleWhenTripUnfocused),
  ]);
}

export default dashboardRootSaga;

import moment from 'weego-common/src/providers/moment';

const parseDateRangeString = dateRangeString => {
  const parts = dateRangeString.split(' - ');
  const startDate = parts[0]
    ? moment(parts[0], 'DD-MM-YYYY').startOf('day').toDate()
    : null;
  const endDate = parts[1]
    ? moment(parts[1], 'DD-MM-YYYY').endOf('day').toDate()
    : null;
  return [startDate, endDate];
};

export default parseDateRangeString;

import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

/**
 * @author Babacar NIANG <babacarniang@sunubus.com>
 * TrafficCongestion client for consume TrafficService
 */
class TrafficClient {
  /**
   * Creates an instance of Client.
   * @param {String} endpoint - root url of the sunubus service
   * @memberof TrafficClient
   */
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.api = create({
      baseURL: `${this.endpoint}/congestiontraffic`
    });
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/congestiontraffic`);
  }

  /**
   * @description submit a predefined segment
   *
   * @param {String} name
   * @param {String} start
   * @param {String} end
   *
   * @returns {PredefinedSegment} - the predefined segment created
   * @memberof TrafficClient
   */
  async submitPredefinedSegment(name, start, end) {
    const { api } = this;
    const response = await api.post(`/predefinedsegment`, { name, start, end });
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description delete a predefined segment
   *
   * @param {String} id PredefinedSegment to delete
   *
   * @memberof TrafficClient
   */
  async deletePredefinedSegment(id) {
    const { api } = this;
    const response = await api.delete(`/predefinedsegment/${id}`);
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description get the states of the congestion between two points
   * @param {Position} start point
   * @param {Position} end point
   * @param {language} the language of the congestion audio
   *
   * @returns {Array<CongestionState>} - the list of one congestionStates between points
   *
   * @memberof TrafficClient
   */
  async getCongestionStatePath(start, end, language) {
    const { api } = this;
    const response = await api.get(
      `/congestionstate/${start.latitude},${start.longitude}/${end.latitude},${
        end.longitude
      }/${language}`
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description get the congestion states in all predefined segments
   *
   * @param {language} the language of the congestion audio
   *
   * @returns {Array<CongestionState>} - the congestionStates list of predefinedSegments
   *
   * @memberof TrafficClient
   */
  async getCongestionStatePredefinedSegment(language) {
    const { api } = this;
    const response = await api.get(
      `/congestionstate/predefinedsegments/${language}`
    );
    handleResponseError(response);
    return response.data;
  }
}

export default TrafficClient;

/**
 * @typedef  {Object} Position
 * @property {Number} latitude - The latitude of the position
 * @property {Number} longitude - The longitude of the position
 */

/**
 * @typedef  {Object} CongestionState
 * @property {String} _id - the id of the congestion states
 * @property {String} level - the congestion level
 * @property {String} date - the date of the congestion states
 * @property {Number} averageSpeed - the average speed of vehicles in the congestion state segment
 * @property {Array<Segment>} - an array containing one item (the concerned segment by the state of congestion)
 */

/**
 * @typedef  {Object} Segment
 * @property {String} _id - the id of the segment
 * @property {Position} start - the start point of the segment
 * @property {Position} end - the end point of the segment
 * @property {Array<Position>} shape - the path shape of the segment
 */

/**
 * @typedef {Object} PredefinedSegment
 * @property {String} _id - the id of the predefinedSegment
 * @property {String} name - the segment's name
 * @property {Position} start - the start point of the segment
 * @property {Position} end - the end point of the segment
 * @property {Array<Position>} shape - the path shape of the segment
 *
 */

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import get from 'lodash/get';
import memoize from 'memoize-one';
import i18n from 'weego-common/src/providers/i18n';

import CRUDManager from '../common/CRUDManager';
import vehiclesActions from '../../actions/vehicles';
import driversActions from '../../actions/drivers';
import groupsActions from '../../actions/groups';
import driverLabel from '../../utils/driverLabel';

const fields = [
  {
    key: 'carNumber',
    label: 'Matricule',
    type: 'STRING',
  },
  {
    key: 'carLabel',
    label: 'Libellé',
    type: 'STRING',
  },
  {
    key: 'capacity',
    label: 'Nombre de places',
    type: 'NUMBER',
  },
  {
    key: 'cost',
    label: 'Cout/km',
    type: 'NUMBER',
  },
  {
    key: 'brand',
    label: 'Marque',
    type: 'STRING',
  },
  {
    key: 'driverId',
    label: 'Conducteur',
    refId: 'id',
    type: 'REF',
    ref: 'drivers',
    renderRef: driver => driverLabel(driver, { displayPhoneNumber: true }),
  },
  {
    key: 'available',
    label: 'Disponibilité',
    type: 'BOOLEAN',
    fontSize: 15,
    suffix: 'disponible',
  },
  {
    key: 'unavailabilityReason',
    label: 'Raison',
    type: 'STRING',
    // We make this dynamic because by the time this code is run (at import time)
    // i18n is not setup yet and we get missing translations
    get list() {
      return [i18n.t('fields~Panne'), i18n.t('fields~Maintenance')];
    },
    disabled: record => get(record, 'available', false),
  },
  {
    key: 'depot',
    label: 'Dépôt',
    type: 'PLACE',
    showMap: true,
    inputStyles: {
      width: '100%',
    },
  },
  {
    key: 'groups',
    label: 'Groupes',
    type: ['REF'],
    ref: 'groups',
    refKey: 'name',
  },
  {
    key: 'transporterCompany',
    label: 'Transporteur',
    type: 'STRING',
  },
];
const resourceLabel = 'véhicule';

const style = { height: 'calc(100% - 80px)' };
const translateError = memoize(error => {
  if (!error) {
    return null;
  }
  if (error.code === 422) {
    if (error.details.codes.driverId) {
      return new Error('Ce conducteur est déjà assigné à un véhicule');
    } else if (error.details.code.carNumber) {
      return new Error('Ce matricule existe déjà');
    } else {
      return error;
    }
  }
  return error;
});
const getRelations = memoize((drivers, groups) => ({ drivers, groups }));

const mapStateToProps = state => ({
  recordKey: 'id',
  relations: getRelations(state.drivers, state.groups),
  changeHandler: record => ({
    ...record,
    unavailabilityReason: record.available ? '' : record.unavailabilityReason,
  }),
  records: state.vehicles,
  resourceLabel,
  fields,
  style,
  ...state.vehiclesMeta,
  error: translateError(state.vehiclesMeta.error),
  createError: translateError(state.vehiclesMeta.createError),
  updateError: translateError(state.vehiclesMeta.updateError),
  deleteError: translateError(state.vehiclesMeta.deleteError),
  canImport: true,
});

const mapDispatchToProps = dispatch => ({
  fetchStart(...args) {
    dispatch(vehiclesActions.fetchStart(...args));
    dispatch(driversActions.fetchStart());
    dispatch(groupsActions.fetchStart());
  },
  createStart: compose(dispatch, vehiclesActions.createStart),
  updateStart: compose(dispatch, vehiclesActions.updateStart),
  deleteStart: compose(dispatch, vehiclesActions.deleteStart),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDManager);

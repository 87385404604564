import React, { memo, useEffect } from 'react';
import { Form, Search, Checkbox, Button, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';
import toArray from 'lodash/toArray';
import upperFirst from 'lodash/upperFirst';
import memoizeOne from 'memoize-one';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

import moment from 'weego-common/src/providers/moment';

import driverPropType from '../../drivers/driverPropType';
import vehiclePropType from '../../vehicles/vehiclePropType';
import tripPropType from '../tripPropType';
import accountPropType from '../../auth/accountPropType';
import useSearch from '../../../hooks/useSearch';
import driverLabel from '../../../utils/driverLabel';
import vehicleLabel from '../../../utils/drivers/vehicleLabel';
import dateToString from '../../../utils/date/dateToString';
import stringToDate from '../../../utils/date/stringToDate';
import timeStringToDate from '../../../utils/date/timeStringToDate';
import dateToTimeString from '../../../utils/date/dateTimeToString';
import colors from '../../../theme/colors';

import PlacesAutocompleteInput from '../../common/PlacesAutocompleteInput';
import isTripAutomaticallyPlanned from '../utils/isTripAutomaticallyPlanned';
import withi18n from 'weego-common/src/hoc/i18n';

const driversToArray = memoizeOne(toArray);
const vehiclesToArray = memoizeOne(toArray);

const DRIVERS_FUSE_OPTIONS = {
  keys: ['firstname', 'lastname', 'phone'],
  threshold: 0.3,
  distance: 200,
};

const VEHHICLES_FUSE_OPTIONS = {
  keys: ['carLabel', 'carNumber', 'brand'],
  threshold: 0.3,
  distance: 200,
};

const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const TripGeneralInfosForm = memo(function TripGeneralInfosForm({
  drivers,
  vehicles,
  trip,
  onChange,
  fetchDrivers,
  fetchVehicles,
  account,
  t,
}) {
  const [driversSearchTerm, driversResults, searchDrivers] = useSearch(
    driversToArray(drivers),
    DRIVERS_FUSE_OPTIONS,
    driverLabel(trip.driver),
  );
  const [vehiclesSearchTerm, vehiclesResults, searchVehicles] = useSearch(
    vehiclesToArray(vehicles),
    VEHHICLES_FUSE_OPTIONS,
    vehicleLabel(trip.vehicle),
  );

  const isAdmin =
    account?.roles?.includes('admin') ||
    account?.roles?.includes('super_admin');

  const isAutomaticallyPlanned = isTripAutomaticallyPlanned(trip);

  useEffect(() => {
    fetchDrivers({
      where: {
        available: true,
      },
      replace: true,
    });
    fetchVehicles({
      where: {
        available: true,
      },
      replace: true,
    });
  }, [fetchDrivers, fetchVehicles]);

  const toggleRecurrenceDay = day => {
    if (trip.recurrence.days.includes(day)) {
      onChange({
        recurrence: {
          ...trip.recurrence,
          days: trip.recurrence.days.filter(d => d !== day),
        },
      });
    } else {
      onChange({
        recurrence: {
          ...trip.recurrence,
          days: trip.recurrence.days.concat(day),
        },
      });
    }
  };

  return (
    <div>
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('Conducteur')}</label>
            <Search
              onResultSelect={(e, { result, value }) => {
                const driver = drivers[result.id];
                onChange({
                  driver,
                  driverId: driver.id,
                });
                searchDrivers(driverLabel(driver));
              }}
              onSearchChange={(e, { value }) => searchDrivers(value)}
              results={map(driversResults, driver => ({
                id: driver.id,
                title: driverLabel(driver),
                description: `${driver.phone || ''} ${
                  driver.transporterCompany || ''
                }`.trim(),
              }))}
              value={driversSearchTerm}
              size="tiny"
            />
          </Form.Field>
          <Form.Field>
            <label>{t('Véhicule')}</label>
            <Search
              onResultSelect={(e, { result }) => {
                const vehicle = vehicles[result.id];
                onChange({
                  vehicle,
                  vehicleId: result.id,
                });
                searchVehicles(vehicleLabel(vehicle));
              }}
              onSearchChange={(e, { value }) => searchVehicles(value)}
              results={map(vehiclesResults, vehicle => ({
                id: vehicle.id,
                title: vehicleLabel(vehicle),
              }))}
              value={vehiclesSearchTerm}
              size="tiny"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('Date de départ')}</label>
            <DateInput
              value={dateToString(trip.departureTime)}
              disabled={!isAdmin}
              onChange={(e, { value }) => {
                console.log(value);
                const selectedDate = moment(stringToDate(value));
                const departureTime = moment(trip.departureTime);
                departureTime
                  .set('years', selectedDate.years())
                  .set('months', selectedDate.months())
                  .set('date', selectedDate.date());
                onChange({
                  departureTime: departureTime.toDate(),
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('Heure de départ')}</label>
            <TimeInput
              value={dateToTimeString(trip.departureTime)}
              disabled={!isAdmin}
              onChange={(e, { value }) => {
                const selectedTime = moment(timeStringToDate(value));
                const departureTime = moment(trip.departureTime);
                departureTime
                  .set('hours', selectedTime.hours())
                  .set('minutes', selectedTime.minutes())
                  .set('seconds', selectedTime.seconds());
                onChange({
                  departureTime: departureTime.toDate(),
                });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('Lieu de départ')}</label>
            <PlacesAutocompleteInput
              place={trip.from}
              disabled={!isAdmin || isAutomaticallyPlanned}
              placeholder={t('Rechercher')}
              onChange={place => {
                onChange({
                  from: place,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>{t("Lieu d'arrivée")}</label>
            <PlacesAutocompleteInput
              place={trip.to}
              disabled={!isAdmin || isAutomaticallyPlanned}
              placeholder={t('Rechercher')}
              onChange={place => {
                onChange({
                  to: place,
                });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field style={styles.recurrentCheckboxContainer} width="eight">
            <div>
              <Header size="small">
                {t('Voyage périodique')}
                <Header.Subheader>
                  {t('Ce voyage se répéte périodiquement')}
                </Header.Subheader>
              </Header>
            </div>
            <Checkbox
              toggle
              checked={!!trip.recurrence}
              disabled={!isAdmin}
              onChange={(e, { checked }) => {
                if (checked) {
                  onChange({
                    recurrence: {
                      days: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
                      frequency: {
                        scale: 1,
                        period: 'W',
                      },
                    },
                  });
                } else {
                  onChange({
                    recurrence: null,
                  });
                }
              }}
            />
          </Form.Field>
        </Form.Group>
        {trip.recurrence && (
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t('Répèter le')}</label>
              <div style={styles.recurrenceDaysContainer}>
                {map(range(1, 7).concat(0), dayIndex => {
                  const value = weekDays[dayIndex];
                  const active = trip.recurrence.days.includes(value);
                  const label = upperFirst(
                    moment().set('day', dayIndex).format('dd'),
                  );
                  return (
                    <Button
                      key={value}
                      disabled={!isAdmin}
                      style={{
                        backgroundColor: active ? colors.PRIMARY : colors.WHITE,
                        border: '1px solid #e9eff4',
                        color: active ? colors.WHITE : colors.BLACK,
                      }}
                      active={active}
                      onClick={() => toggleRecurrenceDay(value)}
                    >
                      {label}
                    </Button>
                  );
                })}
              </div>
            </Form.Field>
            <Form.Field>
              <label>{t('Se termine')}</label>
              <DateInput
                value={dateToString(trip.endDate)}
                disabled={!isAdmin}
                onChange={(e, { value }) => {
                  onChange({
                    endDate: stringToDate(value),
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
    </div>
  );
});

const styles = {
  recurrentCheckboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  recurrenceDaysContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

TripGeneralInfosForm.propTypes = {
  drivers: PropTypes.objectOf(driverPropType),
  vehicles: PropTypes.objectOf(vehiclePropType),
  trip: tripPropType,
  onChange: PropTypes.func.isRequired,
  fetchDrivers: PropTypes.func.isRequired,
  fetchVehicles: PropTypes.func.isRequired,
  account: accountPropType,
};

export default withi18n('trips')(TripGeneralInfosForm);

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import StopsManager from '../../../components/stops/StopsManager';

class StopsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Arrêts');
  }

  render() {
    return <StopsManager />;
  }
}

export default withi18n('menu')(StopsScreen);

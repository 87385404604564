import PropTypes from 'prop-types';

const stopPropType = PropTypes.shape({
  name: PropTypes.string,
  coords: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  demand: PropTypes.number,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
});

const travelRequirementPropType = PropTypes.shape({
  id: PropTypes.string,
  departure: stopPropType.isRequired,
  arrival: stopPropType.isRequired,
});

export default travelRequirementPropType;

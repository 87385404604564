import { createActions } from 'redux-actions';

const { payment } = createActions({
  PAYMENT: {
    REQUEST_ACCOUNT: userId => ({ userId }),
    UPDATE_ACCOUNT: account => account,
    UPDATE_ACCOUNT_ERROR: error => error,
    REFILL: (userId, amount) => ({ userId, amount }),
    REFILL_SUCCESS: () => undefined,
    REFILL_ERROR: error => error,
  },
});

export default payment;

import {create} from 'apisauce';
import moment from 'moment';
import toArray from 'lodash/toArray';

import handleResponseError from '../utils/handleResponseError';

class PaymentClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof PaymentClient
   */
  constructor(endpoint, {tokens}) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/payment`,
    });
  }

  /**
   * Get the current user's payment account
   *
   * @returns {Account} - the payment account
   * @memberof PaymentClient
   */
  async getMyAccount() {
    const {api} = this;
    const response = await api.get(
      '/accounts/me',
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const account = response.data;
    return account;
  }

  /**
   * Get a user's payment account
   *
   * @returns {Account} - the payment account
   * @memberof PaymentClient
   */
  async getAccount(accountOrUserId) {
    const {api} = this;
    const response = await api.get(
      `/accounts/${accountOrUserId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const account = response.data;
    return account;
  }

  /**
   * Get the current user's qr code payload
   *
   * @returns {QRCodePayload} - the qr code payload
   * @memberof PaymentClient
   */
  async getMyQRCode() {
    const {api} = this;
    const response = await api.get(
      '/qrcode/me',
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const payload = response.data;
    return payload;
  }

  /**
   * Buy a ticket
   *
   * @param {Transaction} transaction - the ticketing transaction payload
   * @returns {Transaction} transaction - the resulting ticketing transaction
   * @memberof PaymentClient
   */
  async buyTicket(ticketingTransaction) {
    const {api} = this;
    const response = await api.post(
      '/transactions',
      {type: 'TICKETING', ...ticketingTransaction},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const transactions = response.data.transactions;
    return transactions[0];
  }

  async getTodayTicketCount() {
    const {api} = this;
    const response = await api.get(
      '/transactions/my-transactions',
      {
        filter: {
          where: {
            type: 'TICKETING',
            createdAt: {
              gte: moment().startOf('day'),
            },
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const transactions = response.data.transactions;
    return transactions.length;
  }

  /**
   * Subscribe to a subscription package
   *
   * @param {Transaction} transaction - the subscription transaction payload
   * @returns {Transaction} transaction - the resulting subscription transaction
   * @memberof PaymentClient
   */
  async subscribe(subscriptionTransaction) {
    const {api} = this;
    const response = await api.post(
      '/transactions',
      {type: 'SUBSCRIPTION', ...subscriptionTransaction},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const transactions = response.data.transactions;
    return transactions[0];
  }

  async generateTransactionQRCode(transactionId) {
    const {api} = this;
    const response = await api.get(
      `/qrcode/transactions/${transactionId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const qrCode = response.data;
    return qrCode;
  }

  /**
   * Refills a payment account's balance
   *
   * @param {String} accountId - the id of the account to refill
   * @param {Number} amount - the amount to refill
   * @returns {[Transaction]} transactions - the resulting transactions
   * @memberof PaymentClient
   */
  async refill(accountId, amount) {
    const {api} = this;
    const response = await api.post(
      '/transactions',
      {toId: accountId, amount, type: 'REFILL'},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const transactions = response.data.transactions;
    return transactions;
  }

  /**
   * Credit my account using a token
   *
   * @param {String} token - the crediting token
   * @memberof PaymentClient
   */
  async credit(token) {
    const {api} = this;
    const response = await api.post(
      '/accounts/me/credit',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.account.balance;
  }

  /**
   * Get my transactions
   *
   * @memberof PaymentClient
   */
  async getMyTransactions(filter) {
    const {api} = this;
    const response = await api.get(
      '/transactions/my-transactions',
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.transactions;
  }

  async getPaymentFormUrl({
    amount,
    lang = 'fr',
    currency,
    processor,
    linkedTransactionIds,
  }) {
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    const paymentFormPathsByCurrency = {
      MAD: {
        CMI: '/payment/cmi/form',
        CASHPLUS: '/payment/cashplus/form',
      },
      NGN: {
        PAYSTACK: '/payment/paystack/form',
      },
    };
    const paymentFormPath = processor
      ? paymentFormPathsByCurrency[currency][processor]
      : toArray(paymentFormPathsByCurrency[currency])[0];
    if (paymentFormPath) {
      return `${
        this.endpoint
      }${paymentFormPath}?amount=${amount}&lang=${lang}&token=${token}&linkedTransactionIds=${
        linkedTransactionIds ? linkedTransactionIds.join(',') : ''
      }`;
    } else {
      throw new Error('No payment form for given currency');
    }
  }

  async getTransactionReceiptUrl(transactionId) {
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    return `${
      this.endpoint
    }/payment/transactions/${transactionId}/pdf?token=${token}`;
  }

  async getVectaliaSubscriptionFormUrl() {
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    return `${this.endpoint}/payment/vectalia/subscription-form?token=${token}`;
  }

  /**
   * Get demand price
   *
   * @memberof PaymentClient
   */
  async getDemandPrice(demand) {
    const {api} = this;
    const response = await api.post(
      '/transactions/get-demand-price',
      {demand},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    // If the demand price could not be estimated due to
    // wrong route (e.g. different country), dont throw an error
    // but don't return a price
    if (response.status === 400) {
      return null;
    }
    handleResponseError(response);
    return response.data;
  }

  /**
   * Make a transaction
   *
   * @memberof PaymentClient
   */
  async billForAot(demand, trip) {
    const {api} = this;
    const response = await api.post(
      '/transactions',
      {type: 'AOT_TICKETING', demand, trip},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async getBonusAmounts() {
    const {api} = this;
    const response = await api.get(
      '/transactions/bonus-amounts',
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * Get my subscriptions
   *
   * @memberof PaymentClient
   */
  async getMySubscriptions(filter) {
    const {api} = this;
    const response = await api.get(
      '/subscriptions/my-subscriptions',
      {
        filter,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/payment`);
  }
}

export default PaymentClient;

/**
 * @typedef {Object} Otp
 * @property {String} _id - The id of the otp
 * @property {Number} associatedPhoneNumber - The phone number associated with the otp
 */

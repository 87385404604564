import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import accountsActionCreators, { actionTypes } from '../../actions/accounts';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const accountsSaga =
  tokens && tokens.ACCOUNT_VERIFICATION && decodedToken?.company
    ? makeCrudSaga(
        'accounts',
        actionTypes,
        accountsActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL +
          (decodedToken.roles?.includes('super_admin')
            ? '/auth/accounts'
            : `/auth/companies/${decodedToken.company.id}/accounts`),
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
        },
      )
    : () => {};

export default accountsSaga;

const apiClient = accountsSaga.apiClient;

export { apiClient };

import React, { PureComponent } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Message, Icon } from 'semantic-ui-react';

import Dashboard from './Dashboard';
import Drivers from './DriversScreen';
import Vehicles from './VehiclesScreen';
import Stops from './StopsScreen';
import Groups from './GroupsScreen';
import Accounts from './AccountsScreen';
import Passengers from './PassengersScreen';
import Demands from './DemandsScreen';
import Transactions from './TransactionsScreen';
import CustomFields from './CustomFieldsScreen';
import SideMenu from '../../components/common/SideMenu';
import HeaderMenuContainer from '../../components/common/HeaderMenuContainer';
import TripsPlanningScreen from './TripsPlanningScreen';
import TripsRegistryScreen from './TripsRegistryScreen';
import AutomaticPlannerScreen from './AutomaticPlannerScreen';
import LegacyAutomaticPlannerScreen from './LegacyAutomaticPlannerScreen';
import TicketingTransactions from './TicketingTransactionsScreen';
import FoughalTransactions from './FoughalTransactionsScreen';

const mapStateToProps = state => ({
  account: state.auth.account,
  drawerOpened: state.common.drawerOpened,
});

class WeegoPro extends PureComponent {
  state = {
    headerContent: null,
  };

  componentWillUpdate(nextProps) {
    // Reset header content after route change
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        headerContent: null,
      });
    }
  }

  render() {
    const { match, account, drawerOpened, location } = this.props;
    const { headerContent } = this.state;
    if (account && !account.company) {
      return (
        <div>
          <HeaderMenuContainer />
          <Container style={{ paddingTop: 10 }}>
            <Message warning icon>
              <Icon name="ban" />
              <Message.Content>
                <Message.Header>
                  Votre compte n'est pas un compte Pro
                </Message.Header>
                <p>
                  Vous ne pouvez pas accèder aux services Pro de Weego car votre
                  compte n'est pas associé à une entreprise
                </p>
              </Message.Content>
            </Message>
          </Container>
        </div>
      );
    }
    return (
      <SideMenu currentPath={location.pathname} opened={drawerOpened}>
        <HeaderMenuContainer>{headerContent}</HeaderMenuContainer>
        <Route
          exact
          path={`${match.path}/dashboard`}
          children={({ match }) =>
            match ? (
              <Dashboard
                onHeaderContent={node =>
                  this.setState({
                    headerContent: node,
                  })
                }
              />
            ) : null
          }
        ></Route>
        <Route path={`${match.path}/trips`} component={TripsPlanningScreen} />
        <Route
          path={`${match.path}/registry`}
          component={TripsRegistryScreen}
        />
        <Route path={`${match.path}/drivers`} component={Drivers} />
        <Route path={`${match.path}/vehicles`} component={Vehicles} />
        <Route path={`${match.path}/stops`} component={Stops} />
        <Route path={`${match.path}/groups`} component={Groups} />
        <Route path={`${match.path}/accounts`} component={Accounts} />
        <Route path={`${match.path}/passengers`} component={Passengers} />
        <Route path={`${match.path}/demands`} component={Demands} />
        <Route path={`${match.path}/transactions`} component={Transactions} />
        <Route
          path={`${match.path}/tickets`}
          component={TicketingTransactions}
        />
        <Route
          path={`${match.path}/foughal-transactions`}
          component={FoughalTransactions}
        />
        <Route path={`${match.path}/customFields`} component={CustomFields} />
        <Route
          path={`${match.path}/planner`}
          component={LegacyAutomaticPlannerScreen}
        />
        <Route
          path={`${match.path}/planner-v2`}
          component={AutomaticPlannerScreen}
        />
      </SideMenu>
    );
  }
}

export default withRouter(connect(mapStateToProps)(WeegoPro));

/* eslint-disable */
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';

function flattenObject(ob, {flattenArrays = false} = {}) {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      if (!flattenArrays && isArray(ob[i])) {
        toReturn[i] = `array(${ob[i].length})`;
      } else {
        let flatObject = {};
        if (isArray(ob[i])) {
          flatObject = flattenObject(keyBy(ob[i], item => ob[i].indexOf(item)));
        } else {
          flatObject = flattenObject(ob[i]);
        }
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[`${i}_${x}`] = flatObject[x];
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export default flattenObject;

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import DriversManager from '../../../components/drivers/DriversManager';

class DriversScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Conducteurs');
  }

  render() {
    return <DriversManager />;
  }
}

export default withi18n('menu')(DriversScreen);

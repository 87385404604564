function tryJsonParse(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    if (e.name === 'SyntaxError') {
      return {};
    }
    throw e;
  }
}

export default tryJsonParse;

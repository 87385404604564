import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import XLSX from 'xlsx';
import uniqBy from 'lodash/uniqBy';
import keys from 'lodash/keys';
import toArray from 'lodash/toArray';
import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import zipObjectDeep from 'lodash/zipObjectDeep';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import findKey from 'lodash/findKey';
import random from 'lodash/random';
import trim from 'lodash/trim';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';
import LibPhoneNumber from 'google-libphonenumber';
import Promise from 'bluebird';
import moment from 'moment';
import {
  Button,
  Icon,
  Dropdown,
  Table,
  Popup,
  Message,
  Header,
  Segment,
  Form,
} from 'semantic-ui-react';
import { Marker } from 'react-google-maps';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import withFileHandlers from '../../../hoc/withFileHandlers';
import HighlightedText from '../HighlightedText';
import Map from '../Map';

import { renderFieldContent } from '../CRUDManager/CRUDManager';
import fieldPropType from '../CRUDManager/fieldPropType';
import PlacesAutoCompleteInput from '../PlacesAutocompleteInput';

import redWarningIcon from '../../../assets/images/red-warning.svg';
import locationPin from '../../../assets/images/location-pin.svg';
import housePin from '../../../assets/images/house-pin.svg';
import colors from '../../../theme/colors';
import './ExcelImporter.css';

const phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();
const PNF = LibPhoneNumber.PhoneNumberFormat;

const STEPS = ['IMPORT', 'FIELD_MAPPING', 'PREVIEW', 'PREVIEW_GEOCODE'];

const getConstraints = field =>
  isFunction(field.constraints)
    ? field.constraints({
        isImport: true,
      })
    : field.constraints;

const ExcelImporter = memo(function ExcelImporter({
  resourceLabel,
  fields: allFields,
  relations,
  recordKey,
  validateRecord,
  fetch,
  openedFile,
  saving,
  error,
  onSave,
  renderAfterForm,
  openLocalFile,
  t,
}) {
  const [step, setStep] = useState('IMPORT');
  const [readingFile, setReadingFile] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const [fieldErrors, setFieldErrors] = useState([]);
  const [importing, setImporting] = useState(false);
  const [records, setRecords] = useState([]);
  const [canIgnoreErrors, setCanIgnoreErrors] = useState(false);
  const [errorIgnoreStrategy, setErrorIgnoreStragy] = useState('IGNORE_ERRORS');
  const [parsingError, setParsingError] = useState(null);
  const [rowErrors, setRowErrors] = useState([]);
  const [focusedPlace, setFocusedPlace] = useState(null);
  const mapRef = useRef(React.createRef());

  const fields = useMemo(
    () => allFields.filter(f => !f.disableInImport),
    [allFields],
  );
  const uniqueFields = useMemo(() => fields.filter(f => f.unique), [fields]);
  const hasError = !!rowErrors.find(v => !!v);
  const hasPlaceField = fields.find(field => field.type === 'PLACE');

  const recordsToSave = useMemo(
    () =>
      records.filter((record, index) =>
        errorIgnoreStrategy === 'IGNORE_ERRORED_ROWS'
          ? !rowErrors[index]
          : true,
      ),
    [records, errorIgnoreStrategy, rowErrors],
  );

  useEffect(() => {
    if (!openedFile) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {
        type: 'array',
        cellDates: openedFile.name.endsWith('csv') ? false : true,
        raw: openedFile.name.endsWith('csv') ? true : false,
      });
      const worksheet = toArray(workbook.Sheets)[0];
      const excelJsonData = XLSX.utils.sheet_to_json(worksheet, {
        defval: null,
      });
      setExcelData(excelJsonData);
      const excelColumns = keys(excelJsonData[0]);
      setColumns(excelColumns);
      setStep('FIELD_MAPPING');
      setReadingFile(false);
    };
    reader.onerror = function (e) {
      setReadingFile(false);
    };
    setReadingFile(true);
    reader.readAsArrayBuffer(openedFile);
  }, [openedFile, setColumns]);

  useEffect(() => {
    if (!focusedPlace) {
      return;
    }
    mapRef.current.panTo(focusedPlace.coords);
  }, [focusedPlace]);

  const openExcelFile = useCallback(() => {
    openLocalFile({
      accept: '.xlsx,.csv,.xls,.ods',
    });
  }, [openLocalFile]);

  // Focus the first place value when we enter the PREVIEW_GEOCODE step
  useEffect(() => {
    if (focusedPlace) {
      return;
    }
    if (!records.length) {
      return;
    }
    if (step === 'PREVIEW_GEOCODE') {
      const firstPlaceField = fields.find(field => field.type === 'PLACE');
      const firstPlaceValue = records[0][firstPlaceField.key];
      if (!firstPlaceValue) {
        return;
      }
      setFocusedPlace(firstPlaceValue);
    }
  }, [step, records, focusedPlace, fields, setFocusedPlace]);

  const fieldMappingStorageKey = `weego::ExcelImporter::fieldMapping::${resourceLabel}`;

  useEffect(() => {
    if (isEmpty(fieldMapping)) {
      return;
    }
    window.localStorage.setItem(
      fieldMappingStorageKey,
      JSON.stringify(fieldMapping),
    );
  }, [fieldMapping, fieldMappingStorageKey, resourceLabel]);

  useEffect(() => {
    try {
      const savedFieldMappingString = window.localStorage.getItem(
        fieldMappingStorageKey,
      );
      const savedFieldMapping = JSON.parse(savedFieldMappingString);
      if (!savedFieldMapping) {
        return;
      }
      // Remove columns that are not in the current excel
      // from the field mapping
      const restoredFieldMapping = omitBy(
        savedFieldMapping,
        (column, key) =>
          !columns.includes(column) || !fields.find(f => f.key === key),
      );
      setFieldMapping(restoredFieldMapping);
    } catch (e) {
      if (e.name === 'SyntaxError') {
        return;
      }
      throw e;
    }
  }, [fieldMappingStorageKey, columns, fields]);

  const importAndValidate = useCallback(async () => {
    setImporting(true);
    await Promise.delay();
    setParsingError(null);
    try {
      const fieldMappingErrors = fields.map(field => {
        const constraints = getConstraints(field);
        if (constraints?.presence && !fieldMapping[field.key]) {
          return {
            message: t(
              `Le champ {{field}} est obligatoire. Veuillez lui associer une colonne.`,
              {
                field: t(`fields~${field.label}`),
              },
            ),
          };
        }
        return null;
      });
      if (fieldMappingErrors.find(v => !!v)) {
        setFieldErrors(fieldMappingErrors);
        setImporting(false);
        return;
      }
      const fieldsByKey = keyBy(fields, 'key');
      const parsedRecords = await Promise.map(
        excelData,
        async row => {
          const recordKvPairs = await Promise.map(
            sortBy(Object.keys(fieldMapping)),
            async fieldKey => {
              const field = fieldsByKey[fieldKey];
              const rowValue = row[fieldMapping[fieldKey]];
              const fieldValue = await parseFieldValue(rowValue, field, {
                t,
                row,
                fieldMapping,
                relations,
                gmapsInstance:
                  mapRef.current.context
                    .__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
              });
              return [fieldKey, fieldValue];
            },
          );
          const keys = recordKvPairs.map(pair => pair[0]);
          const values = recordKvPairs.map(pair => pair[1]);
          const record = zipObjectDeep(keys, values);
          return record;
        },
        {
          concurrency: 5,
        },
      );
      setRecords(parsedRecords);
      const errorMessagesByRow = parsedRecords.map(record =>
        validateRecord(record, {
          isImport: true,
        }),
      );
      setRowErrors(errorMessagesByRow);
      if (fetch && !errorMessagesByRow.find(v => !!v)) {
        setCanIgnoreErrors(true);
        if (uniqueFields) {
          await Promise.map(uniqueFields, async uniqueField => {
            const fieldValues = parsedRecords
              .map(record => record[uniqueField.key])
              .filter(v => !!v);
            const response = await fetch({
              filter: {
                where: {
                  [uniqueField.key]: {
                    inq: fieldValues,
                  },
                },
              },
            });
            const { data } = response;
            let fieldConflictingRecords;
            if (isArray(data)) {
              fieldConflictingRecords = data;
            } else {
              fieldConflictingRecords = data[Object.keys(data)[0]];
            }
            if (!fieldConflictingRecords.length) {
              return fieldConflictingRecords;
            }
            fieldConflictingRecords.forEach(conflictingRecord => {
              const recordIndex = parsedRecords.findIndex(
                record =>
                  record[uniqueField.key] ===
                  conflictingRecord[uniqueField.key],
              );
              errorMessagesByRow[recordIndex] =
                errorMessagesByRow[recordIndex] || {};
              parsedRecords[recordIndex][recordKey] =
                conflictingRecord[recordKey];
              const errorsByField = errorMessagesByRow[recordIndex];
              errorsByField[uniqueField.key] = [
                t('Cet enregistrement existe déjà'),
              ];
            });
            return fieldConflictingRecords;
          });
          setRowErrors(errorMessagesByRow);
        }
      }
      setStep('PREVIEW');
    } catch (e) {
      console.error('Error parsing records');
      console.error(e);
      setParsingError(e);
    } finally {
      setImporting(false);
    }
  }, [
    excelData,
    fieldMapping,
    fields,
    relations,
    recordKey,
    validateRecord,
    uniqueFields,
    fetch,
    t,
  ]);

  const updateGeocodingForPlace = useCallback(
    (originalAdress, newPlace) => {
      const fieldsByKey = keyBy(fields, 'key');
      // Iterate over records and update them if they have a place
      // field that matches the original address we updated
      // geocoding for
      const updatedRecords = records.map(record => {
        const recordKeys = Object.keys(record);
        const recordKvPairs = recordKeys.map(recordKey => {
          const recordValue = record[recordKey];
          const field = fieldsByKey[recordKey];
          if (
            field &&
            field.type === 'PLACE' &&
            recordValue.originalAdress === originalAdress
          ) {
            return [
              recordKey,
              merge({}, recordValue, pick(newPlace, 'name', 'coords')),
            ];
          } else {
            return [recordKey, recordValue];
          }
        });
        return fromPairs(recordKvPairs);
      });
      setRecords(updatedRecords);
      setFocusedPlace(newPlace);
    },
    [fields, records],
  );

  const save = useCallback(() => {
    onSave(recordsToSave);
  }, [onSave, recordsToSave]);

  const nextStep = useCallback(() => {
    if (step === 'IMPORT' && openedFile) {
      setStep('FIELD_MAPPING');
    } else if (step === 'FIELD_MAPPING') {
      importAndValidate();
    } else if (step === 'PREVIEW') {
      if (hasPlaceField) {
        setStep('PREVIEW_GEOCODE');
      } else {
        save();
      }
    } else if (step === 'PREVIEW_GEOCODE') {
      save();
    }
  }, [step, hasPlaceField, openedFile, setStep, importAndValidate, save]);

  const previousStep = useCallback(() => {
    const stepIndex = STEPS.indexOf(step);
    if (stepIndex === 0) {
      return;
    }
    setStep(STEPS[stepIndex - 1]);
  }, [step, setStep]);

  return (
    <div style={styles.container} className="excel-importer">
      {error && (
        <Message error>{error.message || t('Erreur inconnue')}</Message>
      )}
      {parsingError && (
        <Message error>
          {parsingError.message || t('Erreur inconnue lors de la lecture')}
        </Message>
      )}
      <Header.Subheader style={styles.instructionSubHeader}>
        {step === 'IMPORT' && (
          <span>{t('Choisissez un fichier excel à importer')}</span>
        )}
        {step === 'FIELD_MAPPING' && (
          <span>
            {t(
              'Faites les correspondances entre les champs du tableau et les en-têtes de votre feuille Excel',
            )}
          </span>
        )}
        {step === 'PREVIEW' && (
          <span>
            {t('Vérifiez les enregistrements qui seront importés puis validez')}
          </span>
        )}
        {step === 'PREVIEW_GEOCODE' && (
          <span>
            {t(
              'Nous avons recherché les adresses sur la carte. Vous pouvez les vérifier ci-dessous.',
            )}
            <br />
            {t(
              "Si une adresse ne correspond pas, vous pouvez la modifier avec le champ de recherche à côté de l'adresse.",
            )}
          </span>
        )}
      </Header.Subheader>
      <div style={styles.body}>
        {step === 'IMPORT' && (
          <Segment style={styles.uploadArea}>
            {openedFile ? openedFile.name : t('Aucun fichier ajouté')}
            <Button icon onClick={openExcelFile}>
              <Icon name="upload" />
            </Button>
          </Segment>
        )}
        {step === 'FIELD_MAPPING' && (
          <div style={styles.fieldMappingContainer}>
            {fieldErrors
              .filter(error => !!error)
              .map(error => (
                <Message key={error.message} error>
                  {error.message}
                </Message>
              ))}

            <Form autoComplete="off">
              {fields.map((field, i) => {
                return (
                  <div key={field.key} style={styles.fieldMappingRow}>
                    <HighlightedText style={styles.fieldMappingLabel}>
                      {t(`fields~${field.label}`)}{' '}
                      {getConstraints(field)?.presence ? (
                        <span style={styles.requiredStar}>*</span>
                      ) : null}
                    </HighlightedText>
                    <Dropdown
                      style={styles.fieldMappingDropdown}
                      selection
                      search
                      error={!!fieldErrors[i]}
                      options={[
                        {
                          key: 'none',
                          value: null,
                          text: t('Aucun'),
                        },
                      ].concat(
                        columns.map(column => ({
                          key: column,
                          value: column,
                          text: column,
                        })),
                      )}
                      value={fieldMapping[field.key]}
                      // See https://github.com/Semantic-Org/Semantic-UI/issues/6075
                      // For whatever reason "nope" works, but "off" does not work...
                      onFocus={e => {
                        e.target.setAttribute('autocomplete', 'nope');
                      }}
                      onChange={(e, { value }) => {
                        setFieldMapping({
                          ...fieldMapping,
                          [field.key]: value,
                        });
                      }}
                    />
                  </div>
                );
              })}
            </Form>
          </div>
        )}
        {step === 'PREVIEW' && (
          <div>
            {hasError && errorIgnoreStrategy !== 'IGNORE_ERRORED_ROWS' && (
              <Message error>
                {/* Error count */}
                {t(
                  '{{count}} erreurs détectées: Veuillez corriger ces erreurs avant de continuer svp',
                  {
                    count: sum(
                      rowErrors
                        .filter(v => !!v)
                        .map(
                          errorsByField =>
                            flatten(toArray(errorsByField)).length,
                        ),
                    ),
                  },
                )}{' '}
                ({/* Line numbers */}
                {rowErrors
                  .map((errorsByField, i) => (errorsByField ? i + 1 : null))
                  .filter(v => !!v)
                  .map(lineNumber =>
                    t(`Ligne {{lineNumber}}`, {
                      lineNumber: lineNumber + 1,
                    }),
                  )
                  .join(', ')}
                )
              </Message>
            )}
            {hasError && (
              <div>
                <Form.Select
                  label={t('Gestion des erreurs') + ' '}
                  options={[
                    canIgnoreErrors && {
                      key: 'IGNORE_ERRORS',
                      value: 'IGNORE_ERRORS',
                      text: t('Importer avec les erreurs'),
                    },
                    {
                      key: 'IGNORE_ERRORED_ROWS',
                      value: 'IGNORE_ERRORED_ROWS',
                      text: t('Ignorer les lignes erronées et importer'),
                    },
                  ].filter(v => !!v)}
                  onChange={(e, { value }) => setErrorIgnoreStragy(value)}
                />
              </div>
            )}
            {renderAfterForm && (
              <div>{renderAfterForm({ isCreate: true, isImport: true })}</div>
            )}
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  {fields.map(field => (
                    <Table.HeaderCell key={field.key}>
                      {t(`fields~${field.label}`)}
                    </Table.HeaderCell>
                  ))}
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {records
                  .map((record, i) => {
                    const errorsByField = rowErrors[i];
                    return (
                      <Table.Row
                        key={i}
                        error={
                          !!errorsByField &&
                          !canIgnoreErrors &&
                          errorIgnoreStrategy !== 'IGNORE_ERRORED_ROWS'
                        }
                        warning={
                          !!errorsByField &&
                          canIgnoreErrors &&
                          errorIgnoreStrategy !== 'IGNORE_ERRORED_ROWS'
                        }
                      >
                        <Table.Cell>{i + 2}</Table.Cell>
                        {fields.map(field => {
                          return (
                            <Table.Cell key={field.key}>
                              {renderFieldContent(
                                record,
                                field,
                                relations,
                                null,
                                t,
                              )}
                              {errorsByField &&
                              errorsByField[field.key] &&
                              errorIgnoreStrategy !== 'IGNORE_ERRORED_ROWS' ? (
                                <span style={styles.errorStar}>*</span>
                              ) : null}
                            </Table.Cell>
                          );
                        })}
                        <Table.Cell>
                          {!!errorsByField &&
                            errorIgnoreStrategy !== 'IGNORE_ERRORED_ROWS' && (
                              <Popup
                                on="hover"
                                content={map(errorsByField, errors =>
                                  errors.map(errorMessage => (
                                    <div key={errorMessage}>{errorMessage}</div>
                                  )),
                                )}
                                trigger={
                                  <img
                                    src={redWarningIcon}
                                    alt="Point d'exclamation rouge"
                                  />
                                }
                                style={styles.errorPopup}
                              />
                            )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                  .filter((rowElement, i) =>
                    errorIgnoreStrategy === 'IGNORE_ERRORED_ROWS'
                      ? !rowErrors[i]
                      : true,
                  )}
              </Table.Body>
            </Table>
          </div>
        )}
        {step === 'PREVIEW_GEOCODE' && (
          <div style={styles.previewGeocodeTableContainer}>
            <Table className="no-header" style={styles.previewGeocodeTable}>
              <Table.Body>
                {uniqBy(
                  flatten(
                    (fields || [])
                      .filter(field => field.type === 'PLACE')
                      .map(placeField => {
                        const placeFieldValues = recordsToSave.map(
                          record => record[placeField.key],
                        );
                        return placeFieldValues;
                      })
                      .filter(v => !!v),
                  ).filter(v => v && v.type !== 'DEFAULT_ADDRESS'),
                  'originalAdress',
                ).map(place => {
                  return (
                    <Table.Row
                      key={place.originalAdress}
                      style={styles.placeRow}
                      onClick={() => setFocusedPlace(place)}
                    >
                      <Table.Cell
                        style={
                          place === focusedPlace
                            ? styles.focusedPlaceCell
                            : null
                        }
                      >
                        <HighlightedText style={styles.originalAdressContainer}>
                          <ReactSVG
                            beforeInjection={svg => {
                              svg.setAttribute('width', '26');
                              svg.setAttribute('height', '32');
                            }}
                            className="original-address-location-pin"
                            src={locationPin}
                          />
                          {place.originalAdress}
                        </HighlightedText>
                      </Table.Cell>
                      <Table.Cell
                        style={
                          place === focusedPlace
                            ? styles.focusedPlaceCell
                            : null
                        }
                      >
                        <HighlightedText>
                          <ReactSVG
                            beforeInjection={svg => {
                              svg.setAttribute('width', '26');
                              svg.setAttribute('height', '32');
                            }}
                            className="place-input-location-pin"
                            src={locationPin}
                          />
                          <PlacesAutoCompleteInput
                            place={place}
                            onChange={place =>
                              updateGeocodingForPlace(
                                place.originalAdress,
                                place,
                              )
                            }
                          />
                        </HighlightedText>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
        <div
          style={{
            display: step === 'PREVIEW_GEOCODE' ? 'block' : 'none',
            ...styles.previewGeocodeMapContainer,
          }}
        >
          <Map ref={mapRef} defaultZoom={12}>
            {focusedPlace && (
              <Marker position={focusedPlace.coords} icon={housePin} />
            )}
          </Map>
        </div>
      </div>
      <div style={styles.footer}>
        <Button onClick={previousStep}>{t('Précédent')}</Button>
        <Button
          color="violet"
          onClick={nextStep}
          loading={readingFile || importing || saving}
          disabled={
            readingFile ||
            importing ||
            saving ||
            (step === 'PREVIEW' &&
              hasError &&
              !canIgnoreErrors &&
              errorIgnoreStrategy !== 'IGNORE_ERRORED_ROWS') ||
            (step === 'PREVIEW' && !recordsToSave.length)
          }
        >
          {(hasPlaceField && step === 'PREVIEW_GEOCODE') ||
          (!hasPlaceField && step === 'PREVIEW')
            ? t('Terminer')
            : t('Suivant')}
        </Button>
      </div>
    </div>
  );
});

const styles = {
  container: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  instructionSubHeader: {
    marginBottom: 20,
    fontSize: 16,
    color: colors.DARK_GREY,
  },
  body: {
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    alignSelf: 'flex-end',
    marginTop: 20,
  },
  uploadArea: {
    color: colors.DARK_GREY,
    minWidth: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldMappingContainer: {
    flex: 1,
  },
  fieldMappingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  fieldMappingLabel: {
    width: 200,
    marginRight: 20,
  },
  requiredStar: {
    color: colors.RED,
    fontWeight: 'bold',
    fontSize: 20,
  },
  fieldMappingDropdown: {
    width: 300,
  },
  errorStar: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  previewGeocodeTable: {
    height: '100%',
    overflow: 'auto',
    flex: 1.5,
    marginRight: 30,
  },
  previewGeocodeMapContainer: {
    flex: 1,
    height: 400,
    minWidth: 400,
  },
  placeRow: {
    cursor: 'pointer',
  },
  focusedPlaceCell: {
    borderColor: colors.PRIMARY,
    borderWidth: 2,
  },
  originalAdressContainer: {
    minWidth: 250,
  },
  errorPopup: {
    color: colors.RED,
  },
};

ExcelImporter.propTypes = {
  fields: PropTypes.arrayOf(fieldPropType),
  validateRecord: PropTypes.func.isRequired,
  openedFile: PropTypes.object,
  saving: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  onSave: PropTypes.func.isRequired,
  openLocalFile: PropTypes.func.isRequired,
};

async function parseFieldValue(stringValue, field, options) {
  if (field.parse) {
    const value = await field.parse(stringValue, options);
    // If the field parser returns undefined
    // proceed with generic parsing
    if (!isUndefined(value)) {
      return value;
    }
  }
  if (field.type === 'STRING') {
    return String(stringValue);
  }
  if (field.type === 'NUMBER') {
    return parseFloat(stringValue);
  }
  if (field.type === 'BOOLEAN') {
    const lowerCaseStringValue = String(stringValue).toLowerCase();
    return lowerCaseStringValue ||
      lowerCaseStringValue === 'true' ||
      lowerCaseStringValue === 'vrai' ||
      lowerCaseStringValue === 'oui'
      ? true
      : false;
  }
  if (field.type === 'PLACE') {
    if (!isString(stringValue)) {
      return null;
    }
    if (isEmpty(stringValue)) {
      return null;
    }
    const parts = stringValue.split(';');
    const stop =
      parts.length > 1
        ? await geocode(parts[1], parts[0], options.gmapsInstance, options.t)
        : await geocode(parts[0], null, options.gmapsInstance, options.t);
    return stop;
  }
  if (field.type === 'PHONE') {
    try {
      if (!stringValue) {
        return null;
      }
      const phoneNumber = phoneUtil.parse(String(stringValue), 'MA');
      const formattedPhoneNumber = phoneUtil.format(phoneNumber, PNF.E164);
      return formattedPhoneNumber;
    } catch (error) {
      return null;
    }
  }
  if (field.type === 'ENUM' || field.type[0] === 'ENUM') {
    if (isString(stringValue)) {
      const { options } = field;
      const optionValuesByLabel = mapValues(
        keyBy(options, 'label'),
        option => option.value,
      );
      const labels = stringValue.split(',').map(val => val.trim());
      const values = labels
        .map(label => optionValuesByLabel[label])
        .filter(value => !isUndefined(value));
      if (isArray(field.type)) {
        return values;
      } else {
        return values[0];
      }
    }
  }
  if (field.type === 'TIME') {
    const momentDate = moment(stringValue, [
      'LT',
      'LTS',
      'HH:mm:ss',
      'hh:mm:ss a',
      moment.ISO_8601,
    ]);
    if (momentDate.isValid()) {
      return momentDate.toDate();
    } else {
      return null;
    }
  }
  if (field.type === 'DATE') {
    const momentDate = moment(stringValue, ['DD/MM/YYYY']);
    if (momentDate.isValid()) {
      return momentDate.toDate();
    } else {
      throw new Error('Invalid date format: ' + stringValue);
    }
  }
  if (field.type === 'REF' || field.type[0] === 'REF') {
    const { relations } = options;
    const relationRecords = relations[field.ref] || {};
    const recordRelations = (stringValue || '')
      .split(',')
      .map(relationLabel => {
        const relationKey = findKey(
          relationRecords,
          relationRecord =>
            trim(relationRecord[field.refKey]) === trim(relationLabel),
        );
        return relationKey;
      })
      .filter(v => !!v);
    if (isArray(field.type)) {
      return recordRelations;
    } else {
      return recordRelations[0];
    }
  }
  return stringValue;
}

const geocodingCache = {};
async function geocode(search, district, gmapsInstance, t) {
  try {
    const cacheKey = `${search}-${district}`;
    if (geocodingCache[cacheKey]) {
      return cloneDeep(geocodingCache[cacheKey]);
    }
    await Promise.delay(random(1000, 2000));
    const placesService = new window.google.maps.places.PlacesService(
      gmapsInstance,
    );
    const districtPlaceResponse = await new Promise((resolve, reject) => {
      const params = {
        query: `${district || ''} Maroc`,
        fields: ['name', 'formatted_address', 'geometry'],
        locationBias: {
          center: {
            lat: 33.571729,
            lng: -7.604896,
          },
          radius: 50000,
        },
      };
      placesService.findPlaceFromQuery(
        params,
        async function findPlaceCallback(results, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else if (
            status ===
            window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
          ) {
            resolve([]);
          } else if (
            status ===
            window.google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
          ) {
            await Promise.delay(2000);
            placesService.findPlaceFromQuery(params, findPlaceCallback);
          } else {
            reject(new Error(status));
          }
        },
      );
    });
    const districtPlace = districtPlaceResponse[0];
    if (!districtPlace) {
      console.warn(`No district found for ${district}`);
      return null;
    }

    let stopPlacesParams, stopPlaceResponse;
    async function findStop(keyword) {
      console.log('Looking for', keyword, 'in district', districtPlace?.name);
      if (district) {
        stopPlacesParams = {
          keyword,
          location: {
            lat: districtPlace.geometry.location.lat(),
            lng: districtPlace.geometry.location.lng(),
          },
          radius: 20000,
          language: 'fr',
        };
        stopPlaceResponse = await new Promise((resolve, reject) => {
          placesService.nearbySearch(
            stopPlacesParams,
            async function nearbySearchCallback(results, status) {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                resolve(results);
              } else if (
                status ===
                window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
              ) {
                resolve([]);
              } else if (
                status ===
                window.google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
              ) {
                await Promise.delay(2000);
                placesService.nearbySearch(
                  stopPlacesParams,
                  nearbySearchCallback,
                );
              } else {
                reject(new Error(status));
              }
            },
          );
        });

        const stopPlace = stopPlaceResponse[0];

        return stopPlace;
      } else {
        stopPlacesParams = {
          query: keyword,
          fields: ['name', 'formatted_address', 'geometry'],
        };
        stopPlaceResponse = await new Promise((resolve, reject) => {
          placesService.findPlaceFromQuery(
            stopPlacesParams,
            async function findPlaceCallback(results, status) {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                resolve(results);
              } else if (
                status ===
                window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
              ) {
                resolve([]);
              } else if (
                status ===
                window.google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
              ) {
                await Promise.delay(2000);
                placesService.findPlaceFromQuery(
                  stopPlacesParams,
                  findPlaceCallback,
                );
              } else {
                reject(new Error(status));
              }
            },
          );
        });

        const stopPlace = stopPlaceResponse[0];
        return stopPlace;
      }
    }

    let stopPlace = await findStop(`${search} ${districtPlace.name}`);
    if (!stopPlace) {
      stopPlace = await findStop(
        `${search
          .replace(/BOULEVARD/, '')
          .replace(/AVENUE/, '')
          .replace(/AVENUEENUE/, '')} ${districtPlace.name}`,
      );
    }
    if (!stopPlace) {
      const heuristicPlaceNameRegexResults =
        /(CAFE|Café|PHCIE|PHARMACIE|MOSQUEE|LYCEE|ECOLE|COLLEGE|CIMETIERE|GARE|ESPACE|HOPITAL|PARC|THEATRE|STATION|COMMISSARIAT|LABO|TERMINUS|SOUK)(.+)/i.exec(
          search,
        );
      if (heuristicPlaceNameRegexResults) {
        const heuristicPlaceName = heuristicPlaceNameRegexResults[0];
        stopPlace = await findStop(
          `${heuristicPlaceName} ${districtPlace.name}`,
        );
        const addressWithoutPlaceName = search.replace(heuristicPlaceName, '');
        if (!stopPlace) {
          stopPlace = await findStop(
            `${addressWithoutPlaceName} ${districtPlace.name}`,
          );
        }
        if (!stopPlace) {
          stopPlace = await findStop(`${heuristicPlaceName}`);
        }
        if (!stopPlace) {
          stopPlace = await findStop(`${addressWithoutPlaceName}`);
        }
      }
    }

    if (!stopPlace) {
      stopPlace = await findStop(
        `${search.split(',')[0]} ${districtPlace.name}`,
      );
    }

    if (!stopPlace) {
      stopPlace = await findStop(`${districtPlace.name}`);
    }

    if (!stopPlace) {
      console.error(
        new Error(
          `No stop place found for ${district} ${search}, ${JSON.stringify(
            stopPlacesParams,
          )} ${JSON.stringify(stopPlaceResponse.data)}`,
        ),
      );
      return null;
    }

    const place = {
      name: stopPlace.name,
      coords: {
        lat: stopPlace.geometry.location.lat(),
        lng: stopPlace.geometry.location.lng(),
      },
      originalAdress: search,
      originalDistrict: district,
    };
    geocodingCache[cacheKey] = cloneDeep(place);
    return place;
  } catch (error) {
    console.error('Error when geocoding', error);
    // Return Casablanca by default when geocoding errors out
    return {
      name: t('crud~Non trouvé'),
      originalAdress: search,
      originalDistrict: district,
      coords: {
        lat: 33.571729,
        lng: -7.604896,
      },
    };
  }
}

export default withFileHandlers()(ExcelImporter);

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { Checkbox } from 'semantic-ui-react';
import ObjectID from 'bson-objectid';
import memoize from 'memoize-one';
import merge from 'lodash/merge';

import withi18n from 'weego-common/src/hoc/i18n';

import { apiClient as passengersApiClient } from '../../sagas/passengers/passengersSaga';

import CRUDManager from '../common/CRUDManager';
import passengersActions from '../../actions/passengers';
import accountsActions from '../../actions/accounts';
import groupsActions from '../../actions/groups';
import { fetchSavedFilters, saveFilters } from '../../utils/persistFilters';

const FILTER_SCOPE = "_passengers";

const fields = [
  {
    key: 'firstName',
    label: 'Prénoms',
    type: 'STRING',
    constraints: {
      presence: true,
    },
  },
  {
    key: 'lastName',
    label: 'Noms',
    type: 'STRING',
  },
  {
    key: 'address',
    label: 'Adresse',
    type: 'PLACE',
    showMap: true,
    constraints: {
      presence: true,
    },
  },
  {
    key: 'phoneNumber',
    label: 'Téléphone',
    type: 'PHONE',
  },
  {
    key: 'clientSuppliedId',
    label: 'Identifiant',
    type: 'STRING',
    unique: true,
  },
];
const resourceLabel = 'passager';
const getRelations = memoize(groups => ({ groups }));

const style = { height: 'calc(100% - 80px)' };

const translateError = memoize(error => {
  if (!error) {
    return null;
  }
  if (error.code === 409 || error.code === 11000) {
    return new Error('Ce numéro de téléphone est déjà associé à un compte');
  }
  return error;
});

const mapStateToProps = state => ({
  recordKey: 'id',
  records: state.passengers,
  relations: getRelations(state.groups),
  resourceLabel,
  fields,
  style,
  ...state.passengersMeta,
  creating: state.passengersMeta.creating || state.accountsMeta.creating,
  updating: state.passengersMeta.updating || state.accountsMeta.updating,
  error: state.passengersMeta.error || state.accountsMeta.error,
  createError: translateError(
    state.passengersMeta.createError || state.accountsMeta.createError,
  ),
  updateError: translateError(
    state.passengersMeta.updateError || state.accountsMeta.updateError,
  ),
  userAccount: state.auth.account,
  userCompany: state.auth.company,
  canImport: true,
});

const mapDispatchToProps = dispatch => ({
  fetchStart(...args) {
    dispatch(passengersActions.fetchStart(...args));
    dispatch(groupsActions.fetchStart());
  },
  createStart: compose(dispatch, passengersActions.createStart),
  updateStart: compose(dispatch, passengersActions.updateStart),
  deleteStart: compose(dispatch, passengersActions.deleteStart),
  accountsFetchStart: compose(dispatch, accountsActions.fetchStart),
  accountsCreateStart: compose(dispatch, accountsActions.createStart),
  accountsUpdateStart: compose(dispatch, accountsActions.updateStart),
  accountsDeleteStart: compose(dispatch, accountsActions.deleteStart),
});

const PassengersManager = ({
  fetchStart,
  createStart,
  updateStart,
  accountsFetchStart,
  accountsCreateStart,
  accountsUpdateStart,
  deleteStart,
  accountsDeleteStart,
  creating,
  createError,
  fields,
  userAccount,
  userCompany,
  t,
  ...props
}) => {
  const [createAccount, setCreateAccount] = useState(true);
  const [pendingPassengerId, setPendingPassengerId] = useState(null);
  const [pendingAccountId, setPendingAccountId] = useState(null);
  const [fieldsForRole, setFieldsForRole] = useState(fields);
  const [showB2C, setShowB2C] = useState(!!fetchSavedFilters()?.[FILTER_SCOPE]?.showB2CFilter);
  const [lastFetchStartData, setLastFetchStartData] = useState(null);

  // Administrators can set groups on passengers
  useEffect(() => {
    if (
      userAccount?.roles?.includes('super_admin') ||
      userAccount?.roles?.includes('admin')
    ) {
      setFieldsForRole(
        fields.concat({
          key: 'groups',
          label: 'Groupes',
          type: ['REF'],
          ref: 'groups',
          refKey: 'name',
        }),
      );
    } else {
      setFieldsForRole(fields);
    }
  }, [fields, userAccount, setFieldsForRole]);

  const createPassengerWithAccount = (passenger, options) => {
    if (createAccount) {
      const accountId = ObjectID().toHexString();
      passenger.userId = accountId;
      setPendingPassengerId(passenger.id);
      accountsCreateStart({
        _id: accountId,
        phoneNumber: passenger.phoneNumber,
        metadata: {
          profile: {
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            homeAddress: passenger.address,
          },
          sendOnboardingMessage: true,
        },
        groups: passenger.groups,
        roles: ['passenger'],
        company: userCompany.id,
      });
      setPendingAccountId(accountId);
    }
    createStart(passenger);
  };

  const updatePassengerWithAccount = passenger => {
    if (passenger.userId) {
      accountsFetchStart({
        where: {
          _id: passenger.userId,
        },
        callback: accounts => {
          const account = accounts[0];
          if (!account) {
            return;
          }
          accountsUpdateStart(
            {
              _id: passenger.userId,
              phoneNumber: passenger.phoneNumber,
              metadata: merge(account.metadata, {
                profile: {
                  firstName: passenger.firstName,
                  lastName: passenger.lastName,
                },
              }),
              groups: passenger.groups,
            },
            {
              callback: () => {
                updateStart(passenger);
              },
            },
          );
        },
      });
    } else {
      updateStart(passenger);
    }
  };

  const fetchB2BOrB2C = useCallback(
    data => {
      saveFilters(FILTER_SCOPE, {showB2CFilter: showB2C})
      showB2C
        ? fetchStart(
            merge({}, data, {
              where: {
                companyId: { exists: false },
              },
            }),
          )
        : fetchStart(data);
      setLastFetchStartData(data);
    },
    [showB2C, fetchStart],
  );

  const fetch = useCallback(query => passengersApiClient.get('', query), []);

  useEffect(() => {
    if (!lastFetchStartData) {
      return;
    }
    fetchB2BOrB2C(lastFetchStartData);
  }, [showB2C, lastFetchStartData, fetchB2BOrB2C]);

  useEffect(() => {
    if (!creating && !createError) {
      setPendingPassengerId(null);
      setPendingAccountId(null);
    } else if (!creating && createError) {
      if (pendingPassengerId) {
        deleteStart({ id: pendingPassengerId }, { silent: true });
      }
      if (pendingAccountId) {
        accountsDeleteStart({ _id: pendingAccountId }, { silent: true });
      }
    }
  }, [
    creating,
    createError,
    pendingAccountId,
    pendingPassengerId,
    deleteStart,
    accountsDeleteStart,
  ]);

  return (
    <CRUDManager
      {...props}
      fields={fieldsForRole}
      canImport={true}
      fetch={fetch}
      fetchStart={fetchB2BOrB2C}
      createStart={createPassengerWithAccount}
      updateStart={updatePassengerWithAccount}
      deleteStart={deleteStart}
      creating={creating}
      createError={createError}
      renderBeforeGlobalActions={() => (
        <div style={styles.b2cCheckboxContainer}>
          <Checkbox
            label="B2C"
            checked={showB2C}
            onChange={(e, { checked }) => setShowB2C(checked)}
          />
        </div>
      )}
      renderAfterForm={({ isCreate, isImport }) =>
        isCreate && (
          <div style={styles.createAccountField}>
            <label htmlFor="create-account-checkbox">
              {!isImport
                ? t('Je crée un compte pour ce passager')
                : t('Je crée des comptes pour ces passagers')}
            </label>
            <Checkbox
              id="create-account-checkbox"
              style={styles.createAccountCheckbox}
              checked={createAccount}
              onChange={(e, { checked }) => setCreateAccount(checked)}
            />
          </div>
        )
      }
    />
  );
};

const styles = {
  createAccountField: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12.5,
    marginTop: 10,
  },
  createAccountCheckbox: {
    marginLeft: 5,
  },
  b2cCheckboxContainer: {
    paddingLeft: 5,
    paddingRight: 5,
  },
};

export default withi18n('fields')(
  connect(mapStateToProps, mapDispatchToProps)(PassengersManager),
);

import React from 'react';

import colors from '../../theme/colors.json';

const HighlightedText = ({ children, style, ...otherProps }) => (
  <div style={{ ...styles.container, ...style }} {...otherProps}>
    {children}
  </div>
);

const styles = {
  container: {
    color: colors.GREY_TEXT,
    backgroundColor: colors.LIGHT_GREY,
    padding: 10,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 9,
    fontSize: 14,
  },
};

export default HighlightedText;

import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import 'moment/locale/fr';

moment.locale('fr');

class TripCountdown extends PureComponent {
  componentDidMount() {
    this.setupCountdown();
  }

  componentDidUpdate() {
    this.setupCountdown();
  }

  setupCountdown() {
    const { departureTime } = this.props;
    const startsIn = moment(departureTime).diff(moment());
    const startsInDuration = moment.duration(startsIn, 'ms').abs();
    // If it starts in less than a minute, refresh every second
    // else if it starts in less than an hour, refresh every minute
    if (startsInDuration.asSeconds() < 60) {
      this.forceRerenderEvery(1000);
    } else {
      this.forceRerenderEvery(60000);
    }
  }

  forceRerenderEvery(interval) {
    if (this.rerenderInterval) {
      clearInterval(this.rerenderInterval);
    }
    this.rerenderInterval = setInterval(() => {
      console.log('force rerendering countdown');
      this.forceUpdate();
    }, interval);
  }

  render() {
    const { departureTime, color } = this.props;
    const startsIn = moment(departureTime).diff(moment());

    let content;
    const duration = moment.duration(startsIn, 'ms').abs();
    if (duration.asHours() > 1) {
      const roundedDuration = Math.round(duration.asHours());
      content = `${roundedDuration} heure${roundedDuration >= 2 ? 's' : ''}`;
    } else if (duration.asMinutes() > 1) {
      const roundedDuration = Math.round(duration.asMinutes());
      content = `${roundedDuration} minute${roundedDuration >= 2 ? 's' : ''}`;
    } else {
      const roundedDuration = Math.round(duration.asSeconds());
      content = `${roundedDuration} seconde${roundedDuration >= 2 ? 's' : ''}`;
    }
    return (
      <div className={`ui ${color} text`}>
        Départ {startsIn > 0 ? 'dans' : 'il y a'}
        <br />
        <span style={styles.countdown}>{content}</span>
      </div>
    );
  }
}

const styles = {
  countdown: {
    fontSize: 18,
    fontWeight: 'bold',
  },
};

TripCountdown.propTypes = {
  departureTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  color: PropTypes.string,
};

export default TripCountdown;

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import toArray from 'lodash/toArray';
import memoizeOne from 'memoize-one';

import tripActions from '../../../actions/trip';
import tripUpdatesActions from '../../../actions/tripUpdates';
import tripEventsActions from '../../../actions/tripEvents';
import driverActions from '../../../actions/drivers';
import vehiclesActions from '../../../actions/vehicles';

import TripsRegistry from './TripsRegistry';

const toArrayMemoized = memoizeOne(toArray);

const mapStateToProps = state => {
  return {
    trips: toArrayMemoized(state.registry.tripsById),
    driversMap: state.drivers,
    vehiclesMap: state.vehicles,
  };
};

const mapDispatchToProps = dispatch => ({
  requestTripsInPeriod: compose(dispatch, tripActions.requestTripsInPeriod),
  clearTrips: compose(dispatch, tripActions.clearTrips),
  requestDrivers: compose(dispatch, driverActions.fetchStart),
  requestVehicles: compose(dispatch, vehiclesActions.fetchStart),
  createTripUpdate: compose(dispatch, tripUpdatesActions.createStart),
  removeTripUpdate: compose(dispatch, tripUpdatesActions.deleteStart),
  createTripEvent: compose(dispatch, tripEventsActions.createStart),
  removeTripEvent: compose(dispatch, tripEventsActions.deleteStart),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(TripsRegistry);

import React, { memo, useState, useEffect, useCallback } from 'react';
import { Button, Icon, Confirm } from 'semantic-ui-react';
import without from 'lodash/without';
import cloneDeep from 'lodash/cloneDeep';
import reverse from 'lodash/reverse';
import { Marker, Polyline } from 'react-google-maps';
import moment from 'moment';
import PropTypes from 'prop-types';

import colors from 'weego-common/src/theme/colors.json';

import tripPropType from '../tripPropType';
import accountPropType from '../../auth/accountPropType';
import stopLabel from '../../../utils/stopLabel';
import orangeStep from '../../../assets/images/orange-step.svg';
import purpleStep from '../../../assets/images/purple-step.svg';
import PlacesAutoCompleteInput from '../../common/PlacesAutocompleteInput';
import Map from '../../common/Map';

import './TripStopsEditor.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import isTripAutomaticallyPlanned from '../utils/isTripAutomaticallyPlanned';
import computeTripStopDates from '../../../utils/computeTripStopDates';
import withi18n from 'weego-common/src/hoc/i18n';

const lineSymbol = {
  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  fillColor: colors.PRIMARY,
  fillOpacity: 1,
};

const TripStopsEditor = memo(function TripStopsEditor({
  trip,
  account,
  getTripPath,
  onChange,
  isVisible = true,
  t,
}) {
  const [pendingStop, setPendingStop] = useState({
    name: '',
  });
  const [editingStopIndex, setEditingStopIndex] = useState(null);
  const [pendingRemovalStop, setPendingRemovalStop] = useState(null);
  const [tripPath, setTripPath] = useState(trip.path);
  // Don't compute path by default if component is not visible
  // to avoid unnecessary direction service calls
  const [disablePathUpdateEffect, setDisablePathUpdateEffect] = useState(
    !isVisible,
  );

  const isAdmin =
    account?.roles?.includes('admin') ||
    account?.roles?.includes('super_admin');

  const isAutomaticallyPlanned = isTripAutomaticallyPlanned(trip);

  const updateTripPath = useCallback(
    async function updateTripPath(computeDates = false) {
      const updatedTrip = await computeTripStopDates(trip);
      setDisablePathUpdateEffect(true);
      setTripPath(updatedTrip.path);
      if (computeDates) {
        onChange({
          from: updatedTrip.from,
          to: updatedTrip.to,
          stops: updatedTrip.stops,
          path: updatedTrip.path,
          legs: updatedTrip.legs,
        });
      }
      setDisablePathUpdateEffect(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getTripPath,
      trip.from,
      trip.to,
      trip.stops,
      trip.departureTime,
      isAutomaticallyPlanned,
    ],
  );

  useEffect(() => {
    if (!tripPath) {
      updateTripPath(false);
    }
  }, [tripPath, updateTripPath]);

  useEffect(() => {
    // This is to prevent infinite effect triggers
    // because this effect changes the from, to and stops
    // properties of the trip which happen to be dependencies
    if (disablePathUpdateEffect) {
      return;
    }
    updateTripPath(true);
    // We don't want to trigger when `disablePathUpdateEffect` changes or `onChange` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTripPath,
    trip.from,
    trip.to,
    trip.stops,
    trip.departureTime,
    isAutomaticallyPlanned,
  ]);

  useEffect(() => {
    setDisablePathUpdateEffect(false);
  }, []);

  const addStop = stop => {
    if (!stop.name || !stop.coords) {
      return;
    }
    onChange({
      stops: (trip.stops || []).concat(stop),
    });
    setPendingStop({
      name: '',
    });
  };

  const savePendingStop = () => {
    if (!pendingStop.name || !pendingStop.coords) {
      return;
    }
    if (editingStopIndex === null) {
      addStop(pendingStop);
    } else {
      onChange({
        stops: trip.stops.map((stop, index) =>
          index === editingStopIndex ? pendingStop : stop,
        ),
      });
      setEditingStopIndex(null);
    }
    setPendingStop({
      name: '',
    });
  };

  const startStopEdit = stop => {
    const stopIndex = trip.stops.indexOf(stop);
    setEditingStopIndex(stopIndex);
    setPendingStop(stop);
  };

  const promptStopRemoval = stop => {
    setPendingRemovalStop(stop);
  };

  const cancelStopRemoval = () => {
    setPendingRemovalStop(null);
  };

  const confirmStopRemoval = () => {
    removeStop(pendingRemovalStop);
    setPendingRemovalStop(null);
  };

  const removeStop = stop => {
    onChange({
      stops: without(trip.stops, stop),
    });
  };

  const onStopDragEnd = dragDropPayload => {
    const { source, destination } = dragDropPayload;
    if (!source || !destination) {
      return;
    }
    const { index: sourceIndex } = source;
    const { index: destinationIndex } = destination;
    const updatedStops = cloneDeep(trip.stops);
    updatedStops.splice(sourceIndex, 1);
    updatedStops.splice(destinationIndex, 0, trip.stops[sourceIndex]);
    onChange({
      stops: updatedStops,
    });
  };

  const reverseStops = () => {
    onChange({
      from: {
        ...trip.to,
        demand: trip.to.demand * -1,
      },
      to: {
        ...trip.from,
        demand: trip.from.demand * -1,
      },
      stops: reverse(trip.stops).map(stop => ({
        ...stop,
        demand: stop.demand * -1,
      })),
    });
  };

  const allStops = [trip.from, ...(trip.stops || []), trip.to];

  return (
    <div className="trip-stops-editor" style={styles.container}>
      <div style={styles.mapContainer}>
        <Map>
          {allStops
            .filter(stop => !!stop)
            .map((stop, index) => (
              <Marker
                key={JSON.stringify(stop.coords) + index}
                icon={
                  stop === trip.from || stop === trip.to
                    ? orangeStep
                    : purpleStep
                }
                position={stop.coords}
              />
            ))}

          {tripPath && (
            <Polyline
              path={tripPath}
              options={{
                strokeColor: colors.PRIMARY,
                strokeWeight: 3,
                strokeOpacity: 0.5,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: '100%',
                    repeat: '150px',
                  },
                ],
              }}
            />
          )}
        </Map>
      </div>
      <div style={styles.stopsListContainer}>
        <PlacesAutoCompleteInput
          reverseGeocode={false}
          place={pendingStop}
          disabled={!isAdmin || isAutomaticallyPlanned}
          onChange={stop => {
            setPendingStop(stop);
            if (editingStopIndex === null && stop.name && stop.coords) {
              addStop(stop);
            }
          }}
          placeholder={t('Rechercher')}
          action={
            editingStopIndex !== null ? (
              <Button icon onClick={savePendingStop}>
                <Icon name={'check circle'} />
              </Button>
            ) : null
          }
        />
        <div style={styles.stopsList}>
          <Button
            icon={{
              name: 'refresh',
            }}
            onClick={() => {
              updateTripPath(true);
            }}
            disabled={!isAdmin}
          ></Button>
          <Button
            icon={{
              name: 'exchange',
            }}
            onClick={() => {
              reverseStops();
            }}
            disabled={!isAdmin}
          ></Button>
          <div current={0} direction="vertical" size="small">
            <StopStep stop={trip.from} isFirstOrLast={true} />
            <DragDropContext onDragEnd={onStopDragEnd}>
              <Droppable droppableId="stops-list">
                {provided => (
                  <div
                    style={styles.droppable}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {trip.stops?.map((stop, index) => (
                      <Draggable
                        key={JSON.stringify(stop.coords) + index}
                        draggableId={JSON.stringify(stop.coords) + index}
                        isDragDisabled={!isAdmin || isAutomaticallyPlanned}
                        index={index}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <StopStep
                              stop={stop}
                              disabled={!isAdmin || isAutomaticallyPlanned}
                              onEdit={() => startStopEdit(stop)}
                              onRemove={() => promptStopRemoval(stop)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <StopStep stop={trip.to} isFirstOrLast={true} />
          </div>
        </div>
      </div>
      <Confirm
        open={!!pendingRemovalStop}
        cancelButton={t('Annuler')}
        confirmButton={t('Supprimer')}
        content={t(`Supprimer l'arrêt {{stop}} ?`, {
          stop: stopLabel(pendingRemovalStop),
        })}
        onCancel={cancelStopRemoval}
        onConfirm={confirmStopRemoval}
      />
    </div>
  );
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  mapContainer: {
    flex: 3,
    height: '100%',
    paddingRight: 30,
  },
  stopsListContainer: {
    flex: 1,
    overflow: 'auto',
  },
  stopsList: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  stopStep: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    height: 50,
  },
  firstLastStopIcon: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: colors.APRICOT,
    border: `solid 3.5px ${colors.APRICOT_TRANSPARENT}`,
    backgroundClip: 'padding-box',
  },
  intermediaryStopIcon: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY,
    border: `solid 3.5px ${colors.PRIMARY_TRANSPARENT}`,
    backgroundClip: 'padding-box',
  },
  stopContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginLeft: 10,
  },
  stopActions: {
    marginLeft: 30,
    // The display: flex is in the CSS
    flexDirection: 'row',
  },
  stopAction: {
    marginLeft: 10,
  },
};

TripStopsEditor.propTypes = {
  trip: tripPropType,
  account: accountPropType,
  getTripPath: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default withi18n('trips')(TripStopsEditor);

const StopStep = ({ stop, isFirstOrLast, disabled, onEdit, onRemove }) => (
  <div className="stop-step" style={styles.stopStep}>
    <div
      style={
        isFirstOrLast ? styles.firstLastStopIcon : styles.intermediaryStopIcon
      }
    ></div>
    <div className="stop-container" style={styles.stopContainer}>
      <div>
        {stopLabel(stop)} {moment(stop.maxDate).format('HH:mm')}
      </div>
      <div className="stop-actions" style={styles.stopActions}>
        {onEdit && (
          <Button
            style={styles.stopAction}
            icon
            size="tiny"
            disabled={disabled}
            onClick={onEdit}
          >
            <Icon name="edit" />
          </Button>
        )}
        {onRemove && (
          <Button
            style={styles.stopAction}
            icon
            size="tiny"
            disabled={disabled}
            onClick={onRemove}
          >
            <Icon name="remove" />
          </Button>
        )}
      </div>
    </div>
  </div>
);

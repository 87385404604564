import { all, spawn } from 'redux-saga/effects';

import vehiclesSaga from './vehicles';
import driversSaga from './drivers';
import stopsSaga from './stops';
import groupsSaga from './groups';
import accountsSaga from './accounts';
import passengersSaga from './passengers';
import demandsSaga from './demands';
import transactionsSaga from './transactions';
import customFieldsSaga from './customFields';
import authSaga from './auth';
import tripSaga from './trip';
import tripUpdatesSaga from './tripUpdates';
import tripEventsSaga from './tripEvents';
import liveMapSaga from './liveMap';
import dashboardSaga from './dashboard';
import notificationSaga from './notification';
import paymentSaga from './payment';
import analyticsSaga from './analytics';

function* rootSaga() {
  yield all([
    spawn(vehiclesSaga),
    spawn(driversSaga),
    spawn(stopsSaga),
    spawn(groupsSaga),
    spawn(accountsSaga),
    spawn(passengersSaga),
    spawn(demandsSaga),
    spawn(transactionsSaga),
    spawn(customFieldsSaga),
    spawn(authSaga),
    spawn(tripSaga),
    spawn(tripUpdatesSaga),
    spawn(tripEventsSaga),
    spawn(liveMapSaga),
    spawn(dashboardSaga),
    spawn(notificationSaga),
    spawn(paymentSaga),
    spawn(analyticsSaga),
  ]);
}

export default rootSaga;

import { handleActions } from 'redux-actions';

import paymentActions from '../../actions/payment';

const DEFAULT_STATE = {
  accountsByUserId: {},
  isRefilling: false,
  isLoadingAccount: false,
  refillError: null,
  updateAccountError: null,
};

export default handleActions(
  {
    [paymentActions.requestAccount]: state => ({
      ...state,
      isLoadingAccount: true,
      updateAccountError: null,
    }),
    [paymentActions.updateAccount]: (state, { payload: account }) => ({
      ...state,
      isLoadingAccount: false,
      accountsByUserId: {
        ...state.accountsByUserId,
        [account.userId]: account,
      },
    }),
    [paymentActions.updateAccountError]: (state, { payload: error }) => ({
      ...state,
      isLoadingAccount: false,
      updateAccountError: error,
    }),
    [paymentActions.refill]: state => ({
      ...state,
      isRefilling: true,
      refillError: null,
    }),
    [paymentActions.refillSuccess]: state => ({
      ...state,
      isRefilling: false,
    }),
    [paymentActions.refillError]: (state, { payload: error }) => ({
      ...state,
      isRefilling: false,
      refillError: error,
    }),
  },
  DEFAULT_STATE,
);

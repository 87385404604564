import React, { memo, useEffect, useState, useCallback, useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import keyBy from 'lodash/keyBy';
import without from 'lodash/without';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import Promise from 'bluebird';

import TripTimeline from '../TripTimeline';
import getTripInstanceKey from '../TripTimeline/getTripInstanceKey';
import getTripPath from '../../../utils/getTripPath';
import withi18n from 'weego-common/src/hoc/i18n';

const TripsTimelineView = memo(function TripsTimelineView({
  trips,
  editing,
  deleting,
  error,
  editTrips,
  deleteTrip,
  createDemand,
  showAlert,
  onAfterEdit,
  onEditStart,
  t,
}) {
  const [localTrips, setLocalTrips] = useState(trips);
  const [dirtyTripKeys, setDirtyTripKeys] = useState([]);
  const [deletedTripIds, setDeletedTripIds] = useState([]);
  const [newDemands, setNewDemands] = useState([]);
  const tripsByInstanceKey = keyBy(localTrips, getTripInstanceKey);
  const [preventRefresh, setPreventRefresh] = useState(false);

  const previousTripsRef = useRef(trips);
  const previousEditingRef = useRef(editing);
  const previousDeletingRef = useRef(deleting);

  useEffect(() => {
    if (trips !== previousTripsRef.current) {
      if (preventRefresh && !isEmpty(dirtyTripKeys)) {
        if (
          !window.confirm(
            t(
              "Vous avez des changement en attende d'enregistrement. Souhaitez-vous continuer et perdre vos changements ?",
            ),
          )
        ) {
          return;
        }
        setDirtyTripKeys([]);
      }
      setLocalTrips(trips);
    }
  }, [dirtyTripKeys, preventRefresh, trips, localTrips, setLocalTrips, t]);

  useEffect(() => {
    if (!editing && !deleting && !error) {
      setDirtyTripKeys([]);
      setDeletedTripIds([]);
      setNewDemands([]);
      onAfterEdit();
    }
  }, [editing, deleting, error, onAfterEdit]);

  // Prevent refresh if our last save did not go through
  useEffect(() => {
    if ((previousEditingRef.current || previousDeletingRef.current) && error) {
      setPreventRefresh(true);
    }
  }, [previousEditingRef, previousDeletingRef, error]);

  useEffect(() => {
    previousTripsRef.current = trips;
  });

  useEffect(() => {
    previousEditingRef.current = editing;
  });

  useEffect(() => {
    previousDeletingRef.current = deleting;
  });

  useEffect(() => {
    if (dirtyTripKeys.length) {
      onEditStart();
      setPreventRefresh(true);
    }
  }, [dirtyTripKeys, onEditStart]);

  const markTripDirty = useCallback(
    tripOrTripArray => {
      const keys = isArray(tripOrTripArray)
        ? tripOrTripArray.map(trip => getTripInstanceKey(trip))
        : [getTripInstanceKey(tripOrTripArray)];
      setDirtyTripKeys(uniq(dirtyTripKeys.concat(keys)));
    },
    [dirtyTripKeys, setDirtyTripKeys],
  );

  const onDemandDragged = useCallback(
    event => {
      const destinationDroppableId = event.destination?.droppableId;
      if (!destinationDroppableId) {
        return;
      }

      const sourceDroppableId = event.source.droppableId;

      const sourceTrip = tripsByInstanceKey[sourceDroppableId];
      const destinationTrip = tripsByInstanceKey[destinationDroppableId];

      if (!sourceTrip || !destinationTrip) {
        return;
      }

      const isSourceManuallyPlanned = !sourceTrip.demandIds?.length;
      const isDestinationManuallyPlanned = !destinationTrip.demandIds?.length;

      if (isSourceManuallyPlanned !== isDestinationManuallyPlanned) {
        alert(
          t(
            'Vous ne pouvez pas déplacer des arrêts automatiquements planifiés sur des trajets manuellement planifiés (ou vice-versa)',
          ),
        );
        return;
      }

      const draggableId = event.draggableId;
      const draggableIdParts = draggableId.split('::');
      const draggedDemandId = draggableIdParts[0];
      const draggedDemand = sourceTrip.demands?.find(
        demand => demand.id === draggedDemandId,
      );
      let sourceStopIndex = parseInt(draggableIdParts[1], 10);

      const sourceStops = [
        sourceTrip.from,
        ...(sourceTrip.stops || []),
        sourceTrip.to,
      ].filter(v => !!v);
      const sourceStop = sourceStops[sourceStopIndex];

      const localDirtyTrips = [];
      const updatedTrips = localTrips.map(trip => {
        const isManuallyPlanned = !trip.demandIds?.length;
        const isSourceTrip = getTripInstanceKey(trip) === sourceDroppableId;
        const isDestinationTrip =
          getTripInstanceKey(trip) === destinationDroppableId;

        if (isSourceTrip || isDestinationTrip) {
          const tripStops = [trip.from, ...(trip.stops || []), trip.to].filter(
            v => !!v,
          );
          let updatedStops = tripStops.map(stop => {
            // Remove the demand from the source stop
            if (stop === sourceStop) {
              const updatedDemandIds = without(stop.demandIds, draggedDemandId);
              return {
                ...stop,
                demandIds: updatedDemandIds,
                demand: Math.sign(stop.demand) * updatedDemandIds.length,
              };
            }
            return stop;
          });

          // Add the demand to the target stop
          if (isDestinationTrip) {
            // First we find the index of the target stop
            // based on the index of the demand
            const stopsByDemandIndex = flatten(
              tripStops.map((stop, index) =>
                (stop.demandIds || [index + '-ad-hoc-demand']).map(() => stop),
              ),
            )
              // We need to filter out the demand currently being dragged
              // if it's being dragged into the same trip otherwise the index
              // is off by one
              .filter(
                (demandId, index) =>
                  sourceDroppableId !== destinationDroppableId ||
                  index !== event.source.index,
              )
              .filter(v => !!v);
            const targetStop = stopsByDemandIndex[event.destination.index];
            const targetStopIndex = tripStops.indexOf(targetStop);
            const newStop = {
              ...sourceStop,
              demandIds: isManuallyPlanned ? undefined : [draggedDemandId],
              demand: isManuallyPlanned
                ? undefined
                : Math.sign(sourceStop.demand),
            };
            // If we can't find a target stop, that means the demand was dragged at the end
            if (targetStopIndex === -1) {
              updatedStops.push(newStop);
            } else {
              updatedStops.splice(targetStopIndex, 0, newStop);
              // This means we inserted before the source, so the source
              // index shifted
              if (isSourceTrip && targetStopIndex < sourceStopIndex) {
                sourceStopIndex = sourceStopIndex + 1;
              }
            }
          }
          // Only do this for automatically planned trips
          if (!isManuallyPlanned) {
            // Remove stops that no longer contain a demand
            updatedStops = updatedStops.filter(stop => stop.demandIds?.length);
            // Merge stops that are adjacent and the same
            updatedStops = updatedStops
              .map((stop, stopIndex) => {
                const nextStop = updatedStops[stopIndex + 1];
                if (!nextStop) {
                  return stop;
                }
                if (
                  isEqual(stop.coords, nextStop.coords) &&
                  isEqual(stop.maxDate, nextStop.maxDate)
                ) {
                  nextStop.demandIds = (stop.demandIds || []).concat(
                    nextStop.demandIds || [],
                  );
                  nextStop.demand =
                    Math.sign(nextStop.demand) * nextStop.demandIds.length;
                  return null;
                }
                return stop;
              })
              .filter(stop => stop !== null);
          } else {
            if (isSourceTrip) {
              updatedStops.splice(sourceStopIndex, 1);
            }
          }
          const from = updatedStops[0];
          const fromMaxDate = moment(from?.maxDate);
          const departureTime = moment(trip.departureTime)
            .set({
              hours: fromMaxDate.hours(),
              minutes: fromMaxDate.minutes(),
              seconds: fromMaxDate.seconds(),
            })
            .toDate();
          const updatedTrip = {
            ...trip,
            departureTime,
            from,
            to: updatedStops.length > 1 ? last(updatedStops) : null,
            stops: updatedStops.slice(1, updatedStops.length - 1),
            demandIds: isManuallyPlanned
              ? undefined
              : uniq(flatten(updatedStops.map(stop => stop.demandIds))),
            demands: isManuallyPlanned
              ? undefined
              : uniqBy(
                  isDestinationTrip
                    ? (trip.demands || []).concat(draggedDemand)
                    : trip.demands,
                  'id',
                ),
          };
          localDirtyTrips.push(updatedTrip);
          return updatedTrip;
        }
        return trip;
      });

      markTripDirty(localDirtyTrips);
      setLocalTrips(updatedTrips);
    },
    [localTrips, tripsByInstanceKey, markTripDirty, t],
  );

  const addDemandToTrip = useCallback(
    (trip, demand, index) => {
      const tripStops = [trip.from, ...(trip.stops || []), trip.to];
      tripStops.splice(index, 0, {
        ...demand.departure,
        maxDate: new Date(),
        demandIds: [demand.id],
        demand: 1,
      });
      tripStops.splice(index + 1, 0, {
        ...demand.arrival,
        maxDate: new Date(),
        demandIds: [demand.id],
        demand: -1,
      });
      const from = tripStops[0];
      const fromMaxDate = moment(from?.maxDate);
      const departureTime = moment(trip.departureTime)
        .set({
          hours: fromMaxDate.hours(),
          minutes: fromMaxDate.minutes(),
          seconds: fromMaxDate.seconds(),
        })
        .toDate();
      const updatedTrip = {
        ...trip,
        departureTime,
        from,
        to: tripStops.length > 1 ? last(tripStops) : null,
        stops: tripStops.slice(1, tripStops.length - 1),
        demandIds: trip.demandIds.concat(demand.id),
        demands: trip.demands.concat(demand),
      };
      markTripDirty(updatedTrip);
      setLocalTrips(
        localTrips.map(t =>
          getTripInstanceKey(t) === getTripInstanceKey(trip) ? updatedTrip : t,
        ),
      );
      setNewDemands(newDemands.concat(demand));
    },
    [localTrips, newDemands, markTripDirty],
  );

  const editTripStop = useCallback(
    (trip, updatedStop, index) => {
      const tripStops = [trip.from, ...(trip.stops || []), trip.to];
      tripStops.splice(index, 1, updatedStop);
      const from = tripStops[0];
      const fromMaxDate = moment(from?.maxDate);
      const departureTime = moment(trip.departureTime)
        .set({
          hours: fromMaxDate.hours(),
          minutes: fromMaxDate.minutes(),
          seconds: fromMaxDate.seconds(),
        })
        .toDate();
      const updatedTrip = {
        ...trip,
        departureTime,
        from,
        to: tripStops.length > 1 ? last(tripStops) : null,
        stops: tripStops.slice(1, tripStops.length - 1),
      };
      markTripDirty(updatedTrip);
      setLocalTrips(
        localTrips.map(t =>
          getTripInstanceKey(t) === getTripInstanceKey(trip) ? updatedTrip : t,
        ),
      );
    },
    [localTrips, markTripDirty],
  );

  const deleteDemandFromTrip = useCallback(
    (trip, demand) => {
      const tripStops = [trip.from, ...(trip.stops || []), trip.to];
      // If the demand stop contains real demands, we simply remove the demand
      // from the stop and then remove stops without demands
      // If it's manually planned however and the stop does not have demands
      // We simply remove it from the stops
      const updatedStops = demand.stop?.demandIds?.length
        ? tripStops
            .map(stop => {
              if (stop.demandIds.includes(demand.id)) {
                const updatedDemandIds = without(stop.demandIds, demand.id);
                return {
                  ...stop,
                  demandIds: updatedDemandIds,
                  demand: Math.sign(stop.demand) * updatedDemandIds.length,
                };
              }
              return stop;
            })
            .filter(stop => stop.demandIds?.length)
        : tripStops.filter(stop => stop !== demand.stop);
      const from = updatedStops[0];
      const fromMaxDate = moment(from?.maxDate);
      const departureTime = moment(trip.departureTime)
        .set({
          hours: fromMaxDate.hours(),
          minutes: fromMaxDate.minutes(),
          seconds: fromMaxDate.seconds(),
        })
        .toDate();
      const updatedTrip = {
        ...trip,
        departureTime,
        from,
        to: updatedStops.length > 1 ? last(updatedStops) : null,
        stops: updatedStops.slice(1, updatedStops.length - 1),
        demandIds: without(trip.demandIds, demand.id),
        demands: trip.demands?.filter(d => d.id !== demand.id),
      };
      markTripDirty(updatedTrip);
      setLocalTrips(
        localTrips.map(t =>
          getTripInstanceKey(t) === getTripInstanceKey(trip) ? updatedTrip : t,
        ),
      );
    },
    [localTrips, markTripDirty],
  );

  const deleteLocalTrip = useCallback(
    trip => {
      setDeletedTripIds(deletedTripIds.concat(trip.id));
      setDirtyTripKeys(
        dirtyTripKeys.filter(tripKey => tripKey !== getTripInstanceKey(trip)),
      );
      setLocalTrips(localTrips.filter(t => t.id !== trip.id));
    },
    [deletedTripIds, dirtyTripKeys, localTrips],
  );

  const saveTrips = useCallback(
    async trips => {
      setPreventRefresh(false);
      // Editing trips changes their departure time. If the trip is a recurrent one,
      // we need to keep the original departure date and apply the time changes
      const tripsWithAppropriateDepartureTime = trips.map(t => ({
        ...t,
        departureTime: t.originalDepartureTime
          ? moment(t.originalDepartureTime)
              .set({
                hours: moment(t.departureTime).hours(),
                minutes: moment(t.departureTime).minutes(),
                seconds: moment(t.departureTime).seconds(),
              })
              .toDate()
          : t.departureTime,
      }));
      const tripsWithChangedFields = tripsWithAppropriateDepartureTime.map(
        trip =>
          pick(trip, [
            'id',
            'departureTime',
            'demands',
            'demandIds',
            'driverId',
            'vehicleId',
            'from',
            'to',
            'stops',
            'groups',
          ]),
      );
      const tripsWithPathAndLegs = await Promise.map(
        tripsWithChangedFields,
        async trip => {
          try {
            const { decoded_polyline: path, legs } = await getTripPath(trip);
            return {
              ...trip,
              legs,
              path,
            };
          } catch (error) {
            if (
              window.confirm(
                t(
                  `Nous n'avons pas pu mettre a jour le chemin du trajet: {{errorMessage}}. Continuer ?`,
                  {
                    errorMessage: error.message,
                  },
                ),
              )
            ) {
              return trip;
            } else {
              throw error;
            }
          }
        },
      );
      editTrips(tripsWithPathAndLegs);
    },
    [editTrips, t],
  );

  const saveDirtyTrips = useCallback(() => {
    for (let tripKey of dirtyTripKeys) {
      const trip = localTrips.find(t => getTripInstanceKey(t) === tripKey);
      if (!trip) {
        continue;
      }
      if (!trip.from || !trip.to) {
        showAlert(
          t('Erreur'),
          t('Un trajet doit au moins contenir deux arrêts'),
          'error',
        );
        return;
      }
    }
    const dirtyTrips = dirtyTripKeys
      .map(tripKey => localTrips.find(t => getTripInstanceKey(t) === tripKey))
      .filter(v => !!v);
    if (dirtyTrips.length) {
      saveTrips(dirtyTrips);
    }
    deletedTripIds.forEach(tripId => {
      deleteTrip(tripId);
    });
    newDemands.forEach(demand => {
      createDemand(demand);
    });
  }, [
    localTrips,
    dirtyTripKeys,
    deletedTripIds,
    newDemands,
    saveTrips,
    deleteTrip,
    createDemand,
    showAlert,
    t,
  ]);

  const hasChanges =
    (dirtyTripKeys && dirtyTripKeys.length) ||
    (deletedTripIds && deletedTripIds.length);
  return (
    <DragDropContext onDragEnd={onDemandDragged}>
      <div style={styles.container}>
        <Button
          style={styles.saveButton}
          onClick={saveDirtyTrips}
          loading={editing || deleting}
          disabled={!hasChanges}
          primary={hasChanges}
        >
          {t('Enregistrer')}
        </Button>
        <div style={styles.timelinesContainer}>
          <TripsTimelineList
            trips={localTrips}
            onDemandAdd={addDemandToTrip}
            onStopEdit={editTripStop}
            onDemandDelete={deleteDemandFromTrip}
            onTripDelete={deleteLocalTrip}
            onSave={saveTrips}
            dirtyTripKeys={dirtyTripKeys}
          />
        </div>
      </div>
    </DragDropContext>
  );
});

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: -26, // height of switch button
  },
  saveButton: {
    alignSelf: 'flex-end',
    marginBottom: 10,
  },
  timelinesContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    overflow: 'auto',
  },
  tripTimelineContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
};

export default withi18n('dashboard')(TripsTimelineView);

const TripsTimelineList = memo(function TripsTimelineList({
  trips,
  onDemandAdd,
  onStopEdit,
  onDemandDelete,
  onTripDelete,
  dirtyTripKeys,
}) {
  return trips?.map(trip => {
    return (
      <div key={getTripInstanceKey(trip)} style={styles.tripTimelineContainer}>
        <TripTimeline
          trip={trip}
          onDemandAdd={onDemandAdd}
          onStopEdit={onStopEdit}
          onDemandDelete={onDemandDelete}
          onTripDelete={onTripDelete}
          isDirty={dirtyTripKeys.includes(getTripInstanceKey(trip))}
        />
      </div>
    );
  });
});

import React, { Component } from 'react';
import { Dropdown, Menu, Icon, Image } from 'semantic-ui-react';
import withi18n from 'weego-common/src/hoc/i18n';

import profileImage from '../../assets/images/profile.png';

class HeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  disconnect = () => {
    const { logout } = this.props;
    logout();
    window.location.replace('/auth');
  };

  render() {
    const { profile, toggleDrawer, children, t } = this.props;
    return (
      <div style={styles.headerContainer}>
        <Menu size="huge" style={styles.menu} borderless text>
          <Menu.Item fitted style={styles.headerLogoContainer}>
            <Icon
              onClick={() => toggleDrawer()}
              name="bars"
              color="violet"
              style={styles.cursor}
            />
          </Menu.Item>

          <Menu.Item fitted style={styles.headerContentContainer}>
            {children}
          </Menu.Item>

          <Menu.Menu
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            position="right"
          >
            <Icon
              style={styles.cursor}
              name="bell outline"
              color="violet"
            ></Icon>
            <Dropdown
              trigger={
                <span>
                  <Image avatar src={profileImage} />{' '}
                  {`${(profile && profile.firstName) || 'Mon compte'}`}
                </span>
              }
              item
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.disconnect}>
                  {t('Déconnexion')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
        <div
          style={{
            borderTop: '1px solid #979797',
            marginTop: 10,
            marginBottom: 10,
            opacity: 0.12,
          }}
        ></div>
      </div>
    );
  }
}

const styles = {
  menu: { margin: 0 },
  headerLogoContainer: { paddingLeft: 10 },
  headerContentContainer: { flex: 1 },
  headerContainer: {
    paddingTop: 10,
  },
  cursor: { cursor: 'pointer' },
};

export default withi18n('common')(HeaderMenu);

import {takeEvery, takeLatest, all, put} from 'redux-saga/effects';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import apisauce from 'apisauce';
import pluralize from 'pluralize';

function makeCrudSaga(
  resourceName,
  actionTypes,
  actionCreators,
  resourceURL,
  options = {},
) {
  const api = apisauce.create({
    baseURL: resourceURL,
    headers: {},
  });
  options.key = options.key || '_id';
  options.useRootResponse = options.useRootResponse || false;

  async function refreshToken(tokenParam) {
    const token = tokenParam || options.token;
    if (!token) {
      return;
    }

    if (isFunction(token)) {
      try {
        const tokenString = await token();
        api.setHeader('Authorization', `Bearer ${tokenString}`);
      } catch (e) {
        console.error('Error when setting token in crudSaga', e);
      }
    } else {
      api.setHeader('Authorization', `Bearer ${token}`);
    }
  }

  refreshToken();

  function* count(action) {
    const countResponse = yield api.get('/count', merge(
      {}, action.data?.query, {filter:{
        search: action.data?.search,
        where: action.data?.where
      }}
    ));
    if (!countResponse.ok) {
      const error = new Error();
      error.message = get(countResponse.data, 'message', 'Unknown error');
      throw error;
    }
    return countResponse.data.count;
  }

  function* fetch(action) {
    try {
      const response = yield api.get('', merge(
        {}, action.data?.query, {filter:{
          skip: action.data?.skip, limit: action.data?.limit, search: action.data?.search, order: action.data?.order,
          where: action.data?.where
        }}
      ));
      if (!response.ok) {
        const error = new Error();
        error.message = get(response.data, 'message', 'Unknown error');
        throw error;
      }

      let countValue = null;
      if(action.data && action.data.skip !== null && action.data.limit !== null) {
        countValue = yield count(action);
      }
      
      const records = options.useRootResponse
        ? response.data
        : response.data[resourceName];
      yield put(actionCreators.fetchSuccess(records, {
        replace: action?.data?.replace,
        count: countValue ? countValue : null
      }));
      if (action.data?.callback) {
        action.data.callback(records);
      }
    } catch (error) {
      console.error(error);
      yield put(actionCreators.fetchError(error));
    }
  }

  function* create(action) {
    const {record} = action;
    try {
      const response = yield api.post('', omit(record, 'createdAt', 'updatedAt', options.stripKeyInRequest ? options.key : undefined));
      if (!response.ok) {
        const error = new Error();
        error.message =
          get(response.data, 'message') || get(response.data, 'error.message');
        error.code =
          get(response.data, 'code') ||
          get(response.data, 'error.code') ||
          response.status;
        error.details = get(response.data, 'details') || get(response.data, 'error.details');
        throw error;
      }
      const createdRecord = options.useRootResponse
        ? response.data
        : response.data[pluralize.singular(resourceName)] || response.data[resourceName];
      if (action.data?.callback) {
        action.data.callback(createdRecord);
      }
      const dispatchCreatedRecord = function* (recordToDispatch) {
        if (record.id === recordToDispatch.id) {
          yield put(actionCreators.createSuccess({
            ...record,
            ...recordToDispatch
          }));
        } else {
          yield put(actionCreators.deleteSuccess(record));
          yield put(actionCreators.createSuccess(recordToDispatch));
        }
      }
      if (isArray(createdRecord)) {
        yield all(createdRecord.map(dispatchCreatedRecord));
      } else {
        yield dispatchCreatedRecord(createdRecord);
      }
    } catch (e) {
      console.error(e);
      yield put(actionCreators.createError(e, record));
    }
  }

  function* remove(action) {
    const {record} = action;
    try {
      const response = yield api.delete(`/${record[options.key]}`);
      if (!response.ok) {
        const error = new Error();
        error.message =
          get(response.data, 'message') || get(response.data, 'error.message');
        error.code =
          get(response.data, 'code') ||
          get(response.data, 'error.code') ||
          response.status;
          error.details = get(response.data, 'details') || get(response.data, 'error.details');
        throw error;
      }
      yield put(actionCreators.deleteSuccess(record));
    } catch (e) {
      console.error(e);
      yield put(actionCreators.deleteError(e, record));
    }
  }

  function* update(action) {
    const {record} = action;
    try {
      const response = yield api.patch(`/${record[options.key]}`, omit(record, 'createdAt', 'updatedAt'));
      if (!response.ok) {
        const error = new Error();
        error.message =
          get(response.data, 'message') || get(response.data, 'error.message');
        error.code =
          get(response.data, 'code') ||
          get(response.data, 'error.code') ||
          response.status;
        error.details = get(response.data, 'details') || get(response.data, 'error.details');
        throw error;
      }
      const updatedRecord = options.useRootResponse
        ? response.data
        : response.data[pluralize.singular(resourceName)];
        if (action.data?.callback) {
          action.data.callback(updatedRecord);
        }
      yield put(actionCreators.updateSuccess({
        ...record,
        ...updatedRecord
      }));
    } catch (e) {
      console.error(e);
      yield put(actionCreators.updateError(e, record));
    }
  }

  function* crudRootSaga() {
    yield all([
      takeLatest(actionTypes.fetchStart, fetch),
      takeEvery(actionTypes.createStart, create),
      takeEvery(actionTypes.deleteStart, remove),
      takeEvery(actionTypes.updateStart, update),
    ]);
  }

  crudRootSaga.refreshToken = refreshToken;
  crudRootSaga.apiClient = api;

  return crudRootSaga;
}

export default makeCrudSaga;

import { handleActions } from 'redux-actions';
import alertActions from '../../actions/alert';

const DEFAULT_STATE = {
  openedAlert: null,
};

export default handleActions(
  {
    [alertActions.show]: (state, action) => ({
      ...state,
      openedAlert: {
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type,
      },
    }),
    [alertActions.hide]: state => ({
      ...state,
      openedAlert: null,
    }),
  },
  DEFAULT_STATE,
);

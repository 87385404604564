import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router';
import { lifecycle } from 'recompose';

import tripActions from '../../../actions/trip';
import customFieldsActions from '../../../actions/customFields';

import TripsManager from './TripsManager';

const mapStateToProps = state => ({
  trips: state.trip.trips,
  deleting: state.trip.deleting,
  editing: state.trip.editing,
  creating: state.trip.creating,
  error: state.trip.error,
  account: state.auth.account,
  customFields: state.customFields,
});

const mapDispatchToProps = dispatch => ({
  fetchTrips: compose(dispatch, tripActions.requestTrips),
  clearTrips: compose(dispatch, tripActions.clearTrips),
  deleteManyTrips: compose(dispatch, tripActions.deleteManyTrips),
  deleteTrip: compose(dispatch, tripActions.deleteTrip),
  editTrip: compose(dispatch, tripActions.editTrip),
  editManyTrips: compose(dispatch, tripActions.editManyTrips),
  createTrip: compose(dispatch, tripActions.createTrip),
  fetchCustomFields: compose(dispatch, customFieldsActions.fetchStart),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchCustomFields();
    },
  }),
);

export default enhance(TripsManager);

import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';
import moment from 'weego-common/src/providers/moment';

import driverIcon from '../../assets/images/driver-icon.svg';
import busStopIcon from '../../assets/images/bus-stop.svg';
import userIcon from '../../assets/images/user-icon.svg';

import './StopEvents.css';
import withi18n from 'weego-common/src/hoc/i18n';

const defaultEventLabels = {
  STOP_VISITED: "L'arrêt a été visité",
  STOP_FINISHED: "Le conducteur a confirmé l'arrêt",
  PASSENGER_PICKED_UP: 'Le passager a été récupéré',
};

const defaultEventColors = {
  STOP_VISITED: 'green',
  STOP_FINISHED: 'green',
  PASSENGER_PICKED_UP: 'blue',
};

const defaultEventIcons = {
  STOP_VISITED: busStopIcon,
  STOP_FINISHED: driverIcon,
  PASSENGER_PICKED_UP: userIcon,
};

const eventColor = (event, stop, trip) => {
  const departureTime = moment(trip.departureTime);
  const stopMaxDate = moment(stop.maxDate).set({
    date: departureTime.date(),
    month: departureTime.month(),
    year: departureTime.year(),
  });
  if (
    event.type === 'STOP_VISITED' &&
    moment(event.date).diff(stopMaxDate, 'minutes') > 10
  ) {
    return 'orange';
  }
  return defaultEventColors[event.type];
};

const eventLabel = (event, stop, trip, t) => {
  const departureTime = moment(trip.departureTime);
  const stopMaxDate = moment(stop.maxDate).set({
    date: departureTime.date(),
    month: departureTime.month(),
    year: departureTime.year(),
  });
  if (
    event.type === 'STOP_VISITED' &&
    moment(event.date).diff(moment(stopMaxDate), 'minutes') > 10
  ) {
    return t(`Arrêt visité en retard (+{{diff}})`, {
      diff: moment(event.date).diff(stopMaxDate, 'minutes'),
    });
  }
  return t(defaultEventLabels[event.type]);
};

const eventIcon = (event, stop, trip) => {
  return defaultEventIcons[event.type];
};

const StopEvents = memo(function StopEvents({ stop, events, trip, t }) {
  return (
    <div className="stop-events">
      {events.map(event => (
        <Event event={event} stop={stop} trip={trip} t={t} />
      ))}
    </div>
  );
});

const Event = ({ event, stop, trip, t }) => (
  <div style={styles.eventContainer}>
    <span
      style={{
        ...styles.eventLabel,
        color: eventColor(event, stop, trip),
      }}
    >
      <ReactSVG src={eventIcon(event, stop, trip)} style={styles.eventIcon} />
      {eventLabel(event, stop, trip, t)}
    </span>
    <span style={{ color: eventColor(event, stop, trip) }}>
      {moment(event.date).format('HH:mm')}
    </span>
  </div>
);

const styles = {
  eventContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  eventIcon: {
    marginRight: 5,
  },
};

export default withi18n('dashboard')(StopEvents);

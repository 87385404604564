import React, { memo } from 'react';
import keyBy from 'lodash/keyBy';

import PassengersList from './PassengersList';
import TripActivityLog from './TripActivityLog';

const TripDetails = memo(function TripDetails({
  trip,
  tripEvents,
  fetchTripEvents,
}) {
  return (
    <div style={styles.container}>
      <div style={styles.leftPane}>
        <PassengersList trip={trip} demands={keyBy(trip.demands, 'id')} />
      </div>
      <TripActivityLog
        trip={trip}
        tripEvents={tripEvents}
        fetchTripEvents={fetchTripEvents}
        style={styles.rightPane}
      />
    </div>
  );
});

const styles = {
  container: {
    display: 'flex',
  },
  leftPane: {
    flex: 2,
    paddingRight: 25,
  },
  rightPane: {
    flex: 1,
  },
};

export default TripDetails;

import React, { memo, useState } from 'react';
import memoize from 'memoize-one';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import map from 'lodash/map';
import omit from 'lodash/omit';
import flatten from 'lodash/flatten';
import findLastIndex from 'lodash/findLastIndex';
import { Header, Segment, Button, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import driverLabel from 'weego-common/src/utils/driverLabel';
import withi18n from 'weego-common/src/hoc/i18n';

import tripPropType from '../tripPropType';

const groupTripsByFolder = memoize(trips => {
  return groupBy(trips, 'folder');
});

const DriverRotationTool = memo(
  ({ trips, rotationField, saving, onSave, t }) => {
    const [localTrips, updateLocalTrips] = useState(trips);
    const tripGroupsByFolder = groupTripsByFolder(localTrips);

    const rotateDrivers = () => {
      const rotatedGroups = map(tripGroupsByFolder, group =>
        group.map((trip, index) => {
          let previousDriverTripIndex = findLastIndex(
            group.slice(0, index),
            t => get(t, rotationField) !== get(trip, rotationField),
          );
          if (previousDriverTripIndex === -1) {
            previousDriverTripIndex = group.length - 1;
          }
          const previousDriverTrip = group[previousDriverTripIndex];
          return {
            ...trip,
            driver: previousDriverTrip.driver || null,
            driverId: previousDriverTrip.driverId || null,
          };
        }),
      );
      const updatedTrips = flatten(rotatedGroups);
      updateLocalTrips(updatedTrips);
    };

    const save = () => {
      onSave(localTrips.map(t => omit(t, 'driver')));
    };

    return (
      <div>
        <Button primary disabled={saving} onClick={rotateDrivers}>
          {t('Pivoter')}
        </Button>
        <Button primary disabled={saving} loading={saving} onClick={save}>
          {t('Enregistrer')}
        </Button>
        {map(tripGroupsByFolder, (group, folder) => (
          <Segment key={folder}>
            <Header>{folder === 'undefined' ? 'Aucun' : folder}</Header>
            <Table
              celled
              fixed
              compact="very"
              singleLine
              verticalAlign="middle"
            >
              <Table.Body>
                {group.map(trip => {
                  const tripLabel = `${get(trip, 'from.name')} -> ${get(
                    trip,
                    'to.name',
                  )}`;
                  return (
                    <Table.Row key={trip.id}>
                      <Table.Cell width="two" style={styles.sortingFieldLabel}>
                        {get(trip, rotationField)}
                      </Table.Cell>
                      <Table.Cell
                        width="four"
                        style={styles.driverLabel}
                        title={driverLabel(trip.driver, {
                          displayRegistrationNumber: true,
                        })}
                      >
                        {driverLabel(trip.driver, {
                          displayRegistrationNumber: true,
                        })}
                      </Table.Cell>
                      <Table.Cell style={styles.tripLabel} title={tripLabel}>
                        {tripLabel}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Segment>
        ))}
      </div>
    );
  },
);

DriverRotationTool.propTypes = {
  trips: PropTypes.arrayOf(tripPropType),
  saving: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withi18n('trips')(DriverRotationTool);

const styles = {
  driverLabel: {
    fontWeight: 'bold',
  },
  tripLabel: {
    fontSize: 14,
  },
};

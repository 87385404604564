import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import deburr from 'lodash/deburr';
import camelCase from 'lodash/camelCase';
import { withProps } from 'recompose';

import CRUDManager from '../common/CRUDManager';
import customFieldsActions from '../../actions/customFields';

const fields = [
  {
    key: 'name',
    label: 'Nom',
    type: 'STRING',
  },
  {
    key: 'type',
    label: 'Type',
    type: 'ENUM',
    options: [
      {
        value: 'string',
        label: 'Chaîne de caractères',
      },
    ],
  },
];
const resourceLabel = 'champ';

const style = { height: 'calc(100% - 80px)' };

const mapStateToProps = state => ({
  recordKey: 'id',
  records: state.customFields,
  resourceLabel,
  fields,
  style,
  ...state.customFieldsMeta,
});

const mapDispatchToProps = dispatch => ({
  fetchStart: compose(dispatch, customFieldsActions.fetchStart),
  createStart: compose(dispatch, customFieldsActions.createStart),
  updateStart: compose(dispatch, customFieldsActions.updateStart),
  deleteStart: compose(dispatch, customFieldsActions.deleteStart),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ createStart }) => ({
    createStart: (customField, ...args) =>
      createStart(
        { ...customField, key: deburr(camelCase(customField.name)) },
        ...args,
      ),
  })),
);
export default enhance(CRUDManager);

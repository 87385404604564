import momentTz from 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/ar';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment';

momentDurationFormatSetup(momentTz);

configureLocale(navigator.language);
momentTz.tz.setDefault(momentTz.tz.guess());

// Workaround because for some reason
// after locale is set in here, it's
// getting set back to english
// So we wait for the next stack frame
// and reset it to the device language
setTimeout(() => {
  configureLocale(navigator.language);
});

// This is required when working with moment timezone
// https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
function configureLocale(locale = 'fr') {
  if (!locale) {
    // On some devices, the language returns null
    // and crashes this function
    locale = 'fr-FR';
  }
  const localeWithoutZone = locale.split('-')[0];
  moment.locale(localeWithoutZone);
  momentTz.defineLocale(localeWithoutZone, moment.localeData()._config);
  momentTz.locale(localeWithoutZone);
}

export default momentTz;
export {configureLocale};

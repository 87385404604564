import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import merge from 'lodash/merge';
import memoize from 'memoize-one';

import AccountsManager from './AccountsManager';
import accountsActions from '../../../actions/accounts';
import groupsActions from '../../../actions/groups';
import paymentActions from '../../../actions/payment';

import { apiClient as accountsApiClient } from '../../../sagas/accounts/accountsSaga';

const fields = [
  {
    key: 'phoneNumber',
    label: 'Numéro de téléphone',
    type: 'PHONE',
    unique: true,
  },
  {
    key: 'metadata.profile.firstName',
    label: 'Prénom',
    type: 'STRING',
  },
  {
    key: 'metadata.profile.lastName',
    label: 'Nom',
    type: 'STRING',
  },
  {
    key: 'roles',
    label: 'Rôles',
    type: ['ENUM'],
    options: [
      {
        value: 'super_admin',
        label: 'Super Administrateur',
      },
      {
        value: 'admin',
        label: 'Administrateur',
      },
      {
        value: 'client',
        label: 'Client',
      },
      {
        value: 'transporter',
        label: 'Transporteur',
      },
      {
        value: 'driver',
        label: 'Conducteur',
      },
      {
        value: 'passenger',
        label: 'Passager',
      },
    ],
  },
  {
    key: 'groups',
    label: 'Groupes',
    type: ['REF'],
    ref: 'groups',
    refKey: 'name',
  },
];
const resourceLabel = 'compte';
const getRelations = memoize(groups => ({ groups }));

const fetch = query => accountsApiClient.get('', query);

const translateError = memoize(error => {
  if (!error) {
    return null;
  }
  if (error.code === 409) {
    return new Error('Ce numéro de téléphone est déjà associé à un compte');
  }
  return error;
});

const mapStateToProps = state => ({
  userAccount: state.auth.account,
  userCompany: state.auth.company,
  records: state.accounts,
  relations: getRelations(state.groups),
  resourceLabel,
  fields,
  paymentAccountsByUserId: state.payment.accountsByUserId,
  isLoadingAccount: state.payment.isLoadingAccount,
  isRefilling: state.payment.isRefilling,
  refillError: state.payment.refillError,
  updateAccountError: state.payment.updateAccountError,
  ...state.accountsMeta,
  createError: translateError(state.accountsMeta.createError),
  canImport: true,
});

const mapDispatchToProps = dispatch => ({
  fetch,
  fetchStart(...args) {
    dispatch(accountsActions.fetchStart(...args));
    dispatch(groupsActions.fetchStart());
  },
  createStart: account => {
    const accountWithOnboarding = merge(account, {
      metadata: { sendOnboardingMessage: true },
    });
    dispatch(accountsActions.createStart(accountWithOnboarding));
  },
  updateStart: compose(dispatch, accountsActions.updateStart),
  deleteStart: compose(dispatch, accountsActions.deleteStart),
  refill: compose(dispatch, paymentActions.refill),
  requestPaymentAccount: compose(dispatch, paymentActions.requestAccount),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsManager);

import React, { memo, useState, useEffect, useRef } from 'react';
import { Card, Button } from 'semantic-ui-react';
import Steps from 'rc-steps';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';

import colors from 'weego-common/src/theme/colors.json';

import TripGeneralInfosForm from '../TripGeneralInfosForm';
import TripStopsEditor from '../TripStopsEditor';
import TripExtraInfosForm from '../TripExtraInfosForm';
import withi18n from 'weego-common/src/hoc/i18n';

const NewTripBuilder = memo(function NewTripBuilder({
  createTrip,
  creating,
  error,
  fieldPresets,
  onExit,
  t,
}) {
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [trip, setTrip] = useState({
    ...(fieldPresets || {}),
  });
  const [isValidUpToCurrentStep, setIsValidUpToCurrentStep] = useState(false);
  const wasCreating = useRef(creating);

  const nextStep = () => {
    setCurrentStepNumber(Math.min(2, currentStepNumber + 1));
  };

  const previousStep = () => {
    setCurrentStepNumber(Math.max(0, currentStepNumber - 1));
  };

  const setCurrentStepNumberIfValid = stepNumber => {
    if (stepNumber > currentStepNumber && !isValidUpToCurrentStep) {
      return;
    }
    setCurrentStepNumber(stepNumber);
  };

  const patchTrip = patch => {
    setTrip({
      ...trip,
      ...patch,
    });
  };

  const save = () => {
    createTrip(trip);
  };

  useEffect(() => {
    if (wasCreating.current && !creating && !error) {
      onExit();
    }
  }, [wasCreating, creating, error, onExit]);

  useEffect(() => {
    wasCreating.current = creating;
  }, [creating]);

  useEffect(() => {
    let isValidUpToCurrentStep = false;
    if (currentStepNumber >= 0) {
      isValidUpToCurrentStep =
        trip.departureTime &&
        trip.from &&
        trip.from.coords &&
        trip.to &&
        trip.to.coords;
    }

    // We don't have anything to validate for step 1 and 2 yet,
    // this is just for reference purposes
    if (currentStepNumber >= 1) {
    }

    if (currentStepNumber >= 2) {
    }
    setIsValidUpToCurrentStep(isValidUpToCurrentStep);
  }, [trip, currentStepNumber]);

  return (
    <Card style={styles.container} fluid>
      <div style={styles.stepsContainer}>
        <Steps
          current={currentStepNumber}
          direction="vertical"
          onChange={stepNumber => setCurrentStepNumberIfValid(stepNumber)}
        >
          <Steps.Step title={t('Informations sur le trajet')} />
          <Steps.Step title={t('Gestion des arrêts')} />
          <Steps.Step title={t('Infos supplémentaires')} />
        </Steps>
      </div>
      <div style={styles.divider} />
      <div style={styles.body}>
        <div
          style={{
            ...styles.bodyContent,
            ...([0, 2].includes(currentStepNumber) && styles.paddedBodyContent),
          }}
        >
          {currentStepNumber === 0 && (
            <TripGeneralInfosForm trip={trip} onChange={patchTrip} />
          )}
          {currentStepNumber === 1 && (
            <TripStopsEditor trip={trip} onChange={patchTrip} />
          )}
          {currentStepNumber === 2 && (
            <TripExtraInfosForm
              trip={trip}
              onChange={patchTrip}
              disabledFieldKeys={keys(fieldPresets)}
            />
          )}
        </div>
        <div style={styles.actions}>
          {currentStepNumber === 0 && (
            <Button
              style={styles.actionButton}
              basic
              size="medium"
              onClick={onExit}
            >
              {t('Annuler')}
            </Button>
          )}
          {currentStepNumber > 0 && (
            <Button
              style={styles.actionButton}
              basic
              size="medium"
              onClick={previousStep}
            >
              {t('Précédent')}
            </Button>
          )}
          {currentStepNumber < 2 && (
            <Button
              style={styles.actionButton}
              primary
              size="medium"
              onClick={nextStep}
              disabled={!isValidUpToCurrentStep}
            >
              {t('Suivant')}
            </Button>
          )}
          {currentStepNumber === 2 && (
            <Button
              style={styles.actionButton}
              primary
              size="medium"
              onClick={save}
              disabled={!isValidUpToCurrentStep}
            >
              {t('Terminer')}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 23,
    height: '100%',
  },
  stepsContainer: {
    paddingTop: 20,
    paddingRight: 60,
  },
  body: {
    paddingTop: 20,
    paddingLeft: 50,
    paddingRight: 50,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bodyContent: {
    height: '100%',
  },
  paddedBodyContent: {
    paddingLeft: 70,
    paddingRight: 70,
  },
  divider: {
    width: 2,
    alignSelf: 'stretch',
    backgroundColor: colors.LIGHT_GREY,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
  },
  actionButton: {
    paddingLeft: 60,
    paddingRight: 60,
  },
};

NewTripBuilder.propTypes = {
  createTrip: PropTypes.func.isRequired,
  creating: PropTypes.bool.isRequired,
  error: PropTypes.object,
  onExit: PropTypes.func.isRequired,
};

export default withi18n('trips')(NewTripBuilder);

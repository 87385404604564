import {create} from 'apisauce';
import Promise from 'bluebird';

import handleResponseError from '../utils/handleResponseError';

/**
 * @author Babacar NIANG <babacarniang@sunubus.com>
 */
class NotificationClient {
  /**
   * Creates an instance of Client.
   * @param {String} endpoint - root url of the sunubus service
   * @memberof NotificationClient
   */
  constructor(endpoint, {tokens}) {
    this.tokens = tokens;
    this.endpoint = endpoint;
    this.api = create({
      baseURL: `${this.endpoint}/notifservice`,
    });
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/notifservice`);
  }

  /**
   * @description register a device
   *
   * @param {Subscriber} subscriber
   *
   * @returns {Subscriber} - the status of the request
   * @memberof NotificationClient
   */
  async registerDevice(subscriber) {
    const {api} = this;
    const response = await api.post('/notifications/register/', subscriber);
    handleResponseError(response);
    return response.data.subscriber;
  }

  /**
   * @description subscribe to a notification
   *
   * @param {String} idNotification
   * @param {Subscriber} subscriber
   *
   * @returns {StatusOK} - the status of the request
   * @memberof NotificationClient
   */
  async subscribe(idNotification, subscriber) {
    const {api} = this;
    const response = await api.post(
      `/notifications/subscribe/${idNotification}`,
      subscriber,
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description subscribe to a list of notifications
   *
   * @param {Array<String>} notifications
   * @param {Subscriber} subscriber
   *
   * @returns {StatusOK} - the status of the request
   * @memberof NotificationClient
   */
  async subscribeNotifications(notifications, subscriber) {
    const {api} = this;
    await Promise.each(notifications, async idNotification => {
      const response = await api.post(
        `/notifications/subscribe/${idNotification}`,
        subscriber,
      );
      handleResponseError(response);
    });
    return {subscribed: true};
  }

  /**
   * @description unSubscribe to a notification
   *
   * @param {String} idNotification
   * @param {String} idUser - id of the user
   *
   * @returns {SuscribedStatus} - the status of the request
   * @memberof NotificationClient
   */
  async unSubscribe(idNotification, idUser) {
    const {api} = this;
    const response = await api.post(
      `/notifications/unsubscribe/${idNotification}/${idUser}`,
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description unsubscribe to a list of notifications
   *
   * @param {Array<String>} notifications
   * @param {String} idUser - id of the user
   *
   * @returns {StatusOK} - the status of the request
   * @memberof NotificationClient
   */
  async unSubscribeNotifications(notifications, idUser) {
    const {api} = this;
    const requests = notifications.map(idNotification => {
      return api.post(`/notifications/unsubscribe/${idNotification}/${idUser}`);
    });
    const [response] = await Promise.all(requests);
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description check subscription to a notification
   *
   * @param {String} idNotification
   * @param {String} idUser - id of the user
   *
   * @returns {SuscribedStatus} - the status of the request
   * @memberof NotificationClient
   */
  async checkSubscription(idNotification, idUser) {
    const {api} = this;
    const response = await api.get(
      `/notifications/checksubscription/${idNotification}/${idUser}`,
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description send notification to subscribers in a topic
   *
   * @param {String} idNotification
   * @param {Notification} notification
   *
   * @returns {StatusOK} - the status of the request
   * @memberof NotificationClient
   */
  async send(idNotification, notification) {
    const {api} = this;
    const response = await api.post(
      `/notifications/send/${idNotification}`,
      notification,
    );
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description get list of notifications that a user has subscribed to
   * @param {String} userId
   * @returns {Data} - the notification list
   * @memberof NotificationClient
   */
  async getNotificationList(userId) {
    const {api} = this;
    const response = await api.get(`notifications/${userId}`);
    handleResponseError(response);
    return response.data;
  }

  /**
   * @description get list of children notifications from parent (from a topic)
   * @param {String} parentId
   * @returns {Array<Notification>} - the status of the request
   * @memberof NotificationClient
   */
  async getChildrenNotification(parentId) {
    const {api} = this;
    const response = await api.get(`/notifications/children/${parentId}`);
    handleResponseError(response);
    return response.data;
  }

  async ackDelivery(notificationId, playerId) {
    const {api} = this;
    const response = await api.post(
      `/notifications/ackdelivery/${notificationId}/${playerId}`,
    );
    handleResponseError(response);
    return response.data;
  }

  async sendNotification(notification) {
    const {api} = this;
    const response = await api.post('/notifications/send', notification);
    handleResponseError(response);
    return response.data;
  }
}

export default NotificationClient;

/**
 * @typedef  {Object} Position
 * @property {Number} latitude - The latitude of the position
 * @property {Number} longitude - The longitude of the position
 */

/**
 * @typedef  {Object} User
 * @property {String} userId - the id of the user
 * @property {String} playerId - the playerId of the user
 * @property {String} phoneNumber - the phoneNumber of the user
 */

/**
 * @typedef  {Object} GeoContext
 * @property {Position} position - the position to receive notifications related to a position
 */

/**
 * @typedef {Object} Subscriber
 * @property {GeoContext} geoContext - the geoContext's user
 * @property {User} user - the user
 */

/**
 * @typedef {Object} StatusOK
 * @property {String} status - the status of the request ('Success')
 */

/**
 * @typedef {Object} SuscribedStatus
 * @property {Boolean} subscribed - the subscribed status of the request
 */

/**
 * @typedef {Object} Notification
 * @property {String} content - the notification content
 * @property {Date} dateNotification - the notification date
 */

import { handleActions } from 'redux-actions';
import merge from 'lodash/merge';
import keyBy from 'lodash/keyBy';

import dashboardActions from '../../actions/dashboard';

const DEFAULT_STATE = {
  focusedTrips: {},
};

export default handleActions(
  {
    [dashboardActions.focusTrips]: (state, action) => ({
      ...state,
      focusedTrips: keyBy(action.payload.trips, 'id'),
    }),
    [dashboardActions.unfocusTrips]: state => ({
      ...state,
      focusedTrips: {},
    }),
    [dashboardActions.updateFocusedTripsPaths]: (state, action) => ({
      ...state,
      focusedTrips: merge(
        {},
        state.focusedTrips,
        action.payload.tripsWithPaths,
      ),
    }),
  },
  DEFAULT_STATE,
);

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import PlannerOptions from './PlannerOptions';
import groupsActions from '../../../actions/groups';

const mapStateToProps = state => ({
  groups: state.groups,
});

const mapDispatchToProps = dispatch => ({
  requestGroups: compose(dispatch, groupsActions.fetchStart),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlannerOptions);

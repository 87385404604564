import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import tripActions from '../../../actions/trip';
import dashboardActions from '../../../actions/dashboard';
import groupsActions from '../../../actions/groups';

import Dashboard from './Dashboard';

const mapStateToProps = state => ({
  trips: state.trip.trips,
  loading: state.trip.loading,
  groups: state.groups,
  account: state.auth.account,
});

const mapDispatchToProps = dispatch => ({
  clearTrips: compose(dispatch, tripActions.clearTrips),
  requestTripsInPeriod: compose(dispatch, tripActions.requestTripsInPeriod),
  focusTrip: trip => {
    dispatch(dashboardActions.focusTrips([trip]));
  },
  unfocusTrip: () => {
    dispatch(dashboardActions.unfocusTrips());
  },
  fetchGroups: compose(dispatch, groupsActions.fetchStart),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

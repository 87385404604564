import { withProps } from 'recompose';
import get from 'lodash/get';

import FilesManager from './FilesManager';

export default withProps({
  async upload(file) {
    const tokens = JSON.parse(localStorage.getItem('tokens'));

    const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
    console.log('Uploading file');
    const formData = new FormData();

    formData.append('file', file);

    const headers = new Headers();
    headers.append('authorization', `Bearer ${accountVerificationToken.token}`);
    const response = await fetch(
      process.env.REACT_APP_SUNUBUS_SERVICES_URL +
        '/ride-booking/containers/com.weego.tms/upload',
      {
        method: 'POST',
        body: formData,
        headers,
      },
    );
    if (!response.ok) {
      const error = new Error();
      error.message = get(
        response.data,
        'message',
        response.statusText || 'Unknown error',
      );
      throw error;
    }
    const responseBody = await response.json();
    const uploadedFile = responseBody.result.files.file[0];
    return {
      name: file.name,
      url: uploadedFile.providerResponse.location,
    };
  },
})(FilesManager);

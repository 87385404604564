/* eslint-disable global-require */

export default {
  auth: require('./auth'),
  menu: require('./menu'),
  crud: require('./crud'),
  fields: require('./fields'),
  accounts: require('./accounts'),
  transactions: require('./transactions'),
  trips: require('./trips'),
  common: require('./common'),
  dashboard: require('./dashboard'),
  planner: require('./planner'),
  notifications: require('./notifications'),
};

import React, { PureComponent } from 'react';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import PropTypes from 'prop-types';
import toArray from 'lodash/toArray';
import map from 'lodash/map';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import minBy from 'lodash/minBy';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import maxBy from 'lodash/maxBy';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { InfoWindow, Polyline } from 'react-google-maps';
import moment from 'moment-timezone';
import geolib from 'geolib';

import Map from '../../common/Map';
import TripSummary from '../../trips/TripSummary';
import StopMarker from '../StopMarker';
import transparent from '../../../assets/images/transparent.png';
import activeBus from '../../../assets/images/active-bus.png';
import inactiveBus from '../../../assets/images/inactive-bus.png';
import pausedBus from '../../../assets/images/paused-bus.png';
import redWarning from '../../../assets/images/red-warning.png';

import colors from '../../../theme/colors.json';

import './VehiclesMap.css';
import withi18n from 'weego-common/src/hoc/i18n';

let mapRef = null;

const lineSymbol = {
  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  fillColor: 'black',
  fillOpacity: 1,
};

class VehiclesMap extends PureComponent {
  state = {
    openStop: null,
    openHistoryPoint: null,
  };

  getStatus(position) {
    if (!position.date) {
      return 'inactive';
    }
    if (
      position.speed > 0 &&
      moment().diff(moment(position.date), 'minutes') < 5
    ) {
      return 'moving';
    }
    // speed is 0 here
    if (moment().diff(moment(position.date), 'minutes') <= 10) {
      return 'pause';
    }
    return 'inactive';
  }

  isLate(position) {
    const { trips } = this.props;
    const relatedTrip = (trips || []).find(
      trip => trip.vehicle.carNumber === position.number,
    );
    if (!relatedTrip) {
      return false;
    }
    if (this.getStatus(position) !== 'moving') {
      // when the trip ends, we consider the vehicle is no more late even if the GPS is not active
      if (
        moment().isAfter(moment(relatedTrip.to.maxDate).add('10', 'minutes'))
      ) {
        return false;
      }
      // 10 minutes before the start of a trip, we consider the vehicle is late if the GPS is not active
      if (
        moment(relatedTrip.departureTime)
          .subtract(10, 'minutes')
          .isBefore(moment())
      ) {
        return true;
      }
    }
    return false;
  }

  isOfInterest(stop) {
    const { account, matcher } = this.props;
    if (
      account?.roles?.includes('super_admin') ||
      account?.roles?.includes('admin')
    ) {
      if (matcher?.groups) {
        return intersection(stop.groups, matcher.groups).length > 0;
      }
      return true;
    }
    return intersection(stop.groups, account?.groups).length > 0;
  }

  openStop = stop => {
    this.setState({
      openStop: stop,
    });
  };

  closeStop = () => {
    this.setState({
      openStop: null,
    });
  };

  openHistoryPoint = point => {
    this.setState({
      openHistoryPoint: point,
    });
  };

  closeHistoryPoint = () => {
    this.setState({
      openHistoryPoint: null,
    });
  };

  openClosestHistoryPoint = latLng => {
    const { focusedVehicleHistory } = this.props;
    const closestHistoryPoint = minBy(focusedVehicleHistory, point => {
      return geolib.getDistance(point.coordinates, {
        latitude: latLng.lat,
        longitude: latLng.lng,
      });
    });
    this.openHistoryPoint(closestHistoryPoint);
  };

  onMapClick = () => {
    this.closeStop();
    this.closeHistoryPoint();
  };

  render() {
    const {
      positions,
      focusedVehicle,
      focusedTrips,
      focusedVehicleHistory,
      focusVehicle,
      unfocusVehicle,
      onTripDetailsClick,
      vehicleTargeted,
      trips,
      t,
    } = this.props;

    const { openStop, openHistoryPoint } = this.state;
    const focusedVehiclePosition = maxBy(
      toArray(positions).filter(p => p.number === focusedVehicle?.carNumber),
      'date',
    );
    const now = moment();
    return (
      <div className="vehicles-map">
        <Map ref={ref => (mapRef = ref)} onClick={this.onMapClick}>
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={30}
            maxZoom={14}
            defaultMinimumClusterSize={5}
          >
            {toArray(positions).map(position => {
              const status = this.getStatus(position);
              const showWarning =
                trips && trips.length > 0 ? this.isLate(position) : false;
              const hasRelatedTrip = (trips || []).some(
                trip => trip.vehicle.carNumber === position.number,
              );

              return (
                <MarkerWithLabel
                  key={position.trackerId || position.number}
                  position={{
                    lat: position.coordinates.latitude,
                    lng: position.coordinates.longitude,
                  }}
                  icon={transparent}
                  // eslint-disable-next-line no-undef
                  labelAnchor={new google.maps.Point(20, 35)}
                  labelStyle={{
                    // images are pointing to the left
                    transform: `rotateZ(${position.bearing}deg)`,
                  }}
                  title={position.number}
                  onClick={() => focusVehicle({ carNumber: position.number })}
                >
                  <div
                    style={{
                      position: 'relative',
                      padding: 5,
                      opacity: hasRelatedTrip ? 1 : 0.2,
                    }}
                  >
                    <img
                      className="marker-bus"
                      src={
                        status === 'moving'
                          ? activeBus
                          : status === 'pause'
                          ? pausedBus
                          : inactiveBus
                      }
                      alt="Bus marker"
                      style={{
                        transform: `rotateZ(90deg)`,
                      }}
                    />
                    {showWarning && (
                      <div style={{ left: '-20px' }}>
                        <img
                          className="marker-bus-warning"
                          src={redWarning}
                          alt="Warning Bus Marker"
                          style={{
                            transform: `rotateZ(${-position.bearing}deg)`,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </MarkerWithLabel>
              );
            })}
          </MarkerClusterer>

          {focusedVehicle &&
            focusedVehiclePosition &&
            mapRef &&
            mapRef.getBounds() &&
            // IIFE
            (function renderInfoWindow() {
              const focusedTripForVehicle = find(
                focusedTrips,
                trip => trip.vehicle?.carNumber === focusedVehicle.carNumber,
              );
              const infoPosition = {
                latitude: focusedVehiclePosition.coordinates.latitude,
                longitude: focusedVehiclePosition.coordinates.longitude,
              };
              // get the bounds of the map
              const polygon = [
                {
                  latitude: mapRef.getBounds().getNorthEast().lat(),
                  longitude: mapRef.getBounds().getSouthWest().lng(),
                },
                {
                  latitude: mapRef.getBounds().getNorthEast().lat(),
                  longitude: mapRef.getBounds().getNorthEast().lng(),
                },
                {
                  latitude: mapRef.getBounds().getSouthWest().lat(),
                  longitude: mapRef.getBounds().getNorthEast().lng(),
                },
                {
                  latitude: mapRef.getBounds().getSouthWest().lat(),
                  longitude: mapRef.getBounds().getSouthWest().lng(),
                },
              ];
              // We do not display the popup when the position is not in the map bounds
              // We display it when we target the vehicle
              const displayInfoWindow =
                vehicleTargeted || geolib.isPointInside(infoPosition, polygon);

              return displayInfoWindow ? (
                <InfoWindow
                  position={{
                    lat: focusedVehiclePosition.coordinates.latitude,
                    lng: focusedVehiclePosition.coordinates.longitude,
                  }}
                  onCloseClick={unfocusVehicle}
                >
                  <div>
                    {focusedTripForVehicle && (
                      <TripSummary
                        trip={focusedTripForVehicle}
                        onDetailsClick={() =>
                          onTripDetailsClick(focusedTripForVehicle)
                        }
                        minimized
                      />
                    )}
                    {!focusedTripForVehicle && (
                      <span>
                        {t('Aucun trajet assigné')} - Vu le{' '}
                        {moment(focusedVehiclePosition.date).format(
                          'DD/MM/YYYY [à] HH:mm',
                        )}
                      </span>
                    )}
                  </div>
                </InfoWindow>
              ) : null;
            })()}

          {map(focusedTrips, trip => {
            const tripDepartureTime = moment(trip.departureTime);
            const allStops = [trip.from, ...(trip.stops || []), trip.to].map(
              (stop, i) => assign(stop, { index: i }),
            );
            const uniqueStops = uniqWith(
              cloneDeep(allStops),
              (stop1, stop2) => {
                if (isEqual(stop1.coords, stop2.coords)) {
                  stop1.otherStops = uniqBy(
                    (stop1.otherStops || []).concat(stop2),
                    'time',
                  );
                  stop2.otherStops = uniqBy(
                    (stop2.otherStops || []).concat(stop1),
                    'time',
                  );
                  return true;
                }
              },
            );
            return map(uniqueStops, (stop, index) => {
              if (!stop) {
                return null;
              }
              const stopMaxDate = moment(stop.maxDate).set({
                year: tripDepartureTime.year(),
                month: tripDepartureTime.month(),
                date: tripDepartureTime.date(),
              });
              const isPast = now.isAfter(
                stopMaxDate.clone().add(10, 'minutes'),
              );
              const originalStop = allStops[stop.index];
              return (
                <StopMarker
                  key={`${trip.id}-${index}`}
                  stop={originalStop}
                  trip={trip}
                  index={index}
                  iconContainerStyles={{
                    ...(originalStop === trip.from &&
                      styles.departureStopIconContainer),
                    ...(originalStop === trip.to &&
                      styles.arrivalStopIconContainer),
                    ...(isPast && styles.pastStopContainer),
                    ...(!this.isOfInterest(originalStop) &&
                      styles.notInterestingStopContainer),
                  }}
                  // Prefer displaying current and upcoming stops
                  // over past and future ones
                  zIndex={
                    !!openStop && originalStop === openStop
                      ? 101
                      : isPast ||
                        now.isBefore(
                          stopMaxDate.clone().subtract(40, 'minutes'),
                        )
                      ? -200
                      : 100 - index
                  }
                  isOpen={!!openStop && originalStop === openStop}
                  isPast={isPast}
                  otherStops={stop.otherStops}
                  onOpen={this.openStop}
                  onClose={this.closeStop}
                />
              );
            });
          })}

          {map(focusedTrips, trip => {
            if (!trip.path) {
              return null;
            }
            const tripDepartureTime = moment(trip.departureTime);
            const tripEndTime = moment(trip.to.maxDate).set({
              year: tripDepartureTime.year(),
              month: tripDepartureTime.month(),
              date: tripDepartureTime.date(),
            });
            return (
              <Polyline
                key={trip.id}
                path={trip.path.path}
                options={{
                  strokeColor: tripEndTime.isBefore(moment())
                    ? colors.DARK_GREY
                    : colors.PRIMARY,
                  strokeWeight: 3,
                  icons: [
                    {
                      icon: lineSymbol,
                      offset: '100%',
                      repeat: '150px',
                    },
                  ],
                }}
              />
            );
          })}

          {focusedVehicleHistory && (
            <Polyline
              path={focusedVehicleHistory.map(position => ({
                lat: position.coordinates.latitude,
                lng: position.coordinates.longitude,
              }))}
              onClick={event =>
                this.openClosestHistoryPoint(event.latLng.toJSON())
              }
              options={{
                strokeColor: colors.APRICOT,
                strokeWeight: 3,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: '100%',
                    repeat: '150px',
                  },
                ],
                zIndex: 101,
              }}
            />
          )}

          {openHistoryPoint && (
            <InfoWindow
              position={{
                lat: openHistoryPoint.coordinates.latitude,
                lng: openHistoryPoint.coordinates.longitude,
              }}
              onCloseClick={this.closeHistoryPoint}
            >
              <div>{moment(openHistoryPoint.date).format('HH:mm')}</div>
            </InfoWindow>
          )}
        </Map>
      </div>
    );
  }
}

const styles = {
  departureStopIconContainer: {
    backgroundColor: colors.PRIMARY,
  },
  arrivalStopIconContainer: {
    backgroundColor: colors.APRICOT,
  },
  pastStopContainer: {
    backgroundColor: colors.GREY,
  },
  notInterestingStopContainer: {
    backgroundColor: colors.GREY,
    opacity: 0.5,
  },
};

const coordinatesPropTypes = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
});

VehiclesMap.propTypes = {
  positions: PropTypes.objectOf(
    PropTypes.shape({
      coordinates: coordinatesPropTypes,
      heading: PropTypes.number.isRequired,
      company: PropTypes.string.isRequired,
      date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date).isRequired,
      ]),
      number: PropTypes.string.isRequired,
      speed: PropTypes.number.isRequired,
    }),
  ),
  focusedVehicle: PropTypes.shape({
    carNumber: PropTypes.string.isRequired,
  }),
  focusedVehicleHistory: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.instanceOf(Date).isRequired,
      ]).isRequired,
      coordinates: coordinatesPropTypes.isRequired,
      speed: PropTypes.number.isRequired,
      heading: PropTypes.number.isRequired,
    }),
  ),
  focusedPositionKey: PropTypes.string,
  unfocusVehicle: PropTypes.func.isRequired,
  matcher: PropTypes.shape({
    driverId: PropTypes.string,
    vehicleId: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default withi18n('dashboard')(VehiclesMap);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';
import memoizeOne from 'memoize-one';

import withi18n from 'weego-common/src/hoc/i18n';

import vehiclePropType from '../vehiclePropType';

import VehicleCard from '../VehicleCard';
import { Header, Input } from 'semantic-ui-react';

class VehicleReplacementList extends PureComponent {
  state = {
    selectedVehicle: null,
    searchTerm: '',
  };
  fuse = null;

  componentDidMount() {
    this.props.fetchVehicles();
    this.updateFuse();
  }

  componentDidUpdate() {
    this.updateFuse();
  }

  updateFuse() {
    this.fuse = new Fuse(this.props.vehicles, {
      keys: ['brand', 'model', 'carLabel', 'carNumber'],
      threshold: 0.4,
    });
  }

  selectVehicle = vehicle => {
    const { onSelect } = this.props;
    this.setState({
      selectedVehicle: vehicle,
    });
    onSelect(vehicle);
  };

  vehicleSearchResults = memoizeOne((vehicles, term) => {
    if (!term) {
      return vehicles;
    }
    if (!this.fuse) {
      return vehicles;
    }
    return this.fuse.search(term).map(result => result.item);
  });

  updateSearchTerm = debounce(searchTerm => {
    this.setState({
      searchTerm,
    });
  }, 100);

  render() {
    const { vehicles, t } = this.props;
    const { selectedVehicle, searchTerm } = this.state;
    const vehicleSearchResults = this.vehicleSearchResults(
      vehicles,
      searchTerm,
    );
    return (
      <div>
        <div style={styles.headerContainer}>
          <Header style={styles.availableVehicles} as="h3">
            {t('{{count}} véhicules disponibles', {
              count: vehicles.length,
            })}
          </Header>
          <Input
            style={styles.search}
            placeholder={t('Rechercher')}
            icon="search"
            iconPosition="left"
            onChange={(e, { value }) => this.updateSearchTerm(value)}
          />
        </div>
        <div style={styles.vehiclesListContainer}>
          {(vehicleSearchResults || []).map(vehicle => (
            <VehicleCard
              key={vehicle.id}
              style={styles.vehicleCard}
              vehicle={vehicle}
              selection
              selected={selectedVehicle && selectedVehicle.id === vehicle.id}
              onSelect={this.selectVehicle}
            />
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  headerContainer: {
    paddingTop: 14,
    paddingBottom: 29,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availableVehicles: {
    marginBottom: 0,
  },
  search: {
    top: 0,
  },
  vehiclesListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    padding: 10,
    minHeight: 150,
  },
  vehicleCard: {
    marginLeft: 10,
    marginRight: 10,
  },
};

VehicleReplacementList.propTypes = {
  vehicles: PropTypes.arrayOf(vehiclePropType),
  departureTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  fetchVehicles: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

VehicleReplacementList.defaultProps = {
  vehicles: [],
};

export default withi18n('trips')(VehicleReplacementList);

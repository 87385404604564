import React, { memo, useState } from 'react';
import { Form, Dropdown, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';

import groupPropType from '../../groups/groupPropType';
import accountPropType from '../../auth/accountPropType';
import tripPropType from '../tripPropType';
import FileUploader from '../../common/FilesManager';
import withi18n from 'weego-common/src/hoc/i18n';

const TripExtraInfosForm = memo(function TripExtraInfosForm({
  groups,
  trip,
  account,
  customFields,
  disabledFieldKeys,
  onChange,
  t,
}) {
  const onGroupsChange = (e, { value: groups }) => {
    onChange({
      groups,
      from: trip.from && {
        ...trip.from,
        groups,
      },
      to: trip.to && {
        ...trip.to,
        groups,
      },
      stops: trip.stops?.map(stop => ({
        ...stop,
        groups,
      })),
    });
  };

  const onTagsChange = (e, { value }) => {
    onChange({
      tags: value,
    });
    setTagsOptions(value.map(tag => ({ text: tag, value: tag })));
  };

  const [tagsOptions, setTagsOptions] = useState([]);

  const addTag = (e, { value }) => {
    setTagsOptions(tagsOptions.concat({ text: value, value }));
  };

  const isAdmin =
    account?.roles?.includes('admin') ||
    account?.roles?.includes('super_admin');

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Field width="eight">
            <label>{t('Groupes')}</label>
            <Dropdown
              placeholder={t('Groupes')}
              search
              fluid
              selection
              multiple
              options={uniqBy(
                map(groups, group => ({
                  key: group._id,
                  value: group._id,
                  text: group.name,
                })).concat(
                  // This is so that deleted groups still appear with their ids
                  (trip.groups || []).map(groupId => ({
                    key: groupId,
                    value: groupId,
                    text: groupId,
                  })),
                ),
                'key',
              )}
              value={trip.groups || []}
              disabled={!isAdmin}
              onChange={onGroupsChange}
            />
          </Form.Field>
          <Form.Field width="four">
            <label>{t('Public')}</label>
            <Checkbox
              checked={!!trip.public}
              disabled={!isAdmin}
              onChange={(e, { checked }) => {
                onChange({ public: checked });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('Tags')}</label>
            <Dropdown
              options={tagsOptions}
              placeholder={t('Tags')}
              search
              selection
              fluid
              multiple
              allowAdditions
              additionLabel=""
              openOnFocus={false}
              closeOnChange={true}
              value={trip.tags || []}
              disabled={!isAdmin}
              onAddItem={addTag}
              onChange={onTagsChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <FileUploader
        files={trip.files}
        disabled={!isAdmin}
        onChange={files => onChange({ files })}
      />
      <Form.Field>
        <label>{t('Dossier')}</label>
        <Form.Input
          value={trip.folder}
          onChange={(e, { value }) => onChange({ folder: value })}
          disabled={disabledFieldKeys?.includes('folder')}
        />
      </Form.Field>
      {map(customFields, field => (
        <Form.Field>
          <label>{field.name}</label>
          <Form.Input
            value={trip[field.key]}
            onChange={(e, { value }) => onChange({ [field.key]: value })}
            disabled={disabledFieldKeys?.includes(field.key)}
          />
        </Form.Field>
      ))}
    </div>
  );
});

TripExtraInfosForm.propTypes = {
  groups: PropTypes.objectOf(groupPropType),
  trip: tripPropType,
  account: accountPropType,
  customFields: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }).isRequired,
  ),
  disabledFieldKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
};

export default withi18n('trips')(TripExtraInfosForm);

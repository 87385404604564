import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import filter from 'lodash/filter';
import compose from 'recompose/compose';

import VehicleReplacementList from './VehicleReplacementList';

import vehiclesActions from '../../../actions/vehicles';

const getAvailableVehicles = memoizeOne(vehicles => {
  return filter(vehicles, v => v.available);
});

const mapStateToProps = (state, ownProps) => ({
  vehicles: getAvailableVehicles(state.vehicles),
});

const mapDispatchToProps = dispatch => ({
  fetchVehicles: compose(dispatch, vehiclesActions.fetchStart),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(VehicleReplacementList);

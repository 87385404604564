import moment from 'weego-common/src/providers/moment';

const dateToTimeString = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('HH:mm');
};

export default dateToTimeString;

import React, { memo, useEffect, useState } from 'react';
import { Segment, Input } from 'semantic-ui-react';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import useSearch from '../../../hooks/useSearch';

import colors from '../../../theme/colors.json';
import withi18n from 'weego-common/src/hoc/i18n';

const FUSE_OPTIONS = {
  keys: ['description'],
  threshold: 0.3,
  distance: 200,
};

const TripActivityLog = memo(function TripActivityLog({
  trip,
  tripEvents,
  fetchTripEvents,
  style,
  t,
}) {
  useEffect(() => {
    const startOfDay = moment().startOf('day').toDate();
    const endOfDay = moment().endOf('day').toDate();
    const fetch = () => {
      fetchTripEvents({
        query: {
          filter: {
            where: {
              and: [
                {
                  tripId: trip.id,
                },
                {
                  date: {
                    lte: endOfDay,
                  },
                },
                {
                  date: {
                    gte: startOfDay,
                  },
                },
              ],
            },
          },
        },
      });
    };
    fetch();
    const intervalId = setInterval(fetch, 10000);
    return () => clearInterval(intervalId);
  }, [trip, fetchTripEvents]);

  const [eventsWithDescription, setEventsWithDescription] = useState([]);

  const [term, results, search] = useSearch(
    eventsWithDescription,
    FUSE_OPTIONS,
  );

  useEffect(() => {
    setEventsWithDescription(
      map(tripEvents, event => ({
        ...event,
        description: getEventDescription(event, t),
      })),
    );
  }, [tripEvents, t]);

  return (
    <div style={{ ...styles.container, ...style }}>
      <Segment style={styles.segment}>
        <Input
          value={term}
          placeholder={t('Rechercher')}
          fluid
          icon="search"
          iconPosition="left"
          onChange={(e, { value }) => search(value)}
        />
        <div style={styles.eventsList}>
          {map(
            sortBy(results, event => -moment(event.date).unix()),
            event => (
              <div style={styles.event}>
                <div style={styles.eventDescription}>
                  <div style={styles.eventIndicator} />
                  {event.description}
                </div>
                <div style={styles.eventDate}>
                  {moment(event.date).fromNow(true)}
                </div>
              </div>
            ),
          )}
        </div>
      </Segment>
    </div>
  );
});

function getEventDescription(event, t) {
  const eventTypeDescriptionMap = {
    VEHICLE_STARTED: t('Le véhicule a démarré'),
    TRIP_STARTED: t('Le trajet a démarré'),
    DRIVER_CONFIRMED: t('Le conducteur a confirmé'),
    DRIVER_CANCELLED: t('Le conducteur a annulé'),
    PASSENGER_PICKED_UP: t('Un passager est monté'),
    PASSENGER_DROPPED_OFF: t('Un passager est descendu'),
  };
  return (
    eventTypeDescriptionMap[event.type] ||
    capitalize(event.type?.replace(/_/g, ' '))
  );
}

const styles = {
  container: {
    display: 'flex',
  },
  segment: {
    flex: 1,
    overflow: 'auto',
  },
  eventsList: {
    paddingTop: 25,
  },
  event: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  eventDescription: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '20px',
    color: colors.GREY_TEXT,
  },
  eventIndicator: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY,
    border: 'solid 7px',
    borderColor: '#E1D9F2',
    marginRight: 12,
  },
  eventDate: {
    fontSize: 11,
    color: colors.GREY,
  },
};

export default withi18n('dashboard')(TripActivityLog);

import {create} from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class FASClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof PaymentClient
   */
  constructor(endpoint, {tokens}) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/fas`,
    });
  }

  async getAvailableFeatures(parameters) {
    const {api} = this;
    const accountVerificationToken = await this.tokens.get(
      'ACCOUNT_VERIFICATION',
    );
    const response = await api.get('/features', parameters, {
      headers: accountVerificationToken
        ? {
            Authorization: `Bearer ${accountVerificationToken}`,
          }
        : {},
    });
    handleResponseError(response);
    const availableFeatures = response.data.features;
    return availableFeatures;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/fas`);
  }
}

export default FASClient;

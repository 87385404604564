import ObjectID from 'bson-objectid';
import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import withi18n from 'weego-common/src/hoc/i18n';

const predefinedIncidents = [
  {
    key: 'DRIVER_LATE',
    value: 'DRIVER_LATE',
    text: 'Conducteur en retard',
  },
  {
    key: 'DRIVER_MISSING',
    value: 'DRIVER_MISSING',
    text: 'Conducteur absent',
  },
  {
    key: 'VEHICLE_BREAKDOWN',
    value: 'VEHICLE_BREAKDOWN',
    text: 'Panne véhicule',
  },
  {
    key: 'ACCIDENT',
    value: 'ACCIDENT',
    text: 'Accident routier',
  },
  {
    key: 'CANCELLED_TRIP',
    value: 'CANCELLED_TRIP',
    text: 'Trajet annulé',
  },
];

const TripIncidentReportForm = ({ trip, createTripEvent, onSubmitted, t }) => {
  const [incidentEvent, setIncidentEvent] = useState({
    id: ObjectID().toHexString(),
    type: null,
    tripId: trip.id,
    metadata: {
      comment: '',
    },
  });
  const [submitting, setSubmitting] = useState(false);

  return (
    <div>
      <Form
        onSubmit={() => {
          setSubmitting(true);
          createTripEvent(
            {
              ...incidentEvent,
              date: new Date(),
            },
            {
              callback: () => {
                if (onSubmitted) {
                  onSubmitted();
                }
              },
            },
          );
        }}
      >
        <Form.Select
          label={t("Type d'incident")}
          options={predefinedIncidents.map(incident => ({
            ...incident,
            text: t(incident.text),
          }))}
          value={incidentEvent.type}
          onChange={(e, { value }) => {
            setIncidentEvent({
              ...incidentEvent,
              type: value,
            });
          }}
        />
        <Form.TextArea
          label={t('Commentaire')}
          placeholder={t('Commentaire')}
          value={incidentEvent.metadata.comment}
          onChange={(e, { value }) => {
            setIncidentEvent({
              ...incidentEvent,
              metadata: {
                ...incidentEvent.metadata,
                comment: value,
              },
            });
          }}
        />
        <Form.Button disabled={!incidentEvent.type || submitting} primary>
          {t('Soumettre')}
        </Form.Button>
      </Form>
    </div>
  );
};

export default withi18n('trips')(TripIncidentReportForm);

export { predefinedIncidents };

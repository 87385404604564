import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class ScriptsClient {
  /**
   * Creates an instance of the scripts client.
   * @param {string} endpoint - root url of the sunubus service
   * @memberof RoutesClient
   */
  constructor(endpoint, options) {
    this.endpoint = endpoint;
    this.api = create({
      baseURL: `${endpoint}/scripts`
    });
    this.tokens = options.tokens;
  }

  async importLine(line) {
    const { api } = this;
    const response = await api.post(
      '/lines/import',
      {
        line
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    return response.data.line;
  }

  async deleteLine(lineId) {
    const { api } = this;
    const response = await api.post(
      '/lines/delete',
      {
        line_id: lineId
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    return response.deleted;
  }

  async lineFromKml(file) {
    const { api } = this;
    const form = new FormData();
    form.append('file', file);
    const response = await api.post('/lines/fromkml', form, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get('ACCOUNT_VERIFICATION')}`
      }
    });
    handleResponseError(response);
    return response.data;
  }

  async lineFromCsv(file, city) {
    const { api } = this;
    const form = new FormData();
    form.append('file', file);
    form.append('city', city);
    const response = await api.post('/lines/fromcsv', form, {
      headers: {
        Authorization: `Bearer ${await this.tokens.get('ACCOUNT_VERIFICATION')}`
      }
    });
    handleResponseError(response);
    return response.data;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/scripts`);
  }
}

export default ScriptsClient;

/**
 * @typedef {Object} Position
 * @property {Number} latitude - The latitude of the position
 * @property {Number} longitude - The longitude of the position
 */

/**
 * @typedef {Object} Stop
 * @property {String} id - The id of the stop
 * @property {String} name - The name of the stop
 * @property {Position} coordinates - The geographical coordinates of the stop
 */

import { connect } from 'react-redux';
import TripExtraInfosForm from './TripExtraInfosForm';
import { compose, lifecycle } from 'recompose';

import groupsActions from '../../../actions/groups/groupsActions';
import customFieldsActions from '../../../actions/customFields';

const mapStateToProps = state => ({
  groups: state.groups,
  error: state.groupsMeta.error,
  account: state.auth.account,
  customFields: state.customFields,
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: compose(dispatch, groupsActions.fetchStart),
  fetchCustomFields: compose(dispatch, customFieldsActions.fetchStart),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchCustomFields();
      this.props.fetchGroups();
    },
  }),
);

export default enhance(TripExtraInfosForm);

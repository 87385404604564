import PropTypes from 'prop-types';

const driverPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string,
  phone: PropTypes.string,
});

export default driverPropType;

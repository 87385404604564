import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class StepIndicator extends Component {
  render() {
    const { steps, focusedStep } = this.props;
    return (
      <div style={styles.container}>
        {steps.map((s, i) => (
          <div key={i} style={styles.step}>
            <div style={styles.stepContainer}>
              <div style={styles.steps}>
                <div
                  style={{
                    ...styles.separator,
                    borderTopLeftRadius: i === 0 ? 5 : 0,
                    borderBottomLeftRadius: i === 0 ? 5 : 0,
                  }}
                />
                <div
                  style={{
                    ...styles.circleBlue,
                    borderColor:
                      i === focusedStep
                        ? 'rgba(103, 58, 183, 0.32)'
                        : 'transparent',
                  }}
                >
                  {s.step}
                </div>
                <div
                  style={{
                    ...styles.separator,
                    borderTopRightRadius: i === steps.length - 1 ? 5 : 0,
                    borderBottomRightRadius: i === steps.length - 1 ? 5 : 0,
                  }}
                />
              </div>

              <div style={styles.labels}>
                <label style={{ fontSize: 12 }}>{s.label}</label>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  steps: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  circleBlue: {
    width: 50,
    height: 50,
    borderRadius: 25,
    border: '6px solid transparent',
    backgroundColor: '#F2F2F7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    width: 40,
    height: 6,
    backgroundColor: '#F2F2F7',
  },
};

StepIndicator.defaultProps = {
  focusedStep: 0,
};

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      step: PropTypes.string,
    }),
  ).isRequired,
  focusedStep: PropTypes.number,
};

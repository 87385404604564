import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import AccountsManager from '../../../components/accounts/AccountsManager';

class AccountsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Comptes');
  }

  render() {
    return <AccountsManager />;
  }
}

export default withi18n('menu')(AccountsScreen);

import { createActions } from 'redux-actions';

const { trip } = createActions({
  TRIP: {
    UPDATE_TRIPS: trips => trips,
    CLEAR_TRIPS: () => undefined,
    REQUEST_TRIPS: filter => ({ filter }),
    REQUEST_TRIPS_ERROR: error => error,
    REQUEST_TRIPS_SUCCESS: trip => trip,
    REQUEST_TRIPS_IN_PERIOD: (period, filter, overrideFilter) => ({
      ...period,
      filter,
      overrideFilter,
    }),
    REQUEST_TRIPS_IN_PERIOD_ERROR: error => error,
    REQUEST_TRIPS_IN_PERIOD_SUCCESS: tripInPeriod => tripInPeriod,
    CREATE_TRIP: (trip, silent = false) => ({ trip, silent }),
    CREATE_TRIP_ERROR: error => error,
    CREATE_TRIP_SUCCESS: trip => ({ trip }),
    DELETE_TRIP: id => id,
    DELETE_TRIP_ERROR: error => error,
    DELETE_TRIP_SUCCESS: deletedTrip => ({ deletedTrip }),
    DELETE_MANY_TRIPS: where => ({ where }),
    DELETE_MANY_TRIPS_ERROR: error => error,
    DELETE_MANY_TRIPS_SUCCESS: (count, where) => ({ count, where }),
    EDIT_TRIP: (trip, silent = false) => ({ trip, silent }),
    EDIT_TRIPS: trips => ({ trips }),
    EDIT_TRIP_SUCCESS: updatedTrip => ({ updatedTrip }),
    EDIT_TRIP_ERROR: error => error,
    EDIT_MANY_TRIPS: (where, updates) => ({ where, updates }),
    EDIT_MANY_TRIPS_SUCCESS: (count, where, updates) => ({
      count,
      where,
      updates,
    }),
    EDIT_MANY_TRIPS_ERROR: error => error,
    IMPORT_TRIPS: (trips, folder) => ({ trips, folder }),
    IMPORT_TRIPS_SUCCESS: trips => ({ trips }),
    IMPORT_TRIPS_ERROR: error => error,
    SUBMIT_TRIP_REPORT: report => report,
    SUBMIT_TRIP_REPORT_SUCCESS: newReport => newReport,
    SUBMIT_TRIP_REPORT_ERROR: error => error,
    REMOVE_TRIP_REPORT: report => report,
    REMOVE_TRIP_REPORT_SUCCESS: removedTripReport => removedTripReport,
    REMOVE_TRIP_REPORT_ERROR: error => error,
  },
});

export default trip;

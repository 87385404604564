import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class TTSClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof TTSClient
   */
  constructor(endpoint, { tokens }) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/tts`
    });
  }

  /**
   * Transcribe a file
   *
   * @param {String} fileUrl - The url to transcribe
   * @memberof TTSClient
   */
  async transcribe(fileUrl) {
    const { api } = this;
    const response = await api.post('/transcribe', {
      fileUrl
    });
    handleResponseError(response);
    return response.data;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/tts`);
  }
}

export default TTSClient;

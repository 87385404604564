import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import TripsRegistry from '../../../components/trips/TripsRegistry';

class TripsRegistryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t('Registre');
  }

  render() {
    return (
      <div style={styles.container}>
        <TripsRegistry />
      </div>
    );
  }
}

const styles = {
  container: {
    height: 'calc(100% - 80px)',
  },
};

export default withi18n('menu')(TripsRegistryScreen);

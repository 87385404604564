import React, { Component } from 'react';
import { Modal, Image, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import closeIcon from '../../assets/images/close.png';

import './DialogBox.css';

export default class DialogBox extends Component {
  render() {
    const {
      actions,
      children,
      open,
      onClose,
      title,
      subTitle,
      style,
      showFooter,
      ...props
    } = this.props;
    return (
      <Modal
        className="dialog-box"
        onClose={onClose}
        dimmer="inverted"
        open={open}
        size="large"
        style={{ ...styles.modal, ...style }}
        {...props}
      >
        <Modal.Header>
          <div style={styles.header}>
            <Header style={styles.title}>
              <label style={styles.titleText}>{title}</label>
              <Header.Subheader>{subTitle}</Header.Subheader>
            </Header>
            <a href="#/" onClick={onClose}>
              <Image src={closeIcon} />
            </a>
          </div>
        </Modal.Header>
        <Modal.Content image style={styles.content}>
          <Modal.Description style={styles.description}>
            {children}
          </Modal.Description>
        </Modal.Content>
        {showFooter && (
          <Modal.Actions style={styles.bottom}>{actions}</Modal.Actions>
        )}
      </Modal>
    );
  }
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    width: '100%',
  },
  title: { padding: 0, margin: 0 },
  bottom: { backgroundColor: 'white' },
  titleText: { fontSize: 18 },
  modal: { width: 753, maxHeight: '95%', overflow: 'auto' },
};

DialogBox.defaultProps = {
  actions: null,
  children: null,
  title: '',
  showFooter: true,
};

DialogBox.propTypes = {
  ...Modal.propTypes,
  actions: PropTypes.node,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  showFooter: PropTypes.bool,
};

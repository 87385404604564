import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import tripUpdatesActionCreators, {
  actionTypes,
} from '../../actions/tripUpdates';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const tripUpdatesSaga = makeCrudSaga(
  'tripUpdates',
  actionTypes,
  tripUpdatesActionCreators,
  process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/tripUpdates`,
  {
    token:
      tokens &&
      tokens.ACCOUNT_VERIFICATION &&
      tokens.ACCOUNT_VERIFICATION.token,
    key: 'id',
    useRootResponse: true,
  },
);

export default tripUpdatesSaga;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';
import memoizeOne from 'memoize-one';

import withi18n from 'weego-common/src/hoc/i18n';

import driverPropType from '../driverPropType';

import DriverCard from '../DriverCard';
import { Header, Input } from 'semantic-ui-react';

class DriverReplacementList extends PureComponent {
  state = {
    selectedDriver: null,
    searchTerm: '',
  };
  fuse = null;

  componentDidMount() {
    this.props.fetchDrivers();
    this.updateFuse();
  }

  componentDidUpdate() {
    this.updateFuse();
  }

  updateFuse() {
    this.fuse = new Fuse(this.props.drivers, {
      keys: ['firstname', 'lastname', 'registrationNumber'],
      threshold: 0.4,
    });
  }

  selectDriver = driver => {
    const { onSelect } = this.props;
    this.setState({
      selectedDriver: driver,
    });
    onSelect(driver);
  };

  driverSearchResults = memoizeOne((drivers, term) => {
    if (!term) {
      return drivers;
    }
    if (!this.fuse) {
      return drivers;
    }
    return this.fuse.search(term).map(result => result.item);
  });

  updateSearchTerm = debounce(searchTerm => {
    this.setState({
      searchTerm,
    });
  }, 100);

  render() {
    const { drivers, t } = this.props;
    const { selectedDriver, searchTerm } = this.state;
    const driverSearchResults = this.driverSearchResults(drivers, searchTerm);
    return (
      <div>
        <div style={styles.headerContainer}>
          <Header style={styles.availableDrivers} as="h3">
            {t('{{count}} conducteurs disponibles', {
              count: drivers.length,
            })}
          </Header>
          <Input
            style={styles.search}
            placeholder={t('Rechercher')}
            icon="search"
            iconPosition="left"
            onChange={(e, { value }) => this.updateSearchTerm(value)}
          />
        </div>
        <div style={styles.driversListContainer}>
          {(driverSearchResults || []).map(driver => (
            <DriverCard
              key={driver.id}
              style={styles.driverCard}
              driver={driver}
              selection
              selected={selectedDriver && selectedDriver.id === driver.id}
              onSelect={this.selectDriver}
            />
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  headerContainer: {
    paddingTop: 14,
    paddingBottom: 29,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  availableDrivers: {
    marginBottom: 0,
  },
  search: {
    top: 0,
  },
  driversListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    padding: 10,
    minHeight: 150,
  },
  driverCard: {
    marginLeft: 10,
    marginRight: 10,
  },
};

DriverReplacementList.propTypes = {
  drivers: PropTypes.arrayOf(driverPropType),
  departureTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  fetchDrivers: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

DriverReplacementList.defaultProps = {
  drivers: [],
};

export default withi18n('trips')(DriverReplacementList);

import React, { memo, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';
import LibPhoneNumber from 'google-libphonenumber';

import i18n from 'weego-common/src/hoc/i18n';

const phoneUtil = LibPhoneNumber.PhoneNumberUtil.getInstance();
const PNF = LibPhoneNumber.PhoneNumberFormat;

const PhoneNumberInput = memo(function PhoneNumberInput({
  value,
  onChange,
  t,
}) {
  const [internalPhoneNumber, setInternalPhoneNumber] = useState(value || '');

  return (
    <PhoneInput
      placeholder={t('Votre numéro de téléphone.')}
      country={'ma'}
      localization={fr}
      value={internalPhoneNumber}
      onChange={(rawPhoneNumber, country) => {
        setInternalPhoneNumber(rawPhoneNumber);
        try {
          if (!rawPhoneNumber) {
            onChange(null);
            return;
          }
          const phoneNumber = phoneUtil.parse(
            '+' + rawPhoneNumber,
            country.countryCode.toUpperCase() || 'MA',
          );
          const formattedPhoneNumber = phoneUtil.format(phoneNumber, PNF.E164);
          onChange(formattedPhoneNumber);
        } catch (error) {
          onChange(null);
        }
      }}
      containerStyle={styles.phoneInputContainer}
      inputStyle={styles.phoneInput}
      inputProps={{
        autoFocus: true,
      }}
    />
  );
});

const styles = {
  phoneInputContainer: {
    width: '100%',
  },
  phoneInput: {
    width: '100%',
    paddingLeft: 48,
  },
};

export default i18n('auth')(PhoneNumberInput);

import { createActions } from 'redux-actions';

const { auth } = createActions({
  AUTH: {
    LOGIN: phoneNumber => ({ phoneNumber }),
    LOGIN_ERROR: error => error,
    LOGIN_SUCCESS: () => undefined,
    LOGOUT: () => undefined,
    LOGOUT_ERROR: error => error,
    LOGOUT_SUCCESS: () => undefined,
    UPDATE_COMPANY: company => ({ company }),
    UPDATE_ACCOUNT: account => account,
  },
});

export default auth;

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import AuthScreen from './AuthScreen';
import authActions from '../../actions/auth';

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = dispatch => ({
  login: compose(
    dispatch,
    authActions.login,
  ),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const AuthScreenContainer = enhance(AuthScreen);

export default AuthScreenContainer;

import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class RouteBuildingClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof RouteBuildingClient
   */
  constructor(endpoint, { tokens }) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/route-building`
    });
  }

  /**
   * Saves a new line
   *
   * @param {Line} line - the line to save
   * @returns {Line} line - the saved line
   * @memberof RouteBuildingClient
   */
  async saveLine(line) {
    const { api } = this;
    const response = await api.post(
      '/lines',
      line,
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    const result = response.data;
    return result.line;
  }

  async subscribeHero(hero) {
    const { api } = this;
    const response = await api.post(
      '/heroes',
      hero,
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    const result = response.data;
    return result.hero;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/route-building`);
  }
}

export default RouteBuildingClient;

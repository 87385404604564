import {create} from 'apisauce';
import handleResponseError from '../utils/handleResponseError';

class PromosClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof PromosClient
   */
  constructor(endpoint, {tokens}) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.pendingPhoneNumber = null;
    this.api = create({
      baseURL: `${endpoint}/promo`,
    });
  }

  async getEligibleCampaigns() {
    const response = await this.api.get(
      '/voucherify/campaigns',
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.campaigns.filter(c => c.active === true);
  }

  async publishVoucherForCampaign(campaignId) {
    const response = await this.api.post(
      '/voucherify/publish-voucher',
      {
        campaignId,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.voucher;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/promo`);
  }
}

export default PromosClient;

import {create} from 'apisauce';
import handleResponseError from '../utils/handleResponseError';

class PlanningClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof PlanningClient
   */
  constructor(endpoint, {tokens}) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.pendingPhoneNumber = null;
    this.api = create({
      baseURL: `${endpoint}/planning`,
    });
  }

  async getDemandTrip({demandId, date}) {
    const response = await this.api.get(
      '/plan/getTripForDemand',
      {
        demandId,
        date,
      },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION',
          )}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.trip;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/planning`);
  }
}

export default PlanningClient;

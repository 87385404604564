import React, { memo, useState, useEffect, useRef } from 'react';
import { Icon, Segment, Button, Input, Message } from 'semantic-ui-react';
import moment from 'moment';
import flatten from 'lodash/flatten';
import uniqWith from 'lodash/uniqWith';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import difference from 'lodash/difference';
import without from 'lodash/without';
import omit from 'lodash/omit';
import max from 'lodash/max';
import isUndefined from 'lodash/isUndefined';
import geolib from 'geolib';
import uniq from 'uniq';
import withi18n from 'weego-common/src/hoc/i18n';

const routesToTrips = (routes, { travelRequirements }) => {
  // we clone deep because we might directly mutate stops when squashing
  const squashedRoutes = cloneDeep(routes).map(splitRoute => {
    return {
      ...splitRoute,
      stops: uniqWith(splitRoute.stops, (stop1, stop2) => {
        const distance = geolib.getDistance(
          { latitude: stop1.coords.lat, longitude: stop1.coords.lng },
          { latitude: stop2.coords.lat, longitude: stop2.coords.lng },
        );
        const areCloseEnough =
          stop1.demand && // Prevent depots from squashed included
          stop2.demand &&
          Math.sign(stop1.demand) === Math.sign(stop2.demand) &&
          distance < 10 &&
          moment(stop1.maxDate).diff(stop2.maxDate, 'minutes') < 3;
        if (areCloseEnough) {
          const summedDemands =
            (parseInt(stop1.demand) || 0) + (parseInt(stop2.demand) || 0);
          stop1.demand = summedDemands;
          stop2.demand = summedDemands;
          const mergedDemandIds = uniq(
            [].concat(stop1.demandIds).concat(stop2.demandIds),
          );
          stop1.demandIds = mergedDemandIds;
          stop2.demandIds = mergedDemandIds;
          const mergedDemandGroups = uniq(
            [].concat(stop1.groups).concat(stop2.groups),
          );
          stop1.groups = mergedDemandGroups;
          stop2.groups = mergedDemandGroups;
        }
        return areCloseEnough;
      }),
    };
  });

  const splitRoutes = flatten(
    squashedRoutes.map(route => {
      const splitRoutes = [];
      let currentRoute = { ...route, stops: [] };
      let pendingDemandIds = [];
      route.stops.slice(1, route.stops.length - 1).forEach(stop => {
        if (pendingDemandIds.length !== 0) {
          currentRoute.stops.push(stop);
          if (stop.demand > 0) {
            pendingDemandIds = pendingDemandIds.concat(stop.demandIds);
          } else {
            pendingDemandIds = without(pendingDemandIds, ...stop.demandIds);
          }
        } else {
          splitRoutes.push({ ...route, stops: [stop] });
          currentRoute = splitRoutes[splitRoutes.length - 1];
          pendingDemandIds = [...stop.demandIds];
        }
      });

      return splitRoutes;
    }),
  );

  const demandRedistributedRoutes = map(splitRoutes, route => {
    const demandRedistributedStops = route.stops.map((stop, index) => {
      // We only do this for pickup stops
      // TODO: find a more reliable way to find if a stop is pickup or dropoff
      if (stop.demand < 0) {
        return stop;
      }
      const similarStopAhead = find(
        route.stops.slice(index + 1),
        stopAhead =>
          geolib.getDistance(
            latLngToCoordinates(stop.coords),
            latLngToCoordinates(stopAhead.coords),
          ) < 10,
      );
      if (!similarStopAhead) {
        return stop;
      }
      // We only do this for pickup stops
      // TODO: find a more reliable way to find if a stop is pickup or dropoff
      if (similarStopAhead.demand < 0) {
        return stop;
      }
      const similarStopAheadIndex = route.stops.indexOf(similarStopAhead);
      const intermediaryStops = route.stops.slice(
        index + 1,
        similarStopAheadIndex,
      );
      const droppedOffDemands = uniq(
        flatten(intermediaryStops.map(stop => stop.demandIds)),
      );
      const carriedOverDemands = uniq(
        difference(stop.demandIds, droppedOffDemands),
      );
      if (!carriedOverDemands.length) {
        return stop;
      }
      similarStopAhead.demand += carriedOverDemands.length;
      similarStopAhead.demandIds =
        similarStopAhead.demandIds.concat(carriedOverDemands);
      return {
        ...stop,
        demand: stop.demand - carriedOverDemands.length,
        demandIds: without(stop.demandIds, ...carriedOverDemands),
      };
    });
    return {
      ...route,
      stops: demandRedistributedStops.filter(stop => stop.demand !== 0),
    };
  });

  const trips = demandRedistributedRoutes.map(finalRoute => {
    const firstStopTime = moment(finalRoute.stops[0].maxDate);
    const departureTime = firstStopTime.clone();
    if (
      departureTime.clone().startOf('day').isBefore(moment().startOf('day'))
    ) {
      departureTime.add(1, 'week');
    }
    return {
      id: `${finalRoute.vehicle.id}-${departureTime}`,
      departureTime,
      from: finalRoute.stops[0],
      to: finalRoute.stops[finalRoute.stops.length - 1],
      stops: finalRoute.stops.slice(1, finalRoute.stops.length - 1),
      totalDistance: finalRoute.stops
        .slice(1)
        .reduce((total, stop) => total + stop.distanceTo, 0),
      vehicle: finalRoute.vehicle,
      vehicleId: finalRoute.vehicle.id,
      driverId: finalRoute.vehicle.driverId,
      demandIds: uniq(
        flatten(finalRoute.stops.map(stop => stop.demandIds || [])),
      ),
      groups: uniq(
        flatten(finalRoute.stops.map(stop => stop.groups || [])).concat(
          finalRoute.vehicle.groups || [],
        ),
      ),
    };
  });

  const orderedTrips = orderBy(trips, 'departureTime');

  return removeStartDelays(orderedTrips, {
    travelRequirements,
  });
};

/**
 * The planner makes vehicles start their route as late as possible
 * in order to minimize vehicle total travel time
 * This is not something we want since we want to minimize passenger wait time as well
 */
function removeStartDelays(trips, { travelRequirements }) {
  return map(trips, trip => {
    const vehicle = trip.vehicle;
    const vehicleTrips = trips.filter(trip => trip.vehicle.id === vehicle.id);
    const firstTrip = vehicleTrips[0];
    if (trip !== firstTrip) {
      return trip;
    }
    const startDemandIds = firstTrip.from.demandIds;
    const startDemands = startDemandIds.map(demandId =>
      travelRequirements.find(req => req.id === demandId),
    );
    const maxDepartureTime = max(
      startDemands.map(demand =>
        demand.departure.time
          ? moment(demand.departure.time).diff(
              moment().startOf('day'),
              'seconds',
            )
          : undefined,
      ),
    );

    if (isUndefined(maxDepartureTime)) {
      return trip;
    }

    const maxDepartureDateTime = moment(firstTrip.departureTime)
      .startOf('day')
      .add(maxDepartureTime, 'seconds');

    if (moment(firstTrip.departureTime).isAfter(maxDepartureDateTime)) {
      const diff = moment(firstTrip.departureTime).diff(
        maxDepartureDateTime,
        'seconds',
      );
      return {
        ...firstTrip,
        departureTime: moment(firstTrip.departureTime)
          .subtract(diff, 'seconds')
          .toDate(),
        from: {
          ...firstTrip.from,
          minDate: moment(firstTrip.from.minDate)
            .subtract(diff, 'seconds')
            .toDate(),
          maxDate: moment(firstTrip.from.maxDate)
            .subtract(diff, 'seconds')
            .toDate(),
        },
        to: {
          ...firstTrip.to,
          minDate: moment(firstTrip.to.minDate)
            .subtract(diff, 'seconds')
            .toDate(),
          maxDate: moment(firstTrip.to.maxDate)
            .subtract(diff, 'seconds')
            .toDate(),
        },
        stops: (firstTrip.stops || []).map(stop => ({
          ...stop,
          minDate: moment(stop.minDate).subtract(diff, 'seconds').toDate(),
          maxDate: moment(stop.maxDate).subtract(diff, 'seconds').toDate(),
        })),
      };
    }

    return trip;
  });
}

const RouteImporter = memo(
  ({ selectedTrips, importing, error, importTrips, onImportFinished, t }) => {
    const [folder, setFolder] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const isFirstRender = useRef(true);

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      if (!importing && !error) {
        setIsSuccess(true);
        onImportFinished(
          selectedTrips.map(t => omit(t, 'id')),
          folder,
        );
      } else {
        setIsSuccess(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importing, error]);

    return (
      <div>
        {isSuccess && (
          <Message success>
            {t('Trajets importés avec succès dans le dossier {{folder}}', {
              folder,
            })}
          </Message>
        )}
        {error && (
          <Message error>{error.message || t('Erreur inconnue')}</Message>
        )}
        <Input
          label="Dossier"
          value={folder}
          onChange={(e, { value }) => setFolder(value)}
        />
        {selectedTrips.map((trip, index) => (
          <Segment key={index}>
            <div>{trip.vehicle.carLabel || trip.vehicle.carNumber}</div>
            {[trip.from, ...(trip.stops || []), trip.to].map((stop, index) => (
              <div style={styles.stop} key={index}>
                <span>{moment(stop.maxDate).format('HH:mm')}</span>:{' '}
                <span>{stop.name}</span>{' '}
                <span>
                  ({stop.demand > 0 ? 'p' : 'd'}
                  {stop.demand},{stop.distanceTo / 1000}kms)
                </span>
                {index < trip.stops.length + 2 - 1 && (
                  <Icon name="arrow right" />
                )}
              </div>
            ))}
            <div>{moment(trip.departureTime).format('DD/MM/YYYY')}</div>
            <div>{trip.totalDistance / 1000} kms</div>
            <div>
              {t('{{count}} passagers', {
                count: trip.demandIds.length,
              })}
            </div>
            <div>
              {t('Cout')}: {(trip.vehicle.cost * trip.totalDistance) / 1000}
            </div>
            <div>
              {t('Revenu')}: {trip.demandIds.length * 16}
            </div>
          </Segment>
        ))}
        <div style={styles.actions}>
          <Button
            primary
            disabled={!folder}
            loading={importing}
            onClick={() =>
              importTrips(
                selectedTrips.map(t => omit(t, 'id')),
                folder,
              )
            }
          >
            {t('Importer')}
          </Button>
        </div>
      </div>
    );
  },
);

const styles = {
  stop: {
    display: 'inline-block',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default withi18n('planner')(RouteImporter);

export { routesToTrips };

const latLngToCoordinates = latLng => ({
  latitude: latLng.lat,
  longitude: latLng.lng,
});

import { takeEvery, all, put } from 'redux-saga/effects';

import paymentActions from '../../actions/payment';
import weegoClient from '../../providers/weego-client';

function* refill(action) {
  const { userId, amount } = action.payload;
  try {
    const account = yield weegoClient.payment.getAccount(userId);
    yield weegoClient.payment.refill(account.id, amount);
    yield put(paymentActions.refillSuccess());
    yield put(
      paymentActions.updateAccount({
        ...account,
        balance: account.balance + amount,
      }),
    );
  } catch (error) {
    console.error(error);
    yield put(paymentActions.refillError(error));
  }
}

function* loadAccount(action) {
  const { userId } = action.payload;
  try {
    const account = yield weegoClient.payment.getAccount(userId);
    yield put(paymentActions.updateAccount(account));
  } catch (error) {
    console.error(error);
    yield put(paymentActions.updateAccountError(error));
  }
}

function* paymentSaga() {
  yield all([
    takeEvery(paymentActions.refill, refill),
    takeEvery(paymentActions.requestAccount, loadAccount),
  ]);
}

export default paymentSaga;

import { createActions } from 'redux-actions';

const { liveMap } = createActions({
  LIVE_MAP: {
    REQUEST_UPDATE: [
      sinceDate => ({ sinceDate }),
      () => ({
        analytics: {
          ignore: true,
        },
      }),
    ],
    UPDATE_POSITIONS: [
      positions => ({ positions }),
      () => ({
        analytics: {
          ignore: true,
        },
      }),
    ],
    FOCUS_VEHICLE: vehicle => ({ vehicle }),
    TARGET_VEHICLE: vehicle => ({ vehicle }),
    UNFOCUS_VEHICLE: () => null,
    REQUEST_VEHICLE_HISTORY: (number, startDate, endDate) => ({
      number,
      startDate,
      endDate,
    }),
    UPDATE_VEHICLE_HISTORY: history => ({ history }),
  },
});

export default liveMap;

import React, { PureComponent, memo } from 'react';
import XLSX from 'xlsx';
import {
  Table,
  Button,
  Dropdown,
  Form,
  Input,
  Loader,
  Icon,
  Radio,
  Image,
  Message,
  Checkbox,
  Card,
  Pagination,
  Menu,
  Grid,
} from 'semantic-ui-react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import set from 'lodash/set';
import size from 'lodash/size';
import isArray from 'lodash/isArray';
import pluralize from 'pluralize';
import ObjectID from 'bson-objectid';
import isFunction from 'lodash/isFunction';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';
import toArray from 'lodash/toArray';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import zipObjectDeep from 'lodash/zipObjectDeep';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import validate from 'validate.js';
import memoizeOne from 'memoize-one';
import { TimeInput, DateInput } from 'semantic-ui-calendar-react';
import { toast } from 'react-semantic-toasts';

import withi18n from 'weego-common/src/hoc/i18n';

import dateToTimeString from '../../../utils/date/dateTimeToString';
import timeStringToDate from '../../../utils/date/timeStringToDate';
import dateToString from '../../../utils/date/dateToString';
import stringToDate from '../../../utils/date/stringToDate';

import fieldPropType from './fieldPropType';

import DialogBox from '../../common/DialogBox';
import PlacesAutoCompleteInput from '../../common/PlacesAutocompleteInput';
import PhoneNumberInput from '../../common/PhoneNumberInput';

import ExcelImporter from '../ExcelImporter';

import editIcon from '../../../assets/images/edit-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import emptyArray from '../../../assets/images/empty-array.png';
import closeIcon from '../../../assets/images/close.png';
import importIcon from '../../../assets/images/import.svg';
import colors from '../../../theme/colors';

import './CRUDManager.css';
import { fetchSavedFilters, saveFilters } from '../../../utils/persistFilters';

window.globalFields = [];

const limitOptions = [
  {
    key: 0,
    text: '10',
    value: 10,
  },
  {
    key: 1,
    text: '25',
    value: 25,
  },
  {
    key: 2,
    text: '50',
    value: 50,
  },
  {
    key: 3,
    text: '100',
    value: 100,
  },
  {
    key: 4,
    text: '1000',
    value: 1000,
  },
];

function renderFieldContent(
  record,
  field,
  relations,
  onEdit,
  t,
  serialize = false,
) {
  if (isArray(field.type)) {
    const contents = [...Array(size(get(record, field.key)))].map((_, index) =>
      renderFieldContent(
        record,
        {
          ...field,
          type: field.type[0],
          key: `${field.key}[${index}]`,
        },
        relations,
        null,
        t,
      ),
    );
    const nullContents = contents.filter(v => !v);
    if (field.render) {
      return contents;
    }
    return (
      contents
        // Remove null references
        .filter(v => !!v)
        .join(', ')
        .concat(
          field.type[0] === 'REF' && nullContents.length
            ? ` (${nullContents.length} supprimé(s))`
            : '',
        )
    );
  }
  let content;
  if (
    field.render &&
    (content = field.render({ record, field, relations, serialize, t })) !==
      undefined
  ) {
    return content;
  } else if (field.type === 'STRING' || field.type === 'NUMBER') {
    content = get(record, field.key);
  } else if (field.type === 'BOOLEAN') {
    if (!serialize) {
      content = (
        <div style={styles.toggleContainer}>
          <Radio
            toggle
            checked={!!get(record, field.key, false)}
            onChange={() => {
              onEdit({ ...record, [field.key]: !record[field.key] });
            }}
          />

          <span
            style={{
              ...styles.suffixLabel,
              color: get(record, field.key, false) ? colors.PRIMARY : '#e0e0e0',
            }}
          >
            {t(`fields~${field.suffix}`)}
          </span>
        </div>
      );
    } else {
      content = get(record, field.key, false);
    }
  } else if (field.type === 'ENUM') {
    const options = field.options;
    const recordOption = options.find(
      option => option.value === get(record, field.key),
    );
    content = recordOption ? t(`fields~${recordOption.label}`) : '';
  } else if (field.type === 'TIME') {
    content = dateToTimeString(get(record, field.key));
  } else if (field.type === 'DATE') {
    content = dateToString(get(record, field.key));
  } else if (field.type === 'DATETIME') {
    content = dateToString(get(record, field.key), true);
  } else if (field.type === 'REF') {
    const relationRecords = relations?.[field.ref];
    const relationRecord = relationRecords?.[get(record, field.key)];
    content = field.renderRef
      ? field.renderRef(relationRecord, { record })
      : field.refKey
      ? get(relationRecord, field.refKey)
      : 'Inconnu';
  } else if (field.type === 'PLACE') {
    content = get(record, `${field.key}.name`);
  } else {
    content = String(get(record, field.key));
  }
  return content;
}

const Record = memo(
  ({
    record,
    fields,
    selected,
    relations,
    onSelect,
    onEdit,
    onDelete,
    recordActions,
    canEdit,
    canRemove,
    t,
  }) => (
    <Table.Row>
      {fields
        .filter(field => !field.hideInTable)
        .map(field => {
          return (
            <Table.Cell key={field.key}>
              {renderFieldContent(record, field, relations, onEdit, t)}
            </Table.Cell>
          );
        })}
      <Table.Cell>
        <div style={styles.actionButtons}>
          {canEdit && (
            <a
              href="#/"
              onClick={() => onEdit(record)}
              style={styles.rightButtons}
            >
              <ReactSVG src={editIcon} />
            </a>
          )}
          {canRemove && (
            <a
              href="#/"
              onClick={() => onDelete(record)}
              style={styles.rightButtons}
            >
              <ReactSVG src={deleteIcon} />
            </a>
          )}
          {(recordActions || []).map(action => action.render({ record }))}
        </div>
      </Table.Cell>
      <Table.Cell>
        <Loader active={record.busy} inline />
        {!record.busy && (canEdit || canRemove) && (
          <Checkbox checked={selected} onClick={() => onSelect(record)} />
        )}
      </Table.Cell>
    </Table.Row>
  ),
);

const RecordForm = memo(
  ({
    record,
    fields,
    relations,
    isCreate,
    isUpdate,
    onChange,
    onSubmit,
    renderAfterForm,
    resourceLabel,
    t,
  }) => (
    <Form className="crud-manager-record-form" onSubmit={onSubmit}>
      {/* <Form.Group> */}
      <div style={styles.formContainer}>
        {fields
          .filter(field => !field.hideInForm)
          .filter(field => record.id || !field.hideInCreateForm)
          .filter(
            field =>
              !field.shouldRender ||
              field.shouldRender({ record, isCreate, isUpdate }),
          )
          .map((field, index) => (
            <Form.Field
              className="field-container"
              style={{ ...styles.fieldContainer, ...field.inputStyles }}
              required={field.required}
              key={field.key}
            >
              <label
                style={{
                  fontSize: field.fontSize ? field.fontSize : 12,
                  color:
                    isFunction(field.disabled) && field.disabled(record)
                      ? '#e9eff4'
                      : 'black',
                }}
              >
                {t(`fields~${field.label}`)}
              </label>
              {field.renderInput
                ? field.renderInput({ record, onChange, t })
                : null}
              {field.type === 'STRING' && (
                <div>
                  <Input
                    list={field.list && field.key}
                    value={get(record, field.key, '')}
                    autoFocus={index === 0}
                    onChange={(e, { value }) =>
                      onChange(set({ ...record }, field.key, value))
                    }
                    disabled={
                      isFunction(field.disabled) && field.disabled(record)
                    }
                  />
                  {field.list && (
                    <datalist id={field.key}>
                      {field.list.map(item => (
                        <option key={item} value={item}></option>
                      ))}
                    </datalist>
                  )}
                </div>
              )}
              {field.type === 'NUMBER' && (
                <Input
                  type="number"
                  value={get(record, field.key, '')}
                  autoFocus={index === 0}
                  onChange={(e, { value }) =>
                    onChange(set({ ...record }, field.key, value))
                  }
                />
              )}
              {field.type === 'BOOLEAN' && (
                <div style={styles.booleanContainer}>
                  <label style={styles.suffixLabelForm}>
                    {t(`Ce {{resource}} {{verb}} {{suffix}}`, {
                      resource: resourceLabel,
                      verb: get(record, field.key, false)
                        ? t('est')
                        : t("n'est pas"),
                      suffix: t(`fields~${field.suffix}`),
                    })}
                  </label>
                  <Radio
                    toggle
                    checked={!!get(record, field.key, false)}
                    onChange={(e, data) => {
                      onChange(set({ ...record }, field.key, data.checked));
                    }}
                  />
                </div>
              )}
              {(field.type === 'ENUM' || field.type[0] === 'ENUM') &&
                (!field.format || field.format === 'DROPDOWN' ? (
                  <Dropdown
                    placeholder={t(`fields~${field.label}`)}
                    fluid
                    search
                    selection
                    multiple={isArray(field.type)}
                    options={field.options.map(field => ({
                      key: field.value,
                      value: field.value,
                      text: t(`fields~${field.label}`),
                    }))}
                    value={get(
                      record,
                      field.key,
                      isArray(field.type) ? [] : null,
                    )}
                    onChange={(e, { value }) =>
                      onChange(set({ ...record }, field.key, value))
                    }
                  />
                ) : field.format === 'TOGGLES' ? (
                  map(field.options, option => {
                    const value = get(record, field.key);
                    const active = isArray(field.type)
                      ? (value || []).includes(option.value)
                      : value === option.value;

                    const toggleOption = () =>
                      onChange(
                        set(
                          { ...record },
                          field.key,
                          active
                            ? value.filter(v => v !== option.value)
                            : (value || []).concat(option.value),
                        ),
                      );

                    const selectOption = () =>
                      onChange(set({ ...record }, field.key, option.value));

                    return (
                      <Button
                        key={option.value}
                        style={{
                          backgroundColor: active
                            ? colors.PRIMARY
                            : colors.WHITE,
                          border: '1px solid #e9eff4',
                          color: active ? colors.WHITE : colors.BLACK,
                        }}
                        active={active}
                        onClick={() =>
                          isArray(field.type) ? toggleOption() : selectOption()
                        }
                        type="button"
                      >
                        {t(`fields~${option.label}`)}
                      </Button>
                    );
                  })
                ) : null)}
              {field.type === 'TIME' && (
                <div style={styles.placeContainer}>
                  <TimeInput
                    value={dateToTimeString(get(record, field.key))}
                    onChange={(event, { value }) => {
                      const time = timeStringToDate(value);
                      onChange(set({ ...record }, field.key, time));
                    }}
                    closable={true}
                    clearable={true}
                  />
                </div>
              )}
              {field.type === 'DATE' && (
                <div style={styles.placeContainer}>
                  <DateInput
                    value={dateToString(get(record, field.key))}
                    onChange={(event, { value }) => {
                      const date = stringToDate(value);
                      onChange(set({ ...record }, field.key, date));
                    }}
                    closable={true}
                    clearable={true}
                  />
                </div>
              )}
              {field.type === 'PHONE' && (
                <PhoneNumberInput
                  value={get(record, field.key)}
                  onChange={phoneNumber =>
                    onChange(set({ ...record }, field.key, phoneNumber))
                  }
                />
              )}
              {field.type === 'PLACE' && (
                <div style={styles.placeContainer}>
                  <PlacesAutoCompleteInput
                    place={get(record, field.key)}
                    showMap={field.showMap}
                    hasDefaultAddress={field.hasDefaultAddress}
                    reverseGeocode={field.reverseGeocode}
                    disableCustomPlaces={field.disableCustomPlaces}
                    placeholder={t(`fields~${field.label}`)}
                    onChange={place => {
                      onChange(set({ ...record }, field.key, place));
                    }}
                  />
                </div>
              )}
              {(field.type === 'REF' || field.type[0] === 'REF') && (
                <Dropdown
                  placeholder={field.label}
                  fluid
                  search
                  selection
                  clearable
                  multiple={isArray(field.type)}
                  options={uniqBy(
                    map(relations[field.ref], relationRecord => ({
                      key: relationRecord[field.refId || '_id'],
                      value: relationRecord[field.refId || '_id'],
                      text: field.renderRef
                        ? field.renderRef(relationRecord, { record })
                        : field.refKey
                        ? relationRecord[field.refKey]
                        : t('Inconnu'),
                    })).concat(
                      // This is so that deleted refs still appear with their ids
                      (isArray(field.type)
                        ? get(record, field.key) || []
                        : []
                      ).map(relationId => ({
                        key: relationId,
                        value: relationId,
                        text: relationId,
                      })),
                    ),
                    'key',
                  )}
                  value={get(
                    record,
                    field.key,
                    isArray(field.type) ? [] : null,
                  )}
                  onChange={(e, { value }) =>
                    onChange(set({ ...record }, field.key, value))
                  }
                />
              )}
            </Form.Field>
          ))}
      </div>
      {renderAfterForm && renderAfterForm({ isCreate, isUpdate })}
    </Form>
  ),
);

class CrudManager extends PureComponent {
  constructor(props) {
    super(props);
    const savedFilters = fetchSavedFilters();
    this.state = {
      openRecord: null,
      selectedRecords: [],
      validationErrors: null,
      importModalOpen: false,
      exportModalOpen: false,
      limit: limitOptions[0].value,
      skip: 0,
      activePage: 1,
      sortingDirection: null,
      sortedColumn: null,
      searchTerm: '',
      filters: savedFilters[props.resourceLabel],
    };
  }

  componentDidMount() {
    this.refreshFetch();
  }

  componentDidUpdate(prevProps) {
    const {
      createError,
      updateError,
      deleteError,
      creating,
      updating,
      deleting,
      resourceLabel,
      t,
      dateRangeFilter,
    } = this.props;

    // After successful create or update
    if (
      ((prevProps.creating && !creating) ||
        (prevProps.updating && !updating) ||
        (prevProps.deleting && !deleting)) &&
      !createError &&
      !updateError &&
      !deleteError
    ) {
      this.setState({
        openRecord: null,
        importModalOpen: false,
        exportModalOpen: false,
        selectedRecords: [],
      });
    }

    if (prevProps.creating && !creating) {
      if (!createError) {
        toast({
          type: 'success',
          icon: 'check',
          title: 'Succès',
          description: t(`{{resource}} ajouté avec succès`, {
            resource: capitalize(t(resourceLabel)),
          }),
          animation: 'fade left',
          time: 5000,
        });
      }
    }
    if (prevProps.updating && !updating) {
      if (!updateError) {
        toast({
          type: 'success',
          icon: 'check',
          title: 'Succès',
          description: t(`{{resource}} modifié avec succès`, {
            resource: capitalize(t(resourceLabel)),
          }),
          animation: 'fade left',
          time: 5000,
        });
      }
    }
    if (prevProps.deleting && !deleting) {
      if (!deleteError) {
        toast({
          type: 'success',
          icon: 'check',
          title: 'Succès',
          description: t(`{{resource}} supprimé avec succès`, {
            resource: capitalize(t(resourceLabel)),
          }),
          animation: 'fade left',
          time: 5000,
        });
        this.setState({ activePage: 1, skip: 0 }, this.refreshFetch);
      }
    }

    if (prevProps?.dateRangeFilter !== dateRangeFilter) {
      this.refreshFetch();
    }
  }

  refreshFetch = () => {
    const { fetchStart, resourceLabel, dateRangeFilter } = this.props;
    const { searchTerm, skip, limit, sortedColumn, sortingDirection, filters } =
      this.state;
    saveFilters(resourceLabel, filters);
    if (dateRangeFilter) {
      fetchStart({
        search: searchTerm || null,
        replace: true,
        skip,
        limit,
        order: sortedColumn
          ? `${sortedColumn} ${
              sortingDirection === 'descending' ? 'DESC' : 'ASC'
            }`
          : null,
        where: {
          ...filters,
          and: [
            {
              createdAt: {
                lte: dateRangeFilter?.end,
              },
            },
            {
              createdAt: {
                gte: dateRangeFilter?.start,
              },
            },
          ],
        },
      });
    } else {
      fetchStart({
        search: searchTerm || null,
        replace: true,
        skip,
        limit,
        order: sortedColumn
          ? `${sortedColumn} ${
              sortingDirection === 'descending' ? 'DESC' : 'ASC'
            }`
          : null,
        where: { ...filters },
      });
    }
  };

  getRecordFieldValue = (record, field) => {
    if (field.omitSubFields) {
      return omit(get(record, field.key), field.omitSubFields);
    }
    return get(record, field.key);
  };

  toggleRecordSelection = record => {
    const { selectedRecords } = this.state;
    this.setState({
      selectedRecords: selectedRecords.includes(record)
        ? selectedRecords.filter(r => r !== record)
        : selectedRecords.concat(record),
    });
  };

  toggleAllRecordSelection = () => {
    const { selectedRecords } = this.state;
    const { records } = this.props;
    this.setState({
      selectedRecords:
        selectedRecords.length === toArray(records).length
          ? []
          : clone(toArray(records)),
    });
  };

  openRecord = record => {
    this.setState({
      openRecord: record,
    });
  };

  validateRecord = (record, options) => {
    const { fields, recordKey, t } = this.props;
    const isCreate = !record[recordKey];
    const isUpdate = !isCreate;
    const validationConstraints = fromPairs(
      fields
        .filter(field => !!field.constraints)
        .filter(
          field =>
            !field.shouldRender ||
            field.shouldRender({ record, isUpdate, isCreate }),
        )
        .map(field => [
          field.key,
          isFunction(field.constraints)
            ? field.constraints(options)
            : field.constraints,
        ]),
    );

    const errorMessages = validate(record, validationConstraints, {
      fields,
      t,
    });
    return errorMessages;
  };

  submitRecord = (record, options = {}) => {
    const { createStart, updateStart, recordKey } = this.props;
    const errorMessages = this.validateRecord(record, options);
    if (errorMessages) {
      this.setState({
        validationErrors: errorMessages,
      });
      return;
    }
    this.setState({
      validationErrors: null,
    });
    if (record[recordKey]) {
      updateStart(record, options);
    } else {
      createStart(
        { ...record, [recordKey]: ObjectID().toHexString() },
        options,
      );
    }
  };

  submitOpenRecord = () => {
    const { openRecord } = this.state;
    const { records, recordKey } = this.props;
    // If the edit was for multiple selected records
    if (isArray(openRecord[recordKey])) {
      const ids = openRecord[recordKey];
      ids.forEach(id => {
        const existingRecord = records[id];
        const updatedRecord = merge(
          {},
          existingRecord,
          omit(openRecord, recordKey),
        );
        this.submitRecord(updatedRecord);
      });
    } else {
      this.submitRecord(openRecord);
    }
  };

  createXLSXFile = () => {
    const { records, fields, relations, t } = this.props;

    const recordsArray = toArray(records);

    let recordsArrayWithLabels = recordsArray.map(function (record) {
      return fromPairs(
        map(fields, function (field, key) {
          return [
            field.label,
            renderFieldContent(record, field, relations, null, t, true),
          ];
        }),
      );
    });

    const worksheet = XLSX.utils.json_to_sheet(recordsArrayWithLabels);
    const new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Records');
    XLSX.writeFile(new_workbook, 'file.xlsx');
  };

  import = records => {
    records.forEach(record => this.submitRecord(record, { isImport: true }));
  };

  deleteRecord = record => {
    const { deleteStart, t } = this.props;
    if (
      window.confirm(
        t('Êtes-vous sur de vouloir supprimer cet enregistrement ?'),
      )
    ) {
      deleteStart(record);
    }
  };

  editSelectedRecords = () => {
    const { selectedRecords } = this.state;
    const { recordKey, fields } = this.props;
    // Get the key value pairs that are similar among selected records
    // to constitue the open record. This way we display in the modal the values for
    // fields that are similar among selected records
    const similarRecordsKvPairs = fields
      .map(field => {
        const { key } = field;
        const valuesAccrossRecords = uniqWith(
          selectedRecords.map(r => get(r, key)),
          (v1, v2) => {
            if (field.type === 'TIME') {
              return dateToTimeString(v1) === dateToTimeString(v2);
            }
            return isEqual(
              this.getRecordFieldValue({ [field.key]: v1 }, field),
              this.getRecordFieldValue({ [field.key]: v2 }, field),
            );
          },
        );
        if (valuesAccrossRecords.length > 1) {
          return null;
        }
        return [key, this.getRecordFieldValue(selectedRecords[0], field)];
      })
      .filter(pair => pair !== null);
    const keys = similarRecordsKvPairs.map(pair => pair[0]);
    const values = similarRecordsKvPairs.map(pair => pair[1]);
    const similarFieldsRecord = zipObjectDeep(keys, values);
    this.openRecord({
      ...similarFieldsRecord,
      [recordKey]: selectedRecords.map(rec => rec[recordKey]),
    });
  };

  deleteSelectedRecords = () => {
    const { deleteStart, t } = this.props;
    const { selectedRecords } = this.state;
    if (
      window.confirm(
        t(`Êtes-vous sur de vouloir supprimer {{count}} enregistrement(s) ?`, {
          count: selectedRecords.length,
        }),
      )
    ) {
      selectedRecords.forEach(record => {
        deleteStart(record);
      });
    }
  };

  clearSelectedRecords = () => {
    this.setState({
      selectedRecords: [],
    });
  };

  openImportModal = () => {
    this.setState({
      importModalOpen: true,
    });
  };

  closeImportModal = () => {
    this.setState({
      importModalOpen: false,
    });
  };

  openExportModal = () => {
    this.setState({
      exportModalOpen: true,
    });
  };
  closeExportModal = () => {
    this.setState({
      exportModalOpen: false,
    });
  };

  renderRecord = record => {
    const { changeHandler, canEdit, canRemove, t } = this.props;
    const { selectedRecords } = this.state;
    return (
      <Record
        key={record[this.props.recordKey]}
        record={record}
        selected={selectedRecords.includes(record)}
        onSelect={this.toggleRecordSelection}
        fields={this.props.fields}
        relations={this.props.relations}
        recordActions={this.props.recordActions}
        onEdit={record => {
          const updatedRecord = changeHandler ? changeHandler(record) : record;
          this.openRecord(updatedRecord);
        }}
        onDelete={this.deleteRecord}
        recordKey={this.props.recordKey}
        canEdit={canEdit}
        canRemove={canRemove}
        t={t}
      />
    );
  };

  handlePageChange = (e, { activePage }) => {
    const { limit } = this.state;
    this.setState(
      { activePage, skip: (activePage - 1) * limit },
      this.refreshFetch,
    );
  };

  handleOrder = column => {
    const { sortingDirection, sortedColumn } = this.state;
    if (!sortedColumn || (sortedColumn && sortedColumn !== column)) {
      this.setState(
        {
          sortingDirection: 'ascending',
          sortedColumn: column,
        },
        this.refreshFetch,
      );
    }
    if (sortedColumn === column) {
      this.setState(
        {
          sortingDirection:
            sortingDirection === 'ascending' ? 'descending' : 'ascending',
          sortedColumn: column,
        },
        this.refreshFetch,
      );
    }
  };

  handleSearchTermChange = (e, { value }) => {
    this.setState({ searchTerm: value });
    this.handleGlobalSearch();
  };

  handleGlobalSearch = debounce(() => {
    this.setState({ activePage: 1, skip: 0 }, this.refreshFetch);
  }, 1000);

  updateFilters = (field, option) => {
    const oldArray = this.state.filters
      ? this.state.filters[field.key]?.inq
      : null;

    // Avoid duplicates
    if (oldArray && oldArray.includes(option.value)) {
      return;
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [field.key]: {
            inq: oldArray ? oldArray.concat([option.value]) : [option.value],
          },
        },
      },
      this.refreshFetch,
    );
  };

  resetFilters = () => {
    this.setState({ filters: null }, this.refreshFetch);
  };

  removeFilter(fieldKey, value) {
    const updatedFilters = mapValues(this.state.filters, (filter, key) => {
      if (key === fieldKey) {
        return { inq: without(filter.inq, value) };
      }
      return filter;
    });
    const withoutEmptyFilters = omitBy(updatedFilters, filter =>
      isEmpty(filter.inq),
    );
    this.setState({ filters: withoutEmptyFilters }, this.refreshFetch);
  }

  renderFiltersDropDown() {
    const { fields, relations, t } = this.props;
    const fieldsToFilter = fields.filter(
      field =>
        field.type === 'ENUM' ||
        field.type.includes('ENUM') ||
        field.type === 'BOOLEAN' ||
        field.type.includes('BOOLEAN') ||
        field.type === 'REF' ||
        field.type.includes('REF'),
    );
    return (
      <Menu style={{ margin: 0 }}>
        <Dropdown
          trigger={
            <div>
              <Icon name="filter" />
              <div style={styles.filtersDropdownLabel}>{t('Filtrer par')}</div>
            </div>
          }
          style={styles.filtersDropdown}
          className="icon dashboard-filters-dropdown"
          fluid
          item
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.resetFilters()}>
              {fieldsToFilter.length === 0
                ? t('Aucun filtre disponible')
                : t('Annuler filtres')}
            </Dropdown.Item>
            <Dropdown.Divider />
            {fieldsToFilter
              .filter(field => !field.disableFilter)
              .map(field => {
                let options = [];
                const isRef =
                  field.type === 'REF' || field.type.includes('REF');
                if (field.type === 'ENUM' || field.type.includes('ENUM')) {
                  options = field.options;
                } else if (isRef) {
                  options = toArray(relations[field.ref]).map(
                    relationRecord => ({
                      label: field.renderRef
                        ? field.renderRef(relationRecord)
                        : field.refKey
                        ? get(relationRecord, field.refKey)
                        : t('Inconnu'),
                      value: relationRecord[field.refId || '_id'],
                    }),
                  );
                } else if (
                  field.type === 'BOOLEAN' ||
                  field.type.includes('BOOLEAN')
                ) {
                  options = [
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                  ];
                }
                return (
                  <Dropdown
                    className="item"
                    fluid
                    text={t(`fields~${field.label}`)}
                  >
                    <Dropdown.Menu>
                      {options.map(option => (
                        <Dropdown.Item
                          key={option.value || option.id}
                          onClick={() => {
                            this.updateFilters(field, option);
                          }}
                        >
                          {isRef ? option.label : t(`fields~${option.label}`)}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );
  }

  renderFiltersOptions() {
    const { fields, relations, t } = this.props;
    const { filters } = this.state;
    return map(filters, (filter, filterKey) => {
      const field = find(fields, f => f.key === filterKey);
      return (
        field &&
        filter.inq.length > 0 && (
          <div style={styles.filterOptionsContainer}>
            {t(`fields~${field.label}`)}:{'  '}
            {filter.inq.map(value => {
              let option = '';
              const isRef = field.type === 'REF' || field.type.includes('REF');
              if (isRef) {
                const relationRecord = relations[field.ref][value];
                option = {
                  label: field.renderRef
                    ? field.renderRef(relationRecord)
                    : field.refKey
                    ? get(relationRecord, field.refKey)
                    : t('Inconnu'),
                  value: relationRecord?.[field.refId || '_id'],
                };
              } else if (field.type === 'BOOLEAN') {
                const optionsList = [
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ];
                option = find(optionsList, o => o.value === value);
              } else {
                option = find(field.options, o => o.value === value);
              }
              return (
                <div>
                  {isRef ? option.label : t(`fields~${option.label}`)}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.removeFilter(filterKey, value)}
                  >
                    <Icon color="red" name="close"></Icon>
                  </span>
                </div>
              );
            })}
          </div>
        )
      );
    });
  }

  render() {
    const {
      records,
      fields,
      relations,
      resourceLabel,
      addButtonLabel,
      modifyButtonLabel,
      recordKey,
      changeHandler,
      style = {},
      canImport,
      canExport,
      creating,
      updating,
      createError,
      updateError,
      renderAfterForm,
      count,
      loading,
      fetch,
      renderBeforeGlobalActions,
      renderAfterGlobalActions,
      renderAfterFilters,
      canEdit,
      canRemove,
      t,
      canAdd = true,
    } = this.props;
    const {
      openRecord,
      validationErrors,
      selectedRecords,
      importModalOpen,
      exportModalOpen,
      limit,
      activePage,
      sortedColumn,
      sortingDirection,
      searchTerm,
    } = this.state;
    const createOrUpdateError = createError || updateError;
    let formTitle = '';
    if (openRecord) {
      if (openRecord[recordKey]) {
        if (isArray(openRecord[recordKey])) {
          formTitle =
            modifyButtonLabel ||
            t('Modifier {{count}} {{resource}}', {
              count: openRecord[recordKey].length,
              resource: t(resourceLabel),
            });
        } else {
          formTitle =
            modifyButtonLabel ||
            t(`Modifier {{resource}}`, {
              resource: t(resourceLabel),
            });
        }
      } else {
        formTitle =
          addButtonLabel ||
          t(`Ajouter {{resource}}`, {
            resource: t(resourceLabel),
          });
      }
    }

    return (
      <div className="crud-manager" style={{ ...styles.container, ...style }}>
        <Grid style={styles.lineAdd}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={2} computer={2}>
              <label style={styles.resourceLabel}>
                {t('Gestion des {{resource}}', {
                  resource: pluralize(t(resourceLabel)),
                })}
              </label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <Input
                iconPosition="left"
                icon="search"
                placeholder={t('Rechercher')}
                onChange={this.handleSearchTermChange}
                value={searchTerm}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              <div style={styles.filtersOptions}>
                {this.renderFiltersDropDown()}
                {renderAfterFilters && renderAfterFilters()}
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <div style={styles.mainActionsContainer}>
                {renderBeforeGlobalActions && renderBeforeGlobalActions()}
                {canImport && (
                  <Button basic icon primary onClick={this.openImportModal}>
                    <Image inline src={importIcon} style={styles.importIcon} />
                    {'  '}
                    {t('Importer des {{resource}}', {
                      resource: pluralize(t(resourceLabel)),
                    })}
                  </Button>
                )}
                {(canImport || canExport) && (
                  <Button basic icon primary onClick={this.openExportModal}>
                    <i className="upload icon"></i>
                    {'  '}
                    {t('Exporter des {{resource}}', {
                      resource: pluralize(t(resourceLabel)),
                    })}
                  </Button>
                )}
                {canAdd && (
                  <Button
                    style={styles.button}
                    icon
                    primary
                    onClick={() => this.openRecord({})}
                  >
                    <Icon name="add"></Icon>
                    <span style={styles.mainActionStyles}>
                      {addButtonLabel
                        ? addButtonLabel
                        : `${t('Ajouter un {{resource}}', {
                            resource: t(resourceLabel),
                          })}`}
                    </span>
                  </Button>
                )}
                {renderAfterGlobalActions && renderAfterGlobalActions()}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={styles.filtersOptions}>{this.renderFiltersOptions()}</div>
        <DialogBox
          title={formTitle}
          subTitle={
            openRecord &&
            openRecord[recordKey] &&
            !isArray(openRecord[recordKey])
              ? openRecord[recordKey]
              : ''
          }
          onClose={() => this.setState({ openRecord: null })}
          open={!!openRecord}
          actions={
            // We did not put this in the form so we can have it
            // show up as a modal action
            <Button
              style={styles.actionButton}
              onClick={this.submitOpenRecord}
              loading={creating || updating}
              disabled={creating || updating}
              primary
            >
              {openRecord && openRecord[recordKey]
                ? t('Modifier')
                : t('Ajouter')}
            </Button>
          }
        >
          {createOrUpdateError && (
            <Message error>
              {createOrUpdateError.message || t('Erreur inconnue')}
            </Message>
          )}
          {validationErrors && (
            <Message error>
              {map(validationErrors, (errors, fieldKey) =>
                map(errors, (error, index) => (
                  <div key={`${fieldKey}-${index}`}>{error}</div>
                )),
              )}
            </Message>
          )}
          <RecordForm
            resourceLabel={t(resourceLabel)}
            record={openRecord}
            fields={this.props.fields}
            relations={this.props.relations}
            isCreate={!openRecord || !openRecord[recordKey]}
            isUpdate={openRecord && !!openRecord[recordKey]}
            renderAfterForm={renderAfterForm}
            onChange={record => {
              const updatedRecord = changeHandler
                ? changeHandler(record)
                : record;
              this.setState({
                openRecord: updatedRecord,
              });
            }}
            t={t}
            onSubmit={this.submitOpenRecord}
          />
        </DialogBox>
        {canImport && (
          <DialogBox
            title={t('Importer')}
            onClose={this.closeImportModal}
            open={importModalOpen}
            style={styles.excelImporterModal}
            showFooter={false}
          >
            <ExcelImporter
              resourceLabel={resourceLabel}
              fields={fields}
              relations={relations}
              recordKey={recordKey}
              validateRecord={this.validateRecord}
              fetch={fetch}
              saving={creating}
              error={createOrUpdateError}
              onSave={this.import}
              renderAfterForm={renderAfterForm}
              t={t}
            />
          </DialogBox>
        )}

        {(canImport || canExport) && (
          <DialogBox
            title={t('Exporter')}
            onClose={this.closeExportModal}
            open={exportModalOpen}
            style={styles.excelImporterModal}
            showFooter={false}
          >
            <Button
              style={styles.actionButton}
              onClick={this.createXLSXFile}
              loading={creating || updating}
              disabled={creating || updating}
              primary
            >
              {t('Telecharger')}
            </Button>
          </DialogBox>
        )}

        <div className="table-container" style={styles.tableContainer}>
          <div style={styles.veil}></div>
          {records && !isEmpty(records) ? (
            <Table sortable>
              <Table.Header>
                <Table.Row>
                  {fields
                    .filter(field => !field.hideInTable)
                    .map(field => (
                      <Table.HeaderCell
                        onClick={() =>
                          this.handleOrder(field.sortingKey || field.key)
                        }
                        key={field.key}
                        style={{ flex: 1, flexDirection: 'row' }}
                      >
                        {t(`fields~${field.label}`)}
                        <Icon
                          style={{
                            position: 'absolute',
                          }}
                          name="sort up"
                          color={
                            sortingDirection === 'ascending' &&
                            sortedColumn === field.key
                              ? 'violet'
                              : 'grey'
                          }
                        ></Icon>
                        <Icon
                          style={{
                            position: 'absolute',
                          }}
                          name="sort down"
                          color={
                            sortingDirection === 'descending' &&
                            sortedColumn === field.key
                              ? 'violet'
                              : 'grey'
                          }
                        ></Icon>
                      </Table.HeaderCell>
                    ))}
                  <Table.HeaderCell />
                  <Table.HeaderCell>
                    {(canEdit || canRemove) && (
                      <Checkbox
                        checked={
                          toArray(records).length === selectedRecords.length
                        }
                        onClick={this.toggleAllRecordSelection}
                      />
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {records && map(records, record => this.renderRecord(record))}
              </Table.Body>
            </Table>
          ) : (
            <div style={styles.emptyState}>
              <Image style={{ width: 204, height: 134 }} src={emptyArray} />
              <span style={styles.emptyStateText}>
                {t('Aucun {{resource}} trouvé(e)', {
                  resource: t(resourceLabel),
                })}
              </span>
            </div>
          )}
        </div>
        {count && (
          <div style={styles.paginationContainer}>
            <div style={styles.loaderContainer}>
              <Loader active={loading} inline></Loader>
            </div>
            <Pagination
              className="menu-pagination"
              firstItem={null}
              lastItem={null}
              borderless
              totalPages={Math.ceil(count / limit)}
              onPageChange={this.handlePageChange}
              activePage={activePage}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span style={{ paddingRight: 10 }}>
                {t('Éléments par page')}:
              </span>
              <Dropdown
                compact
                selection
                options={limitOptions}
                value={limit}
                onChange={(e, { value }) => {
                  this.setState({ limit: value, skip: 0 }, this.refreshFetch);
                }}
              />
            </div>
          </div>
        )}
        {selectedRecords.length > 0 && (
          <div style={styles.contextualAlert}>
            <Card raised fluid>
              <Card.Content textAlign="center">
                {selectedRecords.length > 0 && (
                  <div style={styles.bulkActionsContainer}>
                    <div style={styles.bulkActionsText}>
                      {t('{{count}} élément(s) sélectionné(s)', {
                        count: selectedRecords.length,
                      })}
                    </div>
                    <div style={styles.bulkActionsButtons}>
                      {canEdit && (
                        <Button
                          style={styles.bulkActionsButton}
                          onClick={this.editSelectedRecords}
                        >
                          {t('Modifier')}
                        </Button>
                      )}
                      {canRemove && (
                        <Button
                          style={styles.bulkActionsButton}
                          color="red"
                          onClick={this.deleteSelectedRecords}
                        >
                          {t('Supprimer')}
                        </Button>
                      )}
                    </div>
                    <a
                      href="#/"
                      style={styles.bulkActionsCloseIcon}
                      onClick={this.clearSelectedRecords}
                    >
                      <Image src={closeIcon} />
                    </a>
                  </div>
                )}
              </Card.Content>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  lineAdd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
  },
  actionButtons: { display: 'flex', justifyContent: 'flex-end' },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    height: '100%',
    backgroundColor: colors.BACKGROUND_GREY,
    padding: 20,
  },
  resourceLabel: { fontWeight: 'bold', fontSize: 18 },
  button: {},
  actionButton: {
    width: 225,
    height: 40,
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  fieldContainer: {
    display: 'inline-block',
    width: '50%',
    paddingLeft: 12.5,
    paddingRight: 12.5,
  },
  rightButtons: {
    backgroundColor: colors.BACKGROUND_GREY,
    padding: 9,
    borderRadius: 2,
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
  tableContainer: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white',
    height: 'calc(100% - 130px)',
    overflow: 'auto',
    position: 'relative',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  suffixLabel: {
    paddingLeft: 7,
    fontSize: 14,
  },
  booleanContainer: { display: 'flex', justifyContent: 'space-between' },
  suffixLabelForm: { color: '#8798ad', fontSize: 15 },
  mainActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  mainActionStyles: { paddingLeft: 10 },
  importIcon: {
    height: 18,
  },
  veil: {
    backgroundColor: 'white',
    zIndex: 10,
    width: '100%',
    height: 10,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
  },
  emptyState: {
    display: 'flex',
    height: '98%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  emptyStateText: {
    fontSize: 24,
    color: '#e0e0e0',
    paddingTop: 19,
    width: 399,
    textAlign: 'center',
  },
  contextualAlert: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingBottom: 50,
  },
  bulkActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bulkActionsText: {
    paddingRight: 50,
  },
  bulkActionsButton: {
    marginRight: 10,
  },
  bulkActionsCloseIcon: {
    cursor: 'pointer',
  },
  excelImporterModal: {
    width: 'auto',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 5,
  },
  loaderContainer: {
    display: 'flex',
    flex: 0.3,
    alignItems: 'center',
  },
  sectionInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e1e1e1',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  groupBySectionLabelContainer: {
    paddingLeft: 7,
    paddingRight: 7,
  },
  separator: {
    width: 1,
    backgroundColor: '#e1e1e1',
    alignSelf: 'stretch',
  },
  filtersDropdownLabel: {
    display: 'inline-block',
  },
  headerInput: {
    marginLeft: 15,
  },
  filterOptionsContainer: {
    borderRadius: 20,
    border: '1px solid black',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    marginRight: 5,
  },
  filtersOptions: {
    display: 'flex',
  },
};

const recordActionPropType = PropTypes.shape({
  render: PropTypes.func.isRequired,
});

CrudManager.propTypes = {
  records: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.object.isRequired).isRequired,
    PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  ]),
  recordActions: PropTypes.arrayOf(recordActionPropType),
  fields: PropTypes.arrayOf(fieldPropType),
  resourceLabel: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string,
  modifyButtonLabel: PropTypes.string,
  recordKey: PropTypes.string,
  canImport: PropTypes.bool,
  canExport: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  creating: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  error: PropTypes.object,
  fetchError: PropTypes.object,
  createError: PropTypes.object,
  updateError: PropTypes.object,
  deleteError: PropTypes.object,
  fetchStart: PropTypes.func.isRequired,
  createStart: PropTypes.func.isRequired,
  updateStart: PropTypes.func.isRequired,
  deleteStart: PropTypes.func.isRequired,
  // A direct fetch method through the api client that returns a response from the API
  // is expected here, not an action creator
  fetch: PropTypes.func,
  renderAfterForm: PropTypes.func,
  renderBeforeGlobalActions: PropTypes.func,
  renderAfterGlobalActions: PropTypes.func,
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
};

CrudManager.defaultProps = {
  recordKey: '_id',
  canImport: false,
  canExport: false,
  canEdit: true,
  canRemove: true,
};

export default withi18n('crud')(CrudManager);

const getFieldsByKey = memoizeOne(fields => keyBy(fields, 'key'));

validate.validators.presence.options = {
  message: (value, attribute, validatorOptions, attributes, globalOptions) => {
    const fieldsByKey = getFieldsByKey(globalOptions.fields);
    const { t } = globalOptions;
    const label = fieldsByKey[attribute]?.label;
    return (
      '^' +
      t(`{{label}} est obligatoire`, {
        label: label ? t(`fields~${label}`) : '',
      })
    );
  },
};

validate.validators.different = function (
  value,
  options,
  key,
  attributes,
  globalOptions,
) {
  const otherFieldKey = validate.isString(options) ? options : options?.from;
  const fieldsByKey = getFieldsByKey(globalOptions.fields);
  const { t } = globalOptions;
  const field = fieldsByKey[key];
  const otherField = fieldsByKey[otherFieldKey];
  const label = field?.label || validate.prettify(key);
  const otherLabel = otherField?.label || validate.prettify(otherFieldKey);
  if (!otherFieldKey) {
    return;
  }
  const otherFieldValue = validate.getDeepObjectValue(
    attributes,
    otherFieldKey,
  );
  // Only validate difference if one is set
  if (!value && !otherFieldValue) {
    return;
  }
  if (!isEqual(value, otherFieldValue)) {
    return;
  }

  return (
    '^' +
    t(`{{label}} doit être différent de {{otherLabel}}`, {
      label: label ? t(`fields~${label}`) : '',
      otherLabel: otherLabel ? t(`fields~${otherLabel}`) : '',
    })
  );
};

export { renderFieldContent };

/* eslint-disable global-require */

export default {
  common: require('./common'),
  home: require('./home'),
  profile: require('./profile'),
  'line-selection': require('./line-selection'),
  sharing: require('./sharing'),
  'live-bus-infos': require('./live-bus-infos'),
  'live-bus-map': require('./live-bus-map'),
  'sharing-bus-infos': require('./sharing-bus-infos'),
  routes: require('./routes'),
  onboarding: require('./onboarding'),
  auth: require('./auth'),
  'global-dialog': require('./global-dialog'),
  shop: require('./shop'),
  drawer: require('./drawer'),
  'line-details': require('./line-details'),
  'bug-report': require('./bug-report'),
  connectivity: require('./connectivity'),
  zones: require('./zones'),
  'ride-booking': require('./ride-booking'),
  traffic: require('./traffic'),
  notification: require('./notification'),
  trips: require('./trips'),
  navigation: require('./navigation'),
  places: require('./places'),
  payment: require('./payment'),
  settings: require('./settings'),
  referral: require('./referral'),
  transactions: require('./transactions'),
  rating: require('./rating'),
  booking: require('./booking'),
  promos: require('./promos'),
};

import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import { Search } from 'semantic-ui-react';
import { Marker } from 'react-google-maps';

import Map from '../Map';

export default class PlacesAutoCompleteInput extends Component {
  constructor(props) {
    super(props);
    const { value, place } = this.props;
    this.state = {
      address: value || (place && place.name),
      reverseGeocode: true,
      resultsOpen: false,
    };
  }

  componentDidMount() {
    const { place } = this.props;
    if (!place) {
      return;
    }
    if (!place.name) {
      this.updateAddressFromPlace();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.place !== this.props.place &&
      prevProps.place &&
      this.props.place &&
      !isEqual(prevProps.place.coords, this.props.place.coords)
    ) {
      if (this.state.reverseGeocode) {
        this.updateAddressFromPlace();
      }
      if (prevProps.place.name !== this.props.place.name) {
        this.setState({
          address: this.props.place.name,
        });
      }
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        address: this.props.value,
      });
    }
  }

  updateAddressFromPlace = async () => {
    if (!this.props.reverseGeocode) {
      return;
    }
    const { place } = this.props;
    if (!place) {
      return;
    }
    if (place.coords) {
      const results = await reverseGeocode(place.coords);
      const mostSpecificResult = results[0];
      if (mostSpecificResult) {
        this.updatePlace({ name: mostSpecificResult.formatted_address });
      }
    }
  };

  updatePlace = updates => {
    const { place, onChange } = this.props;
    const updatedPlace = {
      ...place,
      ...updates,
      type: updates.type || null,
    };
    this.setState({
      address: updatedPlace.name,
      searching: false,
      reverseGeocode: false,
    });
    onChange(updatedPlace);
    // In order to prevent the place.name from
    // getting reverse geocoded after a place
    // has been selected from autocomplete results
    // we switch reverseGeocode off and switch it back
    // on after the next render cycle
    setTimeout(() => {
      this.setState({ reverseGeocode: true });
    });
  };

  searchCustomPlaces = debounce(search => {
    const { searchCustomPlaces, disableCustomPlaces } = this.props;
    if (!disableCustomPlaces && searchCustomPlaces) {
      searchCustomPlaces(search);
    }
  }, 100);

  handleChange = address => {
    this.setState({ address });
    const { place, onChange } = this.props;
    // If the place already has coords, update
    // name directly
    if (place && place.coords) {
      onChange({
        ...place,
        name: address,
      });
    }
    this.searchCustomPlaces(address);
  };

  handleSelect = address => {
    this.setState({ address, resultsOpen: false });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.updatePlace({ coords: latLng, name: address });
      });
  };

  render() {
    const {
      place,
      value,
      showMap,
      hasDefaultAddress,
      style,
      onChange,
      customPlaceResults,
      disableCustomPlaces,
      reverseGeocode,
      searchCustomPlaces,
      action,
      ...otherProps
    } = this.props;
    const { address, resultsOpen } = this.state;
    const searchOptions = {
      componentRestrictions: { country: ['sn', 'ma', 'ng'] },
    };
    // The 'ui form' class is to make the search square instead of rounded
    return (
      <div className="ui form" style={{ ...styles.container, ...style }}>
        <PlacesAutocomplete
          value={address || ''}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            const inputProps = getInputProps({
              placeholder: 'Rechercher',
              className: 'location-search-input',
              ...otherProps,
            });
            const defaultAddress = {
              type: 'DEFAULT_ADDRESS',
              name: 'Adresse par défaut',
            };
            const defaultAddressSuggestion = {
              title: 'Adresse par défaut',
              onClick: () => {
                this.updatePlace(defaultAddress);
                this.setState({ resultsOpen: false });
              },
            };
            const results = (
              hasDefaultAddress ? [defaultAddressSuggestion] : []
            )
              .concat(
                !disableCustomPlaces && customPlaceResults
                  ? customPlaceResults.map(place => ({
                      id: place.id,
                      title: place.name,
                      coords: place.coords,
                      onClick: () => {
                        this.updatePlace(place);
                        this.setState({ resultsOpen: false });
                      },
                    }))
                  : [],
              )
              .concat(
                suggestions.map(suggestion => ({
                  id: suggestion.id,
                  title: suggestion.description,
                  ...getSuggestionItemProps(suggestion),
                })),
              );
            return (
              <div style={styles.inputContainer}>
                <Search
                  {...inputProps}
                  input={{ fluid: true }}
                  open={resultsOpen}
                  onSearchChange={event => {
                    inputProps.onChange(event);
                    this.setState({
                      resultsOpen: hasDefaultAddress || !!event.target.value,
                    });
                  }}
                  onFocus={() =>
                    this.setState({
                      resultsOpen: hasDefaultAddress,
                    })
                  }
                  onBlur={() => this.setState({ resultsOpen: false })}
                  loading={loading}
                  results={results}
                  style={styles.search}
                />
                <div style={styles.actionContainer}>
                  {action ? (isFunction(action) ? action() : action) : null}
                </div>
              </div>
            );
          }}
        </PlacesAutocomplete>
        {showMap && (
          <div style={styles.mapContainer}>
            <Map
              center={
                place ? place.coords : { lat: 33.5724866, lng: -7.6152132 }
              }
              defaultZoom={14}
            >
              <Marker
                draggable
                position={
                  place ? place.coords : { lat: 33.5724866, lng: -7.6152132 }
                }
                onDragEnd={event => {
                  this.updatePlace({ coords: event.latLng.toJSON() });
                  this.updateAddressFromPlace();
                }}
              />
            </Map>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  container: {
    width: '100%',
  },
  mapContainer: {
    height: 200,
  },
  inputContainer: {
    position: 'relative',
  },
  actionContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  search: {
    top: 0,
    minWidth: 200,
  },
};

PlacesAutoCompleteInput.defaultProps = {
  value: '',
  place: null,
  showMap: false,
  hasDefaultAddress: false,
  reverseGeocode: true,
  disableCustomPlaces: false,
  onChange: () => {},
};

const latLngPropType = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
});

PlacesAutoCompleteInput.propTypes = {
  value: PropTypes.string.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    coords: latLngPropType,
  }),
  style: PropTypes.object,
  onChange: PropTypes.func,
  showMap: PropTypes.bool,
  hasDefaultAddress: PropTypes.bool,
  reverseGeocode: PropTypes.bool,
  disableCustomPlaces: PropTypes.bool,
  searchCustomPlaces: PropTypes.func,
  customPlaceResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      coords: latLngPropType.isRequired,
    }),
  ),
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

const reverseGeocode = latLng => {
  var geocoder = new window.google.maps.Geocoder();
  var OK = window.google.maps.GeocoderStatus.OK;

  return new Promise(function (resolve, reject) {
    geocoder.geocode({ location: latLng }, function (results, status) {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

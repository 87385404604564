import { takeLatest, all, put, spawn } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';

import sunubusClient from '../../providers/weego-client';
import authActions from '../../actions/auth';
import {clearFilters} from "../../utils/persistFilters"

function* login({ payload }) {
  try {
    yield sunubusClient.auth.sendOtp(payload.phoneNumber);
    yield put(authActions.loginSuccess());
  } catch (e) {
    console.error(e);
    yield put(authActions.loginError(e));
  }
}

function* logout() {
  try {
    yield sunubusClient.auth.disconnect();
    yield put(authActions.logoutSuccess());
    clearFilters();
  } catch (e) {
    console.error(e);
    yield put(authActions.logoutError(e));
  }
}

function* updateAccount() {
  if (!sunubusClient.auth.isLoggedIn()) {
    return;
  }
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
  if (accountVerificationToken && accountVerificationToken.token) {
    const decodedToken = jwt.decode(accountVerificationToken.token);
    yield put(authActions.updateCompany(decodedToken?.company));
  } else {
    console.error(
      'User is logged in but could not find token in local storage',
    );
  }
  const account = yield sunubusClient.auth.getMyAccount();
  yield put(authActions.updateAccount(account));

  window.fcWidget.init({
    token: 'faf9aa1d-63d9-46b6-a7a3-b8312c26db72',
    host: 'https://wchat.freshchat.com',
    externalId: account._id,
    firstName: account.metadata?.profile?.firstName,
    lastName: account.metadata?.profile?.lastName,
    phone: account.phoneNumber,
  });
}

function* authRootSaga() {
  yield all([
    takeLatest(authActions.login.toString(), login),
    takeLatest(authActions.logout.toString(), logout),
    takeLatest(authActions.loginSuccess.toString(), updateAccount),
    spawn(updateAccount),
  ]);
}

export default authRootSaga;

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import AutomaticPlannerScreen from './AutomaticPlannerScreen';
import dashbordActions from '../../../actions/dashboard';
import withFileHandlers from '../../../hoc/withFileHandlers';

const mapStateToProps = state => ({
  focusedTrips: state.dashboard.focusedTrips,
});

const mapDispatchToProps = dispatch => ({
  focusTrips: compose(dispatch, dashbordActions.focusTrips),
});

export default withFileHandlers()(
  connect(mapStateToProps, mapDispatchToProps)(AutomaticPlannerScreen),
);

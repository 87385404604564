import React, { PureComponent } from 'react';
import {
  Table,
  Dropdown,
  Checkbox,
  Loader,
  Popup,
  Header,
  Image,
  Input,
  Modal,
  Button,
  Grid,
} from 'semantic-ui-react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import moment from 'moment';
import memoize from 'memoize-one';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import ObjectID from 'bson-objectid';
import Fuse from 'fuse.js';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import _ from 'lodash';
import Promise from 'bluebird';

import withi18n from 'weego-common/src/hoc/i18n';

import tripPropType from '../tripPropType';
import driverPropType from '../../drivers/driverPropType';
import vehiclePropType from '../../vehicles/vehiclePropType';
import colors from '../../../theme/colors';

import carDriverIcon from '../../../assets/images/car-driver.svg';
import busIcon from '../../../assets/images/bus.svg';
import greenCheckboxIcon from '../../../assets/images/green-checkbox.svg';
import greyCheckboxIcon from '../../../assets/images/grey-checkbox.svg';
import redWarningIcon from '../../../assets/images/red-warning.svg';
import yellowWarningIcon from '../../../assets/images/yellow-warning.svg';
import pathIcon from '../../../assets/images/path.svg';
import callbackIcon from '../../../assets/images/call-back.svg';
import replaceDriverIcon from '../../../assets/images/replace-driver.svg';
import replaceDriverIconBlue from '../../../assets/images/replace-driver-blue.svg';
import replaceDriverIconGreen from '../../../assets/images/replace-driver-green.svg';
import replaceDriverIconRed from '../../../assets/images/replace-driver-red.svg';
import emptyArray from '../../../assets/images/empty-array.png';
import replaceVehicleIcon from '../../../assets/images/replace-vehicle.svg';
import replaceVehicleIconBlue from '../../../assets/images/replace-vehicle-blue.svg';
import replaceVehicleIconGreen from '../../../assets/images/replace-vehicle-green.svg';
import replaceVehicleIconRed from '../../../assets/images/replace-vehicle-red.svg';
import replaceVehicleIconYellow from '../../../assets/images/replace-vehicle-yellow.svg';

import 'moment/locale/fr';

import './TripsRegistry.css';

import TripCountdown from '../TripCountdown';
import DriverReplacementList from '../../drivers/DriverReplacementList';
import VehicleReplacementList from '../../vehicles/VehicleReplacementList';
import dateToString from '../../../utils/date/dateToString';
import stringToDate from '../../../utils/date/stringToDate';
import driverLabel from '../../../utils/driverLabel';
import TripIncidentReportForm from '../TripIncidentReportForm';
import TripIncidentsList from '../TripIncidentsList';
import { fetchSavedFilters, saveFilters } from '../../../utils/persistFilters';

const FILTER_SCOPE = '_registry';

moment.locale('fr');

const TEN_MINUTES_IN_MS = 10 * 60 * 1000;
const ON_DAY_IN_MS = 24 * 60 * 60 * 1000;
const FUSE_OPTIONS = {
  isCaseSensitive: false,
  findAllMatches: false,
  includeMatches: false,
  includeScore: false,
  useExtendedSearch: false,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 1000,
  keys: [
    'from.name',
    'to.name',
    'driver.firstname',
    'driver.lastname',
    'driver.phone',
    'driver.phoneNumber',
    'driver.registrationNumber',
    'vehicle.carNumber',
    'vehicle.carLabel',
    'stops.name',
  ],
};

class TripsRegistry extends PureComponent {
  constructor(props) {
    super(props);
    const filters = fetchSavedFilters();
    const { departureMin, departureMax, groupFilterTerm, groupFilterLabel } =
      filters?.[FILTER_SCOPE] || {};
    this.state = {
      trips: [],
      selectedTrip: null,
      selectedDriver: null,
      selectedVehicle: null,
      driverReplacementModalOpen: false,
      vehicleReplacementModalOpen: false,
      tripRows: [],
      tripsUpdates: {},
      searchTerm: '',
      date: moment().startOf('day').toDate(),
      departureMin: departureMin || '00:00',
      departureMax: departureMax || '23:59',
      groupFilterTerm,
      groupFilterLabel,
      incidentReportModalOpened: false,
      incidentTrip: null,
    };
  }

  componentDidMount() {
    this.props.clearTrips();
    this.refreshTrips();
    this.tripsRefreshIntervalId = setInterval(() => {
      this.refreshTrips();
    }, 60000);
    this.props.requestDrivers();
    this.props.requestVehicles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trips !== prevProps.trips) {
      this.fuse = new Fuse(
        this.tripsWithCustomProperties(this.props.trips),
        FUSE_OPTIONS,
      );
      this.handleGlobalFilter();
    }
  }

  componentWillUnmount() {
    clearInterval(this.tripsRefreshIntervalId);
  }

  refreshTrips() {
    const { date } = this.state;
    const start = moment(date).startOf('day').toDate();
    const end = moment(date).endOf('day').toDate();
    this.props.requestTripsInPeriod({ start, end });
  }

  driverOptions = memoize(drivers =>
    (drivers || []).map(driver => ({
      key: driver.id,
      value: driver.id,
      text: this.driverLabel(driver),
    })),
  );

  vehicleOptions = memoize(vehicles =>
    (vehicles || []).map(vehicle => ({
      key: vehicle.id,
      value: vehicle.id,
      text: this.vehicleLabel(vehicle),
    })),
  );

  tripsWithCustomProperties = memoize(trips =>
    map(
      sortBy(trips, [
        t => !!t.vehicle?.hideInDashboard,
        'departureTime',
        'to.name',
      ]),
      trip => {
        const driverConfirmedEvent =
          trip.events && trip.events.find(e => e.type === 'DRIVER_CONFIRMED');
        const driverCancelledEvent =
          trip.events && trip.events.find(e => e.type === 'DRIVER_CANCELLED');
        const vehicleStartedEvent =
          trip.events && trip.events.find(e => e.type === 'VEHICLE_STARTED');
        const tripStartedEvent =
          trip.events && trip.events.find(e => e.type === 'STOP_VISITED');
        const driverAcknowledgedEvent =
          trip.events &&
          trip.events.find(e => e.type === 'DRIVER_ACKNOWLEDGED');
        const missingGpsEvent =
          trip.events && trip.events.find(e => e.type === 'MISSING_GPS');
        const driverReplacementUpdate =
          trip.updates && trip.updates.find(e => e.field === 'driverId');
        const vehicleReplacementUpdate =
          trip.updates && trip.updates.find(e => e.field === 'vehicleId');
        const { driversMap, vehiclesMap } = this.props;
        const driver = driverReplacementUpdate
          ? driversMap[driverReplacementUpdate.value]
          : trip.driver;
        const vehicle = vehicleReplacementUpdate
          ? vehiclesMap[vehicleReplacementUpdate.value]
          : trip.vehicle;
        return {
          ...trip,
          driverConfirmedEvent,
          driverCancelledEvent,
          vehicleStartedEvent,
          tripStartedEvent,
          missingGpsEvent,
          driverAcknowledgedEvent,
          startsIn: moment(trip.departureTime).diff(moment()),
          tripReport: trip.tripReports && trip.tripReports[0],
          driverReplacementUpdate,
          driver,
          driverId: driver && driver.id,
          vehicleReplacementUpdate,
          vehicle,
          vehicleId: vehicle && vehicle.id,
        };
      },
    ),
  );

  driversById = memoize(drivers => keyBy(drivers, 'id'));
  vehiclesById = memoize(vehicles => keyBy(vehicles, 'id'));

  openDriverReplacementModal = trip => {
    this.setState({
      selectedTrip: trip,
      driverReplacementModalOpen: true,
    });
  };

  closeDriverReplacementModal = () => {
    this.setState({
      selectedTrip: null,
      driverReplacementModalOpen: false,
    });
  };

  openVehicleReplacementModal = trip => {
    this.setState({
      selectedTrip: trip,
      vehicleReplacementModalOpen: true,
    });
  };

  closeVehicleReplacementModal = () => {
    this.setState({
      selectedTrip: null,
      vehicleReplacementModalOpen: false,
    });
  };

  selectDriver = driver => {
    this.setState({
      selectedDriver: driver,
    });
  };

  selectVehicle = vehicle => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  replaceDriver = async () => {
    const { selectedTrip, selectedDriver } = this.state;
    if (!selectedTrip || !selectedDriver) {
      return;
    }
    const targetTrips = selectedTrip.groupedTrips
      ? selectedTrip.groupedTrips
      : [selectedTrip];
    await Promise.map(targetTrips, async targetTrip => {
      this.props.createTripUpdate({
        id: ObjectID().toHexString(),
        field: 'driverId',
        value: selectedDriver.id,
        start: moment().toDate(),
        end: moment().endOf('day').toDate(),
        tripId: targetTrip.id,
      });
      this.closeDriverReplacementModal();
      await Promise.delay(1000);
      this.refreshTrips();
    });
  };

  cancelDriverReplacement = async trip => {
    const { driverReplacementUpdate } = trip;
    if (!driverReplacementUpdate) {
      return;
    }
    this.props.removeTripUpdate(trip.driverReplacementUpdate);
    await Promise.delay(2000);
    this.refreshTrips();
  };

  replaceVehicle = async () => {
    const { selectedTrip, selectedVehicle } = this.state;
    if (!selectedTrip || !selectedVehicle) {
      return;
    }

    const targetTrips = selectedTrip.groupedTrips
      ? selectedTrip.groupedTrips
      : [selectedTrip];
    await Promise.map(targetTrips, async targetTrip => {
      this.props.createTripUpdate({
        id: ObjectID().toHexString(),
        field: 'vehicleId',
        value: selectedVehicle.id,
        start: moment().toDate(),
        end: moment().endOf('day').toDate(),
        tripId: targetTrip.id,
      });
      this.closeVehicleReplacementModal();
      await Promise.delay(1000);
      this.refreshTrips();
    });
  };

  cancelVehicleReplacement = async trip => {
    const { vehicleReplacementUpdate } = trip;
    if (!vehicleReplacementUpdate) {
      return;
    }
    this.props.removeTripUpdate(trip.vehicleReplacementUpdate);
    await Promise.delay(1000);
    this.refreshTrips();
  };

  toggleVehicleStarted = trip => {
    const vehicleStartedEvent = trip.events.find(
      e => e.type === 'VEHICLE_STARTED',
    );
    if (vehicleStartedEvent) {
      this.props.removeTripEvent(vehicleStartedEvent);
    } else {
      this.props.createTripEvent({
        id: ObjectID().toHexString(),
        type: 'VEHICLE_STARTED',
        date: new Date(),
        tripId: trip.id,
        metadata: {
          vehicleId: trip.vehicleId,
          driverId: trip.driverId,
        },
      });
    }
  };

  toggleDriverConfirmed = trip => {
    const vehicleStartedEvent = trip.events.find(
      e => e.type === 'DRIVER_CONFIRMED',
    );
    const targetTrips = trip.groupedTrips ? trip.groupedTrips : [trip];
    targetTrips.forEach(targetTrip => {
      if (vehicleStartedEvent) {
        this.props.removeTripEvent(vehicleStartedEvent);
      } else {
        this.props.createTripEvent({
          id: ObjectID().toHexString(),
          type: 'DRIVER_CONFIRMED',
          date: new Date(),
          tripId: targetTrip.id,
          metadata: {
            vehicleId: targetTrip.vehicleId,
            driverId: targetTrip.driverId,
          },
        });
      }
    });
  };

  toggleTripStarted = trip => {
    const vehicleStartedEvent = trip.events.find(
      e => e.type === 'STOP_VISITED' && e.metadata.stopIndex === 0,
    );
    if (vehicleStartedEvent) {
      this.props.removeTripEvent(vehicleStartedEvent);
    } else {
      this.props.createTripEvent({
        id: ObjectID().toHexString(),
        type: 'STOP_VISITED',
        date: new Date(),
        tripId: trip.id,
        metadata: {
          vehicleId: trip.vehicleId,
          driverId: trip.driverId,
          stopIndex: 0,
        },
      });
    }
  };

  driverLabel(driver) {
    if (!driver) {
      return '';
    }
    if (driver.registrationNumber) {
      return driver.registrationNumber;
    }
    return driver.firstname + ' ' + driver.lastname;
  }

  vehicleLabel(vehicle) {
    if (!vehicle) {
      return '';
    }
    return vehicle.carLabel || vehicle.carNumber || '';
  }

  fuzzySearch(searchTerm, trips) {
    this.fuse = this.fuse || new Fuse(trips, FUSE_OPTIONS);
    const results = this.fuse.search(searchTerm);
    const tripsFound = results.map(r => r.item);
    return tripsFound;
  }

  updateSearchTerm = (e, { value }) => {
    this.setState({ searchTerm: value });
    this.handleSearchChange();
  };

  handleSearchChange = debounce(() => {
    this.handleGlobalFilter();
  }, 300);

  openIncidentReportModal = trip => {
    this.setState({
      incidentReportModalOpened: true,
      incidentTrip: trip,
    });
  };

  closeIncidentReportModal = () => {
    this.setState({
      incidentReportModalOpened: false,
      incidentTrip: null,
    });
  };

  renderVehicleStatusIcon(trip) {
    let content;
    if (trip.vehicleStartedEvent) {
      if (trip.vehicleStartedEvent.pending) {
        content = <Loader active inline size="tiny" />;
      } else {
        content = (
          <img
            src={
              trip.vehicleReplacementUpdate
                ? replaceVehicleIconGreen
                : greenCheckboxIcon
            }
            alt="Case a cocher verte"
          />
        );
      }
    } else if (trip.missingGpsEvent) {
      content = (
        <img
          src={
            trip.vehicleReplacementUpdate
              ? replaceVehicleIconYellow
              : yellowWarningIcon
          }
          alt="Case a cocher jaune"
        />
      );
    } else {
      if (trip.startsIn < TEN_MINUTES_IN_MS) {
        content = (
          <img
            src={
              trip.vehicleReplacementUpdate
                ? replaceVehicleIconRed
                : redWarningIcon
            }
            alt="Case a cocher rouge"
          />
        );
      } else {
        content = (
          <img
            src={
              trip.vehicleReplacementUpdate
                ? replaceVehicleIconBlue
                : greyCheckboxIcon
            }
            alt="Case a cocher grise"
          />
        );
      }
    }
    return (
      <div
        className="status icon"
        style={styles.status}
        onClick={() => this.toggleVehicleStarted(trip)}
      >
        {content}
      </div>
    );
  }

  renderVehicleStatusHeader(trip) {
    const { t } = this.props;
    return (
      <Header
        as="h4"
        size="tiny"
        className="status"
        color={
          trip.vehicleStartedEvent
            ? 'green'
            : trip.missingGpsEvent
            ? 'yellow'
            : trip.startsIn < TEN_MINUTES_IN_MS
            ? 'red'
            : 'grey'
        }
      >
        <ReactSVG className="ui icon" src={busIcon} />
        {trip.vehicleStartedEvent
          ? t('Véhicule sorti')
          : trip.missingGpsEvent
          ? t('Pas de signal GPS')
          : t('Véhicule au garage')}
      </Header>
    );
  }

  renderVehicleStatusContent(trip) {
    const { t } = this.props;
    return (
      <p className="ui grey text" style={styles.statusContent}>
        {trip.vehicleStartedEvent
          ? t(`En route vers {{destination}}`, {
              destination: trip.to && trip.to.name,
            })
          : t('Cliquez sur le véhicule pour remplacer.')}
      </p>
    );
  }

  renderDriverStatusIcon(trip) {
    let content;
    if (trip.driverConfirmedEvent) {
      if (trip.driverConfirmedEvent.pending) {
        content = <Loader active inline size="tiny" />;
      } else {
        content = (
          <img
            src={
              trip.driverReplacementUpdate
                ? replaceDriverIconGreen
                : greenCheckboxIcon
            }
            alt="Case a cocher verte"
          />
        );
      }
    } else if (trip.driverCancelledEvent) {
      content = (
        <img
          src={
            trip.driverReplacementUpdate ? replaceDriverIconRed : redWarningIcon
          }
          alt="Case a cocher rouge"
        />
      );
    } else {
      if (trip.startsIn < TEN_MINUTES_IN_MS) {
        content = (
          <img
            src={
              trip.driverReplacementUpdate
                ? replaceDriverIconRed
                : redWarningIcon
            }
            alt="Case a cocher rouge"
          />
        );
      } else if (
        !trip.driverAcknowledgedEvent &&
        trip.startsIn < ON_DAY_IN_MS
      ) {
        content = (
          <div style={styles.outerCircleWarning}>
            <div style={styles.innerCircleWarning}>
              <span style={styles.questionMark}>?</span>
            </div>
          </div>
        );
      } else {
        content = (
          <img
            src={
              trip.driverReplacementUpdate
                ? replaceDriverIconBlue
                : greyCheckboxIcon
            }
            alt="Case a cocher grise"
          />
        );
      }
    }
    return (
      <div
        className="status icon"
        style={styles.status}
        onClick={() => this.toggleDriverConfirmed(trip)}
      >
        {content}
      </div>
    );
  }

  renderDriverStatusHeader(trip) {
    const { t } = this.props;
    return (
      <Header
        as="h4"
        size="tiny"
        className="status"
        color={
          trip.driverCancelledEvent
            ? 'red'
            : trip.driverConfirmedEvent
            ? 'green'
            : trip.startsIn < TEN_MINUTES_IN_MS
            ? 'red'
            : 'grey'
        }
      >
        <ReactSVG className="ui icon" src={carDriverIcon} />
        {trip.driverCancelledEvent
          ? t('Conducteur annulé')
          : trip.driverConfirmedEvent
          ? t('Conducteur confirmé')
          : trip.startsIn < TEN_MINUTES_IN_MS
          ? t('Conducteur en retard')
          : !trip.driverAcknowledgedEvent && trip.startsIn < ON_DAY_IN_MS
          ? t('Trajet non confirmé')
          : t('Conducteur non confirmé')}
      </Header>
    );
  }

  renderDriverStatusContent(trip) {
    const { t } = this.props;
    return (
      <p className="ui grey text" style={styles.statusContent}>
        {trip.driver && trip.driver.firstname}{' '}
        {trip.driver && trip.driver.lastname}
        <br />
        {trip.driverConfirmedEvent
          ? ''
          : t('Cliquez sur le conducteur pour remplacer ou relancer.')}
      </p>
    );
  }

  renderStartsIn(trip) {
    const color = trip.missingGpsEvent
      ? 'yellow'
      : trip.startsIn < 0 && !trip.tripStartedEvent
      ? 'red'
      : trip.startsIn < TEN_MINUTES_IN_MS &&
        !trip.tripStartedEvent &&
        trip.vehicleStartedEvent
      ? 'yellow'
      : trip.startsIn < TEN_MINUTES_IN_MS &&
        !trip.tripStartedEvent &&
        !trip.vehicleStartedEvent
      ? 'red'
      : trip.startsIn < TEN_MINUTES_IN_MS && trip.tripStartedEvent
      ? 'green'
      : '';
    return <TripCountdown departureTime={trip.departureTime} color={color} />;
  }

  filterByTimeRange = (departureMin, departureMax, trips) => {
    const { date } = this.state;
    const dateMoment = moment(date);
    const departureFormatted = moment(departureMin, 'HH:mm').set({
      date: dateMoment.date(),
      month: dateMoment.month(),
      year: dateMoment.year(),
    });
    const arrivalTimeFormatted = moment(departureMax, 'HH:mm').set({
      date: dateMoment.date(),
      month: dateMoment.month(),
      year: dateMoment.year(),
    });
    const result = trips.filter(
      t =>
        moment(t.departureTime).isSameOrAfter(departureFormatted) &&
        moment(t.departureTime).isSameOrBefore(arrivalTimeFormatted),
    );
    return result;
  };

  handleTimeFilterChange = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
      this.handleGlobalFilter();
    }
  };

  handleGroupFilterChange = (e, { text, value }) => {
    this.setState({ groupFilterTerm: value, groupFilterLabel: text }, () =>
      this.handleGlobalFilter(),
    );
  };

  groupFilter(filter, trips) {
    const groupedValues = _(trips)
      .sortBy([t => !!t.vehicle?.hideInDashboard, 'departureTime', 'to.name'])
      .groupBy(t => t[filter])
      .value();

    const result = map(groupedValues, group => ({
      ...group[0],
      groupedTrips: group.map(trip => ({
        ...trip,
        groupedTrips: group,
      })),
    }));
    return result;
  }

  handleGlobalFilter = () => {
    const {
      searchTerm,
      departureMin,
      departureMax,
      groupFilterTerm,
      groupFilterLabel,
    } = this.state;
    saveFilters(FILTER_SCOPE, {
      departureMin,
      departureMax,
      groupFilterTerm,
      groupFilterLabel,
    });
    const { trips } = this.props;
    const tripsToDisplay = trips.filter(
      t => !t.vehicle?.hideInRegistry && !t.driver?.hideInRegistry,
    );
    const tripsWithCustomProperties =
      this.tripsWithCustomProperties(tripsToDisplay);
    const searchResult = searchTerm
      ? this.fuzzySearch(searchTerm, tripsWithCustomProperties)
      : tripsWithCustomProperties;
    const timeRangeResult = this.filterByTimeRange(
      departureMin,
      departureMax,
      searchResult,
    );
    const finalResult = groupFilterTerm
      ? this.groupFilter(groupFilterTerm, timeRangeResult)
      : timeRangeResult;
    this.setState({ trips: finalResult });
  };

  renderTripSeparator(separator, separatorType) {
    const { driversMap, vehiclesMap, t } = this.props;
    switch (separatorType) {
      case 'driverId':
        return t(`Conducteur: {{driver}}`, {
          driver: this.driverLabel(driversMap[separator]),
        });
      case 'vehicleId':
        return t(`Véhicule: {{vehicle}}`, {
          vehicle: this.vehicleLabel(vehiclesMap[separator]),
        });
      case 'departureTime':
        return t(`Heure de départ: {{time}}`, {
          time: moment(separator).format('H[h]mm'),
        });
      case 'endDate':
        return t(`Heure d'arrivée: {{time}}`, {
          time: moment(separator).format('H[h]mm'),
        });
      default:
        return '-';
    }
  }

  clearAll = () => {
    this.setState(
      {
        searchTerm: '',
        departureMin: '00:00',
        departureMax: '23:59',
        groupFilterTerm: null,
        groupFilterLabel: null,
      },
      () => this.handleGlobalFilter(),
    );
  };

  render() {
    const {
      date,
      trips,
      departureMin,
      departureMax,
      groupFilterTerm,
      searchTerm,
      driverReplacementModalOpen,
      vehicleReplacementModalOpen,
      selectedTrip,
      incidentReportModalOpened,
      incidentTrip,
    } = this.state;
    const { t } = this.props;
    const tripsWithCustomProperties = trips;

    return (
      <div className="trips-registry" style={styles.container}>
        <div style={styles.lineAdd}>
          <Grid celled style={styles.filtersContainer}>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={3}>
                <DateInput
                  value={dateToString(date)}
                  onChange={(event, { value: dateString }) => {
                    this.setState(
                      {
                        date: stringToDate(dateString),
                      },
                      () => {
                        this.refreshTrips();
                      },
                    );
                  }}
                  popupPosition={'bottom center'}
                  preserveViewMode={false}
                  hideMobileKeyboard
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={3}>
                <Input
                  fluid
                  iconPosition="left"
                  icon="search"
                  placeholder={t('Rechercher')}
                  onChange={this.updateSearchTerm}
                  value={searchTerm}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <div style={styles.sectionInputContainer}>
                  <span style={styles.timeRangeContainer}>{t('Période')}</span>
                  <div style={styles.separator}></div>
                  <TimeInput
                    name="departureMin"
                    placeholder={t('Départ')}
                    value={departureMin}
                    icon={false}
                    onChange={this.handleTimeFilterChange}
                    closable
                    className="no-border-input"
                    style={styles.timeInputWidth}
                    popupPosition="bottom center"
                    preserveViewMode={false}
                    hideMobileKeyboard
                  />
                  -
                  <TimeInput
                    name="departureMax"
                    placeholder={t('Arrivée')}
                    value={departureMax}
                    icon={false}
                    onChange={this.handleTimeFilterChange}
                    closable
                    className="no-border-input"
                    style={styles.timeInputWidth}
                    popupPosition="bottom center"
                    preserveViewMode={false}
                    hideMobileKeyboard
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4}>
                <div style={styles.sectionInputContainer}>
                  <span style={styles.groupBySectionLabelContainer}>
                    {t('Grouper par')}
                  </span>
                  <div style={styles.separator}></div>
                  <Dropdown
                    fluid
                    onChange={this.handleGroupFilterChange}
                    options={[
                      { key: 0, text: t('Aucun'), value: null },
                      {
                        key: 1,
                        text: t('Heure de départ'),
                        value: 'departureTime',
                      },
                      { key: 2, text: t("Heure d'arrivée"), value: 'endDate' },
                      { key: 3, text: t('Conducteur'), value: 'driverId' },
                      { key: 4, text: t('Véhicule'), value: 'vehicleId' },
                      { key: 5, text: t('Poste'), value: 'poste' },
                    ]}
                    placeholder={t('Aucun')}
                    selection
                    value={groupFilterTerm}
                    style={{ zIndex: 101 }}
                    className="no-border-dropdown"
                  />
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={2}>
                <div>
                  <Button onClick={this.clearAll} primary>
                    {t('Effacer tout')}
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className="table-container" style={styles.tableContainer}>
          <div style={styles.veil} />
          {tripsWithCustomProperties &&
          tripsWithCustomProperties.length !== 0 ? (
            <Table style={styles.table}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('Poste')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Heure départ')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Destination')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Véhicule')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Conducteur')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('Statut')}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>{t('Incidents')}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {tripsWithCustomProperties &&
                  tripsWithCustomProperties.map(trip => {
                    // const tripUpdates = tripsUpdates[trip.id] || {};
                    return trip.separator && trip.separatorType ? (
                      <Table.Row key={trip.separator}>
                        <Table.Cell style={{ border: 'none' }}>
                          {this.renderTripSeparator(
                            trip.separator,
                            trip.separatorType,
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      <Table.Row key={trip.id}>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(
                            (trip, groupIndex) => {
                              if (
                                groupFilterTerm === 'poste' &&
                                groupIndex > 0
                              ) {
                                return null;
                              }
                              return (
                                <div style={styles.groupedCell}>
                                  {trip['poste']}
                                </div>
                              );
                            },
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(trip => (
                            <div
                              style={{
                                ...styles.groupedCell,
                                ...styles.departureTime,
                              }}
                            >
                              {moment(trip.departureTime).format('H[h]mm')}
                            </div>
                          ))}
                        </Table.Cell>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(trip => (
                            <Dropdown
                              text={
                                trip.from && `${trip.from.name}-${trip.to.name}`
                              }
                              icon={
                                <img
                                  className="dropdown icon"
                                  src={pathIcon}
                                  alt="Icone chemin"
                                />
                              }
                              labeled
                              button
                              className="path-dropdown wrapping icon"
                              style={styles.dropdown}
                            >
                              <Dropdown.Menu>
                                {[
                                  trip.from,
                                  ...(trip.stops || []),
                                  trip.to,
                                ].map((stop, index) => (
                                  <Dropdown.Item
                                    icon={
                                      <img
                                        className="ui image"
                                        src={
                                          trip.events &&
                                          trip.events.find(
                                            e =>
                                              e.metadata?.stopIndex === index,
                                          )
                                            ? greenCheckboxIcon
                                            : greyCheckboxIcon
                                        }
                                        alt="Case a cocher verte"
                                      />
                                    }
                                    text={stop.name}
                                    style={styles.dropdownItem}
                                  />
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          ))}
                        </Table.Cell>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(trip => {
                            return (
                              <div style={styles.dropdownContainer}>
                                <Dropdown
                                  text={this.vehicleLabel(trip.vehicle)}
                                  icon={
                                    <Image
                                      className="dropdown icon"
                                      src={busIcon}
                                      style={styles.dropdownIcon}
                                      alt="Icone bus"
                                    />
                                  }
                                  labeled
                                  button
                                  className="driver-vehicle-dropdown wrapping icon"
                                  style={styles.dropdown}
                                >
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      className={
                                        trip.vehicleReplacementUpdate
                                          ? 'ui red text'
                                          : ''
                                      }
                                      icon={
                                        <ReactSVG
                                          className="ui image"
                                          src={replaceVehicleIcon}
                                        />
                                      }
                                      text={
                                        trip.vehicleReplacementUpdate
                                          ? t('Annuler')
                                          : t('Remplacer')
                                      }
                                      onClick={() =>
                                        trip.vehicleReplacementUpdate
                                          ? this.cancelVehicleReplacement(trip)
                                          : this.openVehicleReplacementModal(
                                              trip,
                                            )
                                      }
                                    />
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Popup
                                  on="hover"
                                  content={this.renderVehicleStatusContent(
                                    trip,
                                  )}
                                  header={this.renderVehicleStatusHeader(trip)}
                                  trigger={this.renderVehicleStatusIcon(trip)}
                                  position="right center"
                                />
                              </div>
                            );
                          })}
                        </Table.Cell>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(trip => {
                            return (
                              <div style={styles.dropdownContainer}>
                                <Dropdown
                                  text={this.driverLabel(trip.driver)}
                                  icon={
                                    <Image
                                      className="dropdown icon"
                                      src={carDriverIcon}
                                      style={styles.dropdownIcon}
                                      alt="Icone conducteur"
                                    />
                                  }
                                  labeled
                                  button
                                  className="driver-vehicle-dropdown wrapping icon"
                                  style={styles.dropdown}
                                >
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      text={driverLabel(trip.driver, {
                                        displayPhoneNumber: true,
                                      })}
                                    />
                                    <Dropdown.Item
                                      icon={
                                        <Image
                                          src={callbackIcon}
                                          alt="Icone relancer"
                                        />
                                      }
                                      text="Relancer"
                                    />
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                      className={
                                        trip.driverReplacementUpdate
                                          ? 'ui red text'
                                          : ''
                                      }
                                      icon={
                                        <ReactSVG
                                          className="ui image"
                                          src={replaceDriverIcon}
                                        />
                                      }
                                      text={
                                        trip.driverReplacementUpdate
                                          ? t('Annuler')
                                          : t('Remplacer')
                                      }
                                      onClick={() =>
                                        trip.driverReplacementUpdate
                                          ? this.cancelDriverReplacement(trip)
                                          : this.openDriverReplacementModal(
                                              trip,
                                            )
                                      }
                                    />
                                  </Dropdown.Menu>
                                </Dropdown>
                                <Popup
                                  on="hover"
                                  content={this.renderDriverStatusContent(trip)}
                                  header={this.renderDriverStatusHeader(trip)}
                                  trigger={this.renderDriverStatusIcon(trip)}
                                  position="right center"
                                />
                              </div>
                            );
                          })}
                        </Table.Cell>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(trip => (
                            <div style={styles.groupedCell}>
                              {this.renderStartsIn(trip)}
                            </div>
                          ))}
                        </Table.Cell>
                        <Table.Cell>
                          {(trip.groupedTrips || [trip]).map(trip => (
                            <div style={styles.groupedCell}>
                              {trip.tripStartedEvent ? (
                                <Checkbox
                                  indeterminate={trip.tripStartedEvent.pending}
                                  checked={true}
                                  onChange={() => this.toggleTripStarted(trip)}
                                />
                              ) : (
                                <Checkbox
                                  indeterminate={false}
                                  checked={false}
                                  onChange={() => this.toggleTripStarted(trip)}
                                />
                              )}
                            </div>
                          ))}
                        </Table.Cell>
                        <Table.Cell>
                          <TripIncidentsList trip={trip} />
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => {
                              this.openIncidentReportModal(trip);
                            }}
                          >
                            {t('Signaler')}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          ) : (
            <div style={styles.emptyState}>
              <Image style={{ width: 204, height: 134 }} src={emptyArray} />
              <span style={styles.emptyStateText}>
                {t('Vous n’avez pas encore ajouté de voyages.')}
              </span>
            </div>
          )}
        </div>
        <Modal
          size="fullscreen"
          className="bottom-sheet"
          open={driverReplacementModalOpen}
          onClose={this.closeDriverReplacementModal}
        >
          <Modal.Content>
            <DriverReplacementList
              onSelect={this.selectDriver}
              departureTime={selectedTrip && selectedTrip.departureTime}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              size="huge"
              basic
              onClick={this.closeDriverReplacementModal}
            >
              {t('Annuler')}
            </Button>
            <Button size="huge" primary onClick={this.replaceDriver}>
              <ReactSVG
                src={replaceDriverIcon}
                className="icon"
                wrapper="span"
              />
              {t('Remplacer')}
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          size="fullscreen"
          className="bottom-sheet"
          open={vehicleReplacementModalOpen}
          onClose={this.closeVehicleReplacementModal}
        >
          <Modal.Content>
            <VehicleReplacementList
              onSelect={this.selectVehicle}
              departureTime={selectedTrip && selectedTrip.departureTime}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              size="huge"
              basic
              onClick={this.closeVehicleReplacementModal}
            >
              {t('Annuler')}
            </Button>
            <Button size="huge" primary onClick={this.replaceVehicle}>
              <ReactSVG
                src={replaceVehicleIcon}
                className="icon"
                wrapper="span"
              />
              {t('Remplacer')}
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          size="small"
          open={incidentReportModalOpened}
          onClose={this.closeIncidentReportModal}
        >
          <Modal.Header>{t('Signaler un incident')}</Modal.Header>
          <Modal.Content>
            <TripIncidentReportForm
              trip={incidentTrip}
              onSubmitted={this.closeIncidentReportModal}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

TripsRegistry.propTypes = {
  trips: PropTypes.arrayOf(tripPropType),
  driversMap: PropTypes.objectOf(driverPropType).isRequired,
  vehiclesMap: PropTypes.objectOf(vehiclePropType).isRequired,
  requestTripsInPeriod: PropTypes.func.isRequired,
  clearTrips: PropTypes.func.isRequired,
  createTripUpdate: PropTypes.func.isRequired,
  removeTripUpdate: PropTypes.func.isRequired,
  createTripEvent: PropTypes.func.isRequired,
  removeTripEvent: PropTypes.func.isRequired,
};

const styles = {
  container: {
    height: '100%',
    backgroundColor: colors.BACKGROUND_GREY,
    padding: 20,
  },
  lineAdd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
  },
  vehiclesLabel: { fontWeight: 'bold', fontSize: 18 },
  tableContainer: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white',
    height: 'calc(100% - 100px)',
    overflow: 'auto',
    position: 'relative',
  },
  veil: {
    backgroundColor: 'white',
    zIndex: 10,
    width: '100%',
    height: 10,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
  },
  table: {
    padding: '0 10px',
  },
  reportForm: {
    display: 'flex',
    alignItems: 'center',
  },
  reportFormDropdown: {
    top: 0,
    marginRight: 5,
    width: 100,
    minWidth: 'auto',
  },
  dropdownContainer: {
    position: 'relative',
  },
  groupedCell: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginBottom: 10,
  },
  dropdown: {
    width: '100%',
    maxWidth: 400,
    paddingRight: '40px !important',
    display: 'block',
    marginBottom: 10,
  },
  departureTime: {},
  dropdownIcon: {
    opacity: '0.5',
  },
  dropdownItem: {
    overflow: 'hidden',
  },
  status: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 42,
    width: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  statusContent: {},

  emptyState: {
    display: 'flex',
    height: '98%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  emptyStateText: {
    fontSize: 24,
    color: '#e0e0e0',
    paddingTop: 19,
    width: 399,
    textAlign: 'center',
  },
  resourceLabelContainer: {
    display: 'flex',
    flex: 0.2,
  },
  filtersContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e1e1e1',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  timeRangeContainer: {
    paddingLeft: 7,
    paddingRight: 7,
    width: 80,
    textAlign: 'center',
  },
  separator: {
    width: 1,
    backgroundColor: '#e1e1e1',
    alignSelf: 'stretch',
  },
  timeInputWidth: { width: 70 },
  groupBySectionLabelContainer: {
    paddingLeft: 7,
    paddingRight: 7,
  },
  outerCircleWarning: {
    backgroundColor: colors.SQUASH_TRANSPARENT,
    width: 32,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCircleWarning: {
    backgroundColor: colors.SQUASH,
    width: 20,
    height: 20,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  questionMark: { color: 'white', fontSize: 16, fontWeight: 'bold' },
};

export default withi18n('trips')(TripsRegistry);

import { connect } from 'react-redux';
import toArray from 'lodash/toArray';
import memoize from 'memoize-one';
import { compose } from 'recompose';

import PlannerVehiclesManager from './PlannerVehiclesManager';
import vehiclesActions from '../../../actions/vehicles';

const vehiclesToArray = memoize(toArray);

const mapStateToProps = state => ({
  vehicles: vehiclesToArray(state.vehicles),
});

const mapDispatchToProps = dispatch => ({
  requestVehicles: compose(dispatch, vehiclesActions.fetchStart),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlannerVehiclesManager);

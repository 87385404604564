import { useRef, useState, useCallback, useEffect } from 'react';
import Fuse from 'fuse.js';

function useSearch(listArg, options, initialSearchValue) {
  const fuse = useRef(new Fuse(listArg, options));
  const [list, setList] = useState(listArg);
  const [results, setResults] = useState(listArg);
  const [term, setTerm] = useState(initialSearchValue || '');

  const updateList = useCallback(
    newList => {
      fuse.current = new Fuse(newList, options);
      setList(newList);
      if (term) {
        setResults(fuse.current.search(term).map(result => result.item));
      } else {
        setResults(newList);
      }
    },
    [options, term],
  );

  const search = useCallback(
    termArg => {
      setTerm(termArg);
      if (termArg) {
        setResults(fuse.current.search(termArg).map(result => result.item));
      } else {
        setResults(list);
      }
    },
    [fuse, list],
  );

  useEffect(() => {
    updateList(listArg);
  }, [updateList, listArg]);

  return [term, results, search];
}

export default useSearch;

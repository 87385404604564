import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Icon,
  Label,
  Modal,
  Header,
} from 'semantic-ui-react';

import Chance from 'chance';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import withi18n from 'weego-common/src/hoc/i18n';
import moment from 'weego-common/src/providers/moment';
import tryJsonParse from 'weego-common/src/utils/tryJsonParse';

import TimeRangeInput from '../../common/TimeRangeInput';
import PlacesAutoCompleteInput from '../../common/PlacesAutocompleteInput/PlacesAutocompleteInput';
import withFileHandlers from '../../../hoc/withFileHandlers';

const chance = new Chance();

const PlannerVehiclesManager = memo(
  ({
    vehicles,
    focusedVehicle,
    style = {},
    requestVehicles,
    saveLocalFile,
    openLocalFile,
    openedFile,
    onChange,
    t,
  }) => {
    const [vehiclesWithColors, setVehiclesWithColors] = useState([]);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [openVehicle, setOpenVehicle] = useState(null);

    useEffect(() => {
      requestVehicles({
        where: {
          available: true,
        },
      });
    }, [requestVehicles]);

    useEffect(() => {
      onChange(vehiclesWithColors);
    }, [vehiclesWithColors, onChange]);

    useEffect(() => {
      setVehiclesWithColors(
        vehicles.map(vehicle => ({
          ...vehicle,
          color: chance.color({ format: 'hex' }),
        })),
      );
    }, [vehicles]);

    useEffect(() => {
      if (!openedFile) {
        return;
      }
      const fileContent = openedFile.text;
      if (!fileContent) {
        alert(t('Aucun texte trouvé dans le fichier'));
        return;
      }
      const importedVehicles = tryJsonParse(fileContent);
      if (!importedVehicles || !isArray(importedVehicles)) {
        alert(t('Format de véhicules invalide'));
        return;
      }
      const missingVehicles = importedVehicles.filter(
        importedVehicle =>
          !vehiclesWithColors
            .filter(v => v.available)
            .find(v => v.id === importedVehicle.id),
      );
      if (missingVehicles.length) {
        alert(
          'Could not find vehicles: ' +
            missingVehicles.map(v => vehicleLabel(v)).join(','),
        );
      }
      setVehiclesWithColors(
        vehiclesWithColors.map(vehicle => {
          const importedVehicle = importedVehicles.find(
            v => v.id === vehicle.id,
          );
          if (!importedVehicle) {
            return {
              ...vehicle,
              disabled: true,
            };
          }
          return {
            ...vehicle,
            ...omit(importedVehicle, 'driverId', 'driver'),
          };
        }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedFile]);

    // If the list of vehicles changes while a vehicle is open
    // refresh it
    useEffect(() => {
      if (openVehicle) {
        setOpenVehicle(vehiclesWithColors.find(v => v.id === openVehicle.id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesWithColors]);

    const updateVehicleColor = (vehicle, color) => {
      const index = vehiclesWithColors.indexOf(vehicle);
      setVehiclesWithColors(
        vehiclesWithColors.map((v, i) => (i === index ? { ...v, color } : v)),
      );
    };

    const updateVehicleTimeWindow = (vehicle, timeWindow) => {
      const index = vehiclesWithColors.indexOf(vehicle);
      setVehiclesWithColors(
        vehiclesWithColors.map((v, i) =>
          i === index ? { ...v, timeWindow } : v,
        ),
      );
    };

    const updateVehicleDepot = (vehicle, depot) => {
      const index = vehiclesWithColors.indexOf(vehicle);
      setVehiclesWithColors(
        vehiclesWithColors.map((v, i) => (i === index ? { ...v, depot } : v)),
      );
    };

    const updateVehicleCapacity = (vehicle, capacity) => {
      const index = vehiclesWithColors.indexOf(vehicle);
      setVehiclesWithColors(
        vehiclesWithColors.map((v, i) =>
          i === index ? { ...v, capacity } : v,
        ),
      );
    };

    const toggleVehicle = vehicle => {
      const index = vehiclesWithColors.indexOf(vehicle);
      setVehiclesWithColors(
        vehiclesWithColors.map((v, i) =>
          i === index ? { ...v, disabled: !v.disabled } : v,
        ),
      );
    };

    const toggleAllVehicles = disabled => {
      setVehiclesWithColors(
        vehiclesWithColors.map(v => ({
          ...v,
          disabled,
        })),
      );
    };

    const vehicleLabel = vehicle => vehicle.carLabel || vehicle.carNumber || '';

    const allToggled = !vehiclesWithColors.some(v => v.disabled);

    return (
      <Card style={{ ...styles.container, ...style }}>
        <Card.Content style={styles.body}>
          <Card.Header style={styles.header}>
            <Checkbox
              checked={allToggled}
              onChange={() => toggleAllVehicles(allToggled)}
              style={styles.vehicleCheckbox}
            />
            {t('Véhicules')}
          </Card.Header>
          <Card.Description>
            {vehiclesWithColors
              .filter(vehicle => vehicle.available)
              .map((vehicle, index) => (
                <div key={vehicle.id} style={styles.vehicleContainer}>
                  <div style={styles.vehicleLabelContainer}>
                    <Checkbox
                      checked={!vehicle.disabled}
                      onChange={() => toggleVehicle(vehicle)}
                      style={styles.vehicleCheckbox}
                    />
                    <div
                      style={{
                        ...styles.vehicleColorIndicator,
                        backgroundColor: vehicle.color,
                      }}
                      onClick={() =>
                        updateVehicleColor(
                          vehicle,
                          chance.color({ format: 'hex' }),
                        )
                      }
                    ></div>
                    <Header
                      size="small"
                      style={{
                        ...styles.vehicleLabel,
                        ...(focusedVehicle && focusedVehicle.id === vehicle.id
                          ? styles.focusedVehicleLabel
                          : {}),
                      }}
                    >
                      {vehicleLabel(vehicle)}
                      <Header.Subheader>
                        {vehicle.timeWindow
                          ? vehicle.timeWindow
                              .map(seconds => {
                                // Relative seconds to date
                                return moment()
                                  .startOf('day')
                                  .add(seconds, 'seconds')
                                  .format('HH:mm');
                              })
                              .join(' - ')
                          : null}
                      </Header.Subheader>
                    </Header>
                  </div>
                  <div>
                    <Label circular style={{ width: 50 }}>
                      <Icon name="user" /> {vehicle.capacity}
                    </Label>
                    <Button
                      icon
                      size="tiny"
                      onClick={() => {
                        setDetailsModalOpen(true);
                        setOpenVehicle(vehicle);
                      }}
                    >
                      <Icon name="settings" />
                    </Button>
                  </div>
                </div>
              ))}
          </Card.Description>
        </Card.Content>
        <Card.Content extra textAlign="right">
          <Button
            icon
            onClick={() => {
              saveLocalFile(
                JSON.stringify(
                  vehiclesWithColors.filter(v => !v.disabled),
                  null,
                  2,
                ),
                {
                  name: 'Planner Vehicles.json',
                },
              );
            }}
          >
            <Icon name="download" />
          </Button>
          <Button
            icon
            onClick={() => {
              openLocalFile({
                accept: 'application/json',
                type: 'text',
              });
            }}
          >
            <Icon name="upload" />
          </Button>
        </Card.Content>
        <Modal
          open={detailsModalOpen}
          onClose={() => setDetailsModalOpen(false)}
        >
          {openVehicle && (
            <Modal.Header>{vehicleLabel(openVehicle)}</Modal.Header>
          )}
          {openVehicle && (
            <Modal.Content>
              <TimeRangeInput
                label={t('Disponibilités')}
                clearable
                value={
                  openVehicle.timeWindow
                    ? openVehicle.timeWindow.map(seconds => {
                        // Relative seconds to date
                        return moment()
                          .startOf('day')
                          .add(seconds, 'seconds')
                          .toDate();
                      })
                    : [null, null]
                }
                onChange={range =>
                  // Date to relative seconds
                  updateVehicleTimeWindow(
                    openVehicle,
                    range &&
                      range.map((date, index) =>
                        date
                          ? moment(date).diff(
                              moment().startOf('day'),
                              'seconds',
                            )
                          : index === 0
                          ? 0
                          : 86399,
                      ),
                  )
                }
              />

              <Form.Field>
                <label>{t('Capacité')}</label>
                <Form.Input
                  type="number"
                  value={openVehicle.capacity}
                  onChange={(e, { value }) =>
                    updateVehicleCapacity(openVehicle, parseInt(value) || 0)
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>{t('Dépôt')}</label>
                <PlacesAutoCompleteInput
                  place={openVehicle.depot}
                  onChange={depot => updateVehicleDepot(openVehicle, depot)}
                  showMap={true}
                />
              </Form.Field>
            </Modal.Content>
          )}
        </Modal>
      </Card>
    );
  },
);

const styles = {
  container: {
    width: '100%',
  },
  body: {
    height: '75%',
    overflow: 'auto',
    maxHeight: 300,
  },
  vehicleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 5,
  },
  vehicleCheckbox: {
    marginRight: 5,
  },
  vehicleLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  vehicleLabel: {
    margin: 5,
  },
  focusedVehicleLabel: {
    fontWeight: 'bold',
  },
  vehicleColorIndicator: {
    display: 'inline-block',
    width: 20,
    height: 20,
    marginRight: 5,
    borderRadius: 5,
  },
};

export default withFileHandlers()(withi18n('planner')(PlannerVehiclesManager));

import React, { memo } from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import driverPropType from '../driverPropType';

import colors from '../../../theme/colors.json';

const DriverCard = memo(
  ({ driver, selection, selected, style, onSelect, ...props }) => (
    <div
      style={{ ...styles.container, ...style }}
      onClick={() => onSelect(driver)}
      {...props}
    >
      <Card style={styles.card}>
        <Card.Content style={styles.cardContent}>
          <Image
            style={styles.driverPicture}
            avatar
            src="https://react.semantic-ui.com/images/avatar/small/elliot.jpg"
          />
          <div style={styles.driverContent}>
            <div style={styles.driverName}>
              {driver.firstname} {driver.lastname}
            </div>
            <div style={styles.driverRegistrationNumber}>
              {driver.registrationNumber}
            </div>
          </div>
          {selection && (
            <Icon
              name={selected ? 'checkmark' : ''}
              circular
              color={selected ? 'orange' : 'grey'}
              inverted={selected}
              size="small"
            />
          )}
        </Card.Content>
      </Card>
    </div>
  ),
);

DriverCard.propTypes = {
  driver: driverPropType,
  selection: PropTypes.bool,
  selected: PropTypes.bool,
};

DriverCard.defaultProps = {
  selection: false,
  selected: false,
};

const styles = {
  container: {
    cursor: 'pointer',
  },
  card: {
    backgroundColor: colors.BACKGROUND_GREY,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  driverPicture: {
    width: 50,
    height: 50,
    marginRight: 14,
  },
  driverContent: {
    flex: 1,
  },
  driverName: {
    fontSize: 18,
  },
  driverRegistrationNumber: {
    fontSize: 18,
    fontWeight: 600,
  },
};

export default DriverCard;

import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class GameClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @param {Object} options - other options
   * @memberof GameClient
   */
  constructor(endpoint, { tokens }) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.api = create({
      baseURL: `${endpoint}/game`
    });
  }

  /**
   * Return the user's gaming account
   *
   * @returns {Account} - the user's account
   * @memberof PaymentClient
   */
  async getMyAccount() {
    const { api } = this;
    const response = await api.get(
      '/accounts/me',
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    const account = response.data.account;
    return account;
  }

  /**
   * Expend a certain amount of points
   *
   * @param {Number} amount - the amount to charge
   * @param {String} purpose - the purpose of this charge, must match a string expected by other clients for consumption
   * @returns {Number} newBalance - the new balance of the account
   * @memberof PaymentClient
   */
  async expend(amount, purpose) {
    const { api } = this;
    const response = await api.post(
      '/points/expend',
      { amount },
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    const result = response.data;
    if (result.token) {
      this.tokens.put(`PURCHASE_${purpose}`, { token: result.token });
    }
    return result.account.balance;
  }

  /**
   * Claim the reward of sharing the app, can only be called once
   *
   * @returns {Account} updatedAccount - the updated account
   * @memberof PaymentClient
   */
  async claimSharingReward() {
    const { api } = this;
    const response = await api.post(
      '/points/claimSharingReward',
      null,
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'ACCOUNT_VERIFICATION'
          )}`
        }
      }
    );
    handleResponseError(response);
    return response.data.account;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/game`);
  }
}

export default GameClient;

function handleResponseError(response) {
  if (response.ok) {
    return;
  }
  const error = new Error();
  if (response.data) {
    error.message = response.data.message || JSON.stringify(response.data);
    error.code = response.data.code;
  } else {
    if (response.problem === 'NETWORK_ERROR') {
      error.message =
        'Could not reach the server. Check if you are still connected to the internet';
    } else {
      error.message = 'Unkown error. Please reach out to support.';
    }
  }
  error.status = response.status;
  error.response = response;
  console.warn(error.response);
  throw error;
}

export default handleResponseError;

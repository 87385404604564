import React, { memo, useEffect, useMemo, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import merge from 'lodash/merge';
import toArray from 'lodash/toArray';
import intersection from 'lodash/intersection';
import orderBy from 'lodash/orderBy';
import isUndefined from 'lodash/isUndefined';
import range from 'lodash/range';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import memoize from 'memoize-one';
import { ReactSVG } from 'react-svg';

import HighlightedText from '../../common/HighlightedText';
import EmptyState from '../../common/EmptyState';

import travelRequirementPropType from '../travelRequirementPropType';
import colors from '../../../theme/colors';
import clock_icon from '../../../assets/images/clock.svg';
import location_pin_icon from '../../../assets/images/location-pin.svg';
import stopLabel from '../../../utils/stopLabel';
import getNextDateOfDay from '../../../utils/date/getNextDateOfDay';
import withi18n from 'weego-common/src/hoc/i18n';

const demandsToRequirements = memoize(
  (demands, { splitMultiPassengerDemands, selectedDay }) => {
    const demandToRequirement = demand => {
      return {
        id: demand.id,
        departure: merge({}, demand.departure, {
          id: `${demand.id}-departure`,
          demand: demand.passengerCount ? demand.passengerCount : 1,
          time:
            demand.departure.time &&
            moment()
              .set({
                hours: moment(demand.departure.time).hours(),
                minute: moment(demand.departure.time).minutes(),
                seconds: moment(demand.departure.time).seconds(),
              })
              .toDate(),
          demandIds: [demand.id],
          groups: demand.groups,
          droppable: demand.passenger?.droppable,
        }),
        arrival: merge({}, demand.arrival, {
          id: `${demand.id}-arrival`,
          demand: demand.passengerCount ? -demand.passengerCount : -1,
          time:
            demand.arrival.time &&
            moment()
              .set({
                hours: moment(demand.arrival.time).hours(),
                minute: moment(demand.arrival.time).minutes(),
                seconds: moment(demand.arrival.time).seconds(),
              })
              .toDate(),
          demandIds: [demand.id],
          groups: demand.groups,
          droppable: demand.passenger?.droppable,
        }),
        days: selectedDay ? [selectedDay] : demand.days,
        passengerCount: demand.passengerCount,
      };
    };
    const requirements = flatten(
      map(demands, demand => {
        // If the user wants to split the demands that are for multiple passengers
        // at once, then we create a separate demand for each of them
        if (!isUndefined(demand.passengerCount) && splitMultiPassengerDemands) {
          return range(demand.passengerCount).map(index =>
            demandToRequirement({
              ...demand,
              id: `${demand.id}-${index}`,
              passengerCount: 1,
            }),
          );
        }
        return demandToRequirement(demand);
      }),
    );

    const orderedRequirements = orderBy(
      requirements,
      r => r.departure.time || r.arrival.time,
    );

    return orderedRequirements;
  },
);

const TravelRequirementsManager = memo(
  ({
    account,
    demands,
    selectedDay,
    selectedGroups,
    selectedTimeRange,
    splitMultiPassengerDemands,
    droppedDemandIds,
    style = {},
    requestDemands,
    onChange,
    onSelect,
    openedFile,
    t,
    includePlanned,
  }) => {
    const [requirements, setRequirements] = useState([]);

    useEffect(() => {
      if (!account) {
        return;
      }
      requestDemands({
        query: {
          ignoreCompanyFilter: true,
          filter: {
            include: ['passenger'],
            where: {
              and: [
                {
                  or: [
                    {
                      companyId: {
                        exists: false,
                      },
                    },
                    {
                      companyId: account.company,
                    },
                  ],
                },
                {
                  or: [
                    {
                      endDate: {
                        exists: false,
                      },
                    },
                    {
                      endDate: {
                        gte: moment().startOf('day').toDate(),
                      },
                    },
                    {
                      endDate: null,
                    },
                  ],
                },
              ],
            },
          },
        },
      });
    }, [account, requestDemands]);

    const selectedDate = useMemo(
      () => getNextDateOfDay(selectedDay),
      [selectedDay],
    );
    useEffect(() => {
      setRequirements(
        demandsToRequirements(
          toArray(demands)
            // Only emit demands for selected days
            .filter(d => d.days && d.days.includes(selectedDay))
            .filter(
              d =>
                !d.startDate ||
                moment(d.startDate).startOf('day').isSameOrBefore(selectedDate),
            )
            .filter(
              d =>
                !d.endDate ||
                moment(d.endDate).endOf('day').isSameOrAfter(selectedDate),
            )
            .filter(
              d =>
                (d.companyId &&
                  d.groups &&
                  selectedGroups?.includes('ALL_GROUPS')) ||
                (d.companyId &&
                  !d.groups?.length &&
                  selectedGroups?.includes('GROUPLESS')) ||
                (selectedGroups?.includes('B2C') && !d.companyId) ||
                intersection(selectedGroups, d.groups).length > 0,
            )
            .filter(
              d =>
                selectedTimeRange &&
                (d.departure.time || d.arrival.time) &&
                moment(d.departure.time || d.arrival.time).hours() >=
                  selectedTimeRange[0] &&
                moment(d.departure.time || d.arrival.time).hours() <=
                  selectedTimeRange[1],
            )
            .filter(d => !d.disabled)
            .filter(
              d =>
                (includePlanned ||
                  d.status !== 'PLANNED' ||
                  !d.upcomingTripDates?.find(upcomingDate =>
                    moment(upcomingDate).isSame(selectedDate, 'date'),
                  )) &&
                d.status !== 'REFUSED',
            )
            .filter(
              d =>
                !d.cancelledDates ||
                !d.cancelledDates.find(cancelledDate =>
                  moment(cancelledDate).isSame(selectedDate, 'date'),
                ),
            ),
          { splitMultiPassengerDemands, selectedDay },
        ),
      );
    }, [
      demands,
      selectedDay,
      selectedGroups,
      selectedTimeRange,
      splitMultiPassengerDemands,
      includePlanned,
      selectedDate,
    ]);

    useEffect(() => {
      onChange(requirements);
    }, [requirements, onChange]);

    const removeRequirement = vehicle => {
      const index = requirements.indexOf(vehicle);
      setRequirements(requirements.filter((r, i) => i !== index));
    };

    return (
      <div style={{ ...styles.container, ...style }}>
        {isEmpty(requirements) && (
          <EmptyState
            text={t(
              'Aucune demande trouvée, ajustez votre recherche au niveau des options',
            )}
            imageHeight={80}
          />
        )}
        {map(requirements, (requirement, index) => {
          const isDropped = !!droppedDemandIds?.includes(requirement.id);
          return (
            <div
              onClick={() => onSelect(requirement)}
              key={requirement.id}
              style={{
                ...styles.requirementContainer,
                ...(isDropped ? styles.droppedRequirementContainer : {}),
              }}
            >
              <div style={styles.requirementLabelContainer}>
                <div style={styles.requirement}>
                  <HighlightedText style={styles.requirementTimeContainer}>
                    <ReactSVG
                      style={styles.requirementTimeIcon}
                      src={clock_icon}
                    />
                    {requirement.departure.time &&
                      moment(requirement.departure.time).format('HH:mm')}
                    {requirement.arrival.time &&
                      moment(requirement.arrival.time).format('HH:mm')}
                    {!!requirement.passengerCount &&
                      `(${requirement.passengerCount})`}
                  </HighlightedText>
                  <HighlightedText style={styles.requirementPathContainer}>
                    <ReactSVG src={location_pin_icon} />
                    <div
                      style={styles.requirementStopContainer}
                      title={stopLabel(requirement.departure)}
                    >
                      {stopLabel(requirement.departure)}
                    </div>
                    &rarr;
                    <div
                      style={styles.requirementStopContainer}
                      title={stopLabel(requirement.arrival)}
                    >
                      {stopLabel(requirement.arrival)}
                    </div>
                    <Icon
                      style={styles.removeRequirementIcon}
                      onClick={() => removeRequirement(requirement)}
                      name="close"
                    />
                  </HighlightedText>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  },
);

const styles = {
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerAction: {
    backgroundColor: colors.BACKGROUND_GREY,
  },
  body: {
    height: '75%',
    overflow: 'auto',
  },
  iconAction: {
    cursor: 'pointer',
    marginRight: 10,
  },
  requirement: {
    display: 'flex',
    flex: 1,
    marginBottom: 10,
  },
  requirementTimeContainer: {
    width: 100,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  requirementTimeIcon: {
    marginRight: 5,
  },
  requirementPathContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  requirementStopContainer: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
  },
  requirementContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  droppedRequirementContainer: {
    border: 'solid 2px red',
  },
  requirementLabelContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  removeRequirementIcon: {
    cursor: 'pointer',
  },
  daysDropdown: {
    flex: 1,
    marginRight: 10,
  },
};

TravelRequirementsManager.propTypes = {
  requirements: PropTypes.arrayOf(travelRequirementPropType),
  mapCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  openedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  saveLocalFile: PropTypes.func.isRequired,
  openLocalFile: PropTypes.func.isRequired,
};

TravelRequirementsManager.defaultProps = {
  requirements: [],
  mapCenter: null,
  openedFile: null,
};

export default withi18n('planner')(TravelRequirementsManager);

import { createActions } from 'redux-actions';

const { alert } = createActions({
  ALERT: {
    SHOW: (title, message, type) => ({ title, message, type }),
    HIDE: () => null,
  },
});

export default alert;

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import PassengersManager from '../../../components/passengers/PassengersManager';

class PassengersScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Passagers');
  }

  render() {
    return <PassengersManager />;
  }
}

export default withi18n('menu')(PassengersScreen);

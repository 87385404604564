import React, { memo } from 'react';
import { Card, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import vehiclePropType from '../vehiclePropType';

import busIcon from '../../../assets/images/bus.svg';
import colors from '../../../theme/colors.json';

import './VehicleCard.css';

const VehicleCard = memo(
  ({ vehicle, selection, selected, style, onSelect, ...props }) => (
    <div
      className="vehicle-card"
      style={{ ...styles.container, ...style }}
      onClick={() => onSelect(vehicle)}
      {...props}
    >
      <Card style={styles.card}>
        <Card.Content style={styles.cardContent}>
          <ReactSVG
            className="bus-icon"
            style={styles.vehiclePicture}
            src={busIcon}
          />
          <div style={styles.vehicleContent}>
            <div style={styles.vehicleName}>
              {vehicle.brand || 'Inconnu'} - {vehicle.capacity || 0} places
            </div>
            <div style={styles.vehicleRegistrationNumber}>
              {vehicle.carLabel || vehicle.carNumber || 'Inconnu'}
            </div>
          </div>
          {selection && (
            <Icon
              name={selected ? 'checkmark' : ''}
              circular
              color={selected ? 'orange' : 'grey'}
              inverted={selected}
              size="small"
              style={styles.selectionIcon}
            />
          )}
        </Card.Content>
      </Card>
    </div>
  ),
);

VehicleCard.propTypes = {
  vehicle: vehiclePropType,
  selection: PropTypes.bool,
  selected: PropTypes.bool,
};

VehicleCard.defaultProps = {
  selection: false,
  selected: false,
};

const styles = {
  container: {
    cursor: 'pointer',
  },
  card: {
    backgroundColor: colors.BACKGROUND_GREY,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  vehiclePicture: {
    width: 50,
    height: 50,
  },
  vehicleContent: {
    paddingLeft: 14,
    flex: 1,
    overflow: 'hidden',
  },
  vehicleName: {
    fontSize: 18,
  },
  vehicleRegistrationNumber: {
    display: 'inline-block',
    fontSize: 18,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  selectionIcon: {
    flexShrink: 0,
  },
};

export default VehicleCard;

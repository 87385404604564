import jwt from 'jsonwebtoken';

import makeCrudSaga from '../../lib/weego-common/src/utils/makeCrudSaga';
import demandsActionCreators, { actionTypes } from '../../actions/demands';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const demandsSaga =
  tokens && tokens.ACCOUNT_VERIFICATION && decodedToken?.company
    ? makeCrudSaga(
        'demands',
        actionTypes,
        demandsActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/demands`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: true,
          key: 'id',
        },
      )
    : () => {};

export default demandsSaga;

import React, { memo } from 'react';
import { Polyline } from 'react-google-maps';

import TravelRequirementStopMarker from './TravelRequirementStopMarker';
import colors from '../../theme/colors.json';
import withi18n from 'weego-common/src/hoc/i18n';

const TravelRequirementEditor = memo(
  ({ requirement, readonly, onChange, onSelect, onDismiss, t }) => {
    const updateRequirement = updates => {
      onChange({
        ...requirement,
        ...updates,
      });
    };

    return (
      <React.Fragment>
        <TravelRequirementStopMarker
          label={t('Départ')}
          stop={requirement.departure}
          color={colors.PRIMARY}
          readonly={readonly}
          maxTime={requirement.arrival.time}
          onChange={stop => updateRequirement({ departure: stop })}
          onSelect={() => onSelect(requirement)}
          onDismiss={() => onDismiss(requirement)}
        />
        <TravelRequirementStopMarker
          label={t('Arrivée')}
          stop={requirement.arrival}
          color={colors.APRICOT}
          readonly={readonly}
          minTime={requirement.departure.time}
          onChange={stop => updateRequirement({ arrival: stop })}
          onSelect={() => onSelect(requirement)}
          onDismiss={() => onDismiss(requirement)}
        />
        <Polyline
          path={[requirement.departure.coords, requirement.arrival.coords]}
          options={{ strokeColor: 'red', zIndex: 1 }}
        />
      </React.Fragment>
    );
  },
);

export default withi18n('planner')(TravelRequirementEditor);

import {createActions} from 'redux-actions';

const {profile} = createActions({
  PROFILE: {
    UPDATE_PROFILE: profileData => profileData,
    UPDATE_PROFILE_ERROR: error => error,
    SAVE_PROFILE: (profileData, silent = false) => ({profileData, silent}),
    SAVE_PROFILE_ERROR: error => error,
  },
});

export default profile;

import get from 'lodash/get';
import { takeLatest, all } from 'redux-saga/effects';
import authActions from 'weego-common/src/actions/auth';
import profileActions from 'weego-common/src/actions/profile';

import { actionTypes as accountsActions } from '../../actions/accounts';
import alertActions from '../../actions/alert';
import { actionTypes as customFieldsActions } from '../../actions/customFields';
import dashboardActions from '../../actions/dashboard';
import { actionTypes as demandsActions } from '../../actions/demands';
import { actionTypes as driversActions } from '../../actions/drivers';
import { actionTypes as groupsActions } from '../../actions/groups';
import liveMapActions from '../../actions/liveMap';
import notificationActions from '../../actions/notification';
import { actionTypes as passengersActions } from '../../actions/passengers';
import paymentActions from '../../actions/payment';
import { actionTypes as stopsActions } from '../../actions/stops';
import { actionTypes as transactionsActions } from '../../actions/transactions';
import tripActions from '../../actions/trip';
import { actionTypes as tripEventsActions } from '../../actions/tripEvents';
import { actionTypes as tripUpdatesActions } from '../../actions/tripUpdates';
import { actionTypes as vehiclesActions } from '../../actions/vehicles';

import trackActions from 'weego-common/src/sagas/utils/trackActions';

function updateSegmentUserProperties(action) {
  const account = action.payload;
  const name = `${get(account, 'metadata.profile.firstName') || ''} ${
    get(account, 'metadata.profile.lastName') || ''
  }`.trim();
  window.analytics.identify(account._id, {
    name,
    email: account.phoneNumber,
    roles: account.roles,
    phoneNumber: account.phoneNumber,
    company: account.company,
    groups: account.groups,
  });
}

function* rootAnalyticsSaga() {
  yield all([
    takeLatest(authActions.updateAccount, updateSegmentUserProperties),
    trackActions(authActions),
    trackActions(profileActions),
    trackActions(accountsActions),
    trackActions(alertActions),
    trackActions(customFieldsActions),
    trackActions(dashboardActions),
    trackActions(demandsActions),
    trackActions(driversActions),
    trackActions(groupsActions),
    trackActions(liveMapActions),
    trackActions(notificationActions),
    trackActions(passengersActions),
    trackActions(paymentActions),
    trackActions(stopsActions),
    trackActions(transactionsActions),
    trackActions(tripActions),
    trackActions(tripEventsActions),
    trackActions(tripUpdatesActions),
    trackActions(vehiclesActions),
  ]);
}

export default rootAnalyticsSaga;

import PropTypes from 'prop-types';

const fieldTypePropType = PropTypes.oneOf([
  'STRING',
  'NUMBER',
  'BOOLEAN',
  'DATE',
  'TIME',
  'DATETIME',
  'PHONE',
  'ENUM',
  'REF',
  'PLACE',
  'CUSTOM',
]);

const fieldPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOfType([
    fieldTypePropType,
    PropTypes.arrayOf(fieldTypePropType),
  ]),
  omitSubFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  hideInForm: PropTypes.bool,
  hideInTable: PropTypes.bool,
  disableFilter: PropTypes.bool,
  shouldRender: PropTypes.func,
  unique: PropTypes.bool,
});

export default fieldPropType;

import React, { memo, useCallback } from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';

import NewTripBuilder from '../../../components/trips/NewTripBuilder';

const NewTripScreen = memo(function NewTripScreen({ history, location }) {
  const goBackToTrips = useCallback(() => {
    history.goBack();
  }, [history]);

  const query = qs.parse(location.search?.substr(1));

  return <NewTripBuilder fieldPresets={query} onExit={goBackToTrips} />;
});

export default withRouter(NewTripScreen);

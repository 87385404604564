import React, { useMemo, useState } from 'react';
import { List, Message, Modal } from 'semantic-ui-react';
import withi18n from 'weego-common/src/hoc/i18n';
import moment from 'weego-common/src/providers/moment';
import { predefinedIncidents } from '../TripIncidentReportForm/TripIncidentReportForm';

const TripIncidentsList = ({ trip, t }) => {
  const [openedEvent, setOpenedEvent] = useState(null);

  const openedEventIncident = useMemo(() => {
    if (!openedEvent) {
      return null;
    }
    return predefinedIncidents.find(
      incident => incident.value === openedEvent.type,
    );
  }, [openedEvent]);

  return (
    <div>
      <List selection>
        {(trip.events || []).map(event => {
          const incident = predefinedIncidents.find(
            incident => incident.value === event.type,
          );
          if (!incident) {
            return null;
          }
          return (
            <List.Item key={event.id} onClick={() => setOpenedEvent(event)}>
              <List.Icon name="warning sign" color="yellow" />
              <List.Content>
                <List.Header>{t(incident.text)}</List.Header>
                <List.Description>
                  {moment(event.date).format('HH:mm')}
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
      {openedEvent && openedEventIncident && (
        <Modal
          size="small"
          open={!!openedEvent}
          onClose={() => setOpenedEvent(null)}
        >
          <Modal.Header>{t(openedEventIncident.text)}</Modal.Header>
          <Modal.Content>
            <Message>
              {openedEvent.metadata && openedEvent.metadata.comment}
            </Message>
          </Modal.Content>
          <Modal.Actions>
            {t('Signalé le %{date} à %{time} par %{user}', {
              date: moment(openedEvent.date).format('DD/MM/YYYY'),
              time: moment(openedEvent.date).format('HH:mm'),
              user: openedEvent.reporter ? openedEvent.reporter.name : 'N/A',
            })}
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export default withi18n('trips')(TripIncidentsList);

import {createActions} from 'redux-actions';

const {auth} = createActions({
  AUTH: {
    CHANGE_COUNTRY: country => country,
    COUNTRY_CHANGED: country => country,
    SEND_OTP: phoneNumber => phoneNumber,
    OTP_SENT: () => undefined,
    OTP_SEND_FAILED: error => error,
    VERIFY_OTP: code => code,
    OTP_VERIFIED: () => undefined,
    OTP_VERIFICATION_FAILED: error => error,
    LOGGED_IN: isSessionRefresh => ({isSessionRefresh}),
    UPDATE_ACCOUNT: account => account,
    LOGOUT: () => undefined,
    LOGGED_OUT: reason => ({reason}),
    LOAD_AWS_KEY: accessKey => ({accessKey}),
    LOAD_AWS_KEY_FAILED: error => error,
    SAVE_USER_APP_ID: appId => appId,
    USER_APP_ID_SAVED: () => undefined,
    SHOW_REFERRAL_ELIGIBILITY_MODAL: () => null,
    CLOSE_REFERRAL_ELIGIBILITY_MODAL: () => null,
    SHOW_REFERRAL_PRIZE_MODAL: amount => ({amount}),
    CLOSE_REFERRAL_PRIZE_MODAL: () => null,
    START_TESTING: () => null,
  },
});

export default auth;

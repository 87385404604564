export function fetchSavedFilters() {
  const savedFilters =  localStorage.getItem("SAVED_FILTERS");
  const savedFiltersParsed = savedFilters ? JSON.parse(savedFilters) : {};
  return savedFiltersParsed;
}

// scope naming convention: _scope_name or resourceLabel screen in CrudManager
export function saveFilters(scope, filter) {
  const  savedFilters = fetchSavedFilters();
  savedFilters[scope] = filter;
  localStorage.setItem("SAVED_FILTERS", JSON.stringify(savedFilters))
}

export function clearFilters() {
  localStorage.removeItem('SAVED_FILTERS');
}
import jwt from 'jsonwebtoken';

import makeCrudSaga from '../../lib/weego-common/src/utils/makeCrudSaga';
import passengersActionCreators, {
  actionTypes,
} from '../../actions/passengers';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const passengersSaga =
  tokens && tokens.ACCOUNT_VERIFICATION && decodedToken?.company
    ? makeCrudSaga(
        'passengers',
        actionTypes,
        passengersActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/passengers`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: true,
          key: 'id',
        },
      )
    : () => {};

export default passengersSaga;

const { apiClient } = passengersSaga;
export { apiClient };

import moment from 'weego-common/src/providers/moment';

const dateToString = (date, includeTime) => {
  if (!date) {
    return '';
  }
  return moment(date).format(includeTime ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY');
};

export default dateToString;

import forEach from 'lodash/forEach';
import {takeEvery, all} from 'redux-saga/effects';
import startCase from 'lodash/startCase';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import toArray from 'lodash/toArray';

import firebaseAnalytics from '../../providers/analytics';
import segmentAnalytics from '../../providers/segment-analytics';
import flattenObject from './flattenObject';

function trackFirebaseAction(actionName, action) {
  if (action.meta?.analytics?.ignore) {
    return;
  }
  if (action.meta?.analytics?.omitParameters) {
    action = {
      ...action,
      payload: omit(action.payload, action.meta?.analytics?.omitParameters),
    };
  }
  try {
    firebaseAnalytics.logEvent(
      actionName.toLowerCase().replace('/', '_'),
      flattenObject(action),
    );
  } catch (error) {
    console.warn('Error tracking firebase event', action.type, error);
  }
}

function trackSegmentAction(action) {
  if (action.meta?.analytics?.ignore) {
    return;
  }
  try {
    const actionTypeParts = action.type.split('/');
    let eventName;
    if (actionTypeParts.length > 1) {
      const namespace = actionTypeParts[0];
      const actionName = actionTypeParts[1];
      eventName = `${startCase(namespace.toLowerCase())} - ${startCase(
        actionName.toLowerCase(),
      )}`;
    } else {
      eventName = startCase(action.type.toLowerCase());
    }
    segmentAnalytics.track(
      eventName,
      flattenObject({
        ...action,
        payload: omit(action.payload, action.meta?.analytics?.omitParameters),
      }),
    );
  } catch (error) {
    console.error('Error tracking event', action.type, error);
  }
}

function* trackActions(actions, {rename} = {}) {
  // eslint-disable-next-line no-param-reassign
  rename = rename || {};
  const sagas = [];
  const actionTypes = uniqBy(toArray(actions), action => action.toString());
  forEach(actionTypes, actionType => {
    const actionString = actionType.toString();
    sagas.push(
      takeEvery(actionType.toString(), trackSegmentAction),
      takeEvery(
        actionType.toString(),
        trackFirebaseAction,
        rename[actionString] || actionString,
      ),
    );
  });
  yield all(sagas);
}

export default trackActions;

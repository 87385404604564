import React, { PureComponent } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import PropTypes from 'prop-types';

import TripsManager from '../../../components/trips/TripsManager';
import NewTripScreen from './NewTripScreen';
import withi18n from 'weego-common/src/hoc/i18n';

class TripsPlanningScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rotationModalOpen: false,
      trip: {},
      datesRange: '',
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t('Planning');
  }

  render() {
    const { match } = this.props;
    return (
      <div style={styles.container}>
        <Switch>
          <Route path={`${match.path}/new`} component={NewTripScreen} />
          <Route
            path={match.path + '/:groupingField?/:groupingFieldValue?'}
            exact
            render={() => <TripsManager newTripPath={`${match.path}/new`} />}
          ></Route>
        </Switch>
      </div>
    );
  }
}

TripsPlanningScreen.propTypes = {
  match: PropTypes.object,
};

const styles = {
  container: {
    height: 'calc(100% - 80px)',
  },
};

export default withi18n('menu')(withRouter(TripsPlanningScreen));

import { withProps } from 'recompose';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import TripStopsEditor from './TripStopsEditor';
import getTripPath from '../../../utils/getTripPath';

const mapStateToProps = state => ({
  account: state.auth.account,
});

const enhance = compose(
  withProps({
    getTripPath: getTripPath,
  }),
  connect(mapStateToProps),
);
export default enhance(TripStopsEditor);

import ee from 'event-emitter';

class TrackingHandler {
  constructor(socket, token) {
    this.socket = socket;
    this.token = token;
    this.join();
  }

  join() {
    const { socket, token } = this;
    const join = () => socket.emit('join', { token });
    join();
    socket.on('reconnect', join);
  }

  publishPosition(position) {
    const { socket } = this;
    socket.emit('publishPosition', position);
  }

  emitDetectionEvent(event) {
    const { socket } = this;
    socket.emit('detectionEvent', event);
  }

  reset() {
    // Native socketio does not support emitting without parameters
    this.socket.emit('reset', {});
  }

  pinLine(line) {
    this.socket.emit('pinLine', { line });
  }

  onReward(fn) {
    this.socket.on('reward', fn);
  }

  onLineConfirm(fn) {
    this.socket.on('lineConfirm', fn);
  }

  onMatches(fn) {
    this.socket.on('matches', fn);
  }

  onDetect(fn) {
    this.socket.on('detect', fn);
  }

  disconnect() {
    this.socket.close();
  }
}
ee(TrackingHandler.prototype);

export default TrackingHandler;

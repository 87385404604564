import React, { memo } from 'react';
import { Modal, Message, Icon } from 'semantic-ui-react';

const alertTypeToIconMap = {
  info: 'info',
  error: 'warning circle',
  warning: 'warning sign',
};

const Alerts = memo(function Alerts({ openedAlert, hideAlert }) {
  return openedAlert ? (
    <Modal
      open={!!openedAlert}
      onClose={hideAlert}
      size="small"
      dimmer="inverted"
    >
      <Modal.Content>
        <Message
          error={openedAlert.type === 'error'}
          warning={openedAlert.type === 'warning'}
          info={openedAlert.type === 'info'}
          onDismiss={hideAlert}
        >
          <Message.Header>
            <Icon name={alertTypeToIconMap[openedAlert.type]} />
            {openedAlert.title}
          </Message.Header>
          <Message.Content style={styles.messageContent}>
            {openedAlert.message}
          </Message.Content>
        </Message>
      </Modal.Content>
    </Modal>
  ) : null;
});

const styles = {
  messageContent: {
    marginLeft: 20,
  },
};

export default Alerts;

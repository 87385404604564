import React, { memo, useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Table,
  Modal,
  Input,
  Form,
  Message,
  Confirm,
  Loader,
  Popup,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import memoizeOne from 'memoize-one';
import { ReactSVG } from 'react-svg';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import reverse from 'lodash/reverse';

import moment from 'weego-common/src/providers/moment';
import colors from 'weego-common/src/theme/colors.json';
import driverLabel from 'weego-common/src/utils/driverLabel';
import vehicleLabel from 'weego-common/src/utils/vehicleLabel';
import withi18n from 'weego-common/src/hoc/i18n';

import tripPropType from '../tripPropType';
import accountPropType from '../../auth/accountPropType';
import stopLabel from '../../../utils/stopLabel';
import folderIcon from '../../../assets/images/folder.svg';
import editIcon from '../../../assets/images/edit-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import duplicateIcon from '../../../assets/images/duplicate-icon.svg';

import TripEditor from '../TripEditor';
import DriverRotationTool from '../DriverRotationTool/DriverRotationTool';
import { DateInput } from 'semantic-ui-calendar-react';
import dateToString from '../../../utils/date/dateToString';
import stringToDate from '../../../utils/date/stringToDate';

const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const getTripsFieldValues = memoizeOne((trips, field) =>
  uniq(trips?.map(trip => trip[field])),
);

const TripsManager = memo(function TripsManager({
  newTripPath,
  trips,
  creating,
  deleting,
  editing,
  error,
  createTrip,
  fetchTrips,
  clearTrips,
  deleteTrip,
  editTrip,
  deleteManyTrips,
  editManyTrips,
  history,
  match,
  account,
  customFields,
  t,
}) {
  const [startDate, setStartDate] = useState(
    moment().subtract(2, 'days').startOf('day').toDate(),
  );
  useEffect(() => {
    clearTrips();
    fetchTrips({
      where: {
        cancelled: { neq: true },
        or: [
          { recurrence: { eq: null }, departureTime: { gte: startDate } },
          { recurrence: { exists: false }, departureTime: { gte: startDate } },
          {
            endDate: { gte: startDate },
          },
        ],
      },
    });
  }, [clearTrips, fetchTrips, startDate]);
  const wasDeleting = useRef(deleting);
  const wasEditing = useRef(editing);
  const [
    newGroupingFieldValueModalOpened,
    setNewGroupingFieldValueModalOpened,
  ] = useState(false);
  const [currentSortingField, setCurrentSortingField] = useState('poste');
  const [currentSortingDirection, setCurrentSortingDirection] = useState('ASC');
  const [newGroupingFieldValue, setNewGroupingFieldValue] = useState('');
  const [newGroupingFieldValueError, setNewGroupingFieldError] = useState(null);
  const [
    pendingRemovalGroupingFieldValue,
    setPendingRemovalGroupingFieldValue,
  ] = useState(null);
  const [
    pendingEditGroupingFieldValue,
    setPendingEditGroupingFieldValue,
  ] = useState(null);
  const [groupingFieldEditError, setGroupingFieldEditError] = useState(null);
  const [
    pendingNewGroupingFieldValues,
    setPendingNewGroupingFieldValues,
  ] = useState([]);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [rotationModalOpened, setRotationModalOpened] = useState(false);

  const [pendingEditTrip, setPendingEditTrip] = useState(null);
  const [pendingRemovalTrip, setPendingRemovalTrip] = useState(null);
  const [tripEditError, setTripEditError] = useState(null);

  const [groupingField, setGroupingField] = useState(
    match.params.groupingField || 'folder',
  );

  const removeByGroupingField = groupingFieldValue => {
    deleteManyTrips({ [groupingField]: groupingFieldValue });
  };

  const updateGroupingFieldValue = (oldValue, newValue) => {
    editManyTrips({ [groupingField]: oldValue }, { [groupingField]: newValue });
  };

  const removeTrip = trip => {
    deleteTrip(trip.id);
  };

  const sort = field => {
    if (currentSortingField !== field) {
      setCurrentSortingField(field);
      setCurrentSortingDirection('ASC');
    } else {
      setCurrentSortingDirection(
        currentSortingDirection === 'ASC' ? 'DESC' : 'ASC',
      );
    }
  };

  const getSortedEnum = field => {
    if (currentSortingField !== field) {
      return null;
    }
    return currentSortingDirection === 'ASC' ? 'ascending' : 'descending';
  };

  useEffect(() => {
    if (wasDeleting.current && !deleting && !error) {
      setPendingRemovalGroupingFieldValue(null);
      setPendingRemovalTrip(null);
    }
  }, [wasDeleting, deleting, error, fetchTrips]);

  useEffect(() => {
    if (wasEditing.current && !editing) {
      if (!error) {
        if (pendingEditGroupingFieldValue) {
          setPendingEditGroupingFieldValue(null);
          setNewGroupingFieldValue('');
        } else {
          setPendingEditTrip(null);
        }
        if (rotationModalOpened) {
          setRotationModalOpened(false);
        }
      } else {
        if (pendingEditGroupingFieldValue) {
          setGroupingFieldEditError(error);
        } else {
          setTripEditError(error);
        }
      }
    }
  }, [
    wasEditing,
    editing,
    error,
    pendingEditGroupingFieldValue,
    rotationModalOpened,
    fetchTrips,
  ]);

  useEffect(() => {
    wasDeleting.current = deleting;
  }, [deleting]);

  useEffect(() => {
    wasEditing.current = editing;
  }, [editing]);

  const tripsByGroupingField = useMemo(() => groupBy(trips, groupingField), [
    trips,
    groupingField,
  ]);
  const tripsWithWarnings = useMemo(
    () =>
      trips?.filter(
        t =>
          (t.driver && !t.driver.available) ||
          (t.vehicle && !t.vehicle.available),
      ),
    [trips],
  );
  const tripsWithWarningsByGroupingFieldValue = useMemo(
    () => groupBy(tripsWithWarnings, groupingField),
    [tripsWithWarnings, groupingField],
  );

  const groupingFieldValues = pendingNewGroupingFieldValues.concat(
    getTripsFieldValues(trips, groupingField),
  );
  const currentGroupingFieldValue = match.params.groupingFieldValue;
  const currentGroupingFieldValueTrips =
    (currentGroupingFieldValue !== 'unclassified'
      ? tripsByGroupingField[currentGroupingFieldValue]
      : tripsByGroupingField[undefined]) || [];

  const sortedCurrentGroupingFieldValueTrips = useMemo(() => {
    const sortedTrips = sortBy(
      currentGroupingFieldValueTrips,
      currentSortingField,
    );
    if (currentSortingDirection === 'DESC') {
      return reverse(sortedTrips);
    }
    return sortedTrips;
  }, [
    currentGroupingFieldValueTrips,
    currentSortingField,
    currentSortingDirection,
  ]);

  const isAdmin =
    account?.roles?.includes('admin') ||
    account?.roles?.includes('super_admin');

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {currentGroupingFieldValue && (
          <div>
            {isAdmin && (
              <Button primary onClick={() => setRotationModalOpened(true)}>
                <Icon name="refresh" />
                {t('Rotation')}
              </Button>
            )}
            {isAdmin && (
              <Link
                to={`${newTripPath}${
                  currentGroupingFieldValue
                    ? `?${groupingField}=${currentGroupingFieldValue}`
                    : ''
                }`}
              >
                <Button primary>
                  <Icon name="add" />
                  {t('Ajouter un trajet')}
                </Button>
              </Link>
            )}
          </div>
        )}
        {currentGroupingFieldValue && (
          <span style={styles.goBackLink} onClick={() => history.goBack()}>
            <Icon name="chevron left" />
            {t('Dossiers')}
          </span>
        )}
        {isAdmin && !currentGroupingFieldValue && (
          <Button
            primary
            onClick={() => setNewGroupingFieldValueModalOpened(true)}
          >
            <Icon name="add" />
            {t('Nouveau Dossier')}
          </Button>
        )}
        {!currentGroupingFieldValue && (
          <Form.Field>
            <label>{t('Depuis')}</label>{' '}
            <DateInput
              value={dateToString(startDate)}
              onChange={(e, { value }) => {
                setStartDate(stringToDate(value));
              }}
            />
          </Form.Field>
        )}
        {!currentGroupingFieldValue && (
          <Form.Field>
            <label>{t('Grouper par')}</label>{' '}
            <Dropdown
              value={groupingField}
              onChange={(e, { value }) => setGroupingField(value)}
              options={[{ text: t('Dossier'), value: 'folder' }].concat(
                map(customFields, field => ({
                  text: field.name,
                  value: field.key,
                })),
              )}
            />
          </Form.Field>
        )}
      </div>
      <div style={styles.body}>
        {!currentGroupingFieldValue ? (
          <Table style={styles.table}>
            <Table.Body>
              {groupingFieldValues?.map(groupingFieldValue => (
                <Table.Row>
                  <Table.Cell style={styles.folderNameCell}>
                    <Link
                      to={`${match.url}/${groupingField}/${
                        groupingFieldValue || 'unclassified'
                      }`}
                    >
                      <div style={styles.folderNameCellContent}>
                        <ReactSVG src={folderIcon} />
                        <span style={styles.folderName}>
                          {groupingFieldValue || t('Non classés')}{' '}
                          {tripsWithWarningsByGroupingFieldValue[
                            groupingFieldValue !== 'unclassified'
                              ? groupingFieldValue
                              : undefined
                          ] ? (
                            <Popup
                              content={t(
                                'Il y a des problèmes au niveau des trajets de ce dossier',
                              )}
                              position="bottom center"
                              trigger={
                                <Icon name="warning sign" color="yellow" />
                              }
                            />
                          ) : null}
                        </span>
                      </div>
                    </Link>
                  </Table.Cell>
                  <Table.Cell width="one">
                    {deleting &&
                    pendingRemovalGroupingFieldValue === groupingFieldValue ? (
                      <Loader />
                    ) : null}
                  </Table.Cell>
                  <Table.Cell width="one">
                    {isAdmin && (
                      <div style={styles.actionButtons}>
                        <span
                          onClick={() => {
                            setIsDuplicating(true);
                            setNewGroupingFieldValue(
                              t(`Copie de {{groupingFieldValue}}`, {
                                groupingFieldValue,
                              }),
                            );
                            setPendingEditGroupingFieldValue(
                              groupingFieldValue,
                            );
                          }}
                          style={styles.rightButtons}
                        >
                          <ReactSVG src={duplicateIcon} />
                        </span>
                        <span
                          onClick={() => {
                            setPendingEditGroupingFieldValue(
                              groupingFieldValue,
                            );
                            setNewGroupingFieldValue(groupingFieldValue);
                          }}
                          style={styles.rightButtons}
                        >
                          <ReactSVG src={editIcon} />
                        </span>
                        <span
                          onClick={event => {
                            setPendingRemovalGroupingFieldValue(
                              groupingFieldValue,
                            );
                            event.stopPropagation();
                          }}
                          style={styles.rightButtons}
                        >
                          <ReactSVG src={deleteIcon} />
                        </span>
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Table style={styles.table} sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={getSortedEnum('departureTime')}
                  onClick={() => sort('departureTime')}
                >
                  {t('Date et heure de départ')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSortedEnum('from.name')}
                  onClick={() => sort('from.name')}
                >
                  {t('Lieu de départ')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSortedEnum('to.name')}
                  onClick={() => sort('to.name')}
                >
                  {t("Lieu d'arrivée")}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSortedEnum('vehicle.carLabel')}
                  onClick={() => sort('vehicle.carLabel')}
                >
                  {t('Véhicule')}
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSortedEnum('driver.firstname')}
                  onClick={() => sort('driver.firstname')}
                >
                  {t('Conducteur')}
                </Table.HeaderCell>
                {map(customFields, field => (
                  <Table.HeaderCell
                    sorted={getSortedEnum(field.key)}
                    onClick={() => sort(field.key)}
                  >
                    {field.name}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sortedCurrentGroupingFieldValueTrips.map(trip => (
                <Table.Row key={trip.id}>
                  <Table.Cell>
                    {trip.departureTime && !trip.recurrence
                      ? moment(trip.departureTime).format(
                          '[Le] DD/MM [à] HH:mm',
                        )
                      : null}
                    <br />
                    {trip.recurrence ? <Icon name="sync" /> : null}
                    {trip.recurrence
                      ? t(
                          `Tous les {{days}}  à {{time}} jusqu'au {{endDate}}`,
                          {
                            days: sortBy(
                              map(trip.recurrence.days, day =>
                                weekDays.indexOf(day),
                              ),
                            )
                              .map(day => moment().set('day', day).format('dd'))
                              .join(', '),
                            time: moment(trip.departureTime).format('HH:mm'),
                            endDate: moment(trip.endDate).format('DD/MM/YYYY'),
                          },
                        )
                      : null}
                  </Table.Cell>
                  <Table.Cell>{stopLabel(trip.from)}</Table.Cell>
                  <Table.Cell>{stopLabel(trip.to)}</Table.Cell>
                  <Table.Cell>
                    {vehicleLabel(trip.vehicle)}
                    {trip.vehicle && !trip.vehicle.available ? (
                      <Popup
                        content={t("Ce véhicule n'est pas disponible")}
                        position="bottom center"
                        trigger={<Icon name="warning sign" color="yellow" />}
                      />
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>
                    {driverLabel(trip.driver, {
                      displayRegistrationNumber: true,
                    })}
                    {trip.driver && !trip.driver.available ? (
                      <Popup
                        content={t("Ce conducteur n'est pas disponible")}
                        position="bottom center"
                        trigger={<Icon name="warning sign" color="yellow" />}
                      />
                    ) : null}
                  </Table.Cell>
                  {map(customFields, field => (
                    <Table.Cell>{trip[field.key]}</Table.Cell>
                  ))}
                  <Table.Cell width="one">
                    <div style={styles.actionButtons}>
                      {isAdmin && (
                        <span
                          onClick={() => {
                            setPendingEditTrip(trip);
                            setIsDuplicating(true);
                          }}
                          style={styles.rightButtons}
                        >
                          <ReactSVG src={duplicateIcon} />
                        </span>
                      )}
                      <span
                        onClick={() => {
                          setPendingEditTrip(trip);
                        }}
                        style={styles.rightButtons}
                      >
                        <ReactSVG src={editIcon} />
                      </span>
                      {isAdmin && (
                        <span
                          onClick={event => {
                            setPendingRemovalTrip(trip);
                          }}
                          style={styles.rightButtons}
                        >
                          <ReactSVG src={deleteIcon} />
                        </span>
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      <Modal
        onClose={() => setNewGroupingFieldValueModalOpened(false)}
        open={newGroupingFieldValueModalOpened}
      >
        <Modal.Content>
          {newGroupingFieldValueError && (
            <Message error>{newGroupingFieldValueError.message}</Message>
          )}
          <Form.Field>
            <label>{t('Nom du dossier')}</label>
            <Input
              fluid
              value={newGroupingFieldValue}
              onChange={(e, { value }) => setNewGroupingFieldValue(value)}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              if (groupingFieldValues.includes(newGroupingFieldValue.trim())) {
                setNewGroupingFieldError(
                  new Error(t('Ce dossier existe déjà')),
                );
                return;
              }
              setPendingNewGroupingFieldValues(
                pendingNewGroupingFieldValues.concat(
                  newGroupingFieldValue.trim(),
                ),
              );
              setNewGroupingFieldValueModalOpened(false);
              setNewGroupingFieldValue('');
              setNewGroupingFieldError(null);
            }}
            primary
          >
            {t('Valider')}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => {
          setPendingEditGroupingFieldValue(null);
          setIsDuplicating(false);
        }}
        open={!!pendingEditGroupingFieldValue}
      >
        <Modal.Content>
          {groupingFieldEditError && (
            <Message error>{groupingFieldEditError.message}</Message>
          )}
          <Form.Field>
            <label>{t('Nom du dossier')}</label>
            <Input
              fluid
              value={newGroupingFieldValue}
              onChange={(e, { value }) => setNewGroupingFieldValue(value)}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              if (groupingFieldValues.includes(newGroupingFieldValue.trim())) {
                setGroupingFieldEditError(
                  new Error(t('Ce dossier existe déjà')),
                );
                return;
              }
              if (isDuplicating) {
                let tripsToDuplicate = trips?.filter(
                  trip => trip[groupingField] === pendingEditGroupingFieldValue,
                );
                tripsToDuplicate.forEach(trip => {
                  trip[groupingField] = newGroupingFieldValue;
                  createTrip(omit(trip, 'id', 'vehicle', 'driver'), true);
                });
              } else {
                updateGroupingFieldValue(
                  pendingEditGroupingFieldValue,
                  newGroupingFieldValue,
                );
              }
              setPendingEditGroupingFieldValue(null);
              setNewGroupingFieldValue('');
              setNewGroupingFieldError(null);
              setIsDuplicating(false);
            }}
            primary
            loading={editing || creating}
            disabled={editing || creating}
          >
            {isDuplicating ? t('Dupliquer') : t('Valider')}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => {
          setPendingEditTrip(null);
          setIsDuplicating(false);
        }}
        open={!!pendingEditTrip}
      >
        <Modal.Content>
          {tripEditError && <Message error>{tripEditError.message}</Message>}
          <TripEditor
            trip={pendingEditTrip}
            onExit={() => {
              setPendingEditTrip(null);
              setIsDuplicating(false);
            }}
            isDuplicating={isDuplicating}
            disabledFieldKeys={[groupingField]}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => {
          setRotationModalOpened(false);
        }}
        open={rotationModalOpened}
      >
        <Modal.Content>
          <DriverRotationTool
            trips={sortedCurrentGroupingFieldValueTrips}
            saving={editing}
            rotationField={currentSortingField}
            onSave={trips => trips.forEach(trip => editTrip(trip, true))}
          />
        </Modal.Content>
      </Modal>
      <Confirm
        open={!!pendingRemovalGroupingFieldValue}
        cancelButton={t('Annuler')}
        confirmButton={
          <Button loading={deleting} disabled={deleting}>
            {t('Supprimer')}
          </Button>
        }
        content={t(`Supprimer le dossier {{folder}} ?`, {
          folder: pendingRemovalGroupingFieldValue,
        })}
        onCancel={() => {
          setPendingRemovalGroupingFieldValue(null);
        }}
        onConfirm={() =>
          removeByGroupingField(pendingRemovalGroupingFieldValue)
        }
      />
      <Confirm
        open={!!pendingRemovalTrip}
        cancelButton={t('Annuler')}
        confirmButton={
          <Button loading={deleting} disabled={deleting}>
            Supprimer
          </Button>
        }
        content={t(`Supprimer le trajet ?`)}
        onCancel={() => {
          setPendingRemovalTrip(null);
        }}
        onConfirm={() => removeTrip(pendingRemovalTrip)}
      />
      <Loader active={creating || editing || deleting} inline />
    </div>
  );
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  goBackLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  body: {
    marginTop: 20,
    height: 'calc(100% - 60px)',
    overflow: 'auto',
  },
  table: {
    padding: 10,
    overflow: 'auto',
  },
  folderNameCellContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'black',
  },
  folderName: {
    marginLeft: 20,
  },
  actionButtons: {
    display: 'flex',
  },
  rightButtons: {
    backgroundColor: colors.BACKGROUND_GREY,
    padding: 9,
    borderRadius: 2,
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

TripsManager.propTypes = {
  newTripPath: PropTypes.string.isRequired,
  trips: PropTypes.arrayOf(tripPropType).isRequired,
  deleting: PropTypes.bool,
  editing: PropTypes.bool,
  error: PropTypes.object,
  fetchTrips: PropTypes.func.isRequired,
  clearTrips: PropTypes.func.isRequired,
  deleteTrip: PropTypes.func.isRequired,
  editTrip: PropTypes.func.isRequired,
  deleteManyTrips: PropTypes.func.isRequired,
  editManyTrips: PropTypes.func.isRequired,
  account: accountPropType,
  customFields: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

export default withi18n('trips')(TripsManager);

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import FoughalTransactionsManager from '../../../components/foughal/FoughalTransactionsManager';

class FoughalTransactionsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Transactions Foughal');
  }

  render() {
    return <FoughalTransactionsManager />;
  }
}

export default withi18n('menu')(FoughalTransactionsScreen);

import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import stopsActionCreators, { actionTypes } from '../../actions/stops';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const stopsSaga =
  decodedToken && decodedToken.company
    ? makeCrudSaga(
        'stops',
        actionTypes,
        stopsActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/stops`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: true,
          key: 'id',
        },
      )
    : () => {};

export default stopsSaga;

import { handleActions } from 'redux-actions';
import isMatch from 'lodash/isMatch';
import merge from 'lodash/merge';

import tripActions from '../../actions/trip';

const DEFAULT_STATE = {
  loading: false,
  error: null,
  trips: null,
  editing: false,
  creating: false,
  deleting: false,
  importing: false,
  importError: null,
  customer: null,
};

export default handleActions(
  {
    [tripActions.updateTrips]: (state, action) => ({
      ...state,
      trips: action.payload,
      error: null,
    }),
    [tripActions.clearTrips]: (state, action) => ({
      ...state,
      trips: [],
    }),
    [tripActions.requestTrips]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [tripActions.requestTripsError]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      trips: null,
    }),
    [tripActions.requestTripsSuccess]: state => ({
      ...state,
      loading: false,
      error: null,
    }),
    [tripActions.requestTripsInPeriod]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [tripActions.requestTripsInPeriodError]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [tripActions.requestTripsInPeriodSuccess]: state => ({
      ...state,
      loading: false,
      error: null,
    }),
    [tripActions.createTrip]: state => ({
      ...state,
      creating: true,
      error: null,
    }),
    [tripActions.createTripError]: (state, action) => ({
      ...state,
      creating: false,
      error: action.payload,
    }),
    [tripActions.createTripSuccess]: (state, action) => ({
      ...state,
      creating: false,
      error: null,
      trips: state.trips?.concat(action.payload.trip),
    }),
    [tripActions.deleteTrip]: state => ({
      ...state,
      deleting: true,
      error: null,
    }),
    [tripActions.deleteTripError]: (state, action) => ({
      ...state,
      deleting: false,
      error: action.payload,
    }),
    [tripActions.deleteTripSuccess]: (state, { payload: { deletedTrip } }) => ({
      ...state,
      trips: state.trips.filter(t => t.id !== deletedTrip),
      deleting: false,
      error: null,
    }),
    [tripActions.deleteManyTrips]: state => ({
      ...state,
      deleting: true,
      error: null,
    }),
    [tripActions.deleteManyTripsError]: (state, action) => ({
      ...state,
      deleting: false,
      error: action.payload,
    }),
    [tripActions.deleteManyTripsSuccess]: (state, action) => ({
      ...state,
      trips: state.trips.filter(t => !isMatch(t, action.payload.where)),
      deleting: false,
      error: null,
    }),
    [tripActions.editTrip]: state => ({
      ...state,
      editing: true,
      error: null,
    }),
    [tripActions.editTrips]: state => ({
      ...state,
      editing: true,
      error: null,
    }),
    [tripActions.editTripSuccess]: (state, { payload: { updatedTrip } }) => ({
      ...state,
      trips: state.trips.map(t =>
        t.id === updatedTrip.id 
          ? { ...updatedTrip }
          : t,
      ),
      editing: false,
      error: null,
    }),
    [tripActions.editTripError]: (state, action) => ({
      ...state,
      editing: false,
      error: action.payload,
    }),
    [tripActions.editManyTrips]: state => ({
      ...state,
      editing: true,
      error: null,
    }),
    [tripActions.editManyTripsError]: (state, action) => ({
      ...state,
      editing: false,
      error: action.payload,
    }),
    [tripActions.editManyTripsSuccess]: (state, action) => ({
      ...state,
      trips: state.trips.map(t =>
        isMatch(t, action.payload.where)
          ? merge({}, t, action.payload.updates)
          : t,
      ),
      editing: false,
      error: null,
    }),
    [tripActions.importTrips]: (state, action) => ({
      ...state,
      importing: true,
      importError: null,
    }),
    [tripActions.importTripsSuccess]: (state, action) => ({
      ...state,
      importing: false,
      importError: null,
    }),
    [tripActions.importTripsError]: (state, action) => ({
      ...state,
      importing: false,
      importError: action.payload,
    }),
  },
  DEFAULT_STATE,
);

import PropTypes, { string } from 'prop-types';

const accountPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(string),
  company: PropTypes.string,
  metadata: PropTypes.object,
});

export default accountPropType;

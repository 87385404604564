import { connect } from 'react-redux';
import compose from 'recompose/compose';

import VehiclesMap from './VehiclesMap';
import liveMapActions from '../../../actions/liveMap';

const mapStateToProps = state => ({
  positions: state.liveMap.positions,
  focusedPositionKey: state.liveMap.focusedPositionKey,
  focusedVehicle: state.liveMap.focusedVehicle,
  focusedVehicleHistory: state.liveMap.focusedVehicleHistory,
  focusedTrips: state.dashboard.focusedTrips,
  account: state.auth.account,
  vehicleTargeted: state.liveMap.vehicleTargeted,
});

const mapDispatchToProps = dispatch => ({
  requestUpdate: compose(dispatch, liveMapActions.requestUpdate),
  focusVehicle: compose(dispatch, liveMapActions.focusVehicle),
  unfocusVehicle: compose(dispatch, liveMapActions.unfocusVehicle),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesMap);

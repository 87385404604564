import { connect } from 'react-redux';
import compose from 'recompose/compose';
import get from 'lodash/get';

import HeaderMenu from './HeaderMenu';
import authActions from '../../actions/auth';
import commonActions from '../../actions/common';

const mapStateToProps = state => ({
  isLogout: state.auth.logout,
  profile: get(state.auth.account, 'metadata.profile'),
});

const mapDispatchToProps = dispatch => ({
  logout: compose(dispatch, authActions.logout),
  toggleDrawer: compose(dispatch, commonActions.toggleDrawer),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const HeaderMenuContainer = enhance(HeaderMenu);

export default HeaderMenuContainer;

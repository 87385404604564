import { connect } from 'react-redux';
import TripGeneralInfosForm from './TripGeneralInfosForm';
import { compose } from 'recompose';
import driversActions from '../../../actions/drivers/driversActions';
import vehiclesActions from '../../../actions/vehicles/vehiclesActions';

const mapStateToProps = state => ({
  drivers: state.drivers,
  vehicles: state.vehicles,
  error: state.driversMeta.error || state.vehiclesMeta.error,
  account: state.auth.account,
});

const mapDispatchToProps = dispatch => ({
  fetchDrivers: compose(dispatch, driversActions.fetchStart),
  fetchVehicles: compose(dispatch, vehiclesActions.fetchStart),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TripGeneralInfosForm);

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import DemandsManager from '../../../components/demands/DemandsManager';

class DemandsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Demandes');
  }

  render() {
    return <DemandsManager />;
  }
}

export default withi18n('menu')(DemandsScreen);

import React, { memo, useState } from 'react';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import memoize from 'memoize-one';
import keyBy from 'lodash/keyBy';
import minBy from 'lodash/minBy';
import { Image } from 'semantic-ui-react';

import moment from 'weego-common/src/providers/moment';

import stopLabel from '../../../utils/stopLabel';
import demandLabel from '../../../utils/stopDemandLabel';

import transparent from '../../../assets/images/transparent.png';
import userIcon from '../../../assets/images/user-icon.svg';
import clock from '../../../assets/images/clock.svg';
import colors from '../../../theme/colors.json';
import HighlightedText from '../../common/HighlightedText';
import withi18n from 'weego-common/src/hoc/i18n';

const getDemandsById = memoize(trip => keyBy(trip?.demands, 'id'));

const StopMarker = memo(function StopMarker({
  stop,
  trip,
  iconContainerStyles = {},
  isOpen,
  isPast,
  otherStops,
  zIndex,
  onOpen,
  onClose,
  t,
}) {
  const [focusedStop, setFocusedStop] = useState(
    otherStops ? getMostRelevantStop(trip, [stop, ...otherStops]) : stop,
  );
  const demandsById = getDemandsById(trip);

  const getNextStopTime = () => {
    const mostRelevantStop = otherStops
      ? getMostRelevantStop(trip, [stop, ...otherStops])
      : stop;
    if (mostRelevantStop.maxDate) {
      return mostRelevantStop.maxDate;
    }
    if (stop === trip.from) {
      return trip.departureTime;
    }
    return null;
  };

  return (
    <MarkerWithLabel
      position={stop?.coords}
      icon={transparent}
      // eslint-disable-next-line no-undef
      labelAnchor={new google.maps.Point(17.5, 40)}
      title={stop?.name}
      zIndex={zIndex}
      onClick={event => {
        if (event.target.classList.contains('otherStopTime')) {
          const stopIndex = parseInt(event.target.getAttribute('data-index'));
          setFocusedStop(stopIndex === 0 ? stop : otherStops[stopIndex - 1]);
        } else if (isOpen) {
          onClose(stop);
        } else {
          onOpen(stop);
        }
      }}
    >
      <div style={styles.stopContainer}>
        <div
          style={{
            ...styles.stop,
            ...(otherStops && otherStops.length >= 2 && styles.bigStop),
          }}
        >
          <div
            style={{
              ...styles.stopIconContainer,
              ...(isPast && styles.pastStopIconContainer),
              ...(stop === trip.from && styles.firstStopIconContainer),
              ...(stop === trip.to && styles.lastStopIconContainer),
              ...(otherStops &&
                otherStops.length >= 2 &&
                styles.bigStopIconContainer),
              ...iconContainerStyles,
            }}
          >
            {(otherStops ? [stop, ...otherStops] : [stop]).map(
              (s, i, allStops) => (
                <span>
                  {s.index + 1}
                  {i !== allStops.length - 1 ? ',' : ''}
                </span>
              ),
            )}
          </div>
        </div>
        <div style={styles.stopArrow} />
        <div
          style={{ ...styles.stopInfos, ...(isOpen && styles.openStopInfos) }}
        >
          <div style={styles.stopHeader}>
            <HighlightedText>
              {getNextStopTime()
                ? moment(getNextStopTime()).format('HH:mm')
                : ''}
            </HighlightedText>
            <div style={styles.stopName}>{stopLabel(stop)}</div>
          </div>
          {isOpen && (
            <div style={styles.passengersContainer}>
              {focusedStop.demandIds?.length &&
                t('{{count}} passagers', {
                  count: focusedStop.demand,
                })}
              {focusedStop.demandIds?.map(demandId => {
                const demand = demandsById[demandId];
                if (!demand) {
                  return null;
                }
                return (
                  <div style={styles.stopPassenger}>
                    <div>
                      <Image
                        src={userIcon}
                        style={styles.passengerIcon}
                        inline
                      />
                      {demandLabel(demand, { stop })}
                    </div>
                    <div style={styles.passengerPhone}>
                      {demand.passenger?.phoneNumber ?? 'N/A'}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {isOpen && otherStops && (
            <div style={styles.otherStopTimesContainer}>
              {[stop].concat(otherStops).map((otherStop, index) => (
                <HighlightedText
                  style={{
                    ...styles.otherStopTime,
                    ...(otherStop.maxDate === focusedStop.maxDate
                      ? styles.focusedStopTime
                      : null),
                  }}
                  className="otherStopTime"
                  data-index={index}
                >
                  <Image
                    className="otherStopTime"
                    data-index={index}
                    style={styles.otherStopTimeClock}
                    src={clock}
                  />
                  {moment(otherStop.maxDate).format('HH:mm')}
                </HighlightedText>
              ))}
            </div>
          )}
        </div>
      </div>
    </MarkerWithLabel>
  );
});

const getMostRelevantStop = (trip, stops) => {
  const now = moment();
  const mostRelevantStop = minBy(stops, stop => {
    const tripDepartureTime = moment(trip.departureTime);
    const stopTime = moment(stop.maxDate).set({
      year: tripDepartureTime.year(),
      month: tripDepartureTime.month(),
      date: tripDepartureTime.date(),
    });
    if (stopTime.diff(now, 'minutes') < 10) {
      return 60 * 24 - stopTime.diff(now, 'minutes');
    }
    return stopTime.diff(now, 'minutes');
  });
  return mostRelevantStop;
};

const styles = {
  stopContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  stop: {
    backgroundColor: colors.WHITE,
    height: 39,
    width: 39,
    boxSizing: 'border-box',
    padding: 3,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: 2.5,
  },
  bigStop: {
    height: 57,
    width: 57,
  },
  stopIconContainer: {
    width: 33,
    height: 33,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontWeight: 'bold',
    color: colors.DARK_GREY,
    backgroundColor: colors.YELLOW,
    fontSize: 14,
  },
  pastStopIconContainer: {
    color: colors.WHITE,
  },
  firstStopIconContainer: {
    color: colors.WHITE,
  },
  lastStopIconContainer: {
    color: colors.WHITE,
  },
  bigStopIconContainer: {
    width: 51,
    height: 51,
    padding: 3,
  },
  stopArrow: {
    position: 'relative',
    top: 15,
    width: 0,
    height: 0,
    borderTop: '7px solid transparent',
    borderBottom: '7px solid transparent',
    borderRight: '7px solid',
    borderRightColor: colors.WHITE,
  },
  stopInfos: {
    backgroundColor: colors.WHITE,
    borderRadius: 10,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    color: colors.DARK_GREY,
    fontSize: 12,
    fontWeight: 'bold',
  },
  stopHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stopName: {
    marginLeft: 10,
  },
  passengersCount: {
    marginLeft: 10,
  },
  passengersContainer: {
    paddingTop: 10,
  },
  otherStopTimesContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    zIndex: 101,
  },
  otherStopTime: {
    padding: 0,
    paddingRight: 5,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  otherStopTimeClock: {
    top: 2,
  },
  focusedStopTime: {
    backgroundColor: colors.PRIMARY_0_1,
    color: colors.PRIMARY,
  },
  stopPassenger: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  passengerIcon: {
    marginRight: 6,
  },
  passengerPhone: {
    paddingLeft: 10,
  },
};

export default withi18n('dashboard')(StopMarker);

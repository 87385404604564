import { connect } from 'react-redux';
import { compose } from 'redux';

import commonActions from '../../../actions/common';

import SideMenu from './SideMenu';

const mapStateToProps = state => ({
  account: state.auth.account,
});

const mapDispatchToProps = dispatch => ({
  toggleDrawer: compose(dispatch, commonActions.toggleDrawer),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import UpcomingTripsSummary from './UpcomingTripsSummary';
import tripActions from '../../../actions/trip';
import dashboardActions from '../../../actions/dashboard';
import liveMapActions from '../../../actions/liveMap';

const mapStateToProps = (state, ownProps) => ({
  focusedTrips: state.dashboard.focusedTrips,
  loading: state.trip.loading || ownProps.loading,
});

const mapDispatchToProps = dispatch => ({
  clearTrips: compose(dispatch, tripActions.clearTrips),
  requestTripsInPeriod: compose(dispatch, tripActions.requestTripsInPeriod),
  focusTrip: trip => {
    dispatch(dashboardActions.focusTrips([trip]));
  },
  unfocusTrip: () => {
    dispatch(dashboardActions.unfocusTrips());
  },
  targetVehicle: compose(dispatch, liveMapActions.targetVehicle),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingTripsSummary);

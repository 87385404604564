import React, { memo, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import map from 'lodash/map';
import merge from 'lodash/merge';
import toArray from 'lodash/toArray';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import isUndefined from 'lodash/isUndefined';
import range from 'lodash/range';
import ObjectID from 'bson-objectid';
import memoize from 'memoize-one';
import {
  Table,
  Checkbox,
  Image,
  Segment,
  Input,
  Tab,
  FormInput,
  Button,
} from 'semantic-ui-react';
import memoizeOne from 'memoize-one';
import get from 'lodash/get';

import moment from 'weego-common/src/providers/moment';

import CRUDManager from '../common/CRUDManager';
import HighlightedText from '../common/HighlightedText';

import demandsActions from '../../actions/demands';
import passengersActions from '../../actions/passengers';
import groupsActions from '../../actions/groups';
import useSearch from '../../hooks/useSearch';

import userIcon from '../../assets/images/user-icon.svg';
import locationPinIcon from '../../assets/images/location-pin.svg';
import colors from '../../theme/colors.json';
import TimeRangeInput from '../../components/common/TimeRangeInput';

import { apiClient as passengersApiClient } from '../../sagas/passengers/passengersSaga';
import withi18n from 'weego-common/src/hoc/i18n';
import i18n from 'weego-common/src/providers/i18n';
import { DateInput } from 'semantic-ui-calendar-react';
import dateToString from '../../utils/date/dateToString';
import stringToDate from '../../utils/date/stringToDate';
import { fetchSavedFilters, saveFilters } from '../../utils/persistFilters';
import { keyBy } from 'lodash';

const FILTER_SCOPE = '_demands';

async function parsePassengerDefaultAddress(stringValue, options) {
  if (
    isEmpty(stringValue) ||
    stringValue === null ||
    stringValue.toUpperCase() === 'DEFAUT' ||
    stringValue.toUpperCase() === 'DÉFAUT' ||
    stringValue === ''
  ) {
    const { row, fieldMapping } = options;
    if (!fieldMapping.passenger) {
      return;
    }
    const clientSuppliedId = row[fieldMapping.passenger];
    const passenger = await getPassengerByClientSuppliedId(clientSuppliedId);
    if (!passenger || !passenger.address) {
      return;
    }
    return {
      ...passenger.address,
      type: 'DEFAULT_ADDRESS',
    };
  }
}

async function getPassengerByClientSuppliedId(clientSuppliedId) {
  if (!clientSuppliedId) {
    return null;
  }
  const response = await passengersApiClient.get('/', {
    filter: {
      where: {
        or: [
          {
            clientSuppliedId: String(clientSuppliedId),
          },
          {
            clientSuppliedId: String(parseInt(clientSuppliedId)),
          },
          {
            id: String(clientSuppliedId),
          },
        ],
      },
      limit: 1,
    },
  });
  if (!response.data) {
    throw new Error('Network error when getting passenger ' + clientSuppliedId);
  }
  const passenger = response.data[0];
  return passenger || null;
}

const fields = [
  {
    key: 'passenger',
    label: 'Passager',
    type: 'STRING',
    hideInForm: true,
    render: ({ record: demand }) => {
      if (!demand.passengerId && !isUndefined(demand.passengerCount)) {
        return `${demand.passengerCount} passagers`;
      }
      const { passenger } = demand;
      if (!passenger) {
        return i18n.t('crud~Non trouvé');
      }
      return `${passenger.firstName || ''} ${passenger.lastName || ''}\n ${
        passenger.phoneNumber || ''
      }`.trim();
    },
    parse: async rawValue => {
      return getPassengerByClientSuppliedId(rawValue);
    },
    constraints({ isImport }) {
      if (isImport) {
        return {
          presence: true,
        };
      }
    },
  },
  {
    key: 'departure',
    label: 'Lieu de départ',
    type: 'PLACE',
    hasDefaultAddress: true,
    omitSubFields: ['time'],
    inputStyles: {
      width: '80%',
    },
    constraints: {
      presence: {
        allowEmpty: false,
      },
      different: 'arrival',
    },
    parse: parsePassengerDefaultAddress,
  },
  {
    key: 'departure.time',
    label: 'Heure de départ',
    type: 'TIME',
    inputStyles: {
      width: '20%',
    },
    constraints: {
      datetime: true,
      different: 'arrival.time',
    },
    sortingKey: 'departureTime',
  },
  {
    key: 'arrival',
    label: "Lieu d'arrivée",
    type: 'PLACE',
    hasDefaultAddress: true,
    omitSubFields: ['time'],
    inputStyles: {
      width: '80%',
    },
    constraints: {
      presence: {
        allowEmpty: false,
      },
    },
    parse: parsePassengerDefaultAddress,
  },
  {
    key: 'arrival.time',
    label: "Heure d'arrivée",
    type: 'TIME',
    inputStyles: {
      width: '20%',
    },
    sortingKey: 'arrivalTime',
    constraints: {
      datetime: true,
    },
  },
  {
    key: 'endDate',
    label: 'Date du trajet',
    type: 'CUSTOM',
    render: ({ record, t }) => {
      // The backend only supports recurrent demands
      // but we can figure out which are only for a single
      // date and display them appropriately
      if (isSingleDate(record)) {
        return moment(record.endDate).format('DD/MM/YYYY');
      }
      if (!record.days || !record.days.length) {
        return t('Aucun jour sélectionné');
      }
      const days = record.days
        .map(day => upperFirst(moment().set('day', day).format('dd')))
        .join(', ');
      const start = moment(record.startDate).format('DD/MM/YYYY');
      const end = moment(record.endDate).format('DD/MM/YYYY');
      if (record.startDate && record.endDate) {
        return t('Tous les %{days} du %{start} au %{end}', {
          days,
          start,
          end,
        });
      } else if (record.startDate) {
        return t('Tous les %{days} à partir du %{start}', {
          days,
          start,
        });
      } else if (record.endDate) {
        return t("Tous les %{days} jusqu'au %{end}", {
          days,
          end,
        });
      }
      return t('Tous les %{days}', {
        days,
      });
    },
    parse: stringValue => {
      // We only support dates for excel import
      if (moment(stringValue, 'DD/MM/YYYY').isValid()) {
        return moment(stringValue, 'DD/MM/YYYY').toDate();
      } else {
        throw new Error('Invalid date format: ' + stringValue);
      }
    },
    renderInput: ({ record, onChange, t }) => {
      return (
        <div>
          <Segment>
            <Button.Group style={styles.recurrencePicker}>
              <Button
                type="button"
                primary={isSingleDate(record)}
                onClick={() => {
                  onChange({
                    ...record,
                    days: null,
                  });
                }}
              >
                {t('Pour une date unique')}
              </Button>
              <Button.Or text={t('ou')} />
              <Button
                type="button"
                primary={!isSingleDate(record)}
                onClick={() => {
                  onChange({
                    ...record,
                    days: [1, 2, 3, 4, 5],
                  });
                }}
              >
                {t('Dates répétées')}
              </Button>
            </Button.Group>
            <div style={styles.daysPicker}>
              {!isSingleDate(record)
                ? map(range(7), value => {
                    const active = (record.days || []).includes(value);

                    const toggleOption = () =>
                      onChange({
                        ...record,
                        days: active
                          ? record.days.filter(v => v !== value)
                          : (record.days || []).concat(value),
                      });

                    return (
                      <Button
                        key={value}
                        style={{
                          backgroundColor: active
                            ? colors.PRIMARY
                            : colors.WHITE,
                          border: '1px solid #e9eff4',
                          color: active ? colors.WHITE : colors.BLACK,
                        }}
                        active={active}
                        onClick={() => toggleOption()}
                        type="button"
                        size="tiny"
                      >
                        {upperFirst(moment().set('day', value).format('dd'))}
                      </Button>
                    );
                  })
                : null}
            </div>
            <div style={styles.endDatePicker}>
              {!isSingleDate(record) ? <span>{t('Date de fin')}</span> : null}
              <DateInput
                value={dateToString(record.endDate)}
                onChange={(event, { value }) => {
                  const date = stringToDate(value);
                  onChange({ ...record, endDate: date });
                }}
                closable={true}
                clearable={true}
                placeholder={
                  isSingleDate(record) ? t('Date du trajet') : t('Date de fin')
                }
              />
            </div>
          </Segment>
        </div>
      );
    },
    inputStyles: {
      width: '100%',
    },
  },
  {
    key: 'disabled',
    label: 'Désactivé',
    type: 'BOOLEAN',
    fontSize: 15,
    suffix: 'désactivé',
    disableInImport: true,
    hideInCreateForm: true,
  },
  {
    key: 'reactivationDate',
    label: 'Date de réactivation',
    type: 'DATE',
    disabled: record => !get(record, 'disabled', false),
    disableInImport: true,
    hideInCreateForm: true,
  },
  {
    key: 'status',
    label: 'Statut',
    type: 'ENUM',
    options: [
      {
        value: 'PLANNED',
        label: 'Planifié',
      },
      {
        value: 'UNPLANNED',
        label: 'En attente',
      },
      {
        value: 'DISABLED',
        label: 'Désactivé',
      },
    ],
    hideInForm: true,
    disableInImport: true,
    render: ({ record: demand, serialize = false }) => {
      const status = demand.disabled ? 'DISABLED' : demand.status;
      const outerFill = {
        DISABLED: colors.GRAY_17,
        PLANNED: colors.LEAFY_GREEN_17,
        UNPLANNED: colors.TANGERINE_17,
      };

      const innerFill = {
        DISABLED: colors.GRAY,
        PLANNED: colors.LEAFY_GREEN,
        UNPLANNED: colors.TANGERINE,
      };

      if (!serialize) {
        return (
          <div
            style={{
              ...styles.outerCircle,
              backgroundColor: outerFill[status],
            }}
          >
            <div
              style={{
                ...styles.innerCircle,
                backgroundColor: innerFill[status],
              }}
            ></div>
          </div>
        );
      } else {
        return status;
      }
    },
  },
  {
    key: 'createdAt',
    label: 'Date de création',
    type: 'DATETIME',
    inputStyles: {
      width: '20%',
    },
    sortingKey: 'createdAt',
    hideInForm: true,
    disableInImport: true,
  },
];
const resourceLabel = 'demande';

const style = { height: 'calc(100% - 80px)' };

const translateError = memoize(error => {
  if (!error) {
    return null;
  }
  if (error.code === 422) {
    return new Error("L'heure de départ ou l'heure d'arrivée est obligatoire");
  }
  return error;
});

const getRelations = memoize((passengers, groups) => ({ passengers, groups }));

const mapStateToProps = state => ({
  recordKey: 'id',
  records: state.demands,
  relations: getRelations(state.passengers, state.groups),
  resourceLabel,
  fields,
  style,
  passengers: state.passengers,
  userAccount: state.auth.account,
  ...state.demandsMeta,
  createError: translateError(state.demandsMeta.createError),
  updateError: translateError(state.demandsMeta.updateError),
  canImport: true,
});

const mapDispatchToProps = dispatch => ({
  fetchStart: data => {
    dispatch(
      demandsActions.fetchStart(
        merge({}, data, {
          query: {
            filter: {
              include: [{ relation: 'passenger', scope: { isDeleted: true } }],
            },
          },
        }),
      ),
    );
    dispatch(groupsActions.fetchStart());
  },
  createStart: compose(dispatch, demandsActions.createStart),
  updateStart: compose(dispatch, demandsActions.updateStart),
  deleteStart: compose(dispatch, demandsActions.deleteStart),
  fetchPassengers: compose(dispatch, passengersActions.fetchStart),
});

const DemandsManager = withi18n('trips')(
  memo(
    ({
      passengers,
      fetchStart,
      createStart,
      updateStart,
      userAccount,
      fields,
      fetchPassengers,
      t,
      ...props
    }) => {
      const savedFilters = fetchSavedFilters()?.[FILTER_SCOPE];
      const { timeRangeFilter, showB2CFilter, showExpiredFilter } =
        savedFilters || {};
      const [selectedPassengers, setSelectedPassengers] = useState([]);
      const [fieldsForRole, setFieldsForRole] = useState(fields);
      const [passengerCount, setPassengerCount] = useState('1');
      const [activeTabIndex, setActiveTabIndex] = useState(0);
      const [showB2C, setShowB2C] = useState(!!showB2CFilter);
      const [showExpired, setShowExpired] = useState(
        showExpiredFilter == null ? true : showExpiredFilter,
      );
      const [lastFetchStartData, setLastFetchStartData] = useState(null);
      const [timeRange, setTimeRange] = useState(
        timeRangeFilter || [
          moment().startOf('day').toDate(),
          moment().endOf('day').toDate(),
        ],
      );

      // Administrators can set groups on passengers
      useEffect(() => {
        if (
          userAccount?.roles?.includes('super_admin') ||
          userAccount?.roles?.includes('admin')
        ) {
          setFieldsForRole(
            fields.concat({
              key: 'groups',
              label: 'Groupes',
              type: ['REF'],
              ref: 'groups',
              refKey: 'name',
              disableInImport: true,
            }),
          );
        } else {
          setFieldsForRole(fields);
        }
      }, [fields, userAccount, setFieldsForRole]);

      // For single date demands, the record only comes
      // with endDate, we convert that back into a single day
      // recurrent trip because that's what the backend supports
      const createWithRecurrentFields = demand => {
        return createStart({
          ...demand,
          startDate: demand.days ? demand.startDate : demand.endDate,
          days: demand.days || [moment(demand.endDate).day()],
        });
      };

      const updateWithRecurrentFields = demand => {
        return updateStart({
          ...demand,
          startDate: demand.days ? demand.startDate : demand.endDate,
          days: demand.days || [moment(demand.endDate).day()],
        });
      };

      const createForSelectedPassengers = (demand, options) => {
        if (options.isImport) {
          demand.groups = demand.passenger?.groups;
          demand.passengerId = demand.passenger?.id;
          createWithRecurrentFields(demand);
        } else if (activeTabIndex === 1) {
          demand.passengerCount = parseInt(passengerCount, 10);
          createWithRecurrentFields(demand);
        } else {
          selectedPassengers.forEach(passenger => {
            const demandForPassenger = merge({}, demand, {
              id: ObjectID().toHexString(),
              passenger,
              passengerId: passenger.id,
              groups: passenger.groups,
            });
            if (
              demandForPassenger.departure &&
              demandForPassenger.departure.type === 'DEFAULT_ADDRESS'
            ) {
              demandForPassenger.departure = merge(
                demandForPassenger.departure,
                passenger.address,
              );
            }
            if (
              demandForPassenger.arrival &&
              demandForPassenger.arrival.type === 'DEFAULT_ADDRESS'
            ) {
              demandForPassenger.arrival = merge(
                demandForPassenger.arrival,
                passenger.address,
              );
            }
            createWithRecurrentFields(demandForPassenger);
          });
        }
      };

      const fetchWithFilters = useCallback(
        data => {
          fetchStart(
            merge(
              {},
              data,
              {
                where: {
                  and: [
                    {
                      or: [
                        {
                          and: [
                            {
                              departureTime: {
                                gte: moment(timeRange[0]).diff(
                                  moment(timeRange[0]).startOf('day'),
                                  'seconds',
                                ),
                              },
                            },
                            {
                              departureTime: {
                                lte: moment(timeRange[1]).diff(
                                  moment(timeRange[1]).startOf('day'),
                                  'seconds',
                                ),
                              },
                            },
                          ],
                        },
                        {
                          and: [
                            {
                              arrivalTime: {
                                gte: moment(timeRange[0]).diff(
                                  moment(timeRange[0]).startOf('day'),
                                  'seconds',
                                ),
                              },
                            },
                            {
                              arrivalTime: {
                                lte: moment(timeRange[1]).diff(
                                  moment(timeRange[1]).startOf('day'),
                                  'seconds',
                                ),
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
              showB2C && {
                where: {
                  companyId: { exists: false },
                },
              },
              !showExpired && {
                where: {
                  or: [
                    {
                      endDate: {
                        exists: false,
                      },
                    },
                    {
                      endDate: {
                        gte: moment().startOf('day').toDate(),
                      },
                    },
                    {
                      endDate: null,
                    },
                  ],
                },
              },
            ),
          );
          setLastFetchStartData(data);
        },
        [fetchStart, timeRange, showB2C, showExpired],
      );

      useEffect(() => {
        if (!lastFetchStartData) {
          return;
        }
        fetchWithFilters(lastFetchStartData);
        saveFilters(FILTER_SCOPE, {
          timeRangeFilter: timeRange,
          showB2CFilter: showB2C,
          showExpiredFilter: showExpired,
        });
      }, [
        showB2C,
        showExpired,
        lastFetchStartData,
        fetchWithFilters,
        timeRange,
        showExpiredFilter,
      ]);

      const [localForm, setLocalForm] = useState(null)

      return (
        <CRUDManager
          {...props}
          fields={fieldsForRole}
          canImport={true}
          addButtonLabel={t('Ajouter des demandes')}
          fetchStart={fetchWithFilters}
          createStart={createForSelectedPassengers}
          updateStart={updateWithRecurrentFields}
          changeHandler={record => {
            setLocalForm(record);
            return ({
            ...record,
            reactivationDate: record.disabled ? record.reactivationDate : null,
          })}}
          renderBeforeGlobalActions={() => (
            <div style={styles.checkboxesContainer}>
              {userAccount?.roles?.includes('super_admin') && (
                <Checkbox
                  label="B2C"
                  checked={showB2C}
                  style={styles.filterCheckbox}
                  onChange={(e, { checked }) => setShowB2C(checked)}
                />
              )}
              <Checkbox
                label={t('Expiré')}
                checked={showExpired}
                style={styles.filterCheckbox}
                onChange={(e, { checked }) => setShowExpired(checked)}
              />
            </div>
          )}
          renderAfterFilters={() => (
            <TimeRangeInput
              label={t('Heure')}
              style={styles.headerInput}
              value={timeRange}
              onChange={value => {
                setTimeRange(value);
              }}
            />
          )}
          renderAfterForm={({ isCreate, isImport }) =>
            isCreate &&
            !isImport && (
              <div style={styles.passengerDetails}>
                <Tab
                  panes={[
                    {
                      menuItem: t('Liste détaillée passagers'),
                      render: () => (
                        <PassengersSelectList
                          fetchPassengers={fetchPassengers}
                          // we only display passengers with the selected groups
                          passengers={keyBy(toArray(passengers).map(p => localForm?.groups?.includes(p?.groups?.[0]) ? p : null).filter(result => !!result), 'id')}
                          selectedPassengers={selectedPassengers}
                          onSelect={setSelectedPassengers}
                        />
                      ),
                    },
                    {
                      menuItem: t('Nombre passagers'),
                      render: () => (
                        <div style={styles.passengerCountInput}>
                          <FormInput
                            label={t('Nombre passagers')}
                            type="number"
                            value={passengerCount}
                            onChange={(e, { value }) =>
                              setPassengerCount(value)
                            }
                          />
                        </div>
                      ),
                    },
                  ]}
                  activeIndex={activeTabIndex}
                  onTabChange={(e, { activeIndex }) =>
                    setActiveTabIndex(activeIndex)
                  }
                />
              </div>
            )
          }
        />
      );
    },
  ),
);

const FUSE_OPTIONS = {
  keys: ['firstName', 'lastName', 'address.name', 'phoneNumber'],
  threshold: 0.3,
  distance: 200,
};

const passengersToArray = memoizeOne(toArray);

const PassengersSelectList = withi18n('trips')(
  memo(function PassengersSelectList({
    passengers = {},
    selectedPassengers = [],
    fetchPassengers,
    onSelect,
    t,
  }) {
    const [term, results, search] = useSearch(
      passengersToArray(passengers),
      FUSE_OPTIONS,
    );

    useEffect(() => {
      fetchPassengers();
    }, [fetchPassengers]);

    const toggleAllPassengersSelection = useCallback(() => {
      selectedPassengers.length === results.length
        ? onSelect([])
        : onSelect(clone(results));
    }, [selectedPassengers, results, onSelect]);

    return (
      <div style={styles.passengersSelectList}>
        <div style={styles.passengersSelectHeader}>
          <span>{t('Choisissez les passagers')} ({(selectedPassengers || []).length})</span>
          <div style={styles.passengersSelectSearch}>
            <Input
              icon="search"
              value={term}
              onChange={(e, { value }) => search(value)}
              style={styles.passengersSelectSearchInput}
            />
            <Checkbox
              checked={selectedPassengers.length === results.length}
              onClick={toggleAllPassengersSelection}
            />
          </div>
        </div>
        <Segment style={styles.passengersSelectSegment}>
          <Table fixed className="no-border">
            <Table.Body>
              {map(results, passenger => {
                const selected = !!selectedPassengers.find(
                  p => p.id === passenger.id,
                );
                return (
                  <Table.Row key={passenger.id}>
                    <Table.Cell width={6}>
                      <HighlightedText
                        style={styles.passengersSelectHighlightedText}
                      >
                        <Image style={styles.icon} inline src={userIcon} />
                        {passenger.firstName} {passenger.lastName}
                      </HighlightedText>
                    </Table.Cell>
                    <Table.Cell width={5}>
                      <HighlightedText
                        style={styles.passengersSelectHighlightedText}
                      >
                        <Image
                          style={styles.icon}
                          inline
                          src={locationPinIcon}
                        />
                        {passenger.address?.name}
                      </HighlightedText>
                    </Table.Cell>
                    <Table.Cell width={4}>{passenger.phoneNumber}</Table.Cell>
                    <Table.Cell
                      width={1}
                      style={styles.passengersSelectCheckboxCell}
                    >
                      <Checkbox
                        checked={selected}
                        onChange={() =>
                          onSelect(
                            selected
                              ? selectedPassengers.filter(
                                  p => p.id !== passenger.id,
                                )
                              : selectedPassengers.concat(passenger),
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }),
);

const styles = {
  passengerDetails: {
    marginTop: 20,
  },
  passengersSelectList: {
    paddingTop: 33,
    paddingLeft: 12.5,
  },
  passengersSelectHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.GREY_TEXT,
  },
  passengersSelectSearch: {
    paddingRight: 35,
  },
  passengersSelectSearchInput: {
    marginRight: 10,
  },
  passengersSelectSegment: {
    maxHeight: 230,
    overflow: 'auto',
    marginTop: 10,
    paddingTop: 0,
    paddingBottom: 0,
  },
  passengersSelectHighlightedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
  },
  passengersSelectCheckboxCell: {
    textOverflow: 'unset',
  },
  passengerCountInput: {
    padding: 10,
  },
  icon: {
    marginRight: 10,
  },
  checkboxesContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    display: 'flex',
  },
  filterCheckbox: {
    marginLeft: 5,
  },
  headerInput: {
    marginLeft: 15,
  },
  outerCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: 14,
  },
  innerCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  recurrencePicker: {
    marginBottom: 10,
  },
  endDatePicker: {
    marginTop: 10,
  },
};

const isSingleDate = demand => {
  if (!demand.days) {
    return true;
  }
  return !!(
    demand.endDate &&
    (!demand.days || demand.days.length === 1) &&
    (!demand.startDate ||
      moment(demand.startDate).isSame(demand.endDate, 'date'))
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandsManager);

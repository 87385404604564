import React, { memo, useMemo, useState } from 'react';
import { Search } from 'semantic-ui-react';
import map from 'lodash/map';
import debounce from 'lodash/debounce';

import passengerLabel from '../../../utils/passengerLabel';

const PassengerSearch = memo(function PassengerSearch({
  loading,
  passengers,
  onSearch,
  onSelect,
  placeholder,
}) {
  const [term, setTerm] = useState('');

  const onSearchDebounced = useMemo(() => debounce(onSearch, 1000), [onSearch]);

  return (
    <Search
      fluid
      value={term}
      onSearchChange={(event, { value }) => {
        setTerm(value);
        onSearchDebounced(value);
      }}
      onResultSelect={(event, { result }) => {
        const passenger = passengers[result.id];
        onSelect(passenger);
        setTerm(passengerLabel(passenger));
      }}
      loading={loading}
      results={map(passengers, p => ({
        id: p.id,
        key: p.id,
        title: passengerLabel(p),
      }))}
      placeholder={placeholder || 'Passager'}
    />
  );
});

export default PassengerSearch;

import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import demandsActions from '../../../actions/demands';
import tripEventsActions from '../../../actions/tripEvents';

import TripDetails from './TripDetails';

const mapStateToProps = state => ({
  tripEvents: state.tripEvents,
});

const mapDispatchToProps = dispatch => ({
  fetchDemands: compose(dispatch, demandsActions.fetchStart),
  fetchTripEvents: compose(dispatch, tripEventsActions.fetchStart),
});

export default connect(mapStateToProps, mapDispatchToProps)(TripDetails);

import isUndefined from 'lodash/isUndefined';

import passengerLabel from './passengerLabel';

function demandLabel(demand, { stop } = {}) {
  return demand.passenger
    ? passengerLabel(demand?.passenger)
    : !isUndefined(demand.passengerCount)
    ? `${demand.passengerCount * Math.sign(stop.demand)} passagers`
    : 'Non trouvé';
}

export default demandLabel;

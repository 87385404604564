import {handleActions} from 'redux-actions';

export default function makeCrudMetaReducer(crudActions) {
  const DEFAULT_STATE = {
    loading: false,
    fetching: false,
    creating: false,
    updating: false,
    deleting: false,
    error: null,
    fetchError: null,
    createError: null,
    updateError: null,
    deleteError: null,
    count: null
  };

  return handleActions(
    {
      [crudActions.fetchStart]: state => ({
        ...state,
        error: null,
        fetchError: null,
        loading: true,
        fetching: true,
      }),
      [crudActions.fetchSuccess]: (state, action) => ({
        ...state,
        error: null,
        fetchError: null,
        loading: false,
        fetching: false,
        count: action && action.data?.count
      }),
      [crudActions.fetchError]: (state, action) => ({
        ...state,
        error: action.error,
        fetchError: action.error,
        loading: false,
        fetching: false,
      }),
      [crudActions.createStart]: (state, action) =>
        action.data?.silent
          ? state
          : {
              ...state,
              error: null,
              createError: null,
              loading: true,
              creating: true,
            },
      [crudActions.createSuccess]: (state) => ({
        ...state,
        error: null,
        createError: null,
        loading: false,
        creating: false,
        count: state.count + 1,
      }),
      [crudActions.createError]: (state, action) => ({
        ...state,
        error: action.error,
        createError: action.error,
        loading: false,
        creating: false,
      }),
      [crudActions.updateStart]: (state, action) =>
        action.data?.silent
          ? state
          : {
              ...state,
              error: null,
              updateError: null,
              loading: true,
              updating: true,
            },
      [crudActions.updateSuccess]: state => ({
        ...state,
        error: null,
        updateError: null,
        loading: false,
        updating: false,
      }),
      [crudActions.updateError]: (state, action) => ({
        ...state,
        error: action.error,
        updateError: action.error,
        loading: false,
        updating: false,
      }),
      [crudActions.deleteStart]: (state, action) =>
        action.data?.silent
          ? state
          : {
              ...state,
              error: null,
              deleteError: null,
              loading: true,
              deleting: true,
            },
      [crudActions.deleteSuccess]: (state) => ({
        ...state,
        error: null,
        deleteError: null,
        loading: false,
        deleting: false,
        count: state.count - 1,
      }),
      [crudActions.deleteError]: (state, action) => ({
        ...state,
        error: action.error,
        deleteError: action.error,
        loading: false,
        deleting: false,
      }),
    },
    DEFAULT_STATE,
  );
}

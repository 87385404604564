import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import CRUDManager from '../common/CRUDManager';
import groupsActions from '../../actions/groups';

const fields = [
  {
    key: 'name',
    label: 'Nom',
    type: 'STRING',
  },
];
const resourceLabel = 'groupe';

const style = { height: 'calc(100% - 80px)' };

const mapStateToProps = state => ({
  records: state.groups,
  resourceLabel,
  fields,
  style,
  ...state.groupsMeta,
  canImport: true,
});

const mapDispatchToProps = dispatch => ({
  fetchStart: compose(dispatch, groupsActions.fetchStart),
  createStart: compose(dispatch, groupsActions.createStart),
  updateStart: compose(dispatch, groupsActions.updateStart),
  deleteStart: compose(dispatch, groupsActions.deleteStart),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDManager);

import {create} from 'apisauce';
import defaultIoFn from 'socket.io-client';
import ee from 'event-emitter';
import ExtendableError from 'es6-error';
import toArray from 'lodash/toArray';

import TrackingHandler from './TrackingHandler';

import handleResponseError from '../utils/handleResponseError';

class TrackingClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - url of the tracking service
   * @param {Object} options - other options
   * @param {Function} [options.io] - custom socket io constructor function (defaults to io from socketio.client)
   * @memberof Client
   */
  constructor(endpoint, {tokens, io}) {
    this.endpoint = endpoint;
    this.tokens = tokens;
    this.io = io || defaultIoFn;
    this.api = create({
      baseURL: `${endpoint}/tracking`,
    });
  }

  /**
   * Start tracking the user's movements
   *
   * @memberof Client
   */
  async track() {
    const {endpoint} = this;
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    if (!token) {
      throw new TypeError("Can't track before user is authenticated");
    }
    const socket = this.io(endpoint, {
      path: '/tracking/ws',
    });
    const handler = new TrackingHandler(socket, token);
    return handler;
  }

  async getLatestPositions(company, line, direction) {
    return toArray(
      await this.getPositions(company, line, {
        direction,
      }),
    ).map(busPositions => busPositions[0]);
  }

  async getPositions(company, line, {direction, startDate, endDate, limit}) {
    const {api} = this;
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    const response = await api.get(
      '/positions',
      {
        company,
        number: line,
        direction,
        startDate,
        endDate,
        limit,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.positions;
  }

  async getRealtimeLines() {
    const {api} = this;
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    const response = await api.get(
      '/positions/realtime-lines',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    handleResponseError(response);
    return response.data.lines;
  }

  async getDirections(parameters) {
    const {api} = this;
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    const response = await api.get('/directions', parameters, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    handleResponseError(response);
    return response.data;
  }

  async publishPosition(position) {
    const {api} = this;
    const token = await this.tokens.get('ACCOUNT_VERIFICATION');
    const response = await api.post('/positions', position, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    handleResponseError(response);
    return response.data.lines;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/tracking`);
  }
}

class EndOfServiceError extends ExtendableError {
  constructor(startsIn) {
    super('End of service for this line');
    this.startsIn = startsIn;
  }
}

ee(TrackingClient.prototype);
export {EndOfServiceError};

export default TrackingClient;

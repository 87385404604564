import React, { memo, useState, useEffect } from 'react';
import { Button, Message } from 'semantic-ui-react';
import isArray from 'lodash/isArray';
import withi18n from 'weego-common/src/hoc/i18n';

const RouteImporter = memo(
  ({ routes = [], openedFile, openLocalFile, saveLocalFile, onImport, t }) => {
    const [importError, setImportError] = useState(null);

    useEffect(() => {
      if (!openedFile) {
        return;
      }
      try {
        setImportError(null);
        const routes = JSON.parse(openedFile.text, null, 2);
        if (!isArray(routes)) {
          throw new Error(t('Erreur de syntaxe. Doit être un tableau JSON'));
        }
        onImport(routes);
      } catch (e) {
        if (e.name === 'SyntaxError') {
          setImportError(
            new Error(
              t('Erreur de syntaxe dans le fichier de demandes importé'),
            ),
          );
        } else {
          setImportError(e);
        }
      }
    }, [openedFile, onImport, t]);
    return (
      <div>
        {importError && (
          <Message error>
            {importError.message || t("Erreur inconnue lors de l'import")}
          </Message>
        )}
        <div style={styles.actions}>
          <Button
            primary
            onClick={() =>
              openLocalFile({ type: 'text', accept: 'application/json' })
            }
          >
            {t('Importer')}
          </Button>
          <Button
            primary
            onClick={() =>
              saveLocalFile(JSON.stringify(routes), {
                name: 'routes.json',
              })
            }
          >
            {t('Exporter')}
          </Button>
        </div>
      </div>
    );
  },
);

const styles = {
  stop: {
    display: 'inline-block',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default withi18n('planner')(RouteImporter);

import React, { Component } from 'react';
import validate from 'validate.js';
import moment from 'moment';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SemanticToastContainer } from 'react-semantic-toasts';

import i18n from 'weego-common/src/providers/i18n';

import fr from './lang/fr';
import en from './lang/en';

import './assets/css/rc-steps-custom.css';
import 'rc-steps/assets/iconfont.css';
import 'react-phone-input-2/lib/semantic-ui.css';
import './semantic/dist/semantic.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

import configureStore from './store';

import Screens from './screens';
import AlertsPortal from './components/common/AlertsPortal';
import './App.css';
import './auth.css';

i18n.locale = navigator.languages ? navigator.languages[0] : navigator.language;
i18n.translations = { fr, en };

class App extends Component {
  store = configureStore();
  render() {
    return (
      <Provider store={this.store}>
        <Router>
          <div className="App">
            <div style={{ position: 'absolute', top: 30, right: 30 }}>
              <SemanticToastContainer />
            </div>
            <Screens />
            <AlertsPortal />
          </div>
        </Router>
      </Provider>
    );
  }
}

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return moment.utc(value).format(format);
  },
});

export default App;

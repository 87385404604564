import { connect } from 'react-redux';

import passengersActions from '../../../actions/passengers';

import PassengerSearch from './PassengerSearch';

const mapStateToProps = state => ({
  passengers: state.passengers,
});

const mapDispatchToProps = dispatch => ({
  onSearch(term) {
    dispatch(
      passengersActions.fetchStart({
        replace: true,
        query: {
          ignoreCompanyFilter: true,
          filter: {
            where: {
              or: [
                {
                  firstName: { regexp: `/${term}/i` },
                },
                {
                  lastName: { regexp: `/${term}/i` },
                },
                {
                  'address.name': { regexp: `/${term}/i` },
                },
                {
                  phoneNumber: { regexp: `/${term}/i` },
                },
              ],
            },
          },
        },
      }),
    );
  },
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(PassengerSearch);

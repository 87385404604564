import { createActions } from 'redux-actions';

const { notification } = createActions({
  NOTIFICATION: {
    SEND_NOTIFICATION: notification => ({ notification }),
    SEND_NOTIFICATION_SUCCESS: () => undefined,
    SEND_NOTIFICATION_ERROR: error => error,
  },
});

export default notification;

import { takeLatest, all, put } from 'redux-saga/effects';
import Promise from 'bluebird';
import omit from 'lodash/omit';

import weegoClient from '../../providers/weego-client';
import notificationActions from '../../actions/notification';

function* sendNotification({ payload }) {
  try {
    const { notification } = payload;
    yield Promise.map(notification.recipients, async recipient => {
      await weegoClient.notification.sendNotification({
        ...omit(notification, 'recipients'),
        phoneNumber: recipient.phoneNumber,
      });
    });
    yield put(notificationActions.sendNotificationSuccess());
  } catch (e) {
    console.error(e);
    yield put(notificationActions.sendNotificationError(e));
  }
}

function* notificationRootSaga() {
  yield all([
    takeLatest(
      notificationActions.sendNotification.toString(),
      sendNotification,
    ),
  ]);
}

export default notificationRootSaga;

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import VehiclesManager from '../../../components/vehicles/VehiclesManager';

class VehiclesScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Véhicules');
  }

  render() {
    return <VehiclesManager />;
  }
}

export default withi18n('menu')(VehiclesScreen);

import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import customFieldsActionCreators, {
  actionTypes,
} from '../../actions/customFields';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const customFieldsSaga =
  decodedToken && decodedToken.company
    ? makeCrudSaga(
        'customFields',
        actionTypes,
        customFieldsActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL +
          `/ride-booking/customFields`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: true,
          key: 'id',
        },
      )
    : () => {};

export default customFieldsSaga;

import { combineReducers } from 'redux';

import i18n from 'weego-common/src/hoc/i18n';

import vehicles from './vehicles';
import vehiclesMeta from './vehiclesMeta';
import drivers from './drivers';
import driversMeta from './driversMeta';
import stops from './stops';
import stopsMeta from './stopsMeta';
import groups from './groups';
import groupsMeta from './groupsMeta';
import accounts from './accounts';
import accountsMeta from './accountsMeta';
import passengers from './passengers';
import passengersMeta from './passengersMeta';
import demands from './demands';
import demandsMeta from './demandsMeta';
import transactions from './transactions';
import transactionsMeta from './transactionsMeta';
import customFields from './customFields';
import customFieldsMeta from './customFieldsMeta';
import auth from './auth';
import trip from './trip';
import registry from './registry';
import tripEvents from './tripEvents';
import liveMap from './liveMap';
import dashboard from './dashboard';
import notification from './notification';
import payment from './payment';
import common from './common';
import alert from './alert';

export default combineReducers({
  vehicles,
  vehiclesMeta,
  drivers,
  driversMeta,
  stops,
  stopsMeta,
  groups,
  groupsMeta,
  accounts,
  accountsMeta,
  passengers,
  passengersMeta,
  demands,
  demandsMeta,
  transactions,
  transactionsMeta,
  customFields,
  customFieldsMeta,
  auth,
  trip,
  registry,
  tripEvents,
  liveMap,
  dashboard,
  notification,
  payment,
  common,
  alert,
  i18n,
});

import TrackingClient from './Tracking/TrackingClient';
import RoutesClient from './Routes/RoutesClient';
import AuthClient from './Auth/AuthClient';
import PaymentClient from './Payment/PaymentClient';
import GameClient from './Game/GameClient';
import RideBookingClient from './RideBooking/RideBookingClient';
import ShopClient from './Shop/ShopClient';
import SponsoringClient from './Sponsoring/SponsoringClient';
import ScriptsClient from './Scripts/ScriptsClient';
import TokenRepository from './TokenRepository';
import RouteBuildingClient from './RouteBuilding/RouteBuildingClient';
import TrafficClient from './Traffic/TrafficClient';
import TTSClient from './TTS/TTSClient';
import NotificationClient from './Notification/NotificationClient';
import FASClient from './FAS/FASClient';
import PlanningClient from './Planning/PlanningClient';
import PromosClient from './Promos/PromosClient';

class Client {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - url of the service
   * @param {Object} options - client options
   * @param {Function} [options.io] - custom socket io constructor function (defaults to io from socketio.client)
   * @param {Object} [options.storage] - storage object with read and write functions (used for persisting information to disk)
   * @memberof Client
   */
  constructor(endpoint, options) {
    this.endpoint = endpoint;
    this.tokenRepo = new TokenRepository(endpoint);
    this.tracking = new TrackingClient(endpoint, {
      tokens: this.tokenRepo,
      io: options.io,
    });
    this.routes = new RoutesClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.auth = new AuthClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.payment = new PaymentClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.game = new GameClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.shop = new ShopClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.sponsoring = new SponsoringClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.scripts = new ScriptsClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.routeBuilding = new RouteBuildingClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.rideBooking = new RideBookingClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.tts = new TTSClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.traffic = new TrafficClient(endpoint);
    this.notification = new NotificationClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.fas = new FASClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.planning = new PlanningClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.promos = new PromosClient(endpoint, {
      tokens: this.tokenRepo,
    });
    this.options = options;
    this._restoreTokens();
    this._setupTokenRepoListeners();
    this._setupTrackingPointsListener();
  }

  async updateEndpoint(newEndpoint) {
    this.endpoint = newEndpoint;
    [
      this.tracking,
      this.routes,
      this.auth,
      this.payment,
      this.game,
      this.shop,
      this.sponsoring,
      this.scripts,
      this.routeBuilding,
      this.rideBooking,
      this.traffic,
      this.tokenRepo,
      this.tts,
      this.notification,
      this.fas,
      this.planning,
      this.promos,
    ].forEach(client => client.updateEndpoint(newEndpoint));
  }

  async _restoreTokens() {
    const tokens = await this.options.storage.read('tokens');
    this.tokenRepo.load(tokens);
  }

  _setupTokenRepoListeners() {
    this.tokenRepo.on('update', async tokens => {
      await this.options.storage.write('tokens', tokens);
    });
  }

  _setupTrackingPointsListener() {
    this.tracking.on('points', token => {
      this.payment.credit(token);
    });
  }
}
export default Client;

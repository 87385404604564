function driverLabel(
  driver,
  { displayPhoneNumber, displayRegistrationNumber } = {
    displayPhoneNumber: false,
    displayRegistrationNumber: false,
  },
) {
  if (!driver || (!driver.firstname && !driver.lastname)) {
    return '';
  }
  return `${
    displayRegistrationNumber && driver.registrationNumber
      ? driver.registrationNumber + ' - '
      : ''
  } ${driver.firstname || ''} ${driver.lastname || ''} ${
    displayPhoneNumber ? ` - ${driver.phone}` : ''
  }`.trim();
}

export default driverLabel;

import moment from 'weego-common/src/providers/moment';

const stringToDate = string => {
  if (!string) {
    return null;
  }

  return moment(string, 'DD-MM-YYYY').toDate();
};

export default stringToDate;

import moment from 'weego-common/src/providers/moment';

const formatDateRange = dateRange => {
  if (!dateRange[0] && !dateRange[1]) {
    return '';
  }
  return `${dateRange[0] ? moment(dateRange[0]).format('DD-MM-YYYY') : ''} - ${
    dateRange[1] ? moment(dateRange[1]).format('DD-MM-YYYY') : ''
  }`;
};

export default formatDateRange;

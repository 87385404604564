import { connect } from 'react-redux';
import { compose } from 'recompose';

import tripActions from '../../../actions/trip';

import RoutesSaver from './RoutesSaver';

const mapStateToProps = state => ({
  importing: state.trip.importing,
  error: state.trip.importError,
});

const mapDispatchToProps = dispatch => ({
  importTrips: compose(dispatch, tripActions.importTrips),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoutesSaver);

import { create } from 'apisauce';

import handleResponseError from '../utils/handleResponseError';

class ShopClient {
  /**
   * Creates an instance of Client.
   * @param {string} endpoint - root url of the sunubus service
   * @memberof ShopClient
   */
  constructor(endpoint, { tokens }) {
    this.endpoint = endpoint;
    this.api = create({
      baseURL: `${endpoint}/shop`,
    });
    this.tokens = tokens;
  }

  /**
   * Get the list of items
   *
   * @returns {Array<Item>} - the list of items
   * @memberof ShopClient
   */
  async getItems() {
    const { api } = this;
    const response = await api.get('/items');
    handleResponseError(response);
    const items = response.data;
    return items;
  }

  /**
   * Buy an item
   *
   * @param {Number} - the id of the item to buy
   * @returns {Item} - the bought item
   * @memberof ShopClient
   */
  async buy(itemId) {
    const { api } = this;
    const response = await api.post(
      `/items/${itemId}/buy`,
      {},
      {
        headers: {
          Authorization: `Bearer ${await this.tokens.get(
            'PURCHASE_SHOP_ITEM',
          )}`,
        },
      },
    );
    handleResponseError(response);
    const item = response.data;
    return item;
  }

  async updateEndpoint(endpoint) {
    this.endpoint = endpoint;
    this.api.setBaseURL(`${endpoint}/shop`);
  }
}

export default ShopClient;

/**
 * @typedef {Object} Item
 * @property {String} category - The category of the item
 * @property {String} label - The label of the item
 * @property {String} image - The image of the item
 * @property {Number} price - The price of the item
 */

import React, { memo, useEffect, useState } from 'react';
import { Form, Modal, Button, Table, Card, Icon } from 'semantic-ui-react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import isPlainObject from 'lodash/isPlainObject';
import { DateTimeInput } from 'semantic-ui-calendar-react';

import moment from 'weego-common/src/providers/moment';
import withi18n from 'weego-common/src/hoc/i18n';
import isUndefined from 'lodash/isUndefined';

import getNextDateOfDay from '../../../utils/date/getNextDateOfDay';
import withFileHandlers from '../../../hoc/withFileHandlers';
import tryJsonParse from 'weego-common/src/utils/tryJsonParse';

const PlannerOptions = memo(function PlannerOptions({
  groups,
  options = {},
  legacy,
  openedFile,
  requestGroups,
  onChange,
  onTmCacheReset,
  saveLocalFile,
  openLocalFile,
  t,
} = {}) {
  useEffect(() => {
    requestGroups();
  }, [requestGroups]);
  const [advancedOptionsOpened, setAdvancedOptionsOpened] = useState(false);

  useEffect(() => {
    if (!openedFile) {
      return;
    }
    const fileContent = openedFile.text;
    if (!fileContent) {
      alert(t('Aucun texte trouvé dans le fichier'));
      return;
    }
    const importedOptions = tryJsonParse(fileContent);
    if (!importedOptions || !isPlainObject(importedOptions)) {
      alert(t("Format d'options invalide"));
      return;
    }
    onChange({
      ...options,
      ...omit(importedOptions, 'selectedDay'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedFile]);

  return (
    <Card style={styles.optionsCard}>
      <Card.Content header={t('Options')} />
      <Card.Content>
        <div>
          <Form.Select
            label={t('Jours')}
            options={daysOptions.map((dayOption, index) => ({
              key: dayOption.value,
              value: dayOption.value,
              text: `${dayOption.text} - ${moment(
                getNextDateOfDay(dayOption.value),
              ).format('DD/MM/YYYY')}`,
            }))}
            value={
              !isUndefined(options.selectedDay) ? options.selectedDay : null
            }
            onChange={(e, { value }) =>
              onChange({
                ...options,
                selectedDay: value,
              })
            }
          />
          <Form.Select
            label={t('Groupes')}
            selection
            multiple
            options={[
              {
                key: 'ALL_GROUPS',
                value: 'ALL_GROUPS',
                text: t('Tous les groupes'),
              },
              {
                key: 'GROUPLESS',
                value: 'GROUPLESS',
                text: t('Sans groupe'),
              },
            ]
              .concat(
                map(groups, group => ({
                  key: group._id,
                  value: group._id,
                  text: group.name,
                })),
              )
              .concat({
                key: 'B2C',
                value: 'B2C',
                text: 'B2C',
              })}
            value={options.selectedGroups || []}
            onChange={(e, { value }) =>
              onChange({
                ...options,
                selectedGroups: value,
              })
            }
          />
          <Form.Select
            label={t('Periode')}
            selection
            options={[
              {
                key: 'am',
                // Form.Select does not support array values unless multiple
                value: JSON.stringify([0, 14]),
                text: t('Matin'),
              },
              {
                key: 'pm',
                // Form.Select does not support array values unless multiple
                value: JSON.stringify([14, 23]),
                text: t('Soir'),
              },
            ]}
            // Form.Select does not support array values unless multiple
            value={JSON.stringify(options.selectedTimeRange) || null}
            onChange={(e, { value }) =>
              onChange({
                ...options,
                // Form.Select does not support array values unless multiple
                selectedTimeRange: JSON.parse(value),
              })
            }
          />

          <Button
            style={styles.advancedOptionsButton}
            type="button"
            onClick={() => setAdvancedOptionsOpened(true)}
          >
            {t('Options avancées')}
          </Button>

          <Modal
            open={advancedOptionsOpened}
            onClose={() => setAdvancedOptionsOpened(false)}
            size="large"
          >
            <Modal.Content>
              {legacy && (
                <Form>
                  <DateTimeInput
                    value={dateToString(options.departureTime)}
                    onChange={(event, { value }) => {
                      const time = stringToDate(value);
                      onChange({
                        ...options,
                        departureTime: time,
                      });
                    }}
                    label={t('Date Matrice DT')}
                    closable={true}
                    clearable={true}
                    minDate={new Date()}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      label={t('Clé Matrice DT')}
                      placeholder={t('Clé Matrice DT')}
                      value={options.timeMapKey}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          timeMapKey: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('Multiplicateur Matrice DT')}
                      placeholder={t('Multiplicateur Matrice DT')}
                      value={options.timeMultiplier}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          timeMultiplier: value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      label={t('MTM')}
                      placeholder={t('Multiplicateur Trajet Max')}
                      value={options.maxTimeMultiplier}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          maxTimeMultiplier: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('OTC')}
                      placeholder={t('Coefficient Temps Supp.')}
                      value={options.overtimePenaltyCoef}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          overtimePenaltyCoef: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('OTP')}
                      placeholder={t('Pénalité Temps Supp.')}
                      value={options.overtimePenalty}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          overtimePenalty: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('MT')}
                      placeholder={t('Max Time')}
                      value={options.maxTime}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          maxTime: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('MTS')}
                      placeholder={t('Max Time Soft')}
                      value={options.maxTimeSoft}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          maxTimeSoft: value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      label={t('DCC')}
                      placeholder={t('Coefficient Cout Distance')}
                      value={options.distanceCostCoefficient}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          distanceCostCoefficient: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('ODP')}
                      placeholder={t('Overdistance Penalty')}
                      value={options.overdistancePenalty}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          overdistancePenalty: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('ODC')}
                      placeholder={t('Overdistance Penalty Coef')}
                      value={options.overdistancePenaltyCoef}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          overdistancePenaltyCoef: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('TL')}
                      placeholder={t('Time Limit')}
                      value={options.timeLimit}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          timeLimit: value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Checkbox
                      checked={options.ignoreVehicleDepots}
                      label={t('Ignorer dépôts')}
                      onChange={(e, { checked }) => {
                        onChange({
                          ...options,
                          ignoreVehicleDepots: checked,
                        });
                      }}
                    />
                    <Form.Checkbox
                      checked={options.splitMultiPassengerDemands}
                      label={t('Séparer les demandes')}
                      onChange={(e, { checked }) => {
                        onChange({
                          ...options,
                          splitMultiPassengerDemands: checked,
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              )}
              {legacy && (
                <Button primary onClick={onTmCacheReset}>
                  {t('Réinitialiser Matrice DT')}
                </Button>
              )}
              {!legacy && (
                <Form>
                  <Form.Group widths="equal">
                    <Form.Input
                      label={t('MTM')}
                      placeholder={t('Multiplicateur Trajet Max')}
                      value={options.maxTimeMultiplier}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          maxTimeMultiplier: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('MT')}
                      placeholder={t('Max Time')}
                      value={options.maxTime}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          maxTime: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('Max Delay')}
                      placeholder={t('Max Delay')}
                      value={options.maxDelay}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          maxDelay: value,
                        })
                      }
                    />
                    <Form.Input
                      label={t('Niveau Traffic (défaut)')}
                      placeholder={t('Niveau Traffic (défaut)')}
                      value={options.defaultTimeMultiplier}
                      onChange={(e, { value }) =>
                        onChange({
                          ...options,
                          defaultTimeMultiplier: value,
                        })
                      }
                    />
                  </Form.Group>
                </Form>
              )}
              {!legacy && (
                <Table>
                  <Table.Header>
                    <Table.HeaderCell>{t('Zone')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Niveau Traffic')}</Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {options.zones?.map(zone => (
                      <Table.Row key={zone.name}>
                        <Table.Cell>{zone.name}</Table.Cell>
                        <Table.Cell>
                          <Form.Input
                            type="number"
                            placeholder={t('Niveau Traffic')}
                            value={zone.timeMultiplier}
                            onChange={(e, { value }) => {
                              onChange({
                                ...options,
                                zones: options.zones?.map(z =>
                                  z === zone
                                    ? {
                                        ...z,
                                        timeMultiplier: parseFloat(value),
                                      }
                                    : z,
                                ),
                              });
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </Modal.Content>
          </Modal>
        </div>
      </Card.Content>{' '}
      <Card.Content extra textAlign="right">
        <Button
          icon
          onClick={() => {
            saveLocalFile(JSON.stringify(options, null, 2), {
              name: 'Planner Options.json',
            });
          }}
        >
          <Icon name="download" />
        </Button>
        <Button
          icon
          onClick={() => {
            openLocalFile({
              accept: 'application/json',
              type: 'text',
            });
          }}
        >
          <Icon name="upload" />
        </Button>
      </Card.Content>
    </Card>
  );
});

const styles = {
  advancedOptionsButton: {
    marginTop: 10,
  },
  optionsCard: {
    marginRight: 10,
  },
};

export default withFileHandlers()(withi18n('planner')(PlannerOptions));

const daysOptions = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 0,
  },
].map(option => ({
  ...option,
  text: upperFirst(moment().set('day', option.value).format('dddd')),
}));

const dateToString = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD-MM-YYYY HH:mm');
};

const stringToDate = string => {
  if (!string) {
    return null;
  }
  return moment(string, 'DD-MM-YYYY HH:mm').toDate();
};

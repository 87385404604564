import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import tripEventsActionCreators, {
  actionTypes,
} from '../../actions/tripEvents';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const tripEventsSaga = makeCrudSaga(
  'tripEvents',
  actionTypes,
  tripEventsActionCreators,
  process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/tripEvents`,
  {
    token:
      tokens &&
      tokens.ACCOUNT_VERIFICATION &&
      tokens.ACCOUNT_VERIFICATION.token,
    key: 'id',
    useRootResponse: true,
  },
);

export default tripEventsSaga;

import React, { memo, useState, useCallback, useEffect } from 'react';
import {
  Card,
  Button,
  Image,
  Header,
  Dropdown,
  Segment,
  Form,
  Icon,
  Divider,
} from 'semantic-ui-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import ObjectID from 'bson-objectid';
import { TimeInput } from 'semantic-ui-calendar-react';
import { ReactSVG } from 'react-svg';

import stopLabel from '../../../utils/stopLabel';
import demandLabel from '../../../utils/stopDemandLabel';
import getTripInstanceKey from './getTripInstanceKey';

import HighlightedText from '../../common/HighlightedText';
import PassengerSearch from '../../passengers/PassengerSearch';
import PlacesAutocompleteInput from '../../common/PlacesAutocompleteInput';

import jamBusIcon from '../../../assets/images/jam-bus.svg';
import userIcon from '../../../assets/images/user-icon.svg';
import locationPinIcon from '../../../assets/images/location-pin.svg';
import threeDotMenuIcon from '../../../assets/images/three-dot-menu.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';

import colors from '../../../theme/colors.json';

import './TripTimeline.css';
import DialogBox from '../../common/DialogBox';
import dateToTimeString from '../../../utils/date/dateTimeToString';
import timeStringToDate from '../../../utils/date/timeStringToDate';
import StopEvents from '../StopEvents';
import withi18n from 'weego-common/src/hoc/i18n';

const TripTimeline = memo(function TripTimeline({
  trip,
  isDirty,
  onDemandAdd,
  onDemandDelete,
  onStopEdit,
  onTripDelete,
  t,
}) {
  const [addStopModalOpen, setAddStopModalOpen] = useState(false);
  const [pendingStopIndex, setPendingStopIndex] = useState(null);
  const [pendingDemand, setPendingDemand] = useState(null);

  const [editStopModalOpen, setEditStopModalOpen] = useState(false);
  const [openStop, setOpenStop] = useState(null);

  const [allDemands, setAllDemands] = useState([]);

  const openAddStopModal = useCallback(
    index => {
      setPendingStopIndex(index);
      setPendingDemand({
        id: ObjectID().toHexString(),
      });
      setAddStopModalOpen(true);
    },
    [setPendingStopIndex, setAddStopModalOpen, setPendingDemand],
  );

  const openEditStopModal = useCallback(
    (stop, index) => {
      setOpenStop(cloneDeep(stop));
      setEditStopModalOpen(true);
      setPendingStopIndex(index);
    },
    [setPendingStopIndex, setEditStopModalOpen, setOpenStop],
  );

  useEffect(() => {
    const allStops = [trip.from, ...(trip.stops || []), trip.to];
    const demandsById = keyBy(trip.demands, 'id');
    const updatedAllDemands = flatten(
      allStops.map((stop, stopIndex) => {
        if (!stop) {
          return [];
        }
        if (!stop.demandIds?.length) {
          return {
            id: `${stopIndex}-ad-hoc-demand`,
            departure: {
              name: stop.name,
              coords: stop.coords,
            },
            stop,
            stopIndex,
          };
        }
        return (
          stop?.demandIds?.map(demandId => {
            const demand = demandsById[demandId];
            if (!demand) {
              return {
                id: demandId,
                stop,
                stopIndex,
              };
            }
            return {
              ...demand,
              stop,
              stopIndex,
            };
          }) || []
        );
      }),
    );
    setAllDemands(updatedAllDemands);
  }, [trip]);

  return (
    <div>
      <Card className="trip-timeline">
        <Card.Content>
          <div style={styles.header}>
            <div style={styles.vehicle}>
              <Image style={styles.vehicleIcon} src={jamBusIcon} />
              <span style={styles.vehicleLabel}>
                {trip.vehicle?.carLabel || trip.vehicle?.carNumber}
                <br />
              </span>
              <div style={styles.dirtyIndicator}>
                {isDirty && <Icon color="orange" name="circle" />}
              </div>
            </div>
            <Button
              onClick={() => onTripDelete(trip)}
              icon={<ReactSVG src={deleteIcon} />}
              style={styles.deleteButton}
            />
          </div>

          <HighlightedText style={styles.driver}>
            <span style={styles.driverName}>
              {trip.driver ? trip.driver.firstname : t('Pas de conducteur')}
            </span>{' '}
            <span style={styles.driverPhone}>
              {trip.driver
                ? trip.driver.phone || t('Pas de téléphone')
                : t('Pas de conducteur')}
            </span>
          </HighlightedText>

          <Divider />

          <Card.Content>
            <Droppable droppableId={getTripInstanceKey(trip)}>
              {provided => {
                return (
                  <div
                    style={styles.droppable}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <StopDemandList
                      trip={trip}
                      demands={allDemands}
                      onAdd={openAddStopModal}
                      onEdit={openEditStopModal}
                      onDelete={onDemandDelete}
                      t={t}
                    />

                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </Card.Content>
        </Card.Content>
      </Card>
      <DialogBox
        open={addStopModalOpen}
        title={t('Ajouter une demande')}
        onClose={() => setAddStopModalOpen(false)}
        actions={
          <Button
            onClick={() => {
              if (
                !pendingDemand.passenger ||
                !pendingDemand.departure ||
                !pendingDemand.arrival
              ) {
                return;
              }
              onDemandAdd(
                trip,
                {
                  ...pendingDemand,
                  days: [moment(trip.departureTime).day()],
                  groups: pendingDemand.passenger.groups,
                  isAdhoc: true,
                },
                pendingStopIndex,
              );
              setAddStopModalOpen(false);
            }}
            primary
          >
            {t('Valider')}
          </Button>
        }
      >
        <Header.Subheader>
          {t('Entrez les informations de la demande')}
        </Header.Subheader>
        {addStopModalOpen && (
          <Form style={styles.addStopModalContent}>
            <PassengerSearch
              onSelect={passenger => {
                setPendingDemand({
                  ...pendingDemand,
                  passenger,
                  passengerId: passenger.id,
                });
              }}
              placeholder={t('Passager')}
            />
            <PlacesAutocompleteInput
              place={pendingDemand.departure}
              placeholder={t('Arrêt de départ')}
              onChange={place =>
                setPendingDemand({
                  ...pendingDemand,
                  departure: place,
                })
              }
              style={{ ...styles.stopInput, ...styles.stopInputLeftSpaced }}
            />
            <PlacesAutocompleteInput
              place={pendingDemand.arrival}
              placeholder={t("Arrêt d'arrivée")}
              onChange={place =>
                setPendingDemand({
                  ...pendingDemand,
                  arrival: place,
                })
              }
              style={{ ...styles.stopInput, ...styles.stopInputLeftSpaced }}
            />
          </Form>
        )}
      </DialogBox>
      <DialogBox
        open={editStopModalOpen}
        title={t('Modifier un arrêt')}
        onClose={() => setEditStopModalOpen(false)}
        actions={
          <Button
            onClick={() => {
              onStopEdit(trip, openStop, pendingStopIndex);
              setEditStopModalOpen(false);
            }}
            primary
          >
            {t('Valider')}
          </Button>
        }
      >
        <Header.Subheader>
          {t("Entrez les informations de l'arrêt")}
        </Header.Subheader>
        {editStopModalOpen && (
          <Form style={styles.editStopModalContent}>
            <PlacesAutocompleteInput
              place={openStop}
              onChange={place =>
                setOpenStop({
                  ...openStop,
                  ...place,
                })
              }
              style={{ ...styles.stopInput, ...styles.stopInputRightSpaced }}
            />
            <TimeInput
              value={dateToTimeString(openStop.maxDate)}
              onChange={(event, { value }) => {
                const time = timeStringToDate(value);
                setOpenStop({
                  ...openStop,
                  maxDate: time,
                });
              }}
              closable={true}
              clearable={true}
            />
          </Form>
        )}
      </DialogBox>
    </div>
  );
});
const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vehicle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  vehicleLabel: {
    color: colors.GREY_TEXT,
    paddingLeft: 10,
  },
  driver: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  driverName: {
    fontWeight: 'normal',
  },
  dirtyIndicator: {
    marginLeft: 5,
  },
  deleteButton: {
    padding: 9,
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  droppable: {
    minHeight: 100,
  },
  lateStop: {
    border: 'solid 1px red',
    borderRadius: 5,
    color: 'red',
  },
  stopHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  stopName: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  stopIcon: {
    marginRight: 5,
    marginLeft: 5,
  },
  stopTimeActions: {
    display: 'flex',
  },
  stopTime: {
    marginRight: 10,
  },
  dropdownTriggerIcon: {
    minWidth: 20,
    minHeight: 20,
  },
  passengerText: {
    fontWeight: 'normal',
  },
  passengerIcon: {
    marginRight: 5,
  },
  addStopModalContent: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 30,
  },
  editStopModalContent: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 30,
  },
  stopInput: {
    flex: 1,
  },
  stopInputLeftSpaced: {
    marginLeft: 10,
  },
  stopInputRightSpaced: {
    marginRight: 10,
  },
};

export default withi18n('dashboard')(TripTimeline);

const StopDemand = memo(function StopDemand({
  trip,
  demand,
  demandIndex,
  onAdd,
  onEdit,
  onDelete,
  t,
}) {
  const allStops = [trip.from, ...(trip.stops || []), trip.to];
  const stop = allStops[demand.stopIndex];
  if (!stop) {
    return null;
  }
  const departureTime = moment(trip.departureTime);
  const stopMaxDate = moment(stop.maxDate).set({
    date: departureTime.date(),
    month: departureTime.month(),
    year: departureTime.year(),
  });
  const isLateToStop =
    !trip.events?.find(
      e =>
        e.type === 'STOP_VISITED' && e.metadata?.stopIndex === demand.stopIndex,
    ) && moment().diff(stopMaxDate, 'minutes') > 10;
  return (
    <div>
      <Button
        fluid
        className="add-stop-button"
        basic
        onClick={() => onAdd(demand.stopIndex)}
      >
        +
      </Button>
      <Draggable
        key={`${getTripInstanceKey(trip)}::${demand.id}::${demand.stopIndex}`}
        draggableId={`${demand.id}::${demand.stopIndex}::${getTripInstanceKey(
          trip,
        )}`}
        index={demandIndex}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              ...(isLateToStop && styles.lateStop),
            }}
          >
            <Segment>
              <div style={styles.stopHeader}>
                <div style={styles.stopName}>
                  <Image style={styles.stopIcon} src={locationPinIcon} />
                  {stopLabel(demand.stop)}
                </div>
                <div style={styles.stopTimeActions}>
                  <div style={styles.stopTime}>
                    {moment(demand.stop.maxDate).format('HH:mm')}
                  </div>
                  <Dropdown
                    trigger={
                      <Image
                        style={styles.dropdownTriggerIcon}
                        src={threeDotMenuIcon}
                      />
                    }
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => onEdit(demand.stop, demand.stopIndex)}
                      >
                        {t('Modifier')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => onDelete(trip, demand)}>
                        {t('Supprimer')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {demand && (demand.passenger || demand.passengerCount) && (
                <HighlightedText style={styles.passengerText}>
                  <Image style={styles.passengerIcon} src={userIcon} />
                  {demandLabel(demand, { stop })}
                </HighlightedText>
              )}
              <StopEvents
                trip={trip}
                stop={stop}
                events={(trip.events || []).filter(
                  e =>
                    e.metadata?.stopIndex === demand.stopIndex &&
                    (!e.metadata?.passengerId ||
                      e.metadata?.passengerId === demand.passengerId),
                )}
              />
            </Segment>
          </div>
        )}
      </Draggable>
    </div>
  );
});

const StopDemandList = React.memo(function StopDemandList({
  demands,
  trip,
  onAdd,
  onEdit,
  onDelete,
  t,
}) {
  return demands.map((demand, demandIndex) => (
    <StopDemand
      key={`${getTripInstanceKey(trip)}::${demand.id}::${demand.stopIndex}`}
      trip={trip}
      demand={demand}
      demandIndex={demandIndex}
      onAdd={onAdd}
      onEdit={onEdit}
      onDelete={onDelete}
      t={t}
    />
  ));
});

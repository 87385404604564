import React, { memo, useRef, useState } from 'react';
import { Button, Image, Input, List, Message } from 'semantic-ui-react';
import without from 'lodash/without';
import PropTypes from 'prop-types';

import fileIcon from '../../../assets/images/file-icon.svg';
import accountPropType from '../../auth/accountPropType';
import withi18n from 'weego-common/src/hoc/i18n';

const FilesManager = memo(function FilesManager({
  files = [],
  disabled,
  upload,
  onChange,
  t,
}) {
  const fileInputRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [error, setError] = useState(null);

  const uploadFiles = async filesToUpload => {
    try {
      setError(null);
      filesToUpload.forEach(file => {
        const THREE_MEGABYTES = 3 * 1000 * 1000;
        if (file.size > THREE_MEGABYTES) {
          throw new Error(
            t(`La taille du fichier {{fileName}} dépasse la limite de 3 Mo.`, {
              fileName: file.name,
            }),
          );
        }
      });
      setPendingFiles(pendingFiles.concat(filesToUpload));
      fileInputRef.current.inputRef.current.value = '';
      const uploadedFiles = await Promise.all(
        filesToUpload.map(file => upload(file)),
      );
      setPendingFiles([]);
      onChange(files.concat(uploadedFiles));
    } catch (error) {
      setError(error);
      setPendingFiles([]);
    }
  };

  const removeFile = file => {
    onChange(without(files, file));
  };

  return (
    <div>
      {error && <Message error>{error.message}</Message>}
      <Input
        type="File"
        fluid
        multiple
        placeholder={t('Aucun fichier sélectionné')}
        ref={fileInputRef}
        action={{
          primary: true,
          inverted: true,
          children: t('Importer fichier'),
          disabled: disabled || !selectedFiles || !selectedFiles.length,
          onClick: () => {
            uploadFiles(selectedFiles);
          },
        }}
        disabled={disabled}
        onChange={e => {
          if (e.target.files.length >= 1) {
            setSelectedFiles(Array.from(e.target.files));
          }
        }}
      />
      <List>
        {files.map(file => (
          <List.Item key={file.url}>
            <Image src={fileIcon} />
            <List.Content>
              <a
                style={styles.fileItemLink}
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.name}
              </a>
            </List.Content>
            <List.Content floated="right">
              <Button
                size="tiny"
                basic
                color="red"
                disabled={disabled}
                onClick={() => removeFile(file)}
              >
                {t('Supprimer')}
              </Button>
            </List.Content>
          </List.Item>
        ))}
        {pendingFiles.map(file => (
          <List.Item key={file.name}>
            <List.Icon name="circle notch" loading />
            <List.Content>{file.name}</List.Content>
          </List.Item>
        ))}
      </List>
    </div>
  );
});

const styles = {
  fileItemLink: { color: 'black' },
};

FilesManager.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  account: accountPropType,
  onChange: PropTypes.func.isRequired,
};

export default withi18n('common')(FilesManager);

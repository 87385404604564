import { handleActions } from 'redux-actions';
import isUndefined from 'lodash/isUndefined';

import commonActions from '../../actions/common';

const DEFAULT_STATE = {
  drawerOpened: false,
};

export default handleActions(
  {
    [commonActions.toggleDrawer]: (state, action) => ({
      ...state,
      drawerOpened: !isUndefined(action.payload.opened)
        ? action.payload.opened
        : !state.drawerOpened,
    }),
  },
  DEFAULT_STATE,
);

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import GroupsManager from '../../../components/groups/GroupsManager';

class GroupsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Groupes');
  }

  render() {
    return <GroupsManager />;
  }
}

export default withi18n('menu')(GroupsScreen);

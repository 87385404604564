import moment from 'moment';

import sunubusClient from '../providers/weego-client';

async function getTripPath(trip) {
  const departureTime = moment(trip.departureTime);
  return await sunubusClient.tracking.getDirections({
    origin: `${trip.from.coords.lat},${trip.from.coords.lng}`,
    destination: `${trip.to.coords.lat},${trip.to.coords.lng}`,
    waypoints: (trip.stops || [])
      .map(stop => `${stop.coords.lat},${stop.coords.lng}`)
      .join('|'),
    travelMode: 'DRIVING',
    drivingOptions: {
      departureTime: departureTime.toDate(),
      trafficModel: 'bestguess',
    },
  });
}

export default getTripPath;

import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import driversActionCreators, { actionTypes } from '../../actions/drivers';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const driversSaga =
  decodedToken && decodedToken.company
    ? makeCrudSaga(
        'drivers',
        actionTypes,
        driversActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/Drivers`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: true,
          key: 'id',
        },
      )
    : () => {};

export default driversSaga;

import moment from 'moment-timezone';

function getNextDateOfDay(day, fromDate = new Date()) {
  const nextDate = moment(fromDate).set('day', day);
  if (nextDate.isBefore(fromDate)) {
    nextDate.add(1, 'week');
  }
  return nextDate.startOf('day').toDate();
}

export default getNextDateOfDay;

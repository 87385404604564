import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import AlertsPortal from './AlertsPortal';
import alertActions from '../../../actions/alert';

const mapStateToProps = state => ({
  openedAlert: state.alert.openedAlert,
});

const mapDispatchToProps = dispatch => ({
  hideAlert: compose(dispatch, alertActions.hide),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPortal);

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import CustomFieldsManager from '../../../components/trips/CustomFieldsManager';

class GroupsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Champs');
  }

  render() {
    return <CustomFieldsManager />;
  }
}

export default withi18n('menu')(GroupsScreen);

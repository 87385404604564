import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';

function tripPassengerCount(trip) {
  if (!trip.demands?.length) {
    return trip.demandIds.length;
  }
  return sum(
    uniqBy(trip.demands, 'id').map(demand =>
      demand.passengerCount ? demand.passengerCount : 1,
    ),
  );
}

export default tripPassengerCount;

import { connect } from 'react-redux';
import memoize from 'memoize-one';
import toArray from 'lodash/toArray';

import stopsActions from '../../../actions/stops';
import PlacesAutocompleteInput from './PlacesAutocompleteInput';

const stopsToCustomPlaces = memoize(stops => toArray(stops));

const mapStateToProps = state => ({
  customPlaceResults: stopsToCustomPlaces(state.stops),
});

const mapDispatchToProps = dispatch => ({
  searchCustomPlaces: term => {
    dispatch(
      stopsActions.fetchStart({
        replace: true,
        query: {
          filter: {
            where: {
              name: {
                regexp: `/${term}/i`,
              },
            },
          },
        },
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlacesAutocompleteInput);

import { connect } from 'react-redux';
import { compose } from 'redux';
import tripEventsActions from '../../../actions/tripEvents';
import TripIncidentReportForm from './TripIncidentReportForm';

const mapDispatchToProps = dispatch => ({
  createTripEvent: compose(dispatch, tripEventsActions.createStart),
});

export default connect(null, mapDispatchToProps)(TripIncidentReportForm);

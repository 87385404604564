import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { lifecycle } from 'recompose';
import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import memoize from 'memoize-one';
import ObjectID from 'bson-objectid';

import withi18n from 'weego-common/src/hoc/i18n';
import tryJsonParse from 'weego-common/src/utils/tryJsonParse';
import momentTz from 'weego-common/src/providers/moment';

import CRUDManager from '../common/CRUDManager';
import transactionsActions from '../../actions/transactions';
import accountsActions from '../../actions/accounts';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import dateToString from '../../utils/date/dateToString';
import { DatesRangeInput } from 'semantic-ui-calendar-react';

const fields = [
  {
    key: 'id',
    label: 'Identifiant',
    type: 'STRING',
  },
  {
    label: 'Carte',
    type: 'STRING',
    render: ({ record: transaction }) => {
      const metadata = tryJsonParse(transaction.metadata);
      return metadata?.identifier ? metadata?.identifier : 'N/A';
    },
  },
  {
    key: 'type',
    label: 'Type',
    type: 'ENUM',
    options: [
      {
        value: 'REFILL_PREPAID_CARD',
        label: 'Recharge',
      },
      {
        value: 'THIRD_PARTY_SUBSCRIPTION',
        label: 'Renouvellement',
      },
    ],
  },
  {
    key: 'amount',
    label: 'Montant',
    type: 'NUMBER',
    render: ({ record: transaction }) => {
      return (
        transaction.amount ||
        tryJsonParse(transaction.metadata)?.busLine?.ticketPrice?.value
      );
    },
  },
  {
    key: 'createdAt',
    label: 'Date',
    type: 'DATETIME',
    render({ record }) {
      if (record.type === 'TICKETING') {
        const metadata = tryJsonParse(record.metadata);
        if (metadata?.departureTime) {
          return dateToString(metadata.departureTime);
        }
      }
    },
  },
  {
    label: 'Extra',
    type: 'STRING',
    render: ({ record: transaction }) => {
      const metadata = tryJsonParse(transaction.metadata);
      return (metadata?.periods || []).map(p => p.mois_reel).join(',') || '';
    },
  },
];
const resourceLabel = 'transaction';

const getRelations = memoize(accounts => ({ accounts }));

const style = { height: 'calc(100% - 80px)' };

const mapStateToProps = state => ({
  recordKey: 'id',
  records: state.transactions,
  relations: getRelations(state.accounts),
  resourceLabel,
  fields,
  style,
  ...state.transactionsMeta,
  error: state.transactionsMeta.error,
  canEdit: false,
  canRemove: false,
  canExport: true,
  canAdd: false,
});

const mapDispatchToProps = dispatch => ({
  fetchStart(payload) {
    if (payload.search) {
      dispatch(
        accountsActions.fetchStart({
          search: payload.search,
          callback: searchResultAccounts => {
            const accountIds = searchResultAccounts.map(account => account._id);
            dispatch(
              transactionsActions.fetchStart(
                merge({}, omit(payload, 'search'), {
                  where: {
                    status: payload.where?.status || {
                      inq: ['SETTLED', 'ERRORED', 'REFUNDED'],
                    },
                    type: {
                      inq: ['REFILL_PREPAID_CARD', 'THIRD_PARTY_SUBSCRIPTION'],
                    },
                    or: [
                      {
                        '$from.userId$': {
                          inq: accountIds,
                        },
                      },
                      {
                        '$to.userId$': {
                          inq: accountIds,
                        },
                      },
                    ],
                  },
                  query: {
                    filter: {
                      include: ['from', 'to'],
                    },
                  },
                }),
              ),
            );
          },
        }),
      );
    } else {
      dispatch(
        transactionsActions.fetchStart(
          merge({}, payload, {
            where: {
              status: payload.where?.status || {
                inq: ['SETTLED', 'ERRORED', 'REFUNDED'],
              },
              type: {
                inq: payload?.where?.type?.inq || [
                  'REFILL_PREPAID_CARD',
                  'THIRD_PARTY_SUBSCRIPTION',
                ],
              },
              // and: [
              //   {
              //     createdAt: {
              //       lte:
              //         payload?.where?.createdAt?.lte ||
              //         momentTz().add(5, 'day').toDate(),
              //     },
              //   },
              //   {
              //     createdAt: {
              //       gte:
              //         payload?.where?.createdAt?.gte ||
              //         momentTz().subtract(5, 'day').toDate(),
              //     },
              //   },
              // ],
            },
            query: {
              filter: {
                include: { all: true, nested: true },
              },
            },
          }),
        ),
      );
    }
  },
  createStart: compose(dispatch, transactionsActions.createStart),
  updateStart: compose(dispatch, transactionsActions.updateStart),
  updateSuccess: compose(dispatch, transactionsActions.updateSuccess),
  deleteStart: compose(dispatch, transactionsActions.deleteStart),
  fetchAccounts: compose(dispatch, accountsActions.fetchStart),
});

const enhance = compose(
  withi18n('transactions'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.records !== prevProps.records) {
        const transactions = this.props.records;
        const userIds = uniq(
          flatten(map(transactions, t => [t.from?.userId, t.to?.userId]))
            .filter(v => !!v)
            .filter(v => ObjectID.isValid(v)),
        );
        this.props.fetchAccounts({
          where: {
            _id: {
              inq: userIds,
            },
          },
        });
      }
    },
  }),
);

const FoughalTransactionsManager = ({
  records,
  fetchStart,
  createStart,
  updateSuccess,
  creating,
  createError,
  t,
  fields,
  ...props
}) => {
  const [currentOrder, setCurrentOrder] = useState(null);

  const fieldsWithMetadataField = useMemo(() => {
    return fields;
  }, [fields]);

  const wasCreating = useRef(creating);
  // Transactions records need to have a custom sort because of redux-crud default store sorting
  const localSortedRecords = useMemo(
    () =>
      currentOrder
        ? currentOrder.includes('DESC')
          ? reverse(sortBy(records, currentOrder.split(' ')[0]))
          : sortBy(records, currentOrder.split(' ')[0])
        : records,
    [currentOrder, records],
  );

  useEffect(() => {
    wasCreating.current = creating;
  }, [creating]);

  const [datesRange, setDatesRange] = useState(
    `${momentTz().startOf('month').format('YYYY-MM-DD')}-${momentTz()
      .endOf('month')
      .format('YYYY-MM-DD')}`,
  );
  const [datesRangeDate, setDatesRangeDate] = useState({
    start: momentTz().startOf('month').toDate(),
    end: momentTz().endOf('month').toDate(),
  });

  const handleFromTo = (e, { name, value }) => {
    setDatesRange(value);
    const range = value;
    const dates = range.split(' - ');
    if (dates.length === 2 && !!dates[1]) {
      const start = momentTz(dates[0], 'YYYY-MM-DD').startOf('day').toDate();
      const end = momentTz(dates[1], 'YYYY-MM-DD').endOf('day').toDate();
      setDatesRangeDate({ start, end });
    }
  };

  return (
    <div style={style}>
      <CRUDManager
        recordActions={[]}
        records={localSortedRecords}
        dateRangeFilter={datesRangeDate}
        fetchStart={payload => {
          const payloadWithDefaultOrder = {
            ...payload,
            order: payload.order || 'createdAt DESC',
          };
          setCurrentOrder(payloadWithDefaultOrder.order);
          fetchStart(payloadWithDefaultOrder);
        }}
        fields={fieldsWithMetadataField}
        createStart={() => {}}
        creating={false}
        renderAfterFilters={() => {
          return (
            <DatesRangeInput
              allowSameEndDate
              dateFormat="YYYY-MM-DD"
              iconPosition="left"
              value={datesRange}
              placeholder="x- To"
              onChange={handleFromTo}
              name="fromTo"
            />
          );
        }}
        {...props}
      />
    </div>
  );
};

export default enhance(FoughalTransactionsManager);

import { connect } from 'react-redux';
import { compose } from 'recompose';

import withFileHandlers from '../../../hoc/withFileHandlers';
import demandsActions from '../../../actions/demands';
import groupsActions from '../../../actions/groups';

import TravelRequirementsManager from './TravelRequirementsManager';

const mapStateToProps = state => ({
  demands: state.demands,
  groups: state.groups,
  account: state.auth.account,
});

const mapDispatchToProps = dispatch => ({
  requestDemands: compose(dispatch, demandsActions.fetchStart),
  requestGroups: compose(dispatch, groupsActions.fetchStart),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFileHandlers(),
);

export default enhance(TravelRequirementsManager);

import React, { memo } from 'react';
import moment from 'moment';
import { Table, Segment, Image, Input, Checkbox } from 'semantic-ui-react';
import memoize from 'memoize-one';
import map from 'lodash/map';
import find from 'lodash/find';
import without from 'lodash/without';
import PropTypes from 'prop-types';

import colors from '../../../theme/colors.json';

import userIcon from '../../../assets/images/user-icon.svg';
import locationPinIconRed from '../../../assets/images/location-pin-red.svg';
import locationPinIconGreen from '../../../assets/images/location-pin-green.svg';

import useSearch from '../../../hooks/useSearch';
import tripPassengerCount from '../../../utils/tripPassengerCount';
import demandLabel from '../../../utils/stopDemandLabel';
import tripPropType from '../tripPropType';
import demandPropType from '../demandPropType';
import withi18n from 'weego-common/src/hoc/i18n';

const FUSE_OPTIONS = {
  keys: [
    'passenger.firstName',
    'passenger.lastName',
    'passenger.phoneNumber',
    'stops.name',
  ],
  threshold: 0.2,
  distance: 500,
};

const getAllStops = memoize(trip => [
  trip.from,
  ...(trip.stops || []),
  trip.to,
]);

const getDemandsWithSearchProperties = memoize((demands, stops) =>
  map(demands, demand => ({
    ...demand,
    passenger: {
      ...demand.passenger,
      fullName: demand.passenger?.firstName + ' ' + demand.passenger?.lastName,
    },
    stops: stops.filter(stop => stop.demandIds?.includes(demand.id)),
  })),
);

const PassengersList = memo(function PassengersList({
  trip,
  demands,
  displayMode = 'multiStop',
  selected = [],
  onSelectionChange,
  style,
  t,
}) {
  const allStops = getAllStops(trip);
  const tripType = trip.from.demandIds?.find(
    demandId => demands[demandId]?.departureTime,
  )
    ? 'backward'
    : 'forward';

  const [demandsSearchTerm, demandsSearchResults, searchDemands] = useSearch(
    getDemandsWithSearchProperties(demands, allStops),
    FUSE_OPTIONS,
  );

  return (
    <div style={{ ...styles.passengersContainer, ...style }}>
      <div style={styles.header}>
        <span style={styles.count}>
          <strong>{tripPassengerCount(trip)}</strong> passagers
        </span>
        <Input
          icon="search"
          iconPosition="left"
          placeholder={t('Rechercher')}
          value={demandsSearchTerm}
          onChange={(e, { value }) => searchDemands(value)}
        />
      </div>
      <Segment style={styles.segment}>
        <Table style={styles.table}>
          <Table.Body>
            {allStops.map(stop =>
              stop.demandIds?.map(demandId => {
                if (displayMode === 'singleStop') {
                  // For a forwad trip, in single stop mode
                  // we don't display the dropoff stops of the passengers
                  if (tripType === 'forward' && stop.demand < 0) {
                    return null;
                  }
                  // For a backward trip, in single stop mode
                  // we don't display the pickup stops of the passengers
                  if (tripType === 'backward' && stop.demand > 0) {
                    return null;
                  }
                }
                const demand = demands[demandId];
                if (!demand) {
                  return null;
                }
                if (!find(demandsSearchResults, { id: demand.id })) {
                  return null;
                }
                return (
                  <Table.Row key={demand.id}>
                    <Table.Cell>
                      <div style={styles.tableCell}>
                        <Image src={userIcon} style={styles.tableIcon} inline />
                        {demandLabel(demand, { stop })}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={styles.tableCell}>
                        {stop.demand > 0 ? (
                          <Image
                            src={locationPinIconGreen}
                            style={styles.tableIcon}
                            inline
                          />
                        ) : (
                          <Image
                            src={locationPinIconRed}
                            style={styles.tableIcon}
                            inline
                          />
                        )}
                        {stop?.name}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {stop?.maxDate
                        ? moment(stop?.maxDate).format('HH:mm')
                        : t('Inconnu')}
                    </Table.Cell>
                    <Table.Cell>
                      {demand.passenger?.phoneNumber ?? 'N/A'}
                    </Table.Cell>
                    {onSelectionChange && (
                      <Table.Cell>
                        <Checkbox
                          checked={selected.includes(demand.passenger)}
                          onChange={(e, { checked }) => {
                            checked
                              ? onSelectionChange(
                                  (selected || []).concat(demand.passenger),
                                )
                              : onSelectionChange(
                                  without(selected, demand.passenger),
                                );
                          }}
                        />
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              }),
            )}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
});

const styles = {
  segment: {
    height: '100%',
    overflow: 'auto',
  },
  table: {
    color: colors.GREY_TEXT,
    fontSize: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  count: {
    fontSize: 16,
    color: colors.GREY_TEXT,
  },
  tableIcon: {
    marginRight: 10,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
  },
};

PassengersList.propTypes = {
  trip: tripPropType,
  demands: PropTypes.objectOf(demandPropType),
};

export default withi18n('trips')(PassengersList);

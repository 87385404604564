import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import isString from 'lodash/isString';

const withFileHandlers = options => WrappedComponent => props => {
  const [openedFile, setOpenedFile] = useState(null);
  const [openedFileOptions, setOpenedFileOptions] = useState({ type: 'text' });

  const [fileInputRef] = useState(React.createRef());

  useEffect(() => {
    if (!fileInputRef.current) {
      return;
    }
    if (!openedFileOptions) {
      return;
    }
    fileInputRef.current.setAttribute(
      'accept',
      openedFileOptions.accept || `${openedFileOptions.type}/*`,
    );
  }, [openedFileOptions, fileInputRef]);

  const saveLocalFile = (data, options) => {
    let blob;
    if (isString(data)) {
      blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    } else if (data instanceof Blob) {
      blob = data;
    } else {
      throw new Error('Local file save only accepts strings or blobs');
    }
    FileSaver.saveAs(blob, options.name);
  };

  const openLocalFile = options => {
    setOpenedFileOptions(options);
    setTimeout(() => {
      fileInputRef.current.click();
    });
  };

  const onFileOpened = file => {
    if (openedFileOptions.type === 'text') {
      const reader = new FileReader();
      // Read file into memory as UTF-8
      reader.readAsText(file, 'UTF-8');
      reader.onload = event => {
        setOpenedFile({ name: file.name, text: event.target.result });
      };
    } else {
      setOpenedFile(file);
    }
    fileInputRef.current.value = null;
  };

  return (
    <React.Fragment>
      <WrappedComponent
        {...props}
        openedFile={openedFile}
        saveLocalFile={saveLocalFile}
        openLocalFile={openLocalFile}
      />
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={e => {
          if (e.target.files.length >= 1) {
            onFileOpened(e.target.files[0]);
          }
        }}
      />
    </React.Fragment>
  );
};

export default withFileHandlers;

import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

import TransactionsManager from '../../../components/transactions/TransactionsManager';

class TransactionsScreen extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t('Transactions');
  }

  render() {
    return <TransactionsManager />;
  }
}

export default withi18n('menu')(TransactionsScreen);

import jwt from 'jsonwebtoken';

import makeCrudSaga from 'weego-common/src/utils/makeCrudSaga';
import vehiclesActionCreators, { actionTypes } from '../../actions/vehicles';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

const vehiclesSaga =
  tokens && tokens.ACCOUNT_VERIFICATION && decodedToken?.company
    ? makeCrudSaga(
        'vehicles',
        actionTypes,
        vehiclesActionCreators,
        process.env.REACT_APP_SUNUBUS_SERVICES_URL + `/ride-booking/Vehicles`,
        {
          token: tokens.ACCOUNT_VERIFICATION.token,
          useRootResponse: true,
          key: 'id',
        },
      )
    : () => {};

export default vehiclesSaga;

import { connect } from 'react-redux';
import { compose } from 'redux';

import withi18n from 'weego-common/src/hoc/i18n';

import notificationActions from '../../../actions/notification';

import TripNotifications from './TripNotifications';

const mapStateToProps = state => ({
  sending: state.notification.loading,
  error: state.notification.error,
});

const mapDispatchToProps = dispatch => ({
  sendNotification: compose(dispatch, notificationActions.sendNotification),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withi18n('notifications'),
)(TripNotifications);

import i18n from 'i18n-js';

import fr from 'weego-common/lang/fr';
import ar from 'weego-common/lang/ar';
import en from 'weego-common/lang/en';

i18n.locale = navigator.language;
i18n.fallbacks = true;
i18n.translations = {fr, ar, en};
i18n.defaultSeparator = '~';

export default i18n;

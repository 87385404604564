import { connect } from 'react-redux';
import compose from 'lodash/flowRight';

import AutomaticPlannerScreen from './LegacyAutomaticPlannerScreen';
import dashbordActions from '../../../actions/dashboard';

const mapStateToProps = state => ({
  focusedTrips: state.dashboard.focusedTrips,
});

const mapDispatchToProps = dispatch => ({
  focusTrips: compose(dispatch, dashbordActions.focusTrips),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutomaticPlannerScreen);
